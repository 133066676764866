import { Icon, Table } from '@ats/ui';
import SortIconAscending from '@components/common/icons/SortIconAscending';
import SortIconDescending from '@components/common/icons/SortIconDescending';
import { SORT_TYPE } from '@constants/evaluation.constant.ts';
import { useGridHeadCol } from '@hooks/evaluation/grid-screening/useGridHeadCol';
import type { Header } from '@repositories/evaluation/document/document.dto';
import * as S from '../gridHeader.style';

interface GridHeadColProps {
  header: Header;
  colIndex: number;
}

export const GridHeadCol = ({ header, colIndex }: GridHeadColProps) => {
  const {
    screeningNoticeSn,
    updateRef,
    theme,
    freezePanesIndex,
    handleClickFreezePanesIndex,
    hovering,
    handleHoverIn,
    handleHoverOut,
    freezeStyle,
    gridSortingColumnAndType,
    handleClickSorting,
  } = useGridHeadCol(header, colIndex);

  const renderSortingIcon = () => {
    if (
      header.code &&
      gridSortingColumnAndType[header.code] === SORT_TYPE.ASC
    ) {
      return <SortIconAscending fill={`${theme.colors['primary-600']}`} />;
    }

    if (
      header.code &&
      gridSortingColumnAndType[header.code] === SORT_TYPE.DESC
    ) {
      return <SortIconDescending fill={`${theme.colors['primary-600']}`} />;
    }

    return <Icon name='icon_sort_18_filled' />;
  };

  return (
    <Table.Cell
      key={screeningNoticeSn}
      ref={updateRef}
      style={{ ...freezeStyle, minWidth: '120px' }}
      onMouseOver={handleHoverIn}
      onMouseOut={handleHoverOut}
      as='th'
    >
      <S.HeadCellInner>
        <span>{header.title}</span>
        <S.IconWrapper>
          {/* 열 고정 */}
          <button
            onClick={handleClickFreezePanesIndex}
            style={{ minWidth: '18px' }}
          >
            {hovering && freezePanesIndex !== colIndex ? (
              <Icon name='icon_stick_18_filled' />
            ) : (
              <Icon
                name='icon_stick_18_filled'
                color={
                  freezePanesIndex === colIndex
                    ? theme.colors['primary-600']
                    : 'transparent'
                }
              />
            )}
          </button>
          {/* 오름차순/내림차순 */}
          {header.orderYn && (
            <button style={{ minWidth: '18px' }} onClick={handleClickSorting}>
              {header.orderYn && renderSortingIcon()}
            </button>
          )}
        </S.IconWrapper>
      </S.HeadCellInner>
    </Table.Cell>
  );
};
