import { Checkbox, Dropdown, Icon, Portal, Table, useDropdown } from '@ats/ui';
import {
  currentPageScreeningValuerSnListAtom,
  gridWidthAtom,
  isAllPageCheckAtom,
  screeningNoticeSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentPageScreeningValuerSnListAllAtom } from '../../../../../../stores/atoms/evaluation/document.atom';
import * as S from '../gridHeader.style';

export const GridHeadColCheckbox = () => {
  const {
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
    isOpen,
  } = useDropdown();

  const currentPageScreeningValuerSnListAll = useRecoilValue(
    currentPageScreeningValuerSnListAllAtom,
  );
  const [
    currentPageScreeningValuerSnList,
    setCurrentPageScreeningValuerSnList,
  ] = useRecoilState(currentPageScreeningValuerSnListAtom);

  const [allPageCheck, setAllPageCheck] = useRecoilState(isAllPageCheckAtom);
  const handleClickAllPageCheck = () => {
    setAllPageCheck((prev) => !prev);
    handleCloseDropdown();
  };
  const handleClickCurrentPageAllCheck = () => {
    handleCloseDropdown();
    if (
      currentPageScreeningValuerSnList === currentPageScreeningValuerSnListAll
    ) {
      setCurrentPageScreeningValuerSnList([]);
      return;
    }
    setCurrentPageScreeningValuerSnList(currentPageScreeningValuerSnListAll);
  };

  const [ref, setRef] = useState<HTMLElement>();
  const screeningNoticeSn = useRecoilValue(screeningNoticeSnAtom);
  const setGridWidthStore = useSetRecoilState(gridWidthAtom);

  useEffect(() => {
    if (ref) {
      setGridWidthStore({ [0]: ref.getBoundingClientRect().width });
    }
  }, [screeningNoticeSn, ref, setGridWidthStore]);

  const [top, setTop] = useState(0);
  useEffect(() => {
    if (dropdownContainerRef.current) {
      const dropdownRect = dropdownContainerRef.current.getBoundingClientRect();
      const TRIGGER_HEIGHT = 20;

      setTop(dropdownRect.top + TRIGGER_HEIGHT);
    }
  }, [isOpen]);

  return (
    <Table.Cell
      style={{ position: 'sticky', left: 0 }}
      ref={(newRef: HTMLElement) => setRef(newRef)}
      as='th'
    >
      <S.HeadCellCheckboxInner>
        <Checkbox
          style={{ gap: 0 }}
          onChange={handleClickCurrentPageAllCheck}
          indeterminate={
            currentPageScreeningValuerSnList.length > 0 &&
            currentPageScreeningValuerSnList.length !==
              currentPageScreeningValuerSnListAll.length
          }
          checked={
            currentPageScreeningValuerSnList.length ===
              currentPageScreeningValuerSnListAll.length || allPageCheck
          }
          disabled={allPageCheck}
        />
        <Dropdown.Container ref={dropdownContainerRef}>
          <Dropdown.Trigger
            onClick={handleToggleDropdown}
            style={{ padding: 0, background: 'transparent', width: '18px' }}
          >
            <Icon name='icon_arrow_bottom_18_line' />
          </Dropdown.Trigger>
          <Portal selector={'#dropdown-root'}>
            <Dropdown.ItemContainer
              isOpen={isOpen}
              style={{ left: '10px', width: 'auto', zIndex: 3, top: top }}
            >
              <Dropdown.ButtonItem onClick={handleClickAllPageCheck}>
                {allPageCheck ? '전체 선택 해제' : '전체 선택'}
              </Dropdown.ButtonItem>
              <Dropdown.ButtonItem onClick={handleClickCurrentPageAllCheck}>
                {currentPageScreeningValuerSnList ===
                currentPageScreeningValuerSnListAll
                  ? '현재 페이지만 선택 해제'
                  : '현재 페이지만 선택'}
              </Dropdown.ButtonItem>
            </Dropdown.ItemContainer>
          </Portal>
        </Dropdown.Container>
      </S.HeadCellCheckboxInner>
    </Table.Cell>
  );
};
