import styled from '@emotion/styled';

export const EmptyCurrentCheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ theme }) => theme.body.b2}

  height: 100%;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const Span = styled.span`
  ${({ theme }) => theme.body.b2};
`;

export const Tooltip = styled.div`
  background-color: ${({ theme }) => theme.colors['gray-800']};
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
`;
