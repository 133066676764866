import { documentKeys } from '@constants/key.query';
import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { DocumentGridRqDTO, DocumentGridRsDTO } from './document.dto';
import { DocumentRepository } from './document.repository';

const documentRepository = new DocumentRepository('');

export const useScreeningDocumentGridQuery = (
  request: DocumentGridRqDTO,
): UseSuspenseQueryResult<DocumentGridRsDTO, AxiosError> => {
  const { page, size } = request;
  const {
    screeningSn,
    keyword,
    recruitNoticeSn,
    recruitSectorSn,
    priorityList,
    screeningValuerReadStatusList,
    remarkableApplicantCodeList,
    evaluationItemRatingScales,
    screeningResultCodeList,
    managerFileUploadStatusList,
    sortColumn,
    sortType,
  } = request.evaluationGridDefaultCondition;

  return useSuspenseQuery({
    queryKey: [
      documentKeys.evaluateDocumentGrid,
      keyword,
      screeningSn,
      recruitSectorSn,
      priorityList,
      screeningValuerReadStatusList,
      remarkableApplicantCodeList,
      evaluationItemRatingScales,
      screeningResultCodeList,
      managerFileUploadStatusList,
      sortColumn,
      sortType,
      page,
      size,
    ],
    queryFn: () =>
      documentRepository.getDocumentDataGrid({
        evaluationGridDefaultCondition: {
          screeningSn,
          keyword,
          recruitNoticeSn,
          recruitSectorSn,
          priorityList,
          screeningValuerReadStatusList,
          remarkableApplicantCodeList,
          evaluationItemRatingScales,
          screeningResultCodeList,
          managerFileUploadStatusList,
          sortColumn,
          sortType,
        },
        page,
        size,
      }),
    retry: true,
  });
};
