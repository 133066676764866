import styled from '@emotion/styled';

export const Container = styled.aside`
  position: sticky;
  top: 0px;
`;
export const Nav = styled.nav`
  display: flex;
  gap: 8px;
  margin-bottom: ${({ theme }) => theme.spacing.space400};
`;

export const SidebarList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.space100};
`;
