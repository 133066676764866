import { convertToFormData } from '@helpers/formData.util';
import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type {
  EvaluationStepListRqDTO,
  EvaluationStepListRsDTO,
  ValuerSecurityOathRqDTO,
} from './evaluation.dto';
import { evaluationStepListRepository } from './evaluation.repository';

export const EvaluationQueryKey = {
  securityOath: (request: ValuerSecurityOathRqDTO) => [
    'useValuerSecurityOathQuery',
    { request },
  ],
};

export const useEvaluationStepListQuery = (
  request: EvaluationStepListRqDTO,
): UseSuspenseQueryResult<EvaluationStepListRsDTO, AxiosError> =>
  useSuspenseQuery({
    queryKey: ['evaluationStepList', request],
    queryFn: () =>
      evaluationStepListRepository.getEvaluationStepList(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useICalUrlQuery = () =>
  useSuspenseQuery({
    queryKey: ['iCalUrl'],
    queryFn: evaluationStepListRepository.getICalUrl,
  });

export const useValuerSecurityOathQuery = (request: ValuerSecurityOathRqDTO) =>
  useSuspenseQuery({
    queryKey: EvaluationQueryKey.securityOath(request),
    queryFn: () =>
      evaluationStepListRepository.getValuerSecurityOath(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useValuerSecurityOathMutation = () =>
  useMutation({
    mutationFn: (request: ValuerSecurityOathRqDTO) =>
      evaluationStepListRepository.saveValuerSecurityOath(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });
