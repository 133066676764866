import {
  DROPDOWN_BUTTON_ITEM_TYPE,
  DROPDOWN_POSITION,
  Dropdown,
  Icon,
  SIZE,
  useDropdown,
} from '@ats/ui';
import type { SCREENING_RESULT_CODE } from '@constants/evaluation.constant';
import { finalEvalMapping } from '@constants/evaluation.constant';
import { useTheme } from '@emotion/react';
import { useUpdateFinalEvaluationQuery } from '@repositories/evaluation/common/common.query';
import {
  resultCodeMappingsAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as S from './gridBodyCol.style';

interface GridBodyColFinalEvalProps {
  valuerSn: number;
  applicantFinalEval: SCREENING_RESULT_CODE;
  handleClickToggleReadStatus: (modifyYn: boolean) => void;
}

export const GridBodyColFinalEval = ({
  valuerSn,
  applicantFinalEval,
  handleClickToggleReadStatus,
}: GridBodyColFinalEvalProps) => {
  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  const theme = useTheme();

  const [finalEval, setFinalEval] =
    useState<SCREENING_RESULT_CODE>(applicantFinalEval);
  const [confirmFinalEval, setConfirmFinalEval] =
    useState<SCREENING_RESULT_CODE>(applicantFinalEval);
  const handleChangeFinalEval = (e: ChangeEvent<HTMLInputElement>) => {
    setFinalEval(e.target.value as SCREENING_RESULT_CODE);
  };

  const { mutate: updateFinalEvaluationMutate } =
    useUpdateFinalEvaluationQuery();
  const screeningSn = useRecoilValue(screeningSnAtom);
  const handleClickFinalEvalConfirm = () => {
    setConfirmFinalEval(finalEval);
    updateFinalEvaluationMutate({
      finalEvalResultCode: finalEval,
      screeningSn,
      screeningValuerSnList: [valuerSn],
    });
    handleClickToggleReadStatus(true);
    handleCloseDropdown();
  };

  useEffect(() => {
    setFinalEval(applicantFinalEval);
    setConfirmFinalEval(applicantFinalEval);
  }, [applicantFinalEval]);

  const resultCodeMappings = useRecoilValue(resultCodeMappingsAtom);

  const iconColor = () => {
    switch (finalEvalMapping[confirmFinalEval]) {
      case finalEvalMapping.NOT_EVALUATE:
      case finalEvalMapping.NOT_PASS:
        return theme.colors['gray-600'];
      case finalEvalMapping.SPARE_PASS:
        return theme.colors['cyan-600'];
      case finalEvalMapping.PASS:
        return theme.colors['primary-600'];
      default:
        return;
    }
  };

  return (
    <S.CellCenterPosition>
      <Dropdown.Container ref={dropdownContainerRef}>
        <S.FinalEvalButton
          onClick={handleToggleDropdown}
          evalResultCode={finalEvalMapping[confirmFinalEval]}
        >
          <S.Span>{finalEvalMapping[confirmFinalEval]}</S.Span>
          <div>
            <Icon
              name='icon_arrow_drop_down_18_filled'
              style={{ display: 'inline' }}
              color={iconColor()}
            />
          </div>
        </S.FinalEvalButton>
        <Dropdown.ItemContainer
          isOpen={isOpen}
          position={DROPDOWN_POSITION.CENTER}
          style={{
            width: '126px',
          }}
        >
          <Dropdown.RadioGroup>
            {resultCodeMappings.map((resultCode) => {
              return (
                <Dropdown.RadioItem
                  key={resultCode.screeningResultCode}
                  name='finalEval'
                  label={resultCode.screeningResultCodeName}
                  onChange={handleChangeFinalEval}
                  value={resultCode.screeningResultCode}
                  checked={finalEval === resultCode.screeningResultCode}
                />
              );
            })}
            <Dropdown.Separate />
            <Dropdown.ButtonItem
              onClick={handleClickFinalEvalConfirm}
              type={DROPDOWN_BUTTON_ITEM_TYPE.CONFIRM}
              size={SIZE.SM}
            >
              확인
            </Dropdown.ButtonItem>
          </Dropdown.RadioGroup>
        </Dropdown.ItemContainer>
      </Dropdown.Container>
    </S.CellCenterPosition>
  );
};
