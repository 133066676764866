import styled from '@emotion/styled';

export const headerHeight = 56;
export const footerHeight = 53;

export const Container = styled.div`
  width: 100vw;
  min-width: 1080px;
  height: 100%;
  min-height: calc(100vh - ${headerHeight + footerHeight}px);

  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 100px 0;

  display: flex;
  justify-content: center;
  @media screen and (min-width: 1793px) {
    gap: 64px;
  }
  @media screen and (max-width: 1793px) {
    gap: 32px;
  }
`;

export const Main = styled.main`
  width: 100%;
  max-width: 1392px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1793px) {
    max-width: 1392px;
  }
  @media screen and (max-width: 1793px) {
    max-width: 1038px;
  }
  @media screen and (max-width: 1440px) {
    max-width: 686px;
  }
`;
