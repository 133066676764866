import { fileDownload } from '@helpers/fileDownload.util';
import { useExamScreeningResultFileDownload } from '@repositories/evaluation/exam/exam.query';

export const GridBodyExamScreeningResult = ({ uid }: { uid: string }) => {
  const { refetch, data: res } = useExamScreeningResultFileDownload(uid);

  const handleClickDownload = async () => {
    const { data: response } = await refetch();
    if (!response) return;

    fileDownload(response);
  };

  if (!uid) return '-';
  return <button onClick={handleClickDownload}>버튼 클릭</button>;
};
