import { PAGES } from '@ats/constants';
import SearchPage from '@pages/Search';
import type { RouteObject } from 'react-router-dom';

const SearchRoute: RouteObject = {
  path: PAGES.INTERVIEWER_EVALUATOR.SEARCH,
  children: [
    {
      index: true,
      element: <SearchPage />,
    },
  ],
};

export default SearchRoute;
