import type { Nullable } from '@ats/interfaces';
import type {
  GRID_FIELD_TYPE,
  SORT_TYPE,
} from '@constants/evaluation.constant.ts';
import {
  INTEREST_OR_BLACK,
  READ_STATUS,
  SCREENING_RESULT_CODE,
  SCREENING_TYPE,
  UPLOAD_STATUS,
  VALUER_ASSIGNMENT_TYPE,
} from '@constants/evaluation.constant.ts';
import type { ScreeningNoticeListRsDTO } from '@repositories/evaluation/common/common.dto';
import type {
  DocumentGridRqDTO,
  EvaluationItemInfos,
  ScreeningResultCodeMappings,
} from '@repositories/evaluation/document/document.dto';
import { atom, selector } from 'recoil';
import {
  gridFilterBlackAtom,
  gridFilterEvaluationItemRatingScales,
  gridFilterFinalEvaluateListAtom,
  gridFilterInterestAtom,
  gridFilterReadAtom,
  gridFilterUnReadAtom,
  gridFilterUnUploadFileAtom,
  gridFilterUploadFileAtom,
} from './document.filter';

export const initScreeningSnAtom = atom({
  key: 'initScreeningSnAtom',
  default: 0,
});
export const screeningSnAtom = atom({
  key: 'screeningSnAtom',
  default: 0,
});

export const freezePanesIndexAtom = atom({
  key: 'freezePanesIndexAtom',
  default: 0,
});

export const currentPageScreeningValuerSnListAtom = atom<number[]>({
  key: 'currentPageScreeningValuerSnListAtom',
  default: [],
});
export const currentPageScreeningValuerSnListAllAtom = atom<number[]>({
  key: 'currentPageScreeningValuerSnListAllAtom',
  default: [],
});

export const currentPageResumeSnListAtom = atom<number[]>({
  key: 'currentPageResumeSnListAtom',
  default: [],
});

export const isAllPageCheckAtom = atom({
  key: 'isAllPageCheckAtom',
  default: false,
});

export const gridWidthAtom = atom({
  key: 'gridWidthAtom',
  default: {},
});

type GridSumWidthObject = Record<number, number>;
export const gridSumWidthSelector = selector({
  key: 'gridSumWidthSelector',
  get: ({ get }) => {
    const result: GridSumWidthObject = {};
    let totalSum = 0;
    for (const [index, width] of Object.entries(get(gridWidthAtom))) {
      totalSum = totalSum + Number(width);
      result[Number(index)] = totalSum;
    }

    return result;
  },
});

export const gridFreezePanesCoordinateAtom = atom({
  key: 'gridFreezePanesCoordinateAtom',
  default: 0,
});

export const gridFreezePanesCoordinateSelector = selector({
  key: 'gridFreezePanesCoordinateSelector',
  get: ({ get }) => {
    const freezePanesIndex = get(freezePanesIndexAtom);
    const gridSubWidthObject = get(gridSumWidthSelector);

    return gridSubWidthObject[freezePanesIndex];
  },
});

export const gridHoverRowAtom = atom({
  key: 'gridHoverRowAtom',
  default: -1,
});

export const gridSortingColumnAndTypeAtom = atom<
  Partial<Record<GRID_FIELD_TYPE, SORT_TYPE>>
>({
  key: 'gridSortingColumnAtom',
  default: {},
});

/**
 * 필터 초기화 버튼 활성화 여부를 관리하기 위해 필터 횟수 카운팅한다.
 */
export const gridFilterCounterAtom = atom({
  key: 'gridFilterCounterAtom',
  default: 0,
});

export const valuerAssignmentTypeAtom = atom<VALUER_ASSIGNMENT_TYPE>({
  key: 'valuerAssignmentTypeAtom',
  default: VALUER_ASSIGNMENT_TYPE.GROUP,
});

export const screeningNameAtom = atom({
  key: 'screeningNameAtom',
  default: '',
});

export const screeningTypeAtom = atom<SCREENING_TYPE>({
  key: 'screeningTypeAtom',
  default: SCREENING_TYPE.DOCUMENT,
});

export const screeningNoticeListAtom = atom<ScreeningNoticeListRsDTO[]>({
  key: 'screeningNoticeListAtom',
  default: [],
});

export const screeningNoticeSnAtom = atom<Nullable<number>>({
  key: 'screeningNoticeSnAtom',
  default: 0,
});

export const screeningSectorSnAtom = atom<Nullable<number>>({
  key: 'screeningSectorSnAtom',
  default: null,
});

export const sectorPriorityListAtom = atom<number[]>({
  key: 'sectorPriorityList',
  default: [],
});

export const selectSectorPriorityListAtom = atom<number[]>({
  key: 'selectSectorPriorityListAtom',
  default: [],
});

export const interestOrBlackAtom = atom<INTEREST_OR_BLACK | ''>({
  key: 'includesInterestOrBlackAtom',
  default: '',
});

export const searchKeywordAtom = atom({
  key: 'searchKeywordAtom',
  default: '',
});

export const gridStateSelector = selector<DocumentGridRqDTO>({
  key: 'gridStateSelector',
  get: ({ get }) => {
    const screeningSn = get(screeningSnAtom);
    const screeningNoticeSn = get(screeningNoticeSnAtom);
    const screeningSectorSn = get(screeningSectorSnAtom);
    const selectSectorPriorityList = get(selectSectorPriorityListAtom);
    const searchKeyword = get(searchKeywordAtom);

    const gridSortingColumnAndType = get(gridSortingColumnAndTypeAtom);
    const [sortColumn] = Object.keys(gridSortingColumnAndType);
    const [sortType] = Object.values(gridSortingColumnAndType);

    const currentPage = get(currentPageAtom);
    const pageSize = get(pageSizeAtom);

    const readStatusArray = [
      get(gridFilterReadAtom) ? READ_STATUS.READ : null,
      get(gridFilterUnReadAtom) ? READ_STATUS.UNREAD : null,
    ].filter(Boolean) as READ_STATUS[];

    const markStatusArray = [
      get(gridFilterInterestAtom) ? INTEREST_OR_BLACK.INTEREST : null,
      get(gridFilterBlackAtom) ? INTEREST_OR_BLACK.BLACK : null,
    ].filter(Boolean) as INTEREST_OR_BLACK[];

    const managerFileUploadStatusArray = [
      get(gridFilterUploadFileAtom) ? UPLOAD_STATUS.UPLOADED : null,
      get(gridFilterUnUploadFileAtom) ? UPLOAD_STATUS.NOT_UPLOADED : null,
    ].filter(Boolean) as UPLOAD_STATUS[];

    const evaluationItemRatingScales = get(
      gridFilterEvaluationItemRatingScales,
    );

    const screeningResultCodeList = get(gridFilterFinalEvaluateListAtom);

    return {
      evaluationGridDefaultCondition: {
        keyword: searchKeyword,
        screeningSn: screeningSn,
        recruitNoticeSn: screeningNoticeSn,
        recruitSectorSn: screeningSectorSn,
        priorityList: selectSectorPriorityList,
        screeningValuerReadStatusList: readStatusArray,
        remarkableApplicantCodeList: markStatusArray,
        evaluationItemRatingScales: evaluationItemRatingScales,
        screeningResultCodeList: screeningResultCodeList,
        managerFileUploadStatusList: managerFileUploadStatusArray,
        sortColumn: sortColumn as GRID_FIELD_TYPE,
        sortType: sortType as SORT_TYPE,
      },
      page: currentPage,
      size: pageSize,
    };
  },
});

export const isSelectSectorAtom = atom({
  key: 'isSelectSectorAtom',
  default: false,
});

export const isGridFilterModalOpenAtom = atom({
  key: 'isGridFilterModalOpenAtom',
  default: false,
});

export const evaluationResultModifyAllowYnAtom = atom({
  key: 'evaluationResultModifyAllowYnAtom',
  default: false,
});

export const isRenderEvaluationResultSubmitButtonAtom = atom({
  key: 'isRenderEvaluationResultSubmitButtonAtom',
  default: true,
});

export const isRenderEvaluationAllButtonAtom = atom({
  key: 'isRenderEvaluationAllButtonAtom',
  default: true,
});

export const currentPageAtom = atom({
  key: 'currentPageAtom',
  default: 1,
});
export const pageSizeAtom = atom({
  key: 'pageSizeAtom',
  default: 30,
});

export const columnListAtom = atom<GRID_FIELD_TYPE[]>({
  key: 'columnList',
  default: [],
});

export const resultCodeMappingsAtom = atom<ScreeningResultCodeMappings[]>({
  key: 'resultCodeMappingsAtom',
  default: [
    {
      screeningResultCode: SCREENING_RESULT_CODE.NOT_EVALUATE,
      screeningResultCodeName: '미평가',
    },
  ],
});

export const evaluationItemInfosAtom = atom<EvaluationItemInfos[]>({
  key: 'evaluationItemInfosAtom',
  default: [],
});

export const totalApplicantCountAtom = atom({
  key: 'totalApplicantCountAtom',
  default: 0,
});

export const isShowScrollbarAtom = atom({
  key: 'isShowScrollbarAtom',
  default: true,
});

export const selectedNoticeAtom = atom({
  key: 'selectedNoticeAtom',
  default: '',
});

export const selectedSectorAtom = atom({
  key: 'selectedSectorAtom',
  default: '',
});

// header column과 열고정 여부
export type headersWithFreezeYn = {
  code: GRID_FIELD_TYPE;
  freezeYn: boolean;
};
export const headersWithFreezeYnAtom = atom<headersWithFreezeYn[]>({
  key: 'headersAtom',
  default: [],
});
