import {
  gridFilterBlackAtom,
  gridFilterInterestAtom,
} from '@stores/atoms/evaluation/document.filter';
import type { ChangeEvent, ForwardedRef } from 'react';
import { useImperativeHandle, useState } from 'react';
import { useRecoilState } from 'recoil';
import type { ChildRefHandle } from '../useGridFilter';

export interface Handle {
  refreshFn: () => void;
  applyFn: () => void;
}

export const useInterestBlack = (ref: ForwardedRef<ChildRefHandle>) => {
  const [gridFilterInterestStore, setGridFilterInterest] = useRecoilState(
    gridFilterInterestAtom,
  );
  const [gridFilterBlackStore, setGridFilterBlackStore] =
    useRecoilState(gridFilterBlackAtom);
  const [isInterest, setIsInterest] = useState(gridFilterInterestStore);
  const [isBlack, setIsBlack] = useState(gridFilterBlackStore);

  const isAllInterestAndBlack = isInterest && isBlack;

  const interestBlackAllProps = {
    checked: isAllInterestAndBlack,
    indeterminate: (isInterest || isBlack) && !isAllInterestAndBlack,
    onChange: () => {
      if (isAllInterestAndBlack) {
        setIsInterest(false);
        setIsBlack(false);
        return;
      }

      setIsInterest(true);
      setIsBlack(true);
    },
  };
  const interestProps = {
    checked: isInterest,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setIsInterest(e.target.checked),
  };
  const blackProps = {
    checked: isBlack,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setIsBlack(e.target.checked),
  };

  const refreshFn = () => {
    setIsInterest(false);
    setIsBlack(false);
  };

  const applyFn = () => {
    setGridFilterInterest(isInterest);
    setGridFilterBlackStore(isBlack);
  };

  useImperativeHandle(ref, () => ({
    refreshFn,
    applyFn,
  }));

  return {
    interestBlackAllProps,
    interestProps,
    blackProps,
  };
};
