import styled from '@emotion/styled';

export const Container = styled.div`
  min-height: 190px;

  .toastui-editor-defaultUI {
    width: 100%;
    height: 100%;
  }

  .toastui-editor-main {
    height: 100%;
  }

  /* .html-editor {
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    padding: 18px 25px;
    width: 100%;
    height: 100%;
    overflow: auto;
    color: #343a40;

    &:focus {
      outline: none;
    }
    &.html {
      display: block;
    }
  } */
`;
