import type { Nullable } from '@ats/interfaces';
import type { InterviewDateType } from '@components/evaluation/interview/interview.constant';
import {
  DATE_NAV_STATE,
  INTERVIEW_DATE_TYPE,
} from '@components/evaluation/interview/interview.constant';
import { atom } from 'recoil';

export const interviewDateNavTypeAtom = atom({
  key: 'interviewDateNavTypeAtom',
  default: DATE_NAV_STATE.ALL,
});
export const isTodayInterviewAtom = atom<number | undefined>({
  key: 'isTodayInterviewAtom',
  default: undefined,
});
export const interviewDateIndexAtom = atom({
  key: 'currentDateIndexAtom',
  default: 0,
});
export const interviewDateAtom = atom<Nullable<string>>({
  key: 'interviewDateAtom',
  default: undefined,
});
export const interviewStartDateTimeAtom = atom<Nullable<string>>({
  key: 'interviewStartDateTimeAtom',
  default: null,
});
export const interviewEndDateTimeAtom = atom<Nullable<string>>({
  key: 'interviewEndDateTimeAtom',
  default: null,
});
export const interviewPlaceSnAtom = atom<Nullable<string>>({
  key: 'interviewPlaceSnAtom',
  default: null,
});
export const interviewDateTypeAtom = atom<InterviewDateType>({
  key: 'interviewDateTypeAtom',
  default: INTERVIEW_DATE_TYPE.ALL,
});
