import { InputItem, InputList, Text } from '@ats/ui';
import { useReadStatusFilter } from '@hooks/evaluation/grid-screening/filter/useReadStatusFilter';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import * as S from '../gridFilterModal.style';

export const ReadStatusFilter = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    const { readUnReadAllProps, readProps, unReadProps } =
      useReadStatusFilter(ref);

    return (
      <S.Column>
        <Text variant={'b2'} bold>
          열람여부
        </Text>
        <InputList>
          <InputItem.Checkbox {...readUnReadAllProps}>전체</InputItem.Checkbox>
          <InputItem.Checkbox {...readProps}>열람</InputItem.Checkbox>
          <InputItem.Checkbox {...unReadProps}>미열람</InputItem.Checkbox>
        </InputList>
      </S.Column>
    );
  },
);
