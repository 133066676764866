import { ReactComponent as EmptyImg } from '@assets/interview/empty_result.svg';
import { Text } from '@ats/ui';
import * as S from './emptyInterview.style.ts';

const EmptyInterview = () => {
  return (
    <S.Container>
      <S.Content>
        <EmptyImg />
        <Text variant={'b2'}>전형에 배정된 지원자가 없습니다.</Text>
      </S.Content>
    </S.Container>
  );
};

export default EmptyInterview;
