import { ReactComponent as Choice } from '@assets/sidebar/icon_choice.svg';
import { ReactComponent as Report } from '@assets/sidebar/icon_evaluation_user_green.svg';
import { ReactComponent as Lock } from '@assets/sidebar/icon_lock_open.svg';
import { ReactComponent as MegaPhone } from '@assets/sidebar/icon_megaphone_yellow.svg';
import { ReactComponent as Person } from '@assets/sidebar/icon_person_blue_fill.svg';
import { PAGES } from '@ats/constants';
import type { ReactElement } from 'react';

export interface NavItemProps {
  icon: ReactElement;
  value: ReactElement;
  key: string;
  to: string;
}

export const TopNavItems: NavItemProps[] = [
  {
    icon: <Report />,
    value: <span>지원자&nbsp;평가</span>,
    key: PAGES.INTERVIEWER_EVALUATOR.EVALUATION.HOME,
    to: PAGES.INTERVIEWER_EVALUATOR.EVALUATION.HOME,
  },
  {
    icon: <Person />,
    value: <span>Q&A</span>,
    key: PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME,
    to: PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME,
  },
  {
    icon: <MegaPhone />,
    value: <span>공지사항</span>,
    key: PAGES.INTERVIEWER_EVALUATOR.NOTICE.HOME,
    to: PAGES.INTERVIEWER_EVALUATOR.NOTICE.HOME,
  },
  // {
  //   icon: <Magnifier />,
  //   value: <span>지원자&nbsp;검색</span>,
  //   key: PAGES.INTERVIEWER_EVALUATOR.SEARCH,
  //   to: PAGES.INTERVIEWER_EVALUATOR.SEARCH,
  // },
];

export const BottomNavItems: NavItemProps[] = [
  // {
  //   icon: <Book />,
  //   value: <span>이용&nbsp;가이드</span>,
  //   key: PAGES.INTERVIEWER_EVALUATOR.GUIDE,
  //   to: PAGES.INTERVIEWER_EVALUATOR.GUIDE,
  // },
  {
    icon: <Lock />,
    value: <span>비밀번호&nbsp;변경</span>,
    key: PAGES.INTERVIEWER_EVALUATOR.CHANGE_PASSWORD,
    to: PAGES.INTERVIEWER_EVALUATOR.CHANGE_PASSWORD,
  },
  {
    icon: <Choice />,
    value: <span>서비스&nbsp;선택</span>,
    key: PAGES.INTERVIEWER_EVALUATOR.CHOICE_SERVICE,
    to: PAGES.INTERVIEWER_EVALUATOR.CHOICE_SERVICE,
  },
];
