import { Icon, Text } from '@ats/ui';
import FullPageSpin from '@components/common/FullPageSpin/FullPageSpin';
import Main from '@components/evaluation/interview/Main/Main';
import { useTheme } from '@emotion/react';
import { useScreeningInfoQuery } from '@repositories/evaluation/interview/interview.query';
import { isVideoInterviewAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import { Suspense, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as S from './style';
import { useCleanStore } from './useCleanInit';

const Interview = () => {
  useCleanStore();

  return (
    <S.Container>
      <Header />
      <Suspense fallback={<FullPageSpin />}>
        <Main />
      </Suspense>
    </S.Container>
  );
};

const Header = () => {
  const theme = useTheme();
  const { screeningSn } = useParams();
  const setIsVideoInterview = useSetRecoilState(isVideoInterviewAtom);

  const { data: screeningInfo } = useScreeningInfoQuery({
    screeningSn: screeningSn!,
  });

  useEffect(() => {
    if (screeningInfo) setIsVideoInterview(screeningInfo.videoInterviewYn);
  }, [screeningInfo]);

  return (
    <S.Header>
      <Link to='/evaluation'>
        <Icon
          name='icon_back_18_line'
          size={24}
          color={theme.colors.white}
          style={{ cursor: 'pointer' }}
        />
      </Link>
      <Text variant={'t3'}>{screeningInfo?.screeningName ?? ''}</Text>
    </S.Header>
  );
};

export default Interview;
