import type { ReactNode } from 'react';
import type { FlatTagShape } from './flatTag.constant';
import * as S from './flatTag.style';

interface Props {
  backgroundColor?: string;
  color?: string;
  className?: string;
  shape?: FlatTagShape;
  children: ReactNode;
}
const FlatTag = ({ color, backgroundColor, children, shape }: Props) => {
  return (
    <S.Container
      shape={shape}
      style={{ color: color, backgroundColor: backgroundColor }}
    >
      {children}
    </S.Container>
  );
};

export default FlatTag;
