import type { IconName } from '@ats/ui';

export enum SCREENING_RESULT_CODE {
  NOT_EVALUATE = 'NOT_EVALUATE',
  NOT_PASS = 'NOT_PASS',
  PASS = 'PASS',
  SPARE_PASS = 'SPARE_PASS',
}
export type ScreeningResultCode = SCREENING_RESULT_CODE;

export enum INTEREST_OR_BLACK {
  INTEREST = 'INTEREST',
  BLACK = 'BLACK',
}
export type InterestOrBlack =
  (typeof INTEREST_OR_BLACK)[keyof typeof INTEREST_OR_BLACK];

export enum SCREENING_TYPE {
  DOCUMENT = 'document',
  EXAM = 'exam',
  PT_INTERVIEW = 'ptInterview',
  INTERVIEW = 'interview',
}
export type ScreeningType =
  (typeof SCREENING_TYPE)[keyof typeof SCREENING_TYPE];

export enum SORT_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}
export type SortType = (typeof SORT_TYPE)[keyof typeof SORT_TYPE];

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
}
export type Gender = (typeof GENDER)[keyof typeof GENDER];

export enum ICON_VIEW {
  RECOMMANDER = 'RECOMMANDER',
  ADDPOINT_CODE = 'ADDPOINT_CODE',
  BLACK = 'BLACK',
  INTEREST = 'INTEREST',
  PATRIOT = 'PATRIOT',
  HANDICAP = 'HANDICAP',
  PARENT_PROTECTION = 'PARENT_PROTECTION',
  BASIC_LIVING_RECIPIENT = 'BASIC_LIVING_RECIPIENT',
}

export type IconView = (typeof ICON_VIEW)[keyof typeof ICON_VIEW];

export const ICON_VIEW_MAP: Record<string, { name: IconName; label: string }> =
  {
    RECOMMANDER: { name: 'icon_like_18_filled', label: '추천인' },
    ADDPOINT_CODE: { name: 'icon_evaluate_18_filled', label: '가점코드' },
    BLACK: { name: 'icon_person_18_filled', label: '블랙 지원자' },
    INTEREST: { name: 'icon_star_18_filled', label: '관심지원자' },
    PATRIOT: { name: 'icon_military_18_filled', label: '보훈대상자' },
    HANDICAP: { name: 'icon_disabled_18_line', label: '장애' },
    BASIC_LIVING_RECIPIENT: {
      name: 'icon_home_18_filled',
      label: '국민기초생활 수급자',
    },
    PARENT_PROTECTION: {
      name: 'icon_healthcare_18_filled',
      label: `'한부모가족 지원법'상 보호대상자`,
    },
  };

export enum VALUER_ASSIGNMENT_TYPE {
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP_INDIVIDUAL = 'GROUP_INDIVIDUAL',
}

let type: typeof VALUER_ASSIGNMENT_TYPE;

export enum MARKING_ACTION {
  REGISTER = 'REGISTER',
  DELETE = 'DELETE',
}

export enum STEP_TYPE_CODE {
  ALL = '',
  DOCUMENT = '10',
  EXAM = '30',
  INTERVIEW = '40',
  ON_LINE_PT = '60',
}
export type StepTypeCode = STEP_TYPE_CODE;

export enum STEP_SEARCH_TYPE {
  ALL = 'all',
  PROCEEDING = 'ing',
  CLOSED = 'closed',
}
export type StepSearchType = STEP_SEARCH_TYPE;

export enum READ_STATUS {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum UPLOAD_STATUS {
  UPLOADED = 'UPLOADED',
  NOT_UPLOADED = 'NOT_UPLOADED',
}

export enum GRID_FIELD_TYPE {
  screeningValuerReadStatus = 'screeningValuerReadStatus',
  resumeDisplayNo = 'resumeDisplayNo',
  name = 'name',
  genderFlag = 'genderFlag',
  age = 'age',
  createDatetime = 'createDatetime',
  submitDatetime = 'submitDatetime',
  finalAcademyCode = 'finalAcademyCode',
  finalAcademySchool = 'finalAcademySchool',
  finalAcademyMajor = 'finalAcademyMajor',
  career = 'career',
  managerUploadFile = 'managerUploadFile',
  finalResultCode = 'finalResultCode',
  examScreeningResult = 'examScreeningResult',
  evaluationPeriod = 'evaluationPeriod',
  screeningResumeAssignmentDateTime = 'screeningResumeAssignmentDateTime',

  recruitNoticeName = 'recruitNoticeName',
  applySectorName = 'applySectorName',
}

export const finalEvalMapping: Record<SCREENING_RESULT_CODE, string> = {
  NOT_EVALUATE: '미평가',
  NOT_PASS: '불합격',
  PASS: '합격',
  SPARE_PASS: '예비합격',
};
