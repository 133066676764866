import type { SubListItemType } from '@ats/ui';
import {
  DIRECTION,
  DROPDOWN_COLOR_VARIANT,
  Dropdown,
  Icon,
  useDropdown,
} from '@ats/ui';
import { SCREENING_RESULT_CODE } from '@constants/evaluation.constant';
import { documentKeys } from '@constants/key.query.ts';
import { useTheme } from '@emotion/react';
import { queryClient } from '@helpers/client.ts';
import {
  useUpdateEvaluationQuery,
  useUpdateFinalEvaluationQuery,
  useUpdateReadStatusQuery,
} from '@repositories/evaluation/common/common.query.ts';
import type {
  EvaluationItemInfos,
  RatingScale,
} from '@repositories/evaluation/document/document.dto';
import {
  currentPageScreeningValuerSnListAtom,
  evaluationItemInfosAtom,
  resultCodeMappingsAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { MouseEvent } from 'react';
import { useRecoilValue } from 'recoil';
import * as S from './right.style';

export const EvaluationAll = () => {
  const theme = useTheme();
  const {
    isOpen,
    dropdownContainerRef,
    handleCloseDropdown,
    handleToggleDropdown,
  } = useDropdown();

  const { mutate: updateEvaluationMutate } = useUpdateEvaluationQuery();
  const { mutate: updateFinalEvaluationMutate } =
    useUpdateFinalEvaluationQuery();

  const screeningSn = useRecoilValue(screeningSnAtom);
  const currentPageScreeningValuerSnList = useRecoilValue(
    currentPageScreeningValuerSnListAtom,
  );
  const finalEvaluateStore = [
    SCREENING_RESULT_CODE.NOT_EVALUATE,
    SCREENING_RESULT_CODE.NOT_PASS,
    SCREENING_RESULT_CODE.PASS,
    SCREENING_RESULT_CODE.SPARE_PASS,
  ];
  const { mutate: updateReadStatusMutate } = useUpdateReadStatusQuery();
  const handleClickEvaluation = (e: MouseEvent<HTMLButtonElement>) => {
    if (
      finalEvaluateStore.includes(
        e.currentTarget.value as SCREENING_RESULT_CODE,
      )
    ) {
      updateFinalEvaluationMutate({
        finalEvalResultCode: e.currentTarget.value as SCREENING_RESULT_CODE,
        screeningSn: screeningSn,
        screeningValuerSnList: currentPageScreeningValuerSnList,
      });

      updateReadStatusMutate({
        evalItemModifyYn: true, // 열람/미열람 클릭 시에는 무조건 false 전달한다. 평가 시에는 true를 전달한다.
        screeningValuerSnList: [...currentPageScreeningValuerSnList],
      });

      queryClient.invalidateQueries({
        queryKey: [documentKeys.evaluateDocumentGrid],
      });
    } else {
      updateEvaluationMutate({
        screeningSn,
        evalItemSn: Number(e.currentTarget.value.split(',')[0]),
        screeningValuerSnList: currentPageScreeningValuerSnList,
        ratingScaleSn: Number(e.currentTarget.value.split(',')[1]),
        evaluationType: 'ITEM',
      });

      updateReadStatusMutate({
        evalItemModifyYn: true, // 열람/미열람 클릭 시에는 무조건 false 전달한다. 평가 시에는 true를 전달한다.
        screeningValuerSnList: [...currentPageScreeningValuerSnList],
      });

      queryClient.invalidateQueries({
        queryKey: [documentKeys.evaluateDocumentGrid],
      });
    }
    handleCloseDropdown();
  };

  /** 평가 항목의 평가 척도들 value를 "evalItemSn, ratingScaleSn"로 만들어서 어떤 evalItemSn의 ratingScaleSn을 update하는지 알 수 있게 한다.  */
  const formatRatingScalesListToItemSnWithRatingScaleSnValue = (
    ratingScalesList: RatingScale[],
    evaluationItemSn: number,
  ) => {
    return ratingScalesList.map((item) => {
      return {
        name: item.name,
        value: `${evaluationItemSn}, ${item.ratingScaleSn}`,
      };
    });
  };

  const formatEvaluationItemInfosForSubList = (
    list: EvaluationItemInfos[],
  ): SubListItemType[] => {
    return list
      .map((item: EvaluationItemInfos) => {
        if (item.evaluationItemType === 'AVERAGE') return null;

        if (item.childList && item.childList.length > 0) {
          return {
            name: item.evaluationItemName,
            value: item.evaluationItemSn,
            childList: formatEvaluationItemInfosForSubList(item.childList),
          };
        }

        if (item.childList.length === 0) {
          return {
            name: item.evaluationItemName,
            value: item.evaluationItemSn,
            childList: formatRatingScalesListToItemSnWithRatingScaleSnValue(
              item.ratingScales as RatingScale[],
              item.evaluationItemSn,
            ),
          };
        }

        return null;
      })
      .filter(Boolean) as SubListItemType[];
  };

  const evaluationItemInfos = useRecoilValue(evaluationItemInfosAtom);
  const reMappingEvaluationItemInfo =
    formatEvaluationItemInfosForSubList(evaluationItemInfos);

  const resultCodeMappings = useRecoilValue(resultCodeMappingsAtom);
  const reMappingResultCode = resultCodeMappings.map((resultCode) => {
    return {
      name: resultCode.screeningResultCodeName,
      value: resultCode.screeningResultCode,
    };
  });
  const finalEval = [
    {
      name: '최종평가',
      value: 0,
      childList: reMappingResultCode,
    },
  ];

  const sumMapping = [...reMappingEvaluationItemInfo, ...finalEval];

  return (
    <Dropdown.Container ref={dropdownContainerRef}>
      <Dropdown.Trigger
        onClick={handleToggleDropdown}
        style={{
          padding: '4px',
          outline: isOpen ? `2px solid ${theme.colors['gray-700']}` : '',
        }}
      >
        <Icon
          name='icon_write_18_filled'
          size={24}
          style={{
            fill: isOpen ? theme.colors['gray-400'] : theme.colors['gray-600'],
          }}
        />
      </Dropdown.Trigger>

      <Dropdown.ItemContainer
        isOpen={isOpen}
        variant={DROPDOWN_COLOR_VARIANT.DARK}
        isOverflowScrollable={false}
        style={{
          height: `${
            currentPageScreeningValuerSnList.length > 0 ? 'auto' : '156px'
          }`,
          width: '156px',
          right: '0%',
        }}
      >
        {currentPageScreeningValuerSnList.length > 0 ? (
          <Dropdown.SubList
            variant={DROPDOWN_COLOR_VARIANT.DARK}
            direction={DIRECTION.LEFT}
            subList={sumMapping}
            onClick={handleClickEvaluation}
          />
        ) : (
          <S.EmptyCurrentCheckContainer>
            <span>
              지원자를
              <br />
              선택해주세요
            </span>
          </S.EmptyCurrentCheckContainer>
        )}
      </Dropdown.ItemContainer>
    </Dropdown.Container>
  );
};
