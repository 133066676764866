import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
  padding-bottom: 80px;

  @media screen and (min-width: 1793px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 1793px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
