import { BaseRepository } from '@ats/repositories';

class CustomerRepository extends BaseRepository {
  public async getCustomerInfo() {
    const { data } = await super.get('/v2/customer/info');
    return data;
  }
}

export const customerRepository = new CustomerRepository(
  '',
);
