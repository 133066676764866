import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { Container, Content, ContentWrapper } from './style';

const MainLayout: FC = () => {
  return (
    <Container>
      <Header />
      <ContentWrapper>
        <Sidebar />
        <Content>
          <Outlet />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default MainLayout;
