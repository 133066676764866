import {
  selectedStepTypeAtom,
  stepCurrentPageAtom,
} from '@stores/atoms/evaluation/evaluation.atom';
import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

export const useCleanInit = () => {
  const refreshStepCurrentPage = useResetRecoilState(stepCurrentPageAtom);
  const selectedStepType = useRecoilValue(selectedStepTypeAtom);

  useEffect(() => {
    refreshStepCurrentPage();
  }, [selectedStepType]);
};
