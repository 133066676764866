import { InputItem, InputList, Text } from '@ats/ui';
import { useManagerUploadFileFilter } from '@hooks/evaluation/grid-screening/filter/useManagerUploadFileFilter';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import * as S from '../gridFilterModal.style';

export const ManagerUploadFileFilter = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    const { uploadAndUnUploadAllProps, uploadProps, unUploadProps } =
      useManagerUploadFileFilter(ref);
    return (
      <>
        <S.Divider />

        <S.Column>
          <Text variant={'b2'} bold>
            관리자 업로드 파일
          </Text>
          <InputList>
            <InputItem.Checkbox {...uploadAndUnUploadAllProps}>
              전체
            </InputItem.Checkbox>
            <InputItem.Checkbox {...uploadProps}>업로드</InputItem.Checkbox>
            <InputItem.Checkbox {...unUploadProps}>미업로드</InputItem.Checkbox>
          </InputList>
        </S.Column>
      </>
    );
  },
);
