import icalGuideImg from '@assets/interview/ical_guide.png';
import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Dropdown,
  Icon,
  SIZE,
  Text,
  TextField,
  useDropdown,
} from '@ats/ui';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip.tsx';
import { STEP_SEARCH_TYPE } from '@constants/evaluation.constant.ts';
import { MODAL_KEY } from '@constants/modal.constant.ts';
import { useTheme } from '@emotion/react';
import { useModal } from '@hooks/shared/useModal.ts';
import {
  selectedStepSearchTypeAtom,
  stepSearchKeywordAtom,
} from '@stores/atoms/evaluation/evaluation.atom.ts';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as S from './header.style.ts';

const STEP_SEARCH_TYPE_LIST = [
  STEP_SEARCH_TYPE.ALL,
  STEP_SEARCH_TYPE.PROCEEDING,
  STEP_SEARCH_TYPE.CLOSED,
] as const;
const STEP_SEARCH_TYPE_MAP = {
  [STEP_SEARCH_TYPE.ALL]: '전체 전형 보기',
  [STEP_SEARCH_TYPE.PROCEEDING]: '진행중 전형만 보기',
  [STEP_SEARCH_TYPE.CLOSED]: '종료 전형만 보기',
} as const;

const Header = () => {
  const [selectedStepSearchType, setSelectedStepSearchType] = useRecoilState(
    selectedStepSearchTypeAtom,
  );
  const setStepSearchKeyword = useSetRecoilState(stepSearchKeywordAtom);
  const [keyword, setKeyword] = useState('');
  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();
  const handleSearch = () => {
    setStepSearchKeyword(keyword);
  };
  const theme = useTheme();
  const { openModal } = useModal();
  const handleClickICal = () => {
    openModal(MODAL_KEY.ICAL_URL_COPY);
  };

  return (
    <S.Header>
      <Dropdown.Container ref={dropdownContainerRef}>
        <Dropdown.Trigger
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={handleToggleDropdown}
          style={{ width: '168px' }}
          size='sm'
          rightSection={
            <Icon
              name='icon_arrow_bottom_18_line'
              size={20}
              style={{ minWidth: '20px' }}
              color={theme.colors['gray-800']}
            />
          }
        >
          {STEP_SEARCH_TYPE_MAP[selectedStepSearchType]}
        </Dropdown.Trigger>
        <Dropdown.ItemContainer
          isOpen={isOpen}
          isOverflowScrollable={false}
          style={{ height: 'auto', width: '100%' }}
        >
          {STEP_SEARCH_TYPE_LIST.map((type) => (
            <Dropdown.ButtonItem
              key={type}
              onClick={() => {
                setSelectedStepSearchType(type);
                handleCloseDropdown();
              }}
            >
              {STEP_SEARCH_TYPE_MAP[type]}
            </Dropdown.ButtonItem>
          ))}
        </Dropdown.ItemContainer>
      </Dropdown.Container>

      <S.HeaderRightSection>
        <TextField
          style={{ width: '190px' }}
          size={'sm'}
          leftSection={
            <Icon
              color={theme.colors['gray-600']}
              name={'icon_search_18_line'}
              size={20}
              style={{ minWidth: '20px' }}
            />
          }
          placeholder={'평가명을 입력해주세요.'}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onPressEnter={handleSearch}
        />
        <Button
          size={SIZE.SM}
          color={BUTTON_COLORS.THEMA}
          onClick={handleSearch}
          style={{ width: '64px' }}
        >
          검색
        </Button>
        <Button
          onClick={handleClickICal}
          style={{ width: '125px' }}
          size='sm'
          rightSection={
            <ICalTooltip>
              <Icon name={'icon_info_18_line'} fill={theme.colors.white} />
            </ICalTooltip>
          }
        >
          iCal주소복사
        </Button>
      </S.HeaderRightSection>
    </S.Header>
  );
};

export default Header;

const ICalTooltip = ({ children }: { children: ReactNode }) => {
  return (
    <HeadlessTooltip
      content={
        <S.ICalTooltipContainer>
          <Text variant={'b3'} bold>
            iCal주소 복사
          </Text>
          <Text variant={'b3'}>
            구글 캘린더, 네이버 캘린더 등 iCal 형식을 지원하는 캘린더 제품에는
            모두 이 주소를 복사하여 붙여넣을 수 있습니다.
          </Text>
          <img width={465} src={icalGuideImg} alt='ical-preview' />
        </S.ICalTooltipContainer>
      }
    >
      {children}
    </HeadlessTooltip>
  );
};
