import styled from '@emotion/styled';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 12px 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-300']};
`;

export const TotalCountArea = styled.div`
  box-sizing: border-box;
`;

export const Total = styled.span`
  ${({ theme }) => theme.body['b2-bold']};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const Count = styled.span`
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['primary-600']};
`;
