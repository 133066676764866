import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { MODAL_KEY } from '@constants/modal.constant';
import { useModal } from '@hooks/shared/useModal';
import { screeningResumeSnAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import type { MouseEventHandler } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  INTERVIEW_STATUS_CODE,
  type InterviewStatusCode,
} from '../../interview.constant';

interface Props {
  interviewStatusCode: InterviewStatusCode;
  screeningResumeSn: string;
  interviewStartDatetime?: string;
}
const VideoInterviewButton = (props: Props) => {
  const { interviewStatusCode, screeningResumeSn, interviewStartDatetime } =
    props;
  const { openModal } = useModal();
  const setScreeningResumeSn = useSetRecoilState(screeningResumeSnAtom);

  const handleClickEnter: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setScreeningResumeSn(screeningResumeSn);
    openModal(MODAL_KEY.VIDEO_INTERVIEW_NAME);
  };

  const formattedStartTime = (interviewStartDatetime?: string) => {
    if (!interviewStartDatetime) return '';

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_, time] = interviewStartDatetime.split(' ');
    return time.slice(0, 5);
  };

  if (interviewStatusCode === INTERVIEW_STATUS_CODE.STANDBY)
    return (
      <Button
        variant={BUTTON_VARIANTS.PRIMARY}
        size={SIZE.SM}
        leftIcon={'icon_check_18_line'}
        onClick={(e) => e.stopPropagation()}
        disabled
      >
        {formattedStartTime(interviewStartDatetime)} 입장 가능
      </Button>
    );

  if (interviewStatusCode === INTERVIEW_STATUS_CODE.OPEN)
    return (
      <Button
        variant={BUTTON_VARIANTS.PRIMARY}
        size={SIZE.SM}
        color={BUTTON_COLORS.THEMA}
        rightIcon={'icon_arrow_right_18_line'}
        style={{ width: '135px', paddingRight: '8px' }}
        onClick={handleClickEnter}
      >
        화상면접 입장
      </Button>
    );

  return (
    <Button
      variant={BUTTON_VARIANTS.PRIMARY}
      size={SIZE.SM}
      color={BUTTON_COLORS.THEMA}
      leftIcon={'icon_check_18_line'}
      style={{ width: '121px', paddingLeft: '8px' }}
      onClick={(e) => e.stopPropagation()}
      disabled
    >
      화상면접 종료
    </Button>
  );
};

export default VideoInterviewButton;
