import { useSearch } from '@hooks/common/useSearch';
import type { ScreeningRecruitSectorListRsDTO } from '@repositories/evaluation/common/common.dto';
import { useScreeningRecruitSectorListQuery } from '@repositories/evaluation/common/common.query';
import {
  screeningNoticeSnAtom,
  screeningSectorSnAtom,
  sectorPriorityListAtom,
  selectSectorPriorityListAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

interface UseSectorList {
  handleSetSectorText: (text: string, isCloseDropdown?: boolean) => void;
}

export const useSectorList = ({ handleSetSectorText }: UseSectorList) => {
  const { screeningSn } = useParams();
  const noticeSn = useRecoilValue(screeningNoticeSnAtom);
  const { data: sectorList } = useScreeningRecruitSectorListQuery({
    screeningSn: screeningSn!,
    recruitNoticeCodeSn: noticeSn,
  });

  useEffect(() => {
    if (sectorList.length === 0) {
      handleSetSectorText('-');
    }
    handleSetSectorText(sectorList[0].name);
  }, [handleSetSectorText, sectorList]);

  const [sectorSn, setSectorSn] = useRecoilState(screeningSectorSnAtom);
  const setSelectSectorPriorityList = useSetRecoilState(
    selectSectorPriorityListAtom,
  );

  const handleClickSector = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.textContent) {
      handleSetSectorText(e.currentTarget.textContent, true);
      setSectorSn(Number(e.currentTarget.value));
    }
    setSelectSectorPriorityList([]);
  };

  const setSelectSectorPriorityListAtom = useSetRecoilState(
    selectSectorPriorityListAtom,
  );

  useEffect(() => {
    setSectorSn(sectorList[0].sn);
    setSelectSectorPriorityListAtom([]);
  }, [setSectorSn, sectorList, setSelectSectorPriorityListAtom]);

  const [searchText, setSearchText] = useState<string>('');
  const { searchResultList } = useSearch<
    ScreeningRecruitSectorListRsDTO,
    'name'
  >(searchText, sectorList, 'name');

  const handleChangeSectorSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  /** 지망 설정 */
  const setSectorPriorityList = useSetRecoilState(sectorPriorityListAtom);
  useEffect(() => {
    if (sectorSn && sectorList) {
      sectorList.map((sectorObj) => {
        if (String(sectorObj.sn) === String(sectorSn)) {
          setSectorPriorityList(sectorObj.applySectorPriorityList);
        }
      });
    }
  }, [sectorSn, sectorList, setSectorPriorityList]);

  return {
    searchText,
    searchResultList,
    handleChangeSectorSearch,
    handleClickSector,
  };
};
