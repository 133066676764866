import {
  atomicKeys,
  gridScreeningKeys,
  screeningListKeys,
} from '@constants/key.query';
import { fileDownload } from '@helpers/fileDownload.util';
import { convertToFormData } from '@helpers/formData.util';
import type {
  MutationOptions,
  UseMutationResult,
  UseQueryResult,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type {
  CheckMyApplicantMarkingCntRqDTO,
  DownloadManagerUploadFileRqDTO,
  GetValuerSecurityOathRqDTO,
  GetValuerSecurityOathRsDTO,
  ScreeningInfoRqDTO,
  ScreeningInfoRsDTO,
  ScreeningNoticeListRsDTO,
  ScreeningRecruitSectorListRqDTO,
  ScreeningRecruitSectorListRsDTO,
  UpdateEvaluationRqDTO,
  UpdateFinalEvaluationRqDTO,
  UpdateReadStatusRqDTO,
} from './common.dto';
import { CommonEvaluationRepository } from './common.repository';

const commonEvaluationRepository = new CommonEvaluationRepository('');

/* ------------------------------- 모든 전형에서 사용 ------------------------------- */
/** 전형 정보 */
export const useScreeningInfoQuery = (
  request: ScreeningInfoRqDTO,
): UseSuspenseQueryResult<
  Pick<
    ScreeningInfoRsDTO,
    'screeningType' | 'screeningName' | 'evalResultModifyAllowYn'
  >,
  AxiosError
> => {
  return useSuspenseQuery({
    queryKey: [gridScreeningKeys.screeningInfo, request.screeningSn],
    queryFn: () =>
      commonEvaluationRepository.getScreeningInfo(
        convertToFormData({
          screeningSn: request.screeningSn,
        } as unknown as Record<string, string>),
      ),
    select: (data) => ({
      screeningName: data.screeningName,
      screeningType: data.screeningType,
      evalResultModifyAllowYn: data.evalResultModifyAllowYn,
    }),
  });
};

export const useGetValuerSecurityOathQuery = (
  request: GetValuerSecurityOathRqDTO,
): UseQueryResult<GetValuerSecurityOathRsDTO, AxiosError> =>
  useQuery({
    queryKey: [screeningListKeys.valuerSecurityOath, request.jobnoticeSn],
    queryFn: () => commonEvaluationRepository.getValuerSecurityOath(request),
  });

/* --------------------------- 서류, 시험, 온라인PT에서 사용 --------------------------- */
/** 로그인 연장 */
export const useKeepLoginQuery = () =>
  useQuery({
    queryKey: [atomicKeys.keepLogin],
    queryFn: () => commonEvaluationRepository.keepLogin(),
  });

/** 공고 리스트 */
export const useScreeningNoticeListQuery = (
  screeningSn: string,
): UseSuspenseQueryResult<ScreeningNoticeListRsDTO[], AxiosError> => {
  return useSuspenseQuery({
    queryKey: [gridScreeningKeys.screeningNoticeList, screeningSn],
    queryFn: () =>
      commonEvaluationRepository.getScreeningNoticeList(
        convertToFormData({ screeningSn } as unknown as Record<string, string>),
      ),
  });
};

/** 채용 분야 리스트 */
export const useScreeningRecruitSectorListQuery = (
  request: ScreeningRecruitSectorListRqDTO,
): UseSuspenseQueryResult<ScreeningRecruitSectorListRsDTO[], AxiosError> => {
  return useSuspenseQuery({
    queryKey: [
      gridScreeningKeys.screeningRecruitSectorList,
      request.screeningSn,
      request.recruitNoticeCodeSn,
    ],
    queryFn: () =>
      commonEvaluationRepository.getScreeningRecruitSectorList(
        convertToFormData({
          screeningSn: request.screeningSn,
          recruitNoticeCodeSn: request.recruitNoticeCodeSn,
        } as unknown as Record<string, string>),
      ),
  });
};

/** 지원자를 관심/블랙지원자 등록한 평가자 수 */
export const useCheckMyApplicantMarkingCntQuery = (
  request: CheckMyApplicantMarkingCntRqDTO,
): UseSuspenseQueryResult<number, AxiosError> => {
  return useSuspenseQuery({
    queryKey: [request.resumeSnList, request.markingCode],
    queryFn: () => {
      return commonEvaluationRepository.checkMyApplicantMarkingCnt(
        convertToFormData({
          resumeSnList: request.resumeSnList.join(','),
          markingCode: request.markingCode,
        } as unknown as Record<string, string>),
      );
    },
  });
};

/** 관심/블랙지원자 등록 */
export const useSaveApplicantMarkingQuery = (
  options: MutationOptions<unknown, AxiosError, unknown, unknown>,
): UseMutationResult<unknown, AxiosError, unknown, unknown> => {
  return useMutation({
    mutationFn: (request) =>
      commonEvaluationRepository.saveApplicantMarking(
        convertToFormData(request as unknown as Record<string, string>),
      ),
    ...options,
  });
};

/** 관심/블랙지원자 해제 */
export const useDeleteApplicantMarkingQuery = (
  // request: DeleteApplicantMarkingRqDTO,
  options: MutationOptions<unknown, AxiosError, unknown, unknown>,
): UseMutationResult<unknown, AxiosError, unknown, unknown> => {
  return useMutation({
    mutationFn: (request) =>
      commonEvaluationRepository.deleteApplicantMarking(
        convertToFormData(request as unknown as Record<string, string>),
      ),
    ...options,
  });
};

/** 지원자 평가 업데이트 */
export const useUpdateEvaluationQuery = () => {
  return useMutation({
    mutationFn: (request: UpdateEvaluationRqDTO) =>
      commonEvaluationRepository.updateEvaluation(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });
};

/** 지원자 평가결과 업데이트 */
export const useUpdateFinalEvaluationQuery = () => {
  return useMutation({
    mutationFn: (request: UpdateFinalEvaluationRqDTO) =>
      commonEvaluationRepository.updateFinalEvaluation(request),
  });
};

/** 평가결과 최종 제출 */
export const useSubmitEvaluationResultQuery = (
  // request: DeleteApplicantMarkingRqDTO,
  options?: MutationOptions<unknown, AxiosError, unknown, unknown>,
): UseMutationResult<unknown, AxiosError, unknown, unknown> => {
  return useMutation({
    mutationFn: (request) =>
      commonEvaluationRepository.submitEvaluationResult(
        convertToFormData(request as unknown as Record<string, string>),
      ),
    ...options,
  });
};

/** 열람/미열람 */
export const useUpdateReadStatusQuery = () => {
  return useMutation({
    mutationFn: (request: UpdateReadStatusRqDTO) =>
      commonEvaluationRepository.updateReadStatus(request),
  });
};

/** 관리자 업로드 파일 다운로드 */
export const useDownloadManagerUploadFile = () => {
  return useMutation({
    mutationFn: (request: DownloadManagerUploadFileRqDTO) =>
      commonEvaluationRepository.downloadManagerUploadFile(request),
    onSuccess: (response) => {
      fileDownload(response);
    },
  });
};
