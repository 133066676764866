import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  InputItem,
  InputList,
  RadioGroup,
  SIZE,
  Text,
} from '@ats/ui';
import { SORT_TYPE, type SortType } from '@constants/evaluation.constant.ts';
import { css } from '@emotion/react';
import { useClickOutsideHandler } from '@hooks/common/useClickOutsideHandler.ts';
import {
  isFilterModalOpenAtom,
  isVideoInterviewAtom,
} from '@stores/atoms/evaluation/interview/interview.atom.ts';
import type { ChangeEvent, FC } from 'react';
import { useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SORT_COLUMN, type SortColumn } from '../../interview.constant.ts';
import * as S from './filterModal.style.ts';
import { useFilterModal } from './useFilterModal.ts';

const FilterModal: FC = () => {
  const modalRef = useRef(null);
  const setIsFilterModalOpen = useSetRecoilState(isFilterModalOpenAtom);
  useClickOutsideHandler(modalRef, () => {
    setIsFilterModalOpen(false);
  });
  const isVideoInterview = useRecoilValue(isVideoInterviewAtom);

  const {
    interestProps,
    blackProps,
    interestBlackAllProps,

    notEvaluateProps,
    notPassProps,
    passProps,
    sparePassProps,
    evaluationAllProps,

    sortColumn,
    setSortColumn,
    summarySortType,
    setSummarySortType,

    handleRefreshFilter,
    handleApplyFilter,
  } = useFilterModal();

  const handleChangeSortColumn = (e: ChangeEvent<HTMLInputElement>) => {
    setSortColumn(e.target.value as SortColumn);
  };
  const handleChangeSummarySortType = (e: ChangeEvent<HTMLInputElement>) => {
    setSummarySortType(e.target.value as SortType);
  };

  return (
    <S.Container ref={modalRef}>
      <S.UpperSection>
        <S.Column>
          <Text variant={'b2'} bold>
            대상지원자
          </Text>
          <InputList>
            <InputItem.Checkbox {...interestBlackAllProps}>
              전체
            </InputItem.Checkbox>
            <InputItem.Checkbox {...interestProps}>
              관심지원자
            </InputItem.Checkbox>
            <InputItem.Checkbox {...blackProps}>블랙지원자</InputItem.Checkbox>
          </InputList>
        </S.Column>

        <S.Divider />

        <S.Column>
          <Text variant={'b2'} bold>
            평가결과
          </Text>
          <InputList
            css={css`
              li:last-child {
                margin-bottom: 8px;
              }
            `}
          >
            <InputItem.Checkbox {...evaluationAllProps}>
              전체
            </InputItem.Checkbox>
            <InputItem.Checkbox {...notEvaluateProps}>
              미평가
            </InputItem.Checkbox>
            <InputItem.Checkbox {...notPassProps}>불합격</InputItem.Checkbox>
            <InputItem.Checkbox {...passProps}>합격</InputItem.Checkbox>
            <InputItem.Checkbox {...sparePassProps}>
              예비합격
            </InputItem.Checkbox>
          </InputList>
        </S.Column>

        <S.Divider />

        <S.Column>
          <Text variant={'b2'} bold>
            정렬
          </Text>
          <div style={{ display: 'flex', gap: '16px' }}>
            <RadioGroup>
              <InputList>
                <InputItem.Radio
                  checked={SORT_COLUMN.RESUME_DISPLAY_NO === sortColumn}
                  value={SORT_COLUMN.RESUME_DISPLAY_NO}
                  onChange={handleChangeSortColumn}
                  name='filterType'
                >
                  <Text variant={'b2'}>수험번호</Text>
                </InputItem.Radio>
                <InputItem.Radio
                  checked={SORT_COLUMN.NAME === sortColumn}
                  value={SORT_COLUMN.NAME}
                  onChange={handleChangeSortColumn}
                  name='filterType'
                >
                  <Text variant={'b2'}>지원자 성명</Text>
                </InputItem.Radio>
                {!isVideoInterview && (
                  <InputItem.Radio
                    checked={SORT_COLUMN.INTERVIEW_GROUP === sortColumn}
                    value={SORT_COLUMN.INTERVIEW_GROUP}
                    onChange={handleChangeSortColumn}
                    name='filterType'
                  >
                    <Text variant={'b2'}>면접조별</Text>
                  </InputItem.Radio>
                )}
                <InputItem.Radio
                  checked={SORT_COLUMN.TOTAL_EVAL === sortColumn}
                  value={SORT_COLUMN.TOTAL_EVAL}
                  onChange={handleChangeSortColumn}
                  name='filterType'
                >
                  <Text variant={'b2'}>종합평가</Text>
                </InputItem.Radio>
              </InputList>
            </RadioGroup>

            <RadioGroup>
              <InputList>
                <InputItem.Radio
                  checked={SORT_TYPE.ASC === summarySortType}
                  value={SORT_TYPE.ASC}
                  onChange={handleChangeSummarySortType}
                  name='filterOrder'
                >
                  <Text variant={'b2'}>오름차순</Text>
                </InputItem.Radio>
                <InputItem.Radio
                  checked={SORT_TYPE.DESC === summarySortType}
                  value={SORT_TYPE.DESC}
                  onChange={handleChangeSummarySortType}
                  name='filterOrder'
                >
                  <Text variant={'b2'}>내림차순</Text>
                </InputItem.Radio>
              </InputList>
            </RadioGroup>
          </div>
        </S.Column>
      </S.UpperSection>

      <S.LowerSection>
        <Button
          leftIcon={'icon_reset_18_line'}
          size={SIZE.SM}
          variant={BUTTON_VARIANTS.OUTLINED}
          onClick={handleRefreshFilter}
        >
          초기화
        </Button>
        <Button
          size={SIZE.SM}
          color={BUTTON_COLORS.THEMA}
          onClick={handleApplyFilter}
        >
          검색
        </Button>
      </S.LowerSection>
    </S.Container>
  );
};

export default FilterModal;
