import { Button, BUTTON_VARIANTS, Icon, SIZE, TextField } from '@ats/ui';
import Editor from '@components/q&a/Editor';
import { queryClient } from '@helpers/client.ts';
import { useAlert } from '@hooks/shared/useAlert.ts';
import { ArticleQueryKey } from '@repositories/article/article.query.ts';
import type { GetQnaCommentsRsDTO } from '@repositories/qna/qna.dto.ts';
import {
  QnAQueryKey,
  useDeleteArticleMutation,
  useUpdateQnaArticleMutation,
} from '@repositories/qna/qna.query.ts';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useState } from 'react';
import * as S from './style';

export enum COMMENT_TYPE {
  READ,
  UPDATE,
}

const MONTH_OFFSET = 1;
const YEAR_OFFSET = 1900;

const Comment: FC<GetQnaCommentsRsDTO & { memberSn: string }> = ({
  upArticleSn,
  articleSn,
  creatorName,
  contents: commentContents,
  title: commentTitle,
  memberSn,
  creatorSn,
  lastModifyDate: { year, day, hours, month, minutes },
}) => {
  const { mutate: updateComment } = useUpdateQnaArticleMutation();
  const { mutate: deleteComment } = useDeleteArticleMutation();

  const [mode, setMode] = useState<COMMENT_TYPE>(COMMENT_TYPE.READ);
  const [contents, setContents] = useState(commentContents);
  const [title, setTitle] = useState(commentTitle);

  const { openAlert } = useAlert();

  const handleDeleteComment = () => {
    openAlert({
      title: '',
      content: '댓글을 삭제하시겠습니까?',
      callback: () => {
        deleteComment(
          { articleSn },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [...QnAQueryKey.getComment()],
              });
              queryClient.invalidateQueries({
                queryKey: [...ArticleQueryKey.detail()],
              });
            },
          },
        );
      },
    });
  };

  const handleCommentUpdate = () => {
    updateComment(
      {
        upArticleSn,
        articleSn,
        title,
        contents,
      },
      {
        onSuccess: () => {
          setMode(COMMENT_TYPE.READ);
          queryClient.invalidateQueries({
            queryKey: [...QnAQueryKey.getComment()],
          });
        },
      },
    );
  };

  return (
    <S.Container>
      <S.WriterArea>
        <S.Writer>{creatorName}</S.Writer>
        <S.CreatedAt>
          {dayjs(
            `${year + YEAR_OFFSET}-${
              month + MONTH_OFFSET
            }-${day} ${hours}:${minutes}`,
          ).format('YYYY.MM.DD H:mm')}
        </S.CreatedAt>
        {memberSn === creatorSn && mode === COMMENT_TYPE.READ && (
          <>
            <Icon
              name='icon_edit_24_filled'
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                marginRight: '8px',
              }}
              onClick={() => setMode(COMMENT_TYPE.UPDATE)}
            />
            <Icon
              name='icon_delete_18_filled'
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteComment()}
            />
          </>
        )}
      </S.WriterArea>
      <S.Title>
        {mode === COMMENT_TYPE.READ && title}
        {mode === COMMENT_TYPE.UPDATE && (
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            size={SIZE.MD}
          />
        )}
      </S.Title>
      {mode === COMMENT_TYPE.READ && (
        <S.Content>
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </S.Content>
      )}
      {mode === COMMENT_TYPE.UPDATE && (
        <S.EditorWrapper>
          <Editor contents={contents} setContents={setContents} height={190} />
          <S.EditorBottom>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={SIZE.SM}
              onClick={() => handleCommentUpdate()}
            >
              등록
            </Button>
            <Button size={SIZE.SM} onClick={() => setMode(COMMENT_TYPE.READ)}>
              취소
            </Button>
          </S.EditorBottom>
        </S.EditorWrapper>
      )}
    </S.Container>
  );
};

export default Comment;
