import { Modal } from '@ats/ui';
import { INTEREST_OR_BLACK } from '@constants/evaluation.constant';
import { documentKeys } from '@constants/key.query';
import { queryClient } from '@helpers/client';
import { useAlert } from '@hooks/shared/useAlert';
import { useModal } from '@hooks/shared/useModal';
import { useSaveApplicantMarkingQuery } from '@repositories/evaluation/common/common.query';
import {
  currentPageResumeSnListAtom,
  interestOrBlackAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as S from './interestOrBlackModal.style';

export const InterestOrBlackModal = () => {
  const [reason, setReason] = useState<string>('');
  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  const { openAlert, closeAlert } = useAlert();
  const { openModal, closeModal } = useModal();

  const interestOrBlack = useRecoilValue(interestOrBlackAtom);
  const currentPageResumeSnList = useRecoilValue(currentPageResumeSnListAtom);

  // 다른 평가자가 선택한 관심/블랙지원자 개수 - 리뉴얼하면서 필요 없어짐
  // useCheckMyApplicantMarkingCntQuery({
  //   resumeSnList: currentPageResumeSnList,
  //   markingCode: interestOrBlack,
  // });

  const { mutate: saveApplicantMarkingMutate } = useSaveApplicantMarkingQuery({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [documentKeys.evaluateDocumentGrid],
      });
    },
  });

  const getInterestOrBlackText = () => {
    switch (interestOrBlack) {
      case INTEREST_OR_BLACK.INTEREST:
        return '관심';
      case INTEREST_OR_BLACK.BLACK:
        return '블랙';
      default:
        return '';
    }
  };

  const registerApplication = () => {
    saveApplicantMarkingMutate({
      resumeSnList: currentPageResumeSnList,
      markingCode: interestOrBlack,
      reason: reason,
      isOverwrite: false,
    });
    closeModal();
    // setGlobalModalOpenState({ isOpenInterestOrBlackModal: false });
  };
  const handleClickConfirm = () => {
    openAlert({
      title: `${getInterestOrBlackText()} 지원자 등록`,
      content: `선택한 지원자를 ${getInterestOrBlackText()}지원자로 등록하시겠습니까?`,
      callback: registerApplication,
    });
  };

  const handleClickCancel = () => {
    closeModal();
  };

  return (
    <Modal.Container style={{ width: '780px', height: '394px' }}>
      <Modal.Header
        onClickClose={handleClickCancel}
        title={`${getInterestOrBlackText()} 지원자 등록 사유`}
      />
      <Modal.Separate />
      <Modal.Body>
        <p>
          {`${getInterestOrBlackText()}지원자로 등록하고자 하는 사유를 입력해주세요`}
          <S.RedText>(필수)</S.RedText>
        </p>
        <S.TextArea
          placeholder='등록하려는 이유를 작성해주세요.'
          onChange={handleChangeTextArea}
        />
      </Modal.Body>
      <Modal.Footer
        confirmText='등록'
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickConfirm}
      />
    </Modal.Container>
  );
};
