import { BaseRepository } from '@ats/repositories';
import { HTTP_HEADER_FORM } from '@constants/repository.constant';
import type { PostQnaPasswordCheckRqDTO } from '@repositories/qna/qna.dto.ts';

class QnaRepository extends BaseRepository {
  public async postQnaArticle(request: FormData, AUToken: string) {
    const { data } = await super.post('/bbs/eval/qna/writeOk', request, {
      headers: { ...HTTP_HEADER_FORM.headers, AUToken },
    });

    return data;
  }

  public async updateQnaArticle(request: FormData) {
    const { data } = await super.post('/bbs/eval/qna/updateOk', request, {
      headers: { ...HTTP_HEADER_FORM.headers },
    });

    return data;
  }

  public async deleteQnaArticle(request: FormData) {
    const { data } = await super.post(
      '/bbs/eval/qna/delete',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async getQnaComments(request: FormData) {
    const { data } = await super.post(
      '/bbs/eval/qna/answer/list',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async postQnaPasswordCheck(
    request: PostQnaPasswordCheckRqDTO,
  ): Promise<{ successYn: boolean }> {
    const { data } = await super.post(
      '/bbs/eval/qna/password/verification',
      request,
      HTTP_HEADER_FORM,
    );

    return data as { successYn: boolean };
  }
}

export const qnaRepository = new QnaRepository('');
