import { BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { useTheme } from '@emotion/react';
import { useSessionTime } from '@hooks/useSessionTime';
import { type FC } from 'react';
import { Container, Name, NameArea, NameSuffix, Time, TimeArea } from './style';

const SessionPanel: FC = () => {
  const { remainSessionTime, refresh, reset } = useSessionTime();
  const { colors } = useTheme();

  return (
    <Container>
      <NameArea>
        <Name>유상현</Name>
        <NameSuffix>님</NameSuffix>
      </NameArea>
      <TimeArea>
        <Time>{remainSessionTime}</Time>
        <Button
          size={SIZE.SM}
          variant={BUTTON_VARIANTS.OUTLINED}
          style={{ background: colors.white }}
          onClick={refresh}
        >
          연장
        </Button>
      </TimeArea>
      <Button
        size={SIZE.SM}
        variant={BUTTON_VARIANTS.OUTLINED}
        style={{ background: colors.white }}
        onClick={reset}
      >
        로그아웃
      </Button>
    </Container>
  );
};

export default SessionPanel;
