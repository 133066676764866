import {
  interviewFilterBlackAtom,
  interviewFilterInterestAtom,
  interviewFilterNotEvaluateAtom,
  interviewFilterNotPassAtom,
  interviewFilterPassAtom,
  interviewFilterSparePassAtom,
} from '@stores/atoms/evaluation/interview/interview.filter.atom';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

export const useFilterCount = () => {
  const interest = useRecoilValue(interviewFilterInterestAtom);
  const black = useRecoilValue(interviewFilterBlackAtom);

  const notEvaluate = useRecoilValue(interviewFilterNotEvaluateAtom);
  const notPass = useRecoilValue(interviewFilterNotPassAtom);
  const pass = useRecoilValue(interviewFilterPassAtom);
  const sparePass = useRecoilValue(interviewFilterSparePassAtom);

  const filterCount = useMemo(() => {
    let cnt = 0;
    const isApplicantFilter = interest || black;
    const isEvaluationResultFilter =
      notEvaluate || notPass || pass || sparePass;

    if (isApplicantFilter) cnt++;
    if (isEvaluationResultFilter) cnt++;

    return cnt;
  }, [interest, black, notEvaluate, notPass, pass, sparePass]);

  return filterCount;
};
