import { useMockAuth } from '@hooks/useMockAuth';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
const AuthProvider = ({ children }: Props) => {
  const { isSettled } = useMockAuth();
  if (!isSettled) return <></>;

  return <div>{children}</div>;
};

export default AuthProvider;
