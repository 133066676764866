import { disassembleHangul } from '@helpers/search';
import { useEffect, useState } from 'react';

export const useSearch = <T, K extends keyof T>(
  searchText: string,
  list: T[],
  key: K,
) => {
  // 검색 결과를 저장할 상태
  const [searchResultList, setSearchResultList] = useState<T[]>([]);

  // 검색어가 변경될 때마다 실행
  useEffect(() => {
    // 리스트에서 검색어를 포함하는 항목만 필터링
    if (list.length === 0 || !Array.isArray(list)) return;
    const resultList = list.filter((item) => {
      const disassembledSearchText = [...searchText]
        .map(disassembleHangul)
        .join('');
      const disassembledItem = [
        ...(key ? (item[key] as string) : (item as string)),
      ]
        .map(disassembleHangul)
        .join('');

      return disassembledItem.includes(disassembledSearchText);
    });
    setSearchResultList(resultList);
  }, [searchText, list, key]);

  return {
    searchResultList,
  };
};
