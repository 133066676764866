import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  z-index: 5;

  align-items: center;
  gap: ${({ theme }) => theme.spacing.space100};
  background-color: ${({ theme }) => theme.colors['primary-700']};
  color: ${({ theme }) => theme.colors.white};
  padding: 14px 24px;
`;
