export const BreakPoints = {
  toArray() {
    return [360, 768, 1280, 1440, 1920];
  },
  toJSON() {
    return {
      xs: '360px',
      sm: '768px',
      md: '1280px',
      lg: '1440px',
      xl: '1920px',
    };
  },
  toJS() {
    return {
      xs: 360,
      sm: 768,
      md: 1280,
      lg: 1440,
      xl: 1920,
    };
  },
};
