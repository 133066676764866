import Pagination from '@components/common/Pagination/Pagination';
import { useEvaluationStepListQuery } from '@repositories/evaluation/evaluation/evaluation.query';
import {
  selectedStepTypeAtom,
  stepCurrentPageAtom,
  stepPageSizeAtom,
} from '@stores/atoms/evaluation/evaluation.atom';
import { evaluationStepListSelector } from '@stores/selectors/evaluation/evaluation.selector';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as S from './footer.style';

const Footer = () => {
  const setCurrentPage = useSetRecoilState(stepCurrentPageAtom);
  const setPageSize = useSetRecoilState(stepPageSizeAtom);

  const request = useRecoilValue(evaluationStepListSelector);
  const selectedStepType = useRecoilValue(selectedStepTypeAtom);
  const { data } = useEvaluationStepListQuery(request);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedStepType]);

  if (!data) return <></>;
  const { pageUtil } = data!;

  return (
    <S.Container>
      <Pagination
        {...pageUtil}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        pageSizeOption={[5, 10, 15, 20]}
      />
    </S.Container>
  );
};

export default Footer;
