import { InputList, Text } from '@ats/ui';
import { FinalEvaluation } from '@components/evaluation/grid-screening/modal/GridFilterModal/FinalEvaluationList.tsx';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import * as S from '../gridFilterModal.style';

export const FinalEvaluationFilter = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    return (
      <>
        <S.Divider />

        <S.Column>
          <Text variant={'b2'} bold>
            최종평가
          </Text>
          <InputList>
            <FinalEvaluation ref={ref} />
          </InputList>
        </S.Column>
      </>
    );
  },
);
