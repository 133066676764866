import { BUTTON_COLORS, Button, Icon, SIZE, TextField } from '@ats/ui';
import { useTheme } from '@emotion/react';
import { searchKeywordAtom } from '@stores/atoms/evaluation/document.atom';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import * as S from './right.style';

export const Search = () => {
  const theme = useTheme();

  const [text, setText] = useState('');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const setSearchKeyword = useSetRecoilState(searchKeywordAtom);
  const handleSubmitSearch = () => {
    setSearchKeyword(text);
  };

  const searchKeywordReset = useResetRecoilState(searchKeywordAtom);
  useEffect(() => {
    searchKeywordReset();
  }, [searchKeywordReset]);

  return (
    <S.TextFieldWrapper>
      <TextField
        size={SIZE.SM}
        variant='dark'
        leftSection={
          <Icon
            name='icon_search_18_line'
            style={{ fill: text.length > 0 ? theme.colors['gray-200'] : '' }}
          />
        }
        onChange={handleChange}
        onPressEnter={handleSubmitSearch}
        value={text}
        placeholder='수험번호, 학교, 성명'
        style={{ width: '100%' }}
      />
      <Button
        color={BUTTON_COLORS.THEMA}
        size={SIZE.SM}
        style={{ padding: '6px 20px' }}
        onClick={handleSubmitSearch}
      >
        검색
      </Button>
    </S.TextFieldWrapper>
  );
};
