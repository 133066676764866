import { ReactNode } from 'react';
import { Await, useLoaderData } from 'react-router-dom';

export const GridScreeningContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const prefetchingData = useLoaderData();

  return <Await resolve={prefetchingData}>{children}</Await>;
};
