import { convertToFormData } from '@helpers/formData.util';
import { videoInterviewerNameAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import axios from 'axios';
import { useRecoilValue } from 'recoil';

export const useEnterVideoInterview = (screeningResumeSn: string) => {
  const interviewerName = useRecoilValue(videoInterviewerNameAtom);

  const enterVideoInterview = async () => {
    const { data } = await axios.post(
      `${import.meta.env.VITE_API_URL}/mrs2/eval/jobMeetingUrl`,
      convertToFormData({ screeningResumeSn }),
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
      },
    );

    const ORIGIN = 'https://meeting.kr-dv-midasitwebsol.com';
    const PATH = '/interviewer';
    const QUERY = new URLSearchParams({
      ...data,
      intervieweeName: data.name,
      name: interviewerName,
    });
    const targetUrl = new URL(`${ORIGIN}${PATH}?${QUERY}`);

    window.open(targetUrl.toString());
  };

  return { enterVideoInterview };
};
