import { TABLE_Z_INDEX } from '@constants/TableZIndex.constants';
import { useTheme } from '@emotion/react';
import {
  gridFreezePanesCoordinateSelector,
  isShowScrollbarAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const GridFreezePanes = () => {
  const gridFreezePanesCoordinate = useRecoilValue(
    gridFreezePanesCoordinateSelector,
  );
  const [positionState, setPositionState] = useState<'absolute' | 'initial'>(
    'absolute',
  );
  const setIsShowScrollbar = useSetRecoilState(isShowScrollbarAtom);

  const theme = useTheme();

  /**
   * 열 고정을 화면 넘어서 지정했을 때 가로 스크롤링을 불가능하게 하도록 설정한 것
   */
  useEffect(() => {
    if (window.innerWidth < gridFreezePanesCoordinate) {
      setPositionState('initial');
      setIsShowScrollbar(false);
    } else {
      setPositionState('absolute');
      setIsShowScrollbar(true);
    }
  }, [gridFreezePanesCoordinate, window.innerWidth]);

  return (
    <div
      style={{
        position: positionState,
        content: '',
        display: 'block',
        width: '1px',
        background: `${theme.colors['gray-800']}`,
        height: 'calc(100vh - 160px)',
        top: '0',
        left: `${gridFreezePanesCoordinate}px`,
        zIndex: TABLE_Z_INDEX.FREEZE_PANES,
      }}
    ></div>
  );
};
