import { ReactComponent as IconEvaluation } from '@assets/interview/icon_evaluation.svg';
import { IconButton, Spin, Text } from '@ats/ui';
import { isEvaluationRNBOpenAtom } from '@stores/atoms/evaluation/interview/interview.atom.ts';
import { Suspense } from 'react';
import { useSetRecoilState } from 'recoil';
import AbilityEvaluation from './AbilityEvaluation.tsx';
import FinalEvaluationFooter from './FinalEvaluationFooter.tsx';
import NoEvaluationItem from './NoEvaluationItem.tsx';
import TotalEvaluation from './TotalEvaluation.tsx';
import * as S from './evaluationRNB.style.ts';

const EvaluationRNB = () => {
  const setEvaluationModal = useSetRecoilState(isEvaluationRNBOpenAtom);
  const closeModal = () => {
    setEvaluationModal(false);
  };

  return (
    <S.Container>
      <S.Header>
        <div
          css={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <IconEvaluation
            css={{
              width: '24px',
              height: '24px',
            }}
          />
          <Text variant={'t3'} bold>
            상세평가
          </Text>
        </div>
        <IconButton
          onClick={closeModal}
          name={'icon_vert_align_top_18_line'}
          style={{ transform: 'rotate(90deg)' }}
        />
      </S.Header>
      <S.Divider />
      <Suspense fallback={<Spin />}>
        <EvaluationRnbBody />
      </Suspense>
    </S.Container>
  );
};
export default EvaluationRNB;

const EvaluationRnbBody = () => {
  const shouldRenderAbilityEvaluation = true;
  const shouldRenderTotalEvaluation = true;
  const noEvaluationItem = true;

  if (noEvaluationItem) return <NoEvaluationItem />;

  return (
    <>
      <S.Body>
        {shouldRenderAbilityEvaluation && <AbilityEvaluation />}
        {shouldRenderTotalEvaluation && <TotalEvaluation />}
      </S.Body>
      <FinalEvaluationFooter />
    </>
  );
};
