import { KeyRepository } from '@ats/repositories';
import { AUTHORIZATION_TYPE } from '@ats/repositories/src/constants/authorization.constant';
import { useQueries } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

const url = new URL(window.location.href);
const queryParams = new URLSearchParams(url.search);

// 임시
// 로컬에서 localhost로 쏘면 되고
// dv(tenant주소를 가지고 접근함)에서 tenant로 쏘면 된다.
const tenant = queryParams.get('tenant')
  ? queryParams.get('tenant')
  : url.origin;

const requestSessionAuth = async () => {
  const formData = new FormData();
  formData.append('authType', 'MANAGER');
  formData.append('id', 'ptj0125');
  formData.append('password', '1q2w3e4r5t6y');
  formData.append('saveTF', 'on');

  const { data } = await axios.post(`${tenant}/cus/loginDo/no-rsa`, formData, {
    withCredentials: true,
  });

  return data;
};

const requestCusTokenAuth = async () => {
  const reqDTO = {
    id: 'ptj0125',
    password: '1q2w3e4r5t6y',
    accessDomain: 'demo01-cms-recruiter-co-kr.kr-dv-midasitwebsol.com',
  };
  const { data } = await axios.post(`${tenant}/cus/auth-token`, reqDTO, {
    withCredentials: true,
  });

  return data;
};

export const useMockAuth = () => {
  const [
    { isSuccess: isSessionAuthSuccess },
    { isSuccess: isTokenAuthSuccess, data: authToken },
  ] = useQueries({
    queries: [
      {
        queryKey: ['auth', 'session'],
        queryFn: requestSessionAuth,
      },
      {
        queryKey: ['auth', 'token'],
        queryFn: requestCusTokenAuth,
      },
    ],
  });

  useEffect(() => {
    if (!authToken) return;

    const keyRepository = KeyRepository.getInstance();
    keyRepository.updateAccessToken(authToken.token);
    keyRepository.updateAuthorizationType(AUTHORIZATION_TYPE.BASIC);
  }, [authToken]);

  return { isSettled: isSessionAuthSuccess && isTokenAuthSuccess };
};
