import { Spin } from '@ats/ui';
import * as S from './fullPageSpin.style.ts';

const FullPageSpin = () => {
  return (
    <S.Container>
      <Spin size={'md'} color={'blue'} />
    </S.Container>
  );
};

export default FullPageSpin;
