import type { ResumeFormProps } from '@components/common/ResumeForm/ResumeForm';
import { ResumeForm } from '@components/common/ResumeForm/ResumeForm';
import type { ReactNode } from 'react';
import { createElement, useRef } from 'react';

export const useResumeForm = ({ ...props }: Omit<ResumeFormProps, 'url'>) => {
  const { ...rest } = props;
  const formRef = useRef<HTMLFormElement>(null);

  const url = `/mrs2/eval/resume/popResume`;

  const handleSubmit = () => {
    const POPUP_WIDTH = 1465;
    const POPUP_HEIGHT = 400;

    const target = window.open(
      url,
      'resumePopup',
      `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},left=${
        screen.width / 2 - POPUP_WIDTH / 2
      },top=${screen.height / 2 - POPUP_HEIGHT / 2}`,
    );

    target?.focus();

    formRef.current?.submit();
  };

  const RenderResumeForm = (): ReactNode => {
    return createElement(ResumeForm, {
      ref: formRef,
      url,
      ...rest,
    });
  };

  return {
    RenderResumeForm,
    handleSubmit,
  };
};
