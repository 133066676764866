import type { Editor } from '@toast-ui/react-editor';
import type { Dispatch, SetStateAction } from 'react';
import { useRef } from 'react';

type HookCallback = (url: string, text?: string) => void;

export function useTextEditor() {
  const editorRef = useRef<Editor>(null);

  const onChange = (setContents: Dispatch<SetStateAction<string>>) => {
    if (!editorRef.current) return;
    setContents(editorRef.current.getInstance().getHTML());
  };

  // @ts-expect-error
  const addImageBlobHook = async (blob: File, callback: HookCallback) => {
    // const fileSn = await sendFile({
    //   file: blob,
    //   uploadFileCategory: 'FILE_CATEGORY_EDITOR_IMAGE',
    // }).unwrap();
    // const fileUrl = await getFileUrl({ attachFileSn: fileSn }).unwrap();
    // callback(fileUrl, blob?.name || 'image');

    return false;
  };

  return { editorRef, addImageBlobHook, onChange };
}
