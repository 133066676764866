import { PAGES } from '@ats/constants';
import FullPageSpin from '@components/common/FullPageSpin/FullPageSpin';
import MainLayout from '@layouts/Main';
import NoticeHomePage from '@pages/Notice/Home';
import NoticeReadPage from '@pages/Notice/Read';
import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

const NoticeRoute: RouteObject = {
  path: PAGES.INTERVIEWER_EVALUATOR.NOTICE.HOME,
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<FullPageSpin />}>
          <NoticeHomePage />
        </Suspense>
      ),
    },
    {
      path: PAGES.INTERVIEWER_EVALUATOR.NOTICE.READ(':sn'),
      element: (
        <Suspense fallback={<FullPageSpin />}>
          <NoticeReadPage />
        </Suspense>
      ),
    },
  ],
};

export default NoticeRoute;
