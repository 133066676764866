import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type {
  EvaluationItemInfos,
  EvaluationItemRatingScales,
  RatingScale,
} from '@repositories/evaluation/document/document.dto';
import { evaluationItemInfosAtom } from '@stores/atoms/evaluation/document.atom';
import { gridFilterEvaluationItemsAtom } from '@stores/atoms/evaluation/document.filter';
import type { ChangeEvent, ForwardedRef } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { EvaluationItem } from './EvaluationItem';

export const EvaluationList = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    const flatEvaluationItems: Partial<EvaluationItemInfos>[] = [];
    const formatFlattenEvaluationItems = (
      evaluationItemInfos: EvaluationItemInfos[],
    ) => {
      for (const evaluationItem of evaluationItemInfos) {
        if (evaluationItem.evaluationItemType !== 'ITEM') continue;
        if (evaluationItem.ratingScales === null) {
          formatFlattenEvaluationItems(evaluationItem.childList);
        }
        if (
          evaluationItem.ratingScales &&
          evaluationItem.ratingScales.length > 0
        ) {
          flatEvaluationItems.push({
            evaluationItemSn: evaluationItem.evaluationItemSn,
            evaluationItemName: evaluationItem.evaluationItemName,
            ratingScales: evaluationItem.ratingScales,
          });
        }
      }
    };
    const evaluationItemInfos = useRecoilValue(evaluationItemInfosAtom);
    formatFlattenEvaluationItems(evaluationItemInfos);

    const [gridFilterEvaluationItems, setGridFilterEvaluationItems] =
      useRecoilState(gridFilterEvaluationItemsAtom);
    const [select, setSelect] = useState<EvaluationItemRatingScales[]>(
      gridFilterEvaluationItems,
    );

    useEffect(() => {
      console.log(select);
    }, [select]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSelect((prev) => {
        const target = e.target as HTMLInputElement;
        const targetEvaluationItemSn = Number(target.dataset.evaluationitemsn);
        const targetValue = Number(target.value);
        if (
          prev.some((item) => item.evaluationItemSn === targetEvaluationItemSn)
        ) {
          return [
            ...prev.map((item) => {
              if (item.evaluationItemSn === targetEvaluationItemSn) {
                if (item.ratingScaleSns.includes(targetValue)) {
                  return {
                    evaluationItemSn: targetEvaluationItemSn,
                    ratingScaleSns: item.ratingScaleSns.filter(
                      (ratingScaleSn) => ratingScaleSn !== targetValue,
                    ),
                  };
                } else {
                  return {
                    evaluationItemSn: targetEvaluationItemSn,
                    ratingScaleSns: [...item.ratingScaleSns, targetValue],
                  };
                }
              }
              return item;
            }),
          ];
        }
        return [
          ...prev,
          {
            evaluationItemSn: targetEvaluationItemSn,
            ratingScaleSns: [targetValue],
          },
        ];
      });
    };

    const refreshFn = () => {
      setSelect([]);
    };
    const applyFn = () => {
      setGridFilterEvaluationItems(select);
    };
    useImperativeHandle(ref, () => ({
      refreshFn,
      applyFn,
    }));

    return (
      <>
        {flatEvaluationItems.map((item) => {
          return (
            <EvaluationItem
              key={item.evaluationItemSn}
              evaluationItemSn={item.evaluationItemSn as number}
              name={item.evaluationItemName as string}
              scaleList={item.ratingScales as RatingScale[]}
              handleChange={handleChange}
              selectedRatingScaleSns={
                select.filter(
                  (selectItem) =>
                    selectItem.evaluationItemSn === item.evaluationItemSn,
                )[0]
              }
              setSelect={setSelect}
            />
          );
        })}
      </>
    );
  },
);
