export const Body = {
  toJSON() {
    return {
      '.b1': {
        'font-size': '16px',
        'line-height': '24px',
        'font-weight': '500',
      },
      '.b1-bold': {
        'font-size': '16px',
        'line-height': '24px',
        'font-weight': '700',
      },
      '.b2': {
        'font-size': '14px',
        'line-height': '20px',
        'font-weight': '500',
      },
      '.b2-bold': {
        'font-size': '14px',
        'line-height': '20px',
        'font-weight': '700',
      },
      '.b3': {
        'font-size': '12px',
        'line-height': '18px',
        'font-weight': '500',
      },
      '.b3-bold': {
        'font-size': '12px',
        'line-height': '18px',
        'font-weight': '700',
      },
    };
  },
  toJS() {
    return {
      b1: `
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    `,
      ['b1-bold']: `
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    `,
      b2: `
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    `,
      ['b2-bold']: `
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    `,
      b3: `
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    `,
      ['b3-bold']: `
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
    `,
    };
  },
};
