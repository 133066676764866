import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const NameArea = styled.div`
  display: flex;
  align-items: center;
`;

export const NameSuffix = styled.span`
  ${({ theme }) => theme.body['b2-bold']};
`;

export const Name = styled(NameSuffix)`
  color: ${({ theme }) => theme.colors['primary-700']};
`;

export const TimeArea = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  padding: 2px 2px 2px 10px;
  margin-left: 8px;
  width: max-content;
  height: 32px;
  background: ${({ theme }) => theme.colors['gray-100']};
  border-radius: ${({ theme }) => theme.radius.radius75};
`;

export const Time = styled.span`
  ${({ theme }) => theme.body.b3};
`;

export const ButtonWrapper = styled.div`
  width: 73px;
  height: 32px;
  margin-left: 6px;
  background: ${({ theme }) => theme.colors['gray-300']};
  border-radius: ${({ theme }) => theme.radius.radius75};
`;
