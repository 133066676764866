import { Spin } from '@ats/ui';
import { GridScreeningContainer } from '@components/evaluation/grid-screening/Container';
import { GridScreeningHeader } from '@components/evaluation/grid-screening/grid-screening-header/GridScreeningHeader';
import { Grid } from '@components/evaluation/grid-screening/grid/Grid';
import { useGridScreening } from '@hooks/evaluation/grid-screening/useGridScreening';
import { Suspense } from 'react';

const GridScreening = () => {
  const { noticeSn } = useGridScreening();

  return (
    <GridScreeningContainer>
      <GridScreeningHeader />
      <Suspense
        fallback={
          <div style={{ height: '100vh' }}>
            <Spin size={'md'} color={'blue'} />
          </div>
        }
      >
        {((typeof noticeSn === 'number' && noticeSn > 0) ||
          noticeSn === null) && <Grid />}
      </Suspense>
    </GridScreeningContainer>
  );
};

export default GridScreening;
