import type { SORT_COLUMN } from '@components/evaluation/interview/interview.constant';
import type { SORT_TYPE } from '@constants/evaluation.constant';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

export interface PrintFormProps {
  url: string;
  screeningSn: number | string;
  sortColumn: SORT_COLUMN;
  sortType: SORT_TYPE;
  recruitSectorSnList?: string;
}
export const PrintForm = forwardRef(
  (
    {
      url,
      screeningSn,
      sortColumn,
      sortType,
      recruitSectorSnList,
    }: PrintFormProps,
    ref: ForwardedRef<HTMLFormElement>,
  ) => {
    return (
      <form
        ref={ref}
        // action={import.meta.env.VITE_API_URL + url}
        action={url}
        method='post'
        target={'printPopup'}
      >
        <input type='hidden' name='screeningSn' value={screeningSn} />
        <input type='hidden' name='sortColumn' value={sortColumn} />
        <input type='hidden' name='sortType' value={sortType} />
        {recruitSectorSnList && (
          <input
            type='hidden'
            name='recruitSectorSnList'
            value={recruitSectorSnList}
          />
        )}
      </form>
    );
  },
);
