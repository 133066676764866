import { BaseRepository } from '@ats/repositories';
import type {
  EvaluationStepListRsDTO,
  ValuerSecurityOathRsDTO,
} from './evaluation.dto';

class EvaluationStepListRepository extends BaseRepository {
  public async getEvaluationStepList(request: FormData) {
    const { data } = await super.post<EvaluationStepListRsDTO>(
      '/cus/evaluation/myStepListForJson',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }

  public async getICalUrl() {
    const { data } = await super.post<string>(
      '/cus/evaluation/iCalMemberRegister',
    );

    return data;
  }

  public async getValuerSecurityOath(request: FormData) {
    const { data } = await super.post<ValuerSecurityOathRsDTO>(
      '/cus/evaluation/getValuerSecurityOath',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }

  public async saveValuerSecurityOath(request: FormData) {
    const { data } = await super.post(
      '/cus/evaluation/saveValuerSecurityOath',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }
}

export const evaluationStepListRepository = new EvaluationStepListRepository(
  '',
);
