import { Dropdown } from '@ats/ui';
import { useSectorList } from '@hooks/evaluation/grid-screening/useSectorList';

interface SectorListProps {
  isOpen: boolean;
  handleSetSectorText: (text: string) => void;
}
export const SectorList = ({
  isOpen,
  handleSetSectorText,
}: SectorListProps) => {
  const {
    searchText,
    searchResultList,
    handleChangeSectorSearch,
    handleClickSector,
  } = useSectorList({ handleSetSectorText });

  return (
    <Dropdown.ItemContainer
      isOpen={isOpen}
      style={{ height: '240px', width: '340px' }}
      showScrollbarWrapper
      isOverflowScrollable
    >
      <Dropdown.TextField
        value={searchText}
        onChange={handleChangeSectorSearch}
        placeholder='검색어를 입력해주세요.'
      />
      <Dropdown.Separate />
      {searchResultList.map((sector) => (
        <Dropdown.ButtonItem
          onClick={handleClickSector}
          key={sector.sn}
          value={sector.sn}
        >
          {sector.name}
        </Dropdown.ButtonItem>
      ))}
    </Dropdown.ItemContainer>
  );
};
