import type { ScreeningInterviewResumeListRqDTO } from '@repositories/evaluation/interview/interview.dto';
import {
  interviewKeywordAtom,
  interviewKeywordTypeAtom,
} from '@stores/atoms/evaluation/interview/interview.atom';
import { selectorFamily } from 'recoil';
import { interviewDateSelector } from './interview.date.selector';
import { interviewFilterSelector } from './interview.filter.selector';
import { InterviewPageUtilSelector } from './interview.page.selector';

export const interviewBaseSelector = selectorFamily<
  ScreeningInterviewResumeListRqDTO,
  string
>({
  key: 'interviewBaseSelector',
  get:
    (screeningSn) =>
    ({ get }) => {
      const interviewPageUtil = get(InterviewPageUtilSelector);

      return {
        screeningSn,
        screeningType: 'interview',
        ...interviewPageUtil,
        searchAllApplicant: true,
        searchDayAllApplicant: false,
      };
    },
});

export const interviewSelector = selectorFamily<
  ScreeningInterviewResumeListRqDTO,
  string
>({
  key: 'interviewSelector',
  get:
    (screeningSn) =>
    ({ get }) => {
      const base = get(interviewBaseSelector(screeningSn));
      const interviewDateDetail = get(interviewDateSelector);
      const interviewFilter = get(interviewFilterSelector);
      const keyword = get(interviewKeywordAtom);
      const keywordType = get(interviewKeywordTypeAtom);

      return {
        ...base,
        keyword,
        keywordType,
        ...interviewDateDetail,
        ...interviewFilter,
      };
    },
});
