import logoSrc from '@assets/header/logo.svg';
import { PAGES } from '@ats/constants';
import { useNavigate } from 'react-router-dom';
import SessionPanel from './SessionPanel';
import { Container, Logo } from './style';

const Header = () => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate(PAGES.INTERVIEWER_EVALUATOR.HOME);
  };
  return (
    <Container>
      <Logo src={logoSrc} onClick={goToHome} />
      <SessionPanel />
    </Container>
  );
};

export default Header;
