import { BaseRepository } from '@ats/repositories';

class CSRFRepository extends BaseRepository {
  public async getCSRFToken(): Promise<string> {
    const { data } = await super.get<string>('/mrs2/createAUToken');
    return data;
  }
}

export const csrfRepository = new CSRFRepository('');
