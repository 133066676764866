import { ReactComponent as AbsenceImg } from '@assets/interview/profile_absence.svg';
import { ReactComponent as DefaultProfileImg } from '@assets/interview/profile_default.svg';
import { PrimaryColors } from '@ats/foundation';
import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Icon,
  SIZE,
  Text,
} from '@ats/ui';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip';
import { ICON_VIEW_MAP } from '@constants/evaluation.constant';
import { useTheme } from '@emotion/react';
import type { InterviewResume } from '@repositories/evaluation/interview/interview.dto';
import { isVideoInterviewAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import { useRecoilValue } from 'recoil';
import { INTERVIEW_STATUS_CODE } from '../interview.constant';
import { FinalEvaluationSection } from './FinalEvaluationSection';
import VideoInterviewButton from './VideoInterviewButton/VideoInterviewButton';
import * as S from './card.style';
import { useCardData } from './useCardData';

interface Props extends InterviewResume {}

const AbsenceCard = (props: Props) => {
  const {
    name,
    age,
    iconViewList,
    resumeDisplayNo,
    recruitNoticeName,
    recruitSectorName,
    interviewStartDatetime,
    interviewClosingDatetime,
    interviewPlaceName,
    interviewGroupName,
    pictureFileUrl,

    finalValuerYn,
    screeningValuerSn,
    screeningResultCode,
  } = props;

  const theme = useTheme();
  const { interviewDate, iconList } = useCardData({
    interviewStartDatetime,
    interviewClosingDatetime,
    iconViewList,
  });
  const isVideoInterview = useRecoilValue(isVideoInterviewAtom);

  return (
    <S.AbsenceContainer>
      <S.BlurBackground />
      <S.AbsenceWrapper>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <AbsenceImg />
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Text variant={'b2'}>본 지원자는 면접에 불참하였습니다.</Text>
            <Text variant={'b2'}>이에 지원서 열람 및 평가가 제한됩니다.</Text>
          </div>
        </div>
      </S.AbsenceWrapper>
      <S.BasicWrapper style={{ filter: 'blur(2px)' }}>
        <S.UpperSection>
          <S.Profile>
            <S.ProfileInfo>
              <S.ProfileName>{name}</S.ProfileName>
              <S.ProfileAge>{age ?? '비어있음'}</S.ProfileAge>
            </S.ProfileInfo>
            <S.ProfileIconGroup>
              {iconList?.map((iconName) => (
                <HeadlessTooltip
                  key={iconName}
                  content={ICON_VIEW_MAP[iconName].label}
                >
                  <Icon
                    name={ICON_VIEW_MAP[iconName].name}
                    color={PrimaryColors['primary-600']}
                  />
                </HeadlessTooltip>
              ))}
            </S.ProfileIconGroup>
          </S.Profile>
          <S.ResumeInfo>
            <S.ResumeDisplayNumber>{resumeDisplayNo}</S.ResumeDisplayNumber>
          </S.ResumeInfo>
        </S.UpperSection>

        <S.MiddleSection>
          <S.Recruit>
            <S.RecruitNotice>{recruitNoticeName}</S.RecruitNotice>
            <S.RecruitSector>{recruitSectorName}</S.RecruitSector>
          </S.Recruit>
          <S.Interview>
            <S.InterviewDate>
              <Icon
                name={'icon_event_18_line'}
                color={theme.colors['gray-600']}
              />
              <div>{interviewDate}</div>
            </S.InterviewDate>
            {isVideoInterview ? (
              <div style={{ height: '22px' }} />
            ) : (
              <>
                <S.InterviewPlace>
                  <Icon
                    name={'icon_pin_18_line'}
                    color={theme.colors['gray-600']}
                  />
                  <div>{interviewPlaceName ?? '장소 미배정'}</div>
                </S.InterviewPlace>
                <S.InterviewGroup>
                  <Icon
                    name={'icon_people_18_line'}
                    color={theme.colors['gray-600']}
                  />
                  {interviewGroupName ?? '면접조 미배정'}
                </S.InterviewGroup>
              </>
            )}
          </S.Interview>
        </S.MiddleSection>

        <S.LowerSection>
          {isVideoInterview ? (
            <VideoInterviewButton
              interviewStatusCode={INTERVIEW_STATUS_CODE.CLOSE}
              screeningResumeSn={''}
            />
          ) : (
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={SIZE.SM}
              color={BUTTON_COLORS.THEMA}
              rightIcon={'icon_arrow_right_18_line'}
              style={{ width: '100px', paddingRight: '8px' }}
            >
              상세평가
            </Button>
          )}

          {pictureFileUrl ? (
            <S.ProfileImg>
              <img src={pictureFileUrl} alt='profile_img' />
            </S.ProfileImg>
          ) : (
            <DefaultProfileImg />
          )}
        </S.LowerSection>
      </S.BasicWrapper>
      {finalValuerYn && (
        <>
          <S.Divider />
          <FinalEvaluationSection
            style={{ filter: 'blur(2px)' }}
            screeningValuerSn={screeningValuerSn}
            screeningResultCode={screeningResultCode}
          />
        </>
      )}
    </S.AbsenceContainer>
  );
};

export default AbsenceCard;
