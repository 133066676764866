import { PAGES } from '@ats/constants';
import { BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { PAGE_KEY } from '@constants/pageKey.constant';
import { useInitArticleSelector } from '@hooks/useInitArticleSelectorFamily';
import type { ArticleItem } from '@repositories/article/article.dto';
import { useArticleListQuery } from '@repositories/article/article.query';
import { useCustomerInfoQuery } from '@repositories/customer/customer.query.ts';
import { articleSelectorFamily } from '@stores/selectors/article.selectorFamily';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Card from './Card';
import Empty from './Empty';
import Footer from './Footer';
import NotFound from './NotFound';
import Search from './Search';
import { Container, Content, Header, Title, TitleArea } from './style';

const QuestionAndAnswerHomePage = () => {
  const navigate = useNavigate();
  const { resetArticleSelector } = useInitArticleSelector(PAGE_KEY.QNA);

  const articleRequestDTO = useRecoilValue(articleSelectorFamily(PAGE_KEY.QNA));
  const {
    data: { articleList },
  } = useArticleListQuery(articleRequestDTO);
  const {
    data: {
      userInfo: { memberSn },
    },
  } = useCustomerInfoQuery();

  useEffect(() => {
    resetArticleSelector();
  }, []);

  return (
    <Container>
      <TitleArea>
        <Title>Q&A</Title>
      </TitleArea>
      <Header>
        <Button
          leftIcon='icon_edit_24_line'
          variant={BUTTON_VARIANTS.PRIMARY}
          size={SIZE.SM}
          onClick={() =>
            navigate(PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.CREATE)
          }
        >
          문의하기
        </Button>
        <Search />
      </Header>
      <Content>
        <ContentArea
          memberSn={memberSn}
          articleList={articleList}
          keyword={articleRequestDTO.searchText}
        />
      </Content>
      <Footer />
    </Container>
  );
};

interface ContentAreaProps {
  articleList: ArticleItem[];
  keyword: string;
  memberSn: string;
}
const ContentArea = ({ articleList, keyword, memberSn }: ContentAreaProps) => {
  if (articleList)
    return (
      <>
        {articleList.map((el) => (
          <Card key={el.articleSn} loginMemberSn={memberSn} {...el} />
        ))}
      </>
    );

  if (keyword) return <NotFound />;
  return <Empty />;
};

export default QuestionAndAnswerHomePage;
