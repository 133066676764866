import {
  PAGE_KEY_ARTICLE_MAP,
  type PageKey,
} from '@constants/pageKey.constant';
import type { ArticleListRqDTO } from '@repositories/article/article.dto';
import {
  searchKeywordAtomFamily,
  searchKeywordTypeAtomFamily,
} from '@stores/atoms/searchKeyword.atomFamily';
import { selectorFamily } from 'recoil';
import { pageSelectorFamily } from './page.selectorFamily';

export const articleSelectorFamily = selectorFamily<ArticleListRqDTO, PageKey>({
  key: 'articleSelectorFamily',
  get:
    (pageKey) =>
    ({ get }) => {
      const { currentPage, pageSize } = get(pageSelectorFamily(pageKey));
      const searchText = get(searchKeywordAtomFamily(pageKey));
      const searchType = get(searchKeywordTypeAtomFamily(pageKey));

      return {
        categorySn: PAGE_KEY_ARTICLE_MAP[pageKey],
        currentPage,
        pageSize,
        searchText,
        searchType,
      };
    },
});
