export enum SEARCH_KEYWORD_TYPE {
  TITLE_CONTENT = 'TITLE_CONTENT',
  TITLE = 'TITLE',
  CONTENT = 'CONTENT',
  CREATOR = 'CREATOR',
}
export const SEARCH_KEYWORD_TYPE_MAP = {
  [SEARCH_KEYWORD_TYPE.TITLE_CONTENT]: '제목+내용',
  [SEARCH_KEYWORD_TYPE.TITLE]: '제목만',
  [SEARCH_KEYWORD_TYPE.CONTENT]: '내용만',
  [SEARCH_KEYWORD_TYPE.CREATOR]: '글작성자',
} as const;
export const SEARCH_KEYWORD_TYPE_LIST = [
  SEARCH_KEYWORD_TYPE.TITLE_CONTENT,
  SEARCH_KEYWORD_TYPE.TITLE,
  SEARCH_KEYWORD_TYPE.CONTENT,
  SEARCH_KEYWORD_TYPE.CREATOR,
] as const;

export type SearchKeywordType = SEARCH_KEYWORD_TYPE;
