import { useDropdown } from '@ats/ui';
import { useSearchIndex } from '@hooks/common/useSearchIndex';
import {
  screeningNoticeSnAtom,
  screeningSectorSnAtom,
  sectorPriorityListAtom,
  selectSectorPriorityListAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const usePriority = () => {
  const [checkPriorityList, setCheckPriorityList] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  const handleCheckPriority = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = Number(e.target.value);

    if (checkPriorityList.includes(targetValue)) {
      setCheckPriorityList((prev) => {
        return prev.filter((priority) => {
          priority !== targetValue;
        });
      });
    } else {
      setCheckPriorityList((prev) => [...prev, targetValue]);
    }
  };

  const handleChangePrioritySearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(e.target.value);
  };

  const noticeSn = useRecoilValue(screeningNoticeSnAtom);
  const sectorSn = useRecoilValue(screeningSectorSnAtom);

  const [sectorPriorityList] = useRecoilState(sectorPriorityListAtom);

  const [sectorPriorityStringList, setSectorPriorityStringList] = useState<
    string[]
  >([]);

  useEffect(() => {
    const stringMapping = sectorPriorityList.map(
      (priority) => `${priority}지망`,
    );
    setSectorPriorityStringList(stringMapping);
  }, [sectorPriorityList]);

  const { searchResultIndexList } = useSearchIndex(
    searchText,
    sectorPriorityStringList,
  );

  const setSelectSectorPriorityList = useSetRecoilState(
    selectSectorPriorityListAtom,
  );

  const handleClickSubmitPriority = () => {
    setSelectSectorPriorityList(checkPriorityList);
    handleCloseDropdown();
  };

  return {
    checkPriorityList,
    searchText,
    sectorPriorityList,
    searchResultIndexList,
    isOpen,
    dropdownContainerRef,
    noticeSn,
    sectorSn,
    handleCheckPriority,
    handleToggleDropdown,
    handleChangePrioritySearch,
    handleClickSubmitPriority,
  };
};
