import { createBrowserRouter } from 'react-router-dom';
import EvaluationRoute from './evaluation';
import ExceptionRoute from './exception';
import HealthRoute from './health';
import MainRoute from './main';
import NoticeRoute from './notice';
import QuestionAndAnswerRoute from './question-and-answer';
import SearchRoute from './search';

type Router = ReturnType<typeof createBrowserRouter>;
export const router: Router = createBrowserRouter([
  HealthRoute,
  MainRoute,
  QuestionAndAnswerRoute,
  NoticeRoute,
  EvaluationRoute,
  SearchRoute,
  ...ExceptionRoute,
]);
