import Pagination from '@components/common/Pagination/Pagination';
import { useTheme } from '@emotion/react';
import type { PageUtil } from '@repositories/common/pageUtil.dto';
import {
  currentPageAtom,
  pageSizeAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useSetRecoilState } from 'recoil';

export const GridScreeningFooter = (props: PageUtil) => {
  const setCurrentPage = useSetRecoilState(currentPageAtom);
  const setPageSize = useSetRecoilState(pageSizeAtom);

  const theme = useTheme();

  return (
    <div
      style={{
        position: 'fixed',
        background: 'white',
        zIndex: 2,
        bottom: '32px',
        height: '32px',
        width: '100%',
        borderTop: `1px solid ${theme.colors['gray-300']}`,
      }}
    >
      <Pagination
        {...props}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        pageSizeOption={[10, 20, 30, 50, 100]}
      />
    </div>
  );
};
