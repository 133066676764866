import { InputItem } from '@ats/ui';
import type { SCREENING_RESULT_CODE } from '@constants/evaluation.constant';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import { resultCodeMappingsAtom } from '@stores/atoms/evaluation/document.atom.ts';
import { gridFilterFinalEvaluateListAtom } from '@stores/atoms/evaluation/document.filter.ts';
import type { ChangeEvent, ForwardedRef } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

export const FinalEvaluation = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    const resultCodeMappings = useRecoilValue(resultCodeMappingsAtom);
    const [gridFilterFinalEvaluateList, setGridFilterFinalEvaluateList] =
      useRecoilState(gridFilterFinalEvaluateListAtom);

    const [select, setSelect] = useState<SCREENING_RESULT_CODE[]>(
      gridFilterFinalEvaluateList,
    );
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      if (select.includes(target.value as SCREENING_RESULT_CODE)) {
        setSelect((prev) =>
          prev.filter(
            (item) => item !== (target.value as SCREENING_RESULT_CODE),
          ),
        );
      } else {
        setSelect((prev) => [...prev, target.value as SCREENING_RESULT_CODE]);
      }
    };

    const refreshFn = () => {
      setSelect([]);
    };
    const applyFn = () => {
      setGridFilterFinalEvaluateList(select);
    };
    useImperativeHandle(ref, () => ({
      refreshFn,
      applyFn,
    }));

    return (
      <>
        {resultCodeMappings.map((resultCode) => {
          return (
            <InputItem.Checkbox
              onChange={handleChange}
              value={resultCode.screeningResultCode}
              checked={select.includes(resultCode.screeningResultCode)}
            >
              {resultCode.screeningResultCodeName}
            </InputItem.Checkbox>
          );
        })}
      </>
    );
  },
);
