import { ReactComponent as ErrorYellow } from '@assets/error/icon_error_yellow_fill.svg';
import { PAGES } from '@ats/constants';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { sessionEndTime } from '@stores/atoms/auth';
import { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Container, Content, Description, Title, Wrapper } from './style';

const NotFoundPage: FC = () => {
  const setSessionEndTime = useSetRecoilState(sessionEndTime);
  const navigate = useNavigate();

  useEffect(() => {
    setSessionEndTime(null);
  }, []);

  return (
    <Container>
      <Wrapper>
        <Content>
          <ErrorYellow />
          <Title>페이지를 찾을 수 없습니다.</Title>
          <Description>
            페이지가 존재하지 않거나 사용할 수 없는 페이지입니다.
            <br />
            입력하신 주소가 정확한지 다시 한 번 확인해주세요.
          </Description>
          <Button
            size={SIZE.SM}
            variant={BUTTON_VARIANTS.PRIMARY}
            color={BUTTON_COLORS.DEFAULT}
            style={{ marginTop: 40 }}
            onClick={() =>
              navigate(PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME)
            }
          >
            뒤로가기
          </Button>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default NotFoundPage;
