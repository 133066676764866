import {
  currentPageResumeSnListAtom,
  isAllPageCheckAtom,
  totalApplicantCountAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue } from 'recoil';
import * as S from './left.style';

export const CheckedApplicantCount = () => {
  const checkedApplicantCount = useRecoilValue(
    currentPageResumeSnListAtom,
  ).length;

  const checkedIsAll = useRecoilValue(isAllPageCheckAtom);
  const totalApplicantCount = useRecoilValue(totalApplicantCountAtom);

  if (checkedApplicantCount === 0 && !checkedIsAll) return <></>;

  const resultCount = () => {
    if (checkedIsAll) return totalApplicantCount;

    return checkedApplicantCount;
  };
  return <S.BlueText>{resultCount()}명 선택</S.BlueText>;
};
