import type { KeywordType } from '@components/evaluation/interview/interview.constant';
import { KEYWORD_TYPE } from '@components/evaluation/interview/interview.constant';
import { atom } from 'recoil';

/**
 * useCleanInit에 atom reset 추가 필요
 */
export const isFilterModalOpenAtom = atom({
  key: 'isFilterModalOpenAtom',
  default: false,
});

export const interviewKeywordAtom = atom({
  key: 'interviewKeywordAtom',
  default: '',
});
export const interviewKeywordTypeAtom = atom<KeywordType>({
  key: 'interviewKeywordTypeAtom',
  default: KEYWORD_TYPE.NAME,
});

export const videoInterviewerNameAtom = atom({
  key: 'videoInterviewerNameAtom',
  default: '',
});
// 화상면접을 볼 면접자의 이력서sn; 화상면접 입장 확인 모달에 전달하기 위해 사용
export const screeningResumeSnAtom = atom({
  key: 'screeningResumeSnAtom',
  default: '',
});

export const isVideoInterviewAtom = atom({
  key: 'isVideoInterviewAtom',
  default: false,
});

export const isEvaluationRNBOpenAtom = atom({
  key: 'isEvaluationRNBOpenAtom',
  default: false,
});
