export const Heading = {
  toJSON() {
    return {
      '.h1': {
        'font-size': '48px',
        'line-height': '60px',
        'font-weight': '500',
      },
      '.h1-bold': {
        'font-size': '48px',
        'line-height': '60px',
        'font-weight': '700',
      },
      '.h2': {
        'font-size': '36px',
        'line-height': '44px',
        'font-weight': '500',
      },
      '.h2-bold': {
        'font-size': '36px',
        'line-height': '44px',
        'font-weight': '700',
      },
      '.h3': {
        'font-size': '30px',
        'line-height': '38px',
        'font-weight': '500',
      },
      '.h3-bold': {
        'font-size': '30px',
        'line-height': '38px',
        'font-weight': '700',
      },
    };
  },
  toJS() {
    return {
      h1: `
      font-size: 48px;
      line-height: 60px;
      font-weight: 500;
    `,
      ['h1-bold']: `
      font-size: 48px;
      line-height: 60px;
      font-weight: 700;
    `,
      h2: `
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;
    `,
      ['h2-bold']: `
      font-size: 36px;
      line-height: 44px;
      font-weight: 700;
    `,
      h3: `
      font-size: 30px;
      line-height: 38px;
      font-weight: 500;
    `,
      ['h3-bold']: `
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
    `,
    };
  },
};
