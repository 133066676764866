import type { ScreeningInterviewScheduleListRsDTO } from '@repositories/evaluation/interview/interview.dto';

export const NOT_ASSIGNED_DATE = null;

export const useSortedDateList = (
  dateList: ScreeningInterviewScheduleListRsDTO,
) => {
  const assignedDateList = dateList.filter((el) => el.date);
  const notAssignedDateItem = dateList.reduce(
    (acc, cur) =>
      cur.date
        ? acc
        : {
            ...acc,
            interviewList: acc.interviewList.concat(cur.interviewList),
          },
    {
      date: NOT_ASSIGNED_DATE,
      interviewList: [],
    },
  );

  if (notAssignedDateItem.interviewList.length)
    assignedDateList.push(notAssignedDateItem);

  return assignedDateList;
};
