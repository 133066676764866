import { DIRECTION, Scroll, Table } from '@ats/ui';
import { GridBody } from '@components/evaluation/grid-screening/grid/grid-body/GridBody.tsx';
import { useGrid } from '@hooks/evaluation/grid-screening/useGrid';
import { isShowScrollbarAtom } from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue } from 'recoil';

import { GridScreeningFooter } from '../grid-screening-footer/GridScreeningFooter';
import { EmptyGrid } from './EmptyGrid';
import { GridFreezePanes } from './grid-body/GridFreezePanes';
import { GridHeaderRow } from './grid-header/GridHeaderRow';

export const Grid = () => {
  const { gridData, scrollHeight } = useGrid();
  const { headers, response } = gridData;
  const {
    content: applicants,
    totalPages: lastPage,
    totalElements: recordCount,
    pageable: { pageNumber: currentPageIndex, pageSize },
  } = response;

  const isShowScrollbar = useRecoilValue(isShowScrollbarAtom);

  return (
    <div style={{ position: 'relative' }}>
      {applicants.length > 0 ? (
        <>
          <Scroll
            scrollDirection={DIRECTION.ALL}
            isOverflowScrollable
            containerStyle={scrollHeight}
            /** 아래 두 스타일은 열 고정을 화면 넘어서 지정했을 때 가로 스크롤링을 불가능하게 하도록 설정한 것 */
            itemContainerStyle={!isShowScrollbar ? { overflow: 'hidden' } : {}}
            horizontalScrollbarStyle={
              !isShowScrollbar ? { display: 'none' } : {}
            }
          >
            <Table.Container>
              <GridHeaderRow headers={headers} />
              <GridBody applicants={applicants} />
            </Table.Container>
          </Scroll>
          <GridFreezePanes />
          <GridScreeningFooter
            lastPage={lastPage}
            recordCount={recordCount}
            pageSize={pageSize}
            currentPage={currentPageIndex}
          />
        </>
      ) : (
        <EmptyGrid />
      )}
    </div>
  );
};
