import type { Nullable } from '@ats/interfaces';

export class LocalStorageHandler {
  public get(key: string): Nullable<string> {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }

  public set(key: string, value: unknown): boolean {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {
      return false;
    }
  }

  public remove(key: string): boolean {
    try {
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}
