import { Table } from '@ats/ui';
import { TABLE_Z_INDEX } from '@constants/TableZIndex.constants';
import { GRID_FIELD_TYPE } from '@constants/evaluation.constant';
import type { Header } from '@repositories/evaluation/document/document.dto';
import type { headersWithFreezeYn } from '@stores/atoms/evaluation/document.atom';
import {
  columnListAtom,
  freezePanesIndexAtom,
  headersWithFreezeYnAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { GridHeadCol } from './gird-header-col/GridHeadCol';
import { GridHeadColCheckbox } from './gird-header-col/GridHeadColCheckbox';

export interface GridHeaderRowProps {
  headers: Header[];
}

export const GridHeaderRow = ({ headers }: GridHeaderRowProps) => {
  const screeningSn = useRecoilValue(screeningSnAtom);
  const setFreezePanesIndex = useSetRecoilState(freezePanesIndexAtom);

  const [headersWithFreezeYn, setHeadersWithFreezeYn] = useRecoilState(
    headersWithFreezeYnAtom,
  );

  // 다른 전형에 들어갔을 때 초기값으로 '성별'에 열고정을 한다.
  useEffect(() => {
    const headerCodes = headers.map((item) => {
      return {
        code: item.code,
        freezeYn: item.code === GRID_FIELD_TYPE.genderFlag,
      };
    });
    setHeadersWithFreezeYn(headerCodes);
  }, [screeningSn]);

  const setColumnList = useSetRecoilState(columnListAtom);
  useEffect(() => {
    /** Grid Body Column을 그리기 위해 순서를 저장한다. */
    const headerCodeList = headers.map((header) => {
      return header.code;
    });
    setColumnList(headerCodeList);

    /** 열 고정 로직 */
    if (headersWithFreezeYn.length > 0) {
      const findCurrentGenderFlagColumnIndex = () => {
        const findLastFreezePanesColumn = headersWithFreezeYn.find(
          (item) => item.freezeYn === true,
        ) as headersWithFreezeYn;
        const isIncludeFreezeColumnInCurrentHeaderList =
          headerCodeList.includes(findLastFreezePanesColumn.code);

        /**
         * headers가 바뀔 때마다 현재 열고정하고 있는 column이 없다면 headers 리스트에서 '성별'의 index를 찾아서 고정시킨다.
         * (전체 공고일 때와 공고 선택했을 때와 열 index가 다름)
         */
        if (!isIncludeFreezeColumnInCurrentHeaderList) {
          const genderFlagIndex = headers.findIndex(
            (header) => header.code === GRID_FIELD_TYPE.genderFlag,
          );
          return genderFlagIndex + 1;
        }

        /**
         * 마지막에 열고정한 column의 이름을 찾고 마지막 header 리스트에서 index를 찾기 위한 로직
         */
        const finalFreezeIndex = headers.findIndex(
          (header) => header.code === findLastFreezePanesColumn.code,
        );
        return finalFreezeIndex + 1;
      };

      const resultFreezeIndex = findCurrentGenderFlagColumnIndex();
      setFreezePanesIndex(resultFreezeIndex);
    }
  }, [headers, headersWithFreezeYn]);

  return (
    <Table.Head>
      <Table.Row
        style={{
          height: '36px',
          position: 'sticky',
          top: '0',
          zIndex: TABLE_Z_INDEX.HEADER,
        }}
      >
        <GridHeadColCheckbox />
        {headers.map((header, index) => {
          // 체크박스 제외하고 index는 1부터 시작
          return (
            <GridHeadCol
              key={header.code}
              header={header}
              colIndex={index + 1}
            />
          );
        })}
      </Table.Row>
    </Table.Head>
  );
};
