import { SORT_TYPE } from '@constants/evaluation.constant';
import { isFilterModalOpenAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import {
  interviewFilterBlackAtom,
  interviewFilterInterestAtom,
  interviewFilterNotEvaluateAtom,
  interviewFilterNotPassAtom,
  interviewFilterPassAtom,
  interviewFilterSparePassAtom,
  localInterviewFilterBlackAtom,
  localInterviewFilterInterestAtom,
  localInterviewFilterNotEvaluateAtom,
  localInterviewFilterNotPassAtom,
  localInterviewFilterPassAtom,
  localInterviewFilterSparePassAtom,
  localSortColumnAtom,
  localSummarySortTypeAtom,
  sortColumnAtom,
  summarySortTypeAtom,
} from '@stores/atoms/evaluation/interview/interview.filter.atom';
import { useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { SORT_COLUMN } from '../../interview.constant';

export const useFilterModal = () => {
  const setIsFilterModalOpen = useSetRecoilState(isFilterModalOpenAtom);
  const [interviewFilterInterestStore, setInterviewFilterInterestStore] =
    useRecoilState(interviewFilterInterestAtom);
  const [interviewFilterBlackStore, setInterviewFilterBlackStore] =
    useRecoilState(interviewFilterBlackAtom);

  const [interviewFilterNotEvaluateStore, setInterviewFilterNotEvaluateStore] =
    useRecoilState(interviewFilterNotEvaluateAtom);
  const [interviewFilterNotPassStore, setInterviewFilterNotPassStore] =
    useRecoilState(interviewFilterNotPassAtom);
  const [interviewFilterPassStore, setInterviewFilterPassStore] =
    useRecoilState(interviewFilterPassAtom);
  const [interviewFilterSparePassStore, setInterviewFilterSparePassStore] =
    useRecoilState(interviewFilterSparePassAtom);

  const [sortColumnStore, setSortColumnStore] = useRecoilState(sortColumnAtom);
  const [summarySortTypeStore, setSummarySortTypeStore] =
    useRecoilState(summarySortTypeAtom);

  const [interviewFilterInterest, setInterviewFilterInterest] = useRecoilState(
    localInterviewFilterInterestAtom,
  );
  const [interviewFilterBlack, setInterviewFilterBlack] = useRecoilState(
    localInterviewFilterBlackAtom,
  );

  const [interviewFilterNotEvaluate, setInterviewFilterNotEvaluate] =
    useRecoilState(localInterviewFilterNotEvaluateAtom);
  const [interviewFilterNotPass, setInterviewFilterNotPass] = useRecoilState(
    localInterviewFilterNotPassAtom,
  );
  const [interviewFilterPass, setInterviewFilterPass] = useRecoilState(
    localInterviewFilterPassAtom,
  );
  const [interviewFilterSparePass, setInterviewFilterSparePass] =
    useRecoilState(localInterviewFilterSparePassAtom);

  const [sortColumn, setSortColumn] = useRecoilState(localSortColumnAtom);
  const [summarySortType, setSummarySortType] = useRecoilState(
    localSummarySortTypeAtom,
  );

  const interestProps = {
    checked: interviewFilterInterest,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterviewFilterInterest(e.target.checked);
    },
  };
  const blackProps = {
    checked: interviewFilterBlack,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterviewFilterBlack(e.target.checked);
    },
  };
  const allInterestBlackChecked = useMemo<boolean>(
    () => interviewFilterInterest && interviewFilterBlack,
    [interviewFilterInterest, interviewFilterBlack],
  );
  const noInterestBlackChecked = useMemo<boolean>(
    () => !interviewFilterInterest && !interviewFilterBlack,
    [interviewFilterInterest, interviewFilterBlack],
  );
  const interestBlackAllProps = {
    checked: allInterestBlackChecked,
    indeterminate: !allInterestBlackChecked && !noInterestBlackChecked,
    onChange: () => {
      if (allInterestBlackChecked) {
        setInterviewFilterInterest(false);
        setInterviewFilterBlack(false);
        return;
      }

      setInterviewFilterInterest(true);
      setInterviewFilterBlack(true);
    },
  };

  const notEvaluateProps = {
    checked: interviewFilterNotEvaluate,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterviewFilterNotEvaluate(e.target.checked);
    },
  };
  const notPassProps = {
    checked: interviewFilterNotPass,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterviewFilterNotPass(e.target.checked);
    },
  };
  const passProps = {
    checked: interviewFilterPass,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterviewFilterPass(e.target.checked);
    },
  };
  const sparePassProps = {
    checked: interviewFilterSparePass,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterviewFilterSparePass(e.target.checked);
    },
  };
  const allEvaluationChecked = useMemo<boolean>(
    () =>
      interviewFilterNotEvaluate &&
      interviewFilterNotPass &&
      interviewFilterPass &&
      interviewFilterSparePass,
    [
      interviewFilterNotEvaluate,
      interviewFilterNotPass,
      interviewFilterPass,
      interviewFilterSparePass,
    ],
  );
  const noEvaluationChecked = useMemo<boolean>(
    () =>
      !interviewFilterNotEvaluate &&
      !interviewFilterNotPass &&
      !interviewFilterPass &&
      !interviewFilterSparePass,
    [
      interviewFilterNotEvaluate,
      interviewFilterNotPass,
      interviewFilterPass,
      interviewFilterSparePass,
    ],
  );
  const evaluationAllProps = {
    checked: allEvaluationChecked,
    indeterminate: !allEvaluationChecked && !noEvaluationChecked,
    onChange: () => {
      if (allEvaluationChecked) {
        setInterviewFilterNotEvaluate(false);
        setInterviewFilterNotPass(false);
        setInterviewFilterPass(false);
        setInterviewFilterSparePass(false);
        return;
      }

      setInterviewFilterNotEvaluate(true);
      setInterviewFilterNotPass(true);
      setInterviewFilterPass(true);
      setInterviewFilterSparePass(true);
    },
  };

  const handleRefreshFilter = () => {
    setInterviewFilterInterest(false);
    setInterviewFilterBlack(false);

    setInterviewFilterNotEvaluate(false);
    setInterviewFilterNotPass(false);
    setInterviewFilterPass(false);
    setInterviewFilterSparePass(false);

    setSortColumn(SORT_COLUMN.RESUME_DISPLAY_NO);
    setSummarySortType(SORT_TYPE.ASC);

    setInterviewFilterInterestStore(false);
    setInterviewFilterBlackStore(false);

    setInterviewFilterNotEvaluateStore(false);
    setInterviewFilterNotPassStore(false);
    setInterviewFilterPassStore(false);
    setInterviewFilterSparePassStore(false);

    setSortColumnStore(SORT_COLUMN.RESUME_DISPLAY_NO);
    setSummarySortTypeStore(SORT_TYPE.ASC);
  };

  const handleApplyFilter = () => {
    setInterviewFilterInterestStore(interviewFilterInterest);
    setInterviewFilterBlackStore(interviewFilterBlack);

    setInterviewFilterNotEvaluateStore(interviewFilterNotEvaluate);
    setInterviewFilterNotPassStore(interviewFilterNotPass);
    setInterviewFilterPassStore(interviewFilterPass);
    setInterviewFilterSparePassStore(interviewFilterSparePass);

    setSortColumnStore(sortColumn);
    setSummarySortTypeStore(summarySortType);

    setIsFilterModalOpen(false);
  };

  const filterCount =
    0 + (noInterestBlackChecked ? 0 : 1) + (noEvaluationChecked ? 0 : 1);

  return {
    interestProps,
    blackProps,
    interestBlackAllProps,

    notEvaluateProps,
    notPassProps,
    passProps,
    sparePassProps,
    evaluationAllProps,

    sortColumn,
    setSortColumn,
    summarySortType,
    setSummarySortType,

    handleRefreshFilter,
    handleApplyFilter,

    filterCount,
  };
};
