/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalStorageHandler } from '@ats/helpers';
import type { Nullable } from '@ats/interfaces';
import dayjs from 'dayjs';

const localStorage = new LocalStorageHandler();

export const syncAccessTokenEffects =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const accessToken: Nullable<string> = localStorage.get(key);

    if (accessToken !== null) {
      setSelf(JSON.parse(accessToken));
    }

    if (accessToken === null) {
      setSelf(null);
    }

    onSet((newValue: Nullable<string>, _: any, isReset: boolean) => {
      isReset ? localStorage.remove(key) : localStorage.set(key, newValue);
    });
  };

export const syncSessionEndTimeEffects =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const sessionEndTime = localStorage.get(key);

    if (sessionEndTime !== null) {
      setSelf(dayjs(JSON.parse(sessionEndTime)));
    }

    if (sessionEndTime === null) {
      setSelf(null);
    }

    onSet((newValue: Nullable<string>, _: any, isReset: boolean) => {
      isReset ? localStorage.remove(key) : localStorage.set(key, newValue);
    });
  };

export const syncTenantIdEffects =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const tenantId = localStorage.get(key);

    if (tenantId !== null) {
      setSelf(JSON.parse(tenantId));
    }

    if (tenantId === null) {
      setSelf(null);
    }

    onSet((newValue: Nullable<string>, _: any, isReset: boolean) => {
      isReset ? localStorage.remove(key) : localStorage.set(key, newValue);
    });
  };
