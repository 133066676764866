import { Table } from '@ats/ui';
import type { Applicant } from '@repositories/evaluation/document/document.dto';
import { GridBodyRow } from './grid-body-row/GridBodyRow';

export interface GridBodyProps {
  applicants: Applicant[];
}

export const GridBody = ({ applicants }: GridBodyProps) => {
  return (
    <Table.Body>
      {applicants.map((applicant, i) => {
        return (
          <GridBodyRow
            key={i}
            applicant={applicant}
            applicantSerialNumberInfo={applicant.serialNumberInfo}
          />
        );
      })}
    </Table.Body>
  );
};
