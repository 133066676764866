import { Button, BUTTON_VARIANTS, Icon, SIZE } from '@ats/ui';
import { EditorBottom } from '@components/notice/Comments/Textarea/style.ts';
import { queryClient } from '@helpers/client.ts';
import { useAlert } from '@hooks/shared/useAlert.ts';
import { csrfRepository } from '@repositories/csrf/csrf.repository.ts';
import type { CustomDate } from '@repositories/notice/notice.dto.ts';
import {
  NoticeQueryKey,
  useDeleteNoticeCommentMutation,
  usePostNoticeCommentMutation,
  useUpdateNoticeCommentMutation,
} from '@repositories/notice/notice.query.ts';
import type { GetQnaCommentsRsDTO } from '@repositories/qna/qna.dto.ts';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useState } from 'react';
import Textarea from '../Textarea';
import {
  Container,
  Content,
  CreatedAt,
  Editor,
  Writer,
  WriterArea,
} from './style';

export const enum COMMENT_TYPE {
  READ,
  UPDATE,
  WRITE,
}

const MONTH_OFFSET = 1;
const YEAR_OFFSET = 1900;
const Comment: FC<
  Partial<GetQnaCommentsRsDTO> & { type?: COMMENT_TYPE; memberSn: string }
> = ({
  creatorName,
  contents: articleContent,
  lastModifyDate,
  type = COMMENT_TYPE.READ,
  memberSn,
  creatorSn,
  articleSn,
  commentSn,
}) => {
  const { mutate: createMutate } = usePostNoticeCommentMutation();
  const { mutate: updateMutate } = useUpdateNoticeCommentMutation();
  const { mutate: deleteMutate } = useDeleteNoticeCommentMutation();

  const { openAlert } = useAlert();
  const { getCSRFToken } = csrfRepository;

  const [mode, setMode] = useState<COMMENT_TYPE>(type);
  const [contents, setContents] = useState(articleContent ?? '');
  const [cached] = useState(articleContent ?? '');

  const convertLastModifyDate = (lastModifyDate?: CustomDate) => {
    if (!lastModifyDate) return;
    const { year, month, day, hours, minutes } = lastModifyDate;
    return `${year + YEAR_OFFSET}-${
      month + MONTH_OFFSET
    }-${day} ${hours}:${minutes}`;
  };

  const handleModifyComment = () => {
    if (!commentSn || !articleSn) return;

    updateMutate(
      {
        articleSn: articleSn,
        contents,
        commentSn,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [...NoticeQueryKey.getComment()],
          });
          setMode(COMMENT_TYPE.READ);
        },
      },
    );
  };

  const handleSubmitComment = async () => {
    if (!articleSn) return;

    createMutate(
      {
        request: { articleSn: articleSn, contents },
        AUToken: await getCSRFToken(),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [...NoticeQueryKey.getComment()],
          });
          setMode(COMMENT_TYPE.READ);
        },
      },
    );
  };

  const handleDeleteComment = () => {
    if (!commentSn || !articleSn) return;
    deleteMutate(
      { articleSn, commentSn },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [...NoticeQueryKey.getComment()],
          });
        },
      },
    );
  };

  const handleClickCancelModify = () => {
    setContents(cached);
    setMode(COMMENT_TYPE.READ);
  };

  const SUBMIT = {
    [COMMENT_TYPE.UPDATE]: handleModifyComment,
    [COMMENT_TYPE.WRITE]: handleSubmitComment,
  };

  return (
    <Container>
      {mode !== COMMENT_TYPE.WRITE && (
        <WriterArea>
          <Writer>{creatorName}</Writer>
          <CreatedAt>
            {dayjs(convertLastModifyDate(lastModifyDate)).format(
              'YYYY.MM.DD H:mm',
            )}
          </CreatedAt>
          {memberSn === creatorSn && mode === COMMENT_TYPE.READ && (
            <>
              <Icon
                name='icon_edit_24_filled'
                style={{
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
                onClick={() => {
                  setMode(COMMENT_TYPE.UPDATE);
                }}
              />
              <Icon
                name='icon_delete_18_filled'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openAlert({
                    title: '',
                    content: '댓글을 삭제하시겠습니까?',
                    callback: () => {
                      handleDeleteComment();
                    },
                  });
                }}
              />
            </>
          )}
        </WriterArea>
      )}
      {mode === COMMENT_TYPE.READ ? (
        <Content>{contents}</Content>
      ) : (
        <Editor>
          <Textarea
            placeholder='내용을 입력해주세요'
            content={contents}
            setContent={setContents}
          />
          <EditorBottom>
            {mode === COMMENT_TYPE.UPDATE && (
              <Button
                variant={BUTTON_VARIANTS.OUTLINED}
                size={SIZE.SM}
                onClick={() => handleClickCancelModify()}
              >
                취소
              </Button>
            )}
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={SIZE.SM}
              onClick={() => SUBMIT[mode]()}
              disabled={contents === ''}
            >
              등록
            </Button>
          </EditorBottom>
        </Editor>
      )}
    </Container>
  );
};

export default Comment;
