import { PAGES } from '@ats/constants';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, Icon, Text } from '@ats/ui';
import FlatTag from '@components/common/FlatTag/FlatTag';
import Comments from '@components/q&a/Comments';
import { ARTICLE_CATEGORY_TYPE } from '@constants/article.constants';
import { useTheme } from '@emotion/react';
import { queryClient } from '@helpers/client.ts';
import { useAlert } from '@hooks/shared/useAlert.ts';
import {
  ArticleQueryKey,
  useArticleQuery,
} from '@repositories/article/article.query';
import { useCustomerInfoQuery } from '@repositories/customer/customer.query.ts';
import { useDeleteArticleMutation } from '@repositories/qna/qna.query.ts';
import type { FC } from 'react';
import { Suspense } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as S from './style';

const QuestionAndAnswerReadPage: FC = () => {
  const { sn } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { openAlert } = useAlert();

  if (!sn) throw Error('잘못된 접근입니다');

  const { data } = useArticleQuery({
    categorySn: ARTICLE_CATEGORY_TYPE.EVALUATOR_QNA,
    articleSn: sn,
    privatePassword: location.state?.privatePassword,
  });

  const { articleInfo } = data;
  const { data: customerInfo } = useCustomerInfoQuery();
  const { mutate } = useDeleteArticleMutation();
  const {
    userInfo: { memberSn },
  } = customerInfo;

  const {
    title,
    creatorName,
    lastModifyDate,
    contents,
    answerCount,
    creatorSn,
  } = articleInfo;

  return (
    <S.Container>
      <S.Area>
        <Text variant={'h3'} bold>
          {title}
        </Text>
      </S.Area>
      <S.Area>
        <S.InformationArea>
          <S.Description>{creatorName}</S.Description>
          <S.Divider />
          <S.Description>{lastModifyDate}</S.Description>
          <FlatTag
            color={
              answerCount ? theme.colors['blue-600'] : theme.colors['gray-600']
            }
            backgroundColor={
              answerCount ? theme.colors['blue-100'] : theme.colors['gray-100']
            }
          >
            <Text variant={'b3'}>답변 {answerCount ?? 0}</Text>
          </FlatTag>
        </S.InformationArea>
      </S.Area>
      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: contents }} />
      </S.Content>
      <S.Area>
        <Suspense fallback={<div>...loading</div>}>
          <Comments upArticleSn={sn} memberSn={memberSn} />
        </Suspense>
      </S.Area>
      <S.Area style={{ display: 'flex' }}>
        <Button
          color={BUTTON_COLORS.THEMA}
          onClick={() =>
            navigate(PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME)
          }
        >
          목록으로
        </Button>
        {memberSn === creatorSn && (
          <>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                openAlert({
                  title: '',
                  content: '게시물을 삭제하시겠습니까?',
                  callback: () => {
                    mutate(
                      { articleSn: sn },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries({
                            queryKey: [...ArticleQueryKey.list()],
                          });
                          navigate(
                            PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER
                              .HOME,
                          );
                        },
                      },
                    );
                  },
                });
              }}
            >
              <Icon name='icon_delete_48_line' />
              삭제
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              onClick={() =>
                navigate(
                  PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.EDIT(sn),
                  {
                    state: {
                      privatePassword: location.state?.privatePassword ?? '',
                    },
                  },
                )
              }
            >
              <Icon name='icon_edit_18_line' />
              수정하기
            </Button>
          </>
        )}
      </S.Area>
    </S.Container>
  );
};

export default QuestionAndAnswerReadPage;
