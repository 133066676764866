import styled from '@emotion/styled';

export const Tooltip = styled.div`
  background-color: ${({ theme }) => theme.colors['gray-800']};
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
`;

export const IconArea = styled.div`
  display: flex;

  margin-left: 4px;
`;
