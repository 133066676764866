import { screeningResumeSnAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import {
  recruitNoticeCodeSnAtom,
  recruitNoticeSnAtom,
  recruitSectorSnAtom,
  screeningValuerSnAtom,
} from '@stores/atoms/evaluation/interview/interview.eval.atom';
import { selectorFamily } from 'recoil';

export const interviewEvaluationSelector = selectorFamily({
  key: 'interviewEvaluationSelector',
  get:
    (screeningSn: string) =>
    ({ get }) => {
      const screeningResumeSn = get(screeningResumeSnAtom);
      const screeningValuerSn = get(screeningValuerSnAtom);
      const recruitNoticeSn = get(recruitNoticeSnAtom);
      const recruitNoticeCodeSn = get(recruitNoticeCodeSnAtom);
      const recruitSectorSn = get(recruitSectorSnAtom);

      return {
        screeningSn,
        screeningResumeSn,
        screeningValuerSn,
        recruitNoticeSn,
        recruitNoticeCodeSn,
        recruitSectorSn,
      };
    },
});
