import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const UpperSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
export const LowerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }
`;
export const LeftSection = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
`;
export const RightSection = styled.div`
  display: flex;
  gap: 8px;
`;
export const EmptyArrow = styled.div`
  width: 32px;
  height: 32px;
`;
