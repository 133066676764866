import {
  BUTTON_VARIANTS,
  Dropdown,
  Icon,
  Portal,
  SIZE,
  usePortalDropdown,
} from '@ats/ui';
import { useSearch } from '@hooks/common/useSearch';
import type {
  EvaluationItemRatingScales,
  RatingScale,
} from '@repositories/evaluation/document/document.dto';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

interface EvaluationItemProps {
  evaluationItemSn: number;
  name: string;
  scaleList: RatingScale[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedRatingScaleSns: EvaluationItemRatingScales;
  setSelect: React.Dispatch<React.SetStateAction<EvaluationItemRatingScales[]>>;
}

export const EvaluationItem = ({
  evaluationItemSn,
  name,
  scaleList,
  handleChange,
  selectedRatingScaleSns,
  setSelect,
}: EvaluationItemProps) => {
  const {
    isOpen,
    dropdownContainerRef,
    dropdownItemContainerRef,
    handleToggleDropdown,
  } = usePortalDropdown();

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const [searchText, setSearchText] = useState('');

  const { searchResultList: searchResultScaleList } = useSearch<
    RatingScale,
    'name'
  >(searchText, scaleList, 'name');

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (dropdownContainerRef.current) {
      const dropdownRect = dropdownContainerRef.current.getBoundingClientRect();
      const TRIGGER_HEIGHT = 32;

      setTop(dropdownRect.top + TRIGGER_HEIGHT);
      setLeft(dropdownRect.left);
    }
  }, [isOpen]);

  const checkAllProps = {
    checked:
      selectedRatingScaleSns?.ratingScaleSns.length ===
      searchResultScaleList.length,
    indeterminate:
      selectedRatingScaleSns?.ratingScaleSns.length !==
        searchResultScaleList.length &&
      selectedRatingScaleSns?.ratingScaleSns.length > 0,
    onChange: () => {
      setSelect((prev) => {
        if (prev.some((item) => item.evaluationItemSn === evaluationItemSn)) {
          return prev.map((item) => {
            if (item.evaluationItemSn === evaluationItemSn) {
              if (item.ratingScaleSns.length === searchResultScaleList.length) {
                return {
                  evaluationItemSn: item.evaluationItemSn,
                  ratingScaleSns: [],
                };
              }
              return {
                evaluationItemSn: item.evaluationItemSn,
                ratingScaleSns: searchResultScaleList.map(
                  (item) => item.ratingScaleSn,
                ),
              };
            }

            return item;
          });
        } else {
          return [
            ...prev,
            {
              evaluationItemSn,
              ratingScaleSns: searchResultScaleList.map(
                (item) => item.ratingScaleSn,
              ),
            },
          ];
        }
      });
    },
  };

  return (
    <Dropdown.Container ref={dropdownContainerRef}>
      <Dropdown.Trigger
        style={{ width: '160px' }}
        variant={BUTTON_VARIANTS.SECONDARY}
        size={SIZE.SM}
        onClick={handleToggleDropdown}
      >
        {name}
        <Icon name='icon_arrow_bottom_18_line' />
      </Dropdown.Trigger>
      <Portal selector={'#dropdown-root'}>
        <Dropdown.ItemContainer
          ref={dropdownItemContainerRef}
          isOpen={isOpen}
          style={{ height: '240px', width: '160px', top, left }}
          showScrollbarWrapper
          isOverflowScrollable
        >
          <Dropdown.TextField
            value={searchText}
            onChange={handleChangeSearch}
            placeholder='입력해주세요.'
          />
          <Dropdown.Separate />
          <Dropdown.CheckItem label='전체' {...checkAllProps} />
          {searchResultScaleList?.map((item, i) => {
            return (
              <Dropdown.CheckItem
                key={i}
                label={item.name}
                data-evaluationitemsn={evaluationItemSn}
                checked={selectedRatingScaleSns?.ratingScaleSns.includes(
                  item.ratingScaleSn,
                )}
                onChange={handleChange}
                value={item.ratingScaleSn}
              />
            );
          })}
        </Dropdown.ItemContainer>
      </Portal>
    </Dropdown.Container>
  );
};
