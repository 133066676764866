interface SortIconAscendingProps {
  fill?: string;
}

const SortIconAscending = (props: SortIconAscendingProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none'>
    <defs>
      <linearGradient id='a' x1='0%' x2='0%' y1='0%' y2='100%'>
        <stop
          offset='0%'
          style={{
            stopColor: props.fill,
            stopOpacity: 1,
          }}
        />
        <stop
          offset='50%'
          style={{
            stopColor: props.fill,
            stopOpacity: 1,
          }}
        />
        <stop
          offset='50%'
          style={{
            stopColor: '#868e96',
            stopOpacity: 1,
          }}
        />
        <stop
          offset='100%'
          style={{
            stopColor: '#868e96',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
    <path
      fill='url(#a)'
      d='M10.8 10.065H7.2c-.435 0-.829.259-.997.656-.173.402-.09.863.21 1.178l1.8 1.897a1.081 1.081 0 0 0 1.575.004l1.8-1.898c.3-.314.382-.776.21-1.177a1.081 1.081 0 0 0-.998-.656v-.004ZM7.2 7.935h3.6c.435 0 .829-.259.998-.656.168-.398.09-.863-.21-1.178l-1.8-1.897a1.084 1.084 0 0 0-1.575 0L6.413 6.1A1.084 1.084 0 0 0 7.2 7.935Z'
    />
  </svg>
);
export default SortIconAscending;
