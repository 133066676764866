import {
  selectedStepSearchTypeAtom,
  selectedStepTypeAtom,
  stepCurrentPageAtom,
  stepPageSizeAtom,
  stepSearchKeywordAtom,
} from '@stores/atoms/evaluation/evaluation.atom';
import { selector } from 'recoil';

export const evaluationStepListSelector = selector({
  key: 'evaluationStepListSelector',
  get: ({ get }) => {
    const stepTypeCode = get(selectedStepTypeAtom);
    const currentPage = get(stepCurrentPageAtom);
    const searchText = get(stepSearchKeywordAtom);
    const searchType = get(selectedStepSearchTypeAtom);
    const pageSize = get(stepPageSizeAtom);

    return { stepTypeCode, currentPage, searchText, searchType, pageSize };
  },
});
