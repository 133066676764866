import type { Nullable } from '@ats/interfaces';
import { atomicKeys } from '@constants/key.query';
import {
  syncAccessTokenEffects,
  syncSessionEndTimeEffects,
  syncTenantIdEffects,
} from '@stores/effects/auth';
import type { Dayjs } from 'dayjs';
import { atom } from 'recoil';

export const accessToken = atom<Nullable<string>>({
  key: atomicKeys.accessToken,
  default: null,
  effects: [syncAccessTokenEffects(atomicKeys.accessToken)],
});

export const sessionEndTime = atom<Nullable<Dayjs>>({
  key: atomicKeys.sessionEndTime,
  default: null,
  effects: [syncSessionEndTimeEffects(atomicKeys.sessionEndTime)],
});

export const tenantId = atom<Nullable<string>>({
  key: atomicKeys.tenantId,
  default: null,
  effects: [syncTenantIdEffects(atomicKeys.tenantId)],
});
