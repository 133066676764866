import { Alert, Text, TextField } from '@ats/ui';
import { useTheme } from '@emotion/react';
import { useAlert } from '@hooks/shared/useAlert';
import { useModal } from '@hooks/shared/useModal';
import {
  screeningResumeSnAtom,
  videoInterviewerNameAtom,
} from '@stores/atoms/evaluation/interview/interview.atom';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEnterVideoInterview } from './useEnterVideoInterview';

const VideoInterviewerNameModal = () => {
  const theme = useTheme();
  const { closeModal } = useModal();
  const { openAlert } = useAlert();
  const setNameStore = useSetRecoilState(videoInterviewerNameAtom);
  const screeningResumeSn = useRecoilValue(screeningResumeSnAtom);

  const [name, setName] = useState('');
  const { enterVideoInterview } = useEnterVideoInterview(screeningResumeSn);

  const handleClickConfirm = () => {
    setNameStore(name);
    closeModal();
    openAlert({
      title: '',
      content: '화상 면접에 입장하시겠습니까?',
      callback: enterVideoInterview,
    });
  };

  return (
    <Alert.Container
      style={{ width: '440px', height: '246px', border: 'none' }}
      showBackgroundDim
    >
      <Alert.Header onClickClose={closeModal} title={'이름설정'} />
      <Alert.Body>
        <Text variant={'b1'} style={{ color: theme.colors['gray-600'] }}>
          화상면접에 사용할 이름을 입력해주세요.
        </Text>
        <div style={{ height: '18px' }}></div>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          onPressEnter={handleClickConfirm}
          placeholder={'이름을 입력해주세요.'}
        />
      </Alert.Body>
      <Alert.Footer
        onClickCancel={closeModal}
        onClickConfirm={handleClickConfirm}
      />
    </Alert.Container>
  );
};

export default VideoInterviewerNameModal;
