import { DIRECTION, Icon, InputItem, SIZE, Scroll, TextField } from '@ats/ui';
import { useSearch } from '@hooks/common/useSearch';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type {
  EvaluationItemInfos,
  EvaluationItemRatingScales,
  RatingScale,
} from '@repositories/evaluation/document/document.dto';
import { evaluationItemInfosAtom } from '@stores/atoms/evaluation/document.atom';
import { gridFilterTotalEvaluationAtom } from '@stores/atoms/evaluation/document.filter';
import type { ChangeEvent, ForwardedRef } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from './gridFilterModal.style';

export const TotalEvaluationList = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    const flatEvaluationItems: Partial<EvaluationItemInfos>[] = [];
    const formatFlattenEvaluationItems = (
      evaluationItemInfos: EvaluationItemInfos[],
    ) => {
      for (const evaluationItem of evaluationItemInfos) {
        if (evaluationItem.evaluationItemType !== 'OVERALL') continue;
        if (evaluationItem.ratingScales === null) {
          formatFlattenEvaluationItems(evaluationItem.childList);
        }
        if (
          evaluationItem.ratingScales &&
          evaluationItem.ratingScales.length > 0
        ) {
          flatEvaluationItems.push({
            evaluationItemSn: evaluationItem.evaluationItemSn,
            evaluationItemName: evaluationItem.evaluationItemName,
            ratingScales: evaluationItem.ratingScales,
          });
        }
      }
    };
    const evaluationItemInfos = useRecoilValue(evaluationItemInfosAtom);
    formatFlattenEvaluationItems(evaluationItemInfos);

    const [gridTotalEvaluation, setGridTotalEvaluation] = useRecoilState(
      gridFilterTotalEvaluationAtom,
    );
    const [select, setSelect] =
      useState<EvaluationItemRatingScales>(gridTotalEvaluation);

    const checkAllProps = {
      checked:
        select.ratingScaleSns.length ===
        flatEvaluationItems[0]?.ratingScales?.length,
      indeterminate:
        select.ratingScaleSns.length !==
          flatEvaluationItems[0]?.ratingScales?.length &&
        select.ratingScaleSns.length > 0,
      onChange: () => {
        setSelect((prev) => {
          if (
            prev.ratingScaleSns.length ===
            flatEvaluationItems[0]?.ratingScales?.length
          ) {
            return {
              evaluationItemSn: flatEvaluationItems[0]
                .evaluationItemSn as number,
              ratingScaleSns: [],
            };
          }

          return {
            evaluationItemSn: flatEvaluationItems[0].evaluationItemSn as number,
            ratingScaleSns: flatEvaluationItems[0].ratingScales!.map(
              (item) => item.ratingScaleSn,
            ),
          };
        });
      },
    };

    useEffect(() => {
      console.log(select);
    }, [select]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSelect((prev) => {
        const targetValue = Number(e.target.value);
        if (
          Object.keys(prev).length > 0 &&
          prev.ratingScaleSns.includes(targetValue)
        ) {
          return {
            evaluationItemSn: flatEvaluationItems[0].evaluationItemSn as number,
            ratingScaleSns: [
              ...prev.ratingScaleSns.filter((rating) => rating !== targetValue),
            ],
          };
        }

        if (Object.keys(prev).length === 0) {
          return {
            evaluationItemSn: flatEvaluationItems[0].evaluationItemSn as number,
            ratingScaleSns: [targetValue],
          };
        } else {
          return {
            evaluationItemSn: flatEvaluationItems[0].evaluationItemSn as number,
            ratingScaleSns: [...prev.ratingScaleSns, targetValue],
          };
        }
      });
    };

    const refreshFn = () => {
      setSelect({
        evaluationItemSn: 0,
        ratingScaleSns: [],
      });
    };
    const applyFn = () => {
      setGridTotalEvaluation(select);
    };
    useImperativeHandle(ref, () => ({
      refreshFn,
      applyFn,
    }));

    const [searchText, setSearchText] = useState('');

    const { searchResultList } = useSearch<RatingScale, 'name'>(
      searchText,
      flatEvaluationItems[0].ratingScales!,
      'name',
    );
    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    };

    return (
      <>
        <TextField
          value={searchText}
          onChange={handleChangeSearch}
          size={SIZE.SM}
          placeholder='입력해주세요.'
          leftSection={<Icon name='icon_search_18_line' size={24} />}
        />
        <S.Separate />
        <Scroll
          containerStyle={{ height: '100%', width: '100%' }}
          scrollDirection={DIRECTION.Y}
          isOverflowScrollable
        >
          <ul style={{ display: 'flex', flexFlow: 'column', gap: '4px' }}>
            <InputItem.Checkbox {...checkAllProps}>전체</InputItem.Checkbox>
            {searchResultList.map((item, i) => {
              return (
                <InputItem.Checkbox
                  key={i}
                  value={item.ratingScaleSn}
                  checked={select.ratingScaleSns?.includes(item.ratingScaleSn)}
                  onChange={handleChange}
                >
                  {item.name}
                </InputItem.Checkbox>
              );
            })}
          </ul>
        </Scroll>
      </>
    );
  },
);
