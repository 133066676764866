/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { createInstance } from '../fetchers/axios';
import KeyRepository from './key.repository';

function deepUnescapeHTML(data: unknown): unknown {
  if (typeof data === 'string') {
    return unescapeHTML(data);
  } else if (Array.isArray(data)) {
    return data.map(deepUnescapeHTML);
  } else if (typeof data === 'object' && data !== null) {
    const result: Record<string, unknown> = {};
    for (const key in data as Record<string, unknown>) {
      result[key] = deepUnescapeHTML((data as Record<string, unknown>)[key]);
    }
    return result;
  }
  return data;
}

export const unescapeHTML = (str: string) => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#x27;/g, "'")
    .replace(/&#x2F;/g, '/')
    .replace(/alert/g, '');
};

class BaseRepository {
  protected static _axios: AxiosInstance;
  private _apiUrl: string;

  constructor(staticURL: string) {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const tenant = queryParams.get('tenant')!;

    this._apiUrl = staticURL || tenant;

    BaseRepository._axios = createInstance({
      baseURL: staticURL || tenant,
      withCredentials: true,
      transformResponse: [
        (data) => {
          try {
            const parsedData: unknown = JSON.parse(data as string);
            return deepUnescapeHTML(parsedData);
          } catch {
            return data as string;
          }
        },
      ],
    });
  }

  protected static get defaultHeaders(): Record<string, any> {
    const accessToken = KeyRepository.getInstance().accessToken;

    return {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...(accessToken !== '' && {
        Authorization: accessToken,
      }),
    };
  }

  protected get currentBaseUrl(): string {
    return this._apiUrl;
  }

  protected get axios(): AxiosInstance {
    return BaseRepository._axios;
  }

  protected async post<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return BaseRepository._axios.post<any, AxiosResponse<T>>(url, data, {
      headers: {
        ...BaseRepository.defaultHeaders,
        ...config?.headers,
      },
      ...config,
    });
  }

  protected async get<T extends any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return BaseRepository._axios.get<any, AxiosResponse<T>>(url, {
      ...config,
      headers: {
        ...BaseRepository.defaultHeaders,
        ...config?.headers,
      },
    });
  }

  protected async put<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return BaseRepository._axios.put<any, AxiosResponse<T>>(
      url,
      JSON.stringify(data),
      {
        ...config,
        headers: {
          ...BaseRepository.defaultHeaders,
          ...config?.headers,
        },
      },
    );
  }

  protected async delete<T extends any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return BaseRepository._axios.delete<any, AxiosResponse<T>>(url, {
      ...config,
      headers: {
        ...BaseRepository.defaultHeaders,
        ...config?.headers,
      },
    });
  }

  protected async postForm<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return BaseRepository._axios.postForm<any, AxiosResponse<T>>(url, data, {
      ...config,
      headers: {
        ...BaseRepository.defaultHeaders,
        ...config?.headers,
      },
    });
  }
}

export default BaseRepository;
