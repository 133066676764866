import { useSuspenseQuery } from '@tanstack/react-query';
import type { ArticleListRqDTO, ArticleRqDTO } from './article.dto';
import { articleRepository } from './article.repository';

export const ArticleQueryKey = {
  all: 'article' as const,
  detail: () => [...ArticleQueryKey.all, 'detail'] as const,
  list: () => [...ArticleQueryKey.all, 'list'] as const,
};
export const useArticleListQuery = (request: ArticleListRqDTO) =>
  useSuspenseQuery({
    queryKey: [...ArticleQueryKey.list(), request],
    queryFn: () => articleRepository.getArticleList(request),
  });

export const useArticleQuery = (request: ArticleRqDTO) =>
  useSuspenseQuery({
    queryKey: [...ArticleQueryKey.detail()],
    queryFn: () => articleRepository.getArticle(request),
  });
