import Pagination from '@components/common/Pagination/Pagination';
import { convertToFormData } from '@helpers/formData.util';
import { interviewQueryKey } from '@repositories/evaluation/interview/interview.query';
import { interviewEvaluationRepository } from '@repositories/evaluation/interview/interview.repository';
import {
  InterviewCurrentPageAtom,
  InterviewPageSizeAtom,
} from '@stores/atoms/evaluation/interview/interview.page.atom';
import { interviewSelector } from '@stores/selectors/evaluation/interview.selector';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as S from './footer.style';

const Footer = () => {
  const setCurrentPage = useSetRecoilState(InterviewCurrentPageAtom);
  const setPageSize = useSetRecoilState(InterviewPageSizeAtom);

  const { screeningSn } = useParams();
  const request = useRecoilValue(interviewSelector(screeningSn!));
  const { data, isFetching } = useQuery({
    queryKey: interviewQueryKey.resumeList(request),
    queryFn: () =>
      interviewEvaluationRepository.getScreeningInterviewResumeList(
        convertToFormData(request as unknown as Record<string, string>),
      ),
    placeholderData: keepPreviousData,
  });

  if (!data) return <></>;
  const { pageUtil } = data;

  return (
    <S.Container>
      <Pagination
        {...pageUtil}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        disabled={isFetching}
      />
    </S.Container>
  );
};

export default Footer;
