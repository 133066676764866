import styled from '@emotion/styled';

export const TriggerText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EmptyArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 160px;
`;

export const EmptyText = styled.span`
  text-align: center;
  ${({ theme }) => theme.body.b2}
`;

export const Tooltip = styled.div`
  background-color: ${({ theme }) => theme.colors['gray-800']};
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
`;

export const BlueText = styled.span`
  color: ${({ theme }) => theme.colors['primary-500']};

  ${({ theme }) => theme.body['b2-bold']};
`;
