import * as S from './gridScreeningHeader.style';
import { GridScreeningTitle } from './title/GridScreeningTitle';
import { GridScreeningToolbar } from './toolbar/GridScreeningToolbar';

interface GridScreeningHeaderProps {}

export const GridScreeningHeader = () => {
  return (
    <S.Container>
      <GridScreeningTitle />
      <GridScreeningToolbar />
    </S.Container>
  );
};
