import type { CSSProperties } from 'react';
import { useEffect, useRef, useState, type ReactNode } from 'react';
import * as S from './headlessTooltip.style.ts';

export interface Props {
  children: ReactNode;
  content: ReactNode;
  width?: number;
  wrap?: boolean;
  isShow?: boolean;
  style?: CSSProperties;
}
const HeadlessTooltip = ({
  children,
  content,
  width,
  wrap = false,
  isShow = true,
  style,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(contentRef.current?.clientHeight ?? 0);
  });

  return (
    <S.Container ref={contentRef}>
      {isShow && (
        <S.Content
          style={{
            top: contentHeight,
            width,
            whiteSpace: wrap ? 'normal' : 'nowrap',
            ...style,
          }}
          className='tooltip'
        >
          {content}
        </S.Content>
      )}
      {children}
    </S.Container>
  );
};

export default HeadlessTooltip;
