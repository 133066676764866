import { gridStateSelector } from '@stores/atoms/evaluation/document.atom';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { useRecoilValue } from 'recoil';

export interface ResumeFormProps {
  url: string;
  resumeSn: number | string;
  screeningValuerSn: number | string;
  recruitNoticeSn: number | string;
  recruitSectorSn: number | string;
  screeningSn: number | string;
  screeningType: string;
  screeningApplySectorSn: number | string;
}
export const ResumeForm = forwardRef(
  (
    {
      url,
      resumeSn,
      screeningValuerSn,
      recruitNoticeSn,
      recruitSectorSn,
      screeningSn,
      screeningType,
      screeningApplySectorSn,
    }: ResumeFormProps,
    ref: ForwardedRef<HTMLFormElement>,
  ) => {
    const gridState = useRecoilValue(gridStateSelector);

    const gridStateWithScreeningApplySectorSn = {
      ...gridState,
      screeningApplySectorSn: String(screeningApplySectorSn),
      /** 인사이트 코드를 분석해보면 넣어야되는 것처럼 보이지만 없어도 잘 되고, 눈에 보이는 차이는 없다. */
      // screeningType,
      // valuerAssignmentType: 'GROUP',
    };
    const gridStateToJson = JSON.stringify(gridStateWithScreeningApplySectorSn);
    const searchData = gridStateToJson.replace(/"/gi, '§');

    return (
      <form
        ref={ref}
        // action={import.meta.env.VITE_API_URL + url}
        action={url}
        method='POST'
        target='resumePopup'
      >
        <input type='hidden' name='resumeSn' value={resumeSn} />
        <input
          type='hidden'
          name='screeningValuerSn'
          value={screeningValuerSn}
        />
        <input type='hidden' name='recruitNoticeSn' value={recruitNoticeSn} />
        <input type='hidden' name='recruitSectorSn' value={recruitSectorSn} />
        <input type='hidden' name='screeningSn' value={screeningSn} />
        {screeningType && (
          <input
            type='hidden'
            name='screeningType'
            value={screeningType.toLowerCase()}
          />
        )}
        <input type='hidden' name='searchData' value={searchData} />
      </form>
    );
  },
);
