import type { SortColumn } from '@components/evaluation/interview/interview.constant';
import { SORT_COLUMN } from '@components/evaluation/interview/interview.constant';
import type { SortType } from '@constants/evaluation.constant';
import { SORT_TYPE } from '@constants/evaluation.constant';
import { atom } from 'recoil';

//대상 지원자
export const interviewFilterInterestAtom = atom({
  key: 'interviewFilterInterestAtom',
  default: false,
});
export const interviewFilterBlackAtom = atom({
  key: 'interviewFilterBlackAtom',
  default: false,
});

export const localInterviewFilterInterestAtom = atom({
  key: 'localInterviewFilterInterestAtom',
  default: false,
});
export const localInterviewFilterBlackAtom = atom({
  key: 'localInterviewFilterBlackAtom',
  default: false,
});

//평가 결과
export const interviewFilterNotEvaluateAtom = atom({
  key: 'interviewFilterNotEvaluateAtom',
  default: false,
});
export const interviewFilterNotPassAtom = atom({
  key: 'interviewFilterNotPassAtom',
  default: false,
});
export const interviewFilterPassAtom = atom({
  key: 'interviewFilterPassAtom',
  default: false,
});
export const interviewFilterSparePassAtom = atom({
  key: 'interviewFilterSparePassAtom',
  default: false,
});

export const localInterviewFilterNotEvaluateAtom = atom({
  key: 'localInterviewFilterNotEvaluateAtom',
  default: false,
});
export const localInterviewFilterNotPassAtom = atom({
  key: 'localInterviewFilterNotPassAtom',
  default: false,
});
export const localInterviewFilterPassAtom = atom({
  key: 'localInterviewFilterPassAtom',
  default: false,
});
export const localInterviewFilterSparePassAtom = atom({
  key: 'localInterviewFilterSparePassAtom',
  default: false,
});

// 정렬
export const sortColumnAtom = atom<SortColumn>({
  key: 'sortColumnAtom',
  default: SORT_COLUMN.RESUME_DISPLAY_NO,
});
export const summarySortTypeAtom = atom<SortType>({
  key: 'summarySortTypeAtom',
  default: SORT_TYPE.ASC,
});
export const localSortColumnAtom = atom<SortColumn>({
  key: 'localSortColumnAtom',
  default: SORT_COLUMN.RESUME_DISPLAY_NO,
});
export const localSummarySortTypeAtom = atom<SortType>({
  key: 'localSummarySortTypeAtom',
  default: SORT_TYPE.ASC,
});
