import { ThemeType } from '../constants/theme.enum';
import { ColorType, InterviewEvaluatorColor } from './colors';
import { SharedTheme, SharedThemeType } from './shared';

export type ThemeProps = { colors: ColorType } & SharedThemeType;

const Theme: Record<
  ThemeType,
  {
    light: ThemeProps;
    dark: ThemeProps;
  }
> = {
  [ThemeType.INTERVIEW_EVALUATOR]: {
    light: {
      colors: InterviewEvaluatorColor.light,
      ...SharedTheme,
    },
    dark: {
      colors: InterviewEvaluatorColor.dark,
      ...SharedTheme,
    },
  },
};

export { Theme };
