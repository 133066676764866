import { Spin } from '@ats/ui';
import { MODAL_COMPONENT_MAP } from '@constants/modal.constant';
import { PORTAL_ID } from '@constants/portal.constant';
import { useModal } from '@hooks/shared/useModal';
import { modalKeyAtom } from '@stores/atoms/modal.atom';
import { Suspense, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue } from 'recoil';

const ESC_KEY_CODE = 27;

export const ModalManager = () => {
  const modalKey = useRecoilValue(modalKeyAtom);
  const { closeModal } = useModal();

  const closeOnEsc = (e: KeyboardEvent) => {
    if (e.keyCode === ESC_KEY_CODE) closeModal();
  };

  useEffect(() => {
    document.addEventListener('keydown', closeOnEsc);
    return () => document.removeEventListener('keydown', closeOnEsc);
  }, []);

  if (!modalKey) return <></>;

  const ModalComponent = MODAL_COMPONENT_MAP[modalKey];

  return createPortal(
    <Suspense fallback={<Spin />}>
      <ModalComponent />
    </Suspense>,
    document.getElementById(PORTAL_ID.MODAL)!,
  );
};
