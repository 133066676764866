import { BaseRepository } from '@ats/repositories';

export class ExamRepository extends BaseRepository {
  public async examScreeningResultFileDownload(request: string) {
    const response = await super.get(
      `/mrs2/attachFile/downloadFile?fileUid=${request}`,
      { responseType: 'blob' },
    );

    return response;
  }
}
