import { Spin, Text } from '@ats/ui';
import Header from '@components/evaluation/evaluation/Header/Header';
import { Suspense } from 'react';
import StepList from '../../components/evaluation/evaluation/StepList/StepList';
import { StepTypeTab } from '../../components/evaluation/evaluation/StepTypeTab/StepTypeTab';
import * as S from './style';

const EvaluationHomePage = () => {
  return (
    <S.Container>
      <Text variant={'t2'} bold>
        지원자 평가
      </Text>
      <StepTypeTab />
      <Header />
      <Suspense fallback={<Spin size={'md'} color={'blue'} />}>
        <StepList />
      </Suspense>
    </S.Container>
  );
};

export default EvaluationHomePage;
