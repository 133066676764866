import { Global, ThemeProvider as Provider } from '@emotion/react';
import { FC, PropsWithChildren } from 'react';
import { ThemeType } from './constants/theme.enum';
import { globalStyle } from './styles/global';
import { Theme } from './styles/theme';

interface ThemaProviderProps {
  themeType?: ThemeType;
  mode?: 'light' | 'dark';
}

export const ThemaProvider: FC<PropsWithChildren<ThemaProviderProps>> = ({
  children,
  themeType = ThemeType.INTERVIEW_EVALUATOR,
  mode = 'light',
}) => {
  const currentTheme = Theme[themeType];

  return (
    <Provider theme={mode === 'dark' ? currentTheme.dark : currentTheme.light}>
      <Global styles={globalStyle} />
      {children}
    </Provider>
  );
};
