import { SENTRY_KEYS } from '@ats/constants';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import AppLayout from './layouts/App';

dayjs.extend(duration);

Sentry.configureScope((scope) => {
  scope.setTag(
    SENTRY_KEYS.TYPES.BROWSER,
    SENTRY_KEYS.SERVICES.INTERVIEW_EVALUATOR,
  );
});

function App() {
  return <AppLayout />;
}

export default App;
