export const Shadow = {
  toJSON() {
    // tailwind custom style 추가를 위해 클래스형식으로 네이밍
    return {
      '.shadow-1': {
        'box-shadow':
          '0px 1px 2px 0px rgba(33, 37, 41, 0.06), 0px 1px 3px 0px rgba(33, 37, 41, 0.10)',
      },
      '.shadow-2': {
        'box-shadow':
          '0px 2px 4px -2px rgba(33, 37, 41, 0.06), 0px 4px 8px -2px rgba(33, 37, 41, 0.10)',
      },
      '.shadow-3': {
        'box-shadow':
          '0px 4px 6px -2px rgba(33, 37, 41, 0.03), 0px 12px 16px -4px rgba(33, 37, 41, 0.08)',
      },
      '.shadow-4': {
        'box-shadow':
          '0px 8px 8px -4px rgba(33, 37, 41, 0.03), 0px 20px 24px -4px rgba(33, 37, 41, 0.08)',
      },
      '.shadow-5': {
        'box-shadow': '0px 24px 48px -12px rgba(33, 37, 41, 0.18)',
      },
      '.shadow-6': {
        'box-shadow': '0px 32px 64px -12px rgba(33, 37, 41, 0.14)',
      },
    };
  },
  toJS() {
    return {
      shadow1: `box-shadow: 0px 1px 2px 0px rgba(33, 37, 41, 0.06), 0px 1px 3px 0px rgba(33, 37, 41, 0.10);`,
      shadow2: `box-shadow: 0px 2px 4px -2px rgba(33, 37, 41, 0.06), 0px 4px 8px -2px rgba(33, 37, 41, 0.10);`,
      shadow3: `box-shadow: 0px 4px 6px -2px rgba(33, 37, 41, 0.03), 0px 12px 16px -4px rgba(33, 37, 41, 0.08);`,
      shadow4: `box-shadow: 0px 8px 8px -4px rgba(33, 37, 41, 0.03), 0px 20px 24px -4px rgba(33, 37, 41, 0.08);`,
      shadow5: `box-shadow: 0px 24px 48px -12px rgba(33, 37, 41, 0.18);`,
      shadow6: `box-shadow: 0px 32px 64px -12px rgba(33, 37, 41, 0.14);`,
    };
  },
};
