import {
  jobNoticeSnForEvaluationOathAtom,
  stepSnForEvaluationOathAtom,
} from '@stores/atoms/evaluation/evaluation.atom';
import { selector } from 'recoil';

export const evaluationOathSelector = selector({
  key: 'evaluationOathSelector',
  get: ({ get }) => {
    const stepSn = get(stepSnForEvaluationOathAtom);
    const jobnoticeSn = get(jobNoticeSnForEvaluationOathAtom);

    return {
      jobnoticeSn,
      stepSn,
      systemKindCode: 'MRS2',
    } as const;
  },
});
