import {
  evaluationResultModifyAllowYnAtom,
  isRenderEvaluationAllButtonAtom,
  isRenderEvaluationResultSubmitButtonAtom,
  screeningSectorSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue } from 'recoil';
import * as S from './gridScreeningToolbar.style';
import { CheckedApplicantCount } from './left/CheckedApplicantCount';
import { Filter } from './left/Filter';
import { Notice } from './left/Notice';
import { Priority } from './left/Priority';
import { Reset } from './left/Reset';
import { Sector } from './left/Sector';
import { EvaluationAll } from './right/EvaluationAll';
import { InterestAndBlack } from './right/InterestAndBlack';
import { PrintEvaluationTable } from './right/PrintEvaluationTable';
import { Search } from './right/Search';
import { SubmitEvaluationResult } from './right/SubmitEvaluationResult';

export const GridScreeningToolbar = () => {
  const sectorSn = useRecoilValue(screeningSectorSnAtom);

  const isRenderEvaluationResultSubmitButton = useRecoilValue(
    isRenderEvaluationResultSubmitButtonAtom,
  );
  const isRenderEvaluationAllButton = useRecoilValue(
    isRenderEvaluationAllButtonAtom,
  );
  const evalResultModifyAllowYn = useRecoilValue(
    evaluationResultModifyAllowYnAtom,
  );

  return (
    <S.FilterContainer>
      <S.Left>
        {/* 공고 */}
        <Notice />
        {/* 채용분야 */}
        <Sector />
        {/* 지망 */}
        <Priority key={sectorSn} />
        {/* 필터추가 */}
        <Filter />
        {/* 초기화 */}
        <Reset />
        {/* 선택한 지원자 수 */}
        <CheckedApplicantCount />
        {/* 평가 결과 제출 */}
        {isRenderEvaluationResultSubmitButton && !evalResultModifyAllowYn && (
          <SubmitEvaluationResult />
        )}
      </S.Left>
      <S.Right>
        {/* 검색 */}
        <Search key={sectorSn} />
        <S.IconArea>
          {/* 일괄 평가 */}
          {isRenderEvaluationAllButton && <EvaluationAll />}
          {/* 일괄 관심지원자 및 블랙리스트 지정 */}
          <InterestAndBlack />
          {/* 정보 필터 */}
          {/* {screeningInfoData.valuerAssignmentType !==
            VALUER_ASSIGNMENT_TYPE.GROUP_INDIVIDUAL && <InformationFilter />} */}
          {/* 평가표 출력 */}
          <PrintEvaluationTable />
        </S.IconArea>
      </S.Right>
    </S.FilterContainer>
  );
};
