import type { Nullable } from '@ats/interfaces';
import type { IconName } from '@ats/ui';
import { Icon, Text } from '@ats/ui';
import FlatTag from '@components/common/FlatTag/FlatTag';
import { useTheme } from '@emotion/react';
import type {
  ScreeningInterview,
  ScreeningInterviewScheduleListRsDTO,
} from '@repositories/evaluation/interview/interview.dto';
import {
  interviewDateAtom,
  interviewDateTypeAtom,
} from '@stores/atoms/evaluation/interview/interview.date.atom';
import { InterviewCurrentPageAtom } from '@stores/atoms/evaluation/interview/interview.page.atom';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { INTERVIEW_DATE_TYPE } from '../../interview.constant';
import { TimeItem } from '../TimeItem/TimeItem';
import { formatDateString } from '../sidebar.utils';
import { NOT_ASSIGNED_DATE } from '../useSortedDateList';
import * as S from './dateItem.style';

export interface DateItemProps {
  isActive?: boolean;
  date: Nullable<string>;
  interviewList: ScreeningInterview[];
  icon?: IconName;
}

export const DateItem = (props: DateItemProps) => {
  const { date, interviewList, icon } = props;
  const theme = useTheme();
  const [interviewDateType, setInterviewDateType] = useRecoilState(
    interviewDateTypeAtom,
  );
  const [isOpen, setIsOpen] = useState(false);
  const resetCurrentPage = useResetRecoilState(InterviewCurrentPageAtom);

  const [interviewDate, setInterviewDate] = useRecoilState(interviewDateAtom);
  const isActive =
    (interviewDateType === INTERVIEW_DATE_TYPE.DATE ||
      interviewDateType === INTERVIEW_DATE_TYPE.DATE_TIME_PLACE) &&
    interviewDate === date;
  const intervieweeCount = useMemo(
    () => interviewList.reduce((prev, cur) => prev + cur.resumeCount, 0),
    [interviewList],
  );
  const dateText = useMemo(
    () => (date === NOT_ASSIGNED_DATE ? '일정 미배정' : formatDateString(date)),
    [date],
  );

  useEffect(() => {
    if (!isActive) setIsOpen(false);
  }, [isActive]);

  return (
    <>
      <S.SidebarItem
        onClick={() => {
          setInterviewDateType(INTERVIEW_DATE_TYPE.DATE);
          setInterviewDate(date);
          setIsOpen((prev) => !prev);
          resetCurrentPage();
        }}
        isActive={isActive}
      >
        <S.SideBarItemLeftSection>
          <Icon size={24} name={icon ?? 'icon_event_18_line'} />
          <Text variant={'b2'}>{dateText}</Text>
        </S.SideBarItemLeftSection>
        <FlatTag
          color={isActive ? theme.colors['blue-600'] : theme.colors['gray-600']}
          backgroundColor={
            isActive ? theme.colors['blue-100'] : theme.colors['gray-100']
          }
        >
          <Text variant='b3'>{intervieweeCount ?? 0}명</Text>
        </FlatTag>
      </S.SidebarItem>
      {isActive && isOpen && (
        <S.TimeListContainer>
          <S.Divider />
          <S.TimeList>
            {interviewList.map((interview) => (
              <TimeItem
                key={interview.interviewScheduleSn}
                interview={interview}
              />
            ))}
          </S.TimeList>
        </S.TimeListContainer>
      )}
    </>
  );
};

export const AllDateItem = ({
  dateList,
}: {
  dateList: ScreeningInterviewScheduleListRsDTO;
}) => {
  const [interviewDateType, setInterviewDateType] = useRecoilState(
    interviewDateTypeAtom,
  );
  const totalIntervieweeCount = useMemo(
    () =>
      dateList.reduce(
        (prev, cur) =>
          prev +
          cur.interviewList.reduce((prev, cur) => prev + cur.resumeCount, 0),
        0,
      ),
    [dateList],
  );
  const theme = useTheme();
  const isAllItemActive = interviewDateType === INTERVIEW_DATE_TYPE.ALL;

  return (
    <S.SidebarItem
      onClick={() => setInterviewDateType(INTERVIEW_DATE_TYPE.ALL)}
      isActive={isAllItemActive}
    >
      <S.SideBarItemLeftSection>
        <Icon size={24} name={'icon_grid_18_line'} />
        <Text variant={'b2'}>모두 보기</Text>
      </S.SideBarItemLeftSection>
      <FlatTag
        color={
          isAllItemActive ? theme.colors['blue-600'] : theme.colors['gray-600']
        }
        backgroundColor={
          isAllItemActive ? theme.colors['blue-100'] : theme.colors['gray-100']
        }
      >
        <Text variant='b3'> 총 {totalIntervieweeCount}명</Text>
      </FlatTag>
    </S.SidebarItem>
  );
};
