import styled from '@emotion/styled';

export const Container = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors['gray-800']};
  outline: none;

  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 126.667% */

  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-100']};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors['gray-400']};
  }
`;
