import { PAGES } from '@ats/constants';
import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Radio,
  RadioGroup,
  SIZE,
  TextField,
} from '@ats/ui';
import BorderlessTextField from '@components/common/BorderlessTextField/BorderlessTextField';
import type { IsPrivateValue } from '@constants/qna.constants';
import { IS_PRIVATE_VALUE } from '@constants/qna.constants';
import { nullableValueFallbackText } from '@helpers/text.util';
import { csrfRepository } from '@repositories/csrf/csrf.repository.ts';
import { useCustomerInfoQuery } from '@repositories/customer/customer.query';
import { usePostQnaArticleMutation } from '@repositories/qna/qna.query.ts';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Editor from '../../../components/q&a/Editor';
import {
  Area,
  Container,
  Description,
  Divider,
  InformationArea,
  PrivacySetting,
} from './style';

const QuestionAndAnswerCreatePage = () => {
  const navigate = useNavigate();
  const { getCSRFToken } = csrfRepository;
  const { mutate } = usePostQnaArticleMutation();
  const { data: customerInfo } = useCustomerInfoQuery();
  const { userInfo } = customerInfo;

  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [isPrivate, setIsPrivate] = useState<IsPrivateValue>(
    IS_PRIVATE_VALUE.PUBLIC,
  );
  const [password, setPassword] = useState('');

  const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword('');
    setIsPrivate(Number(e.target.value) as IsPrivateValue);
  };

  const handleSubmitArticle = async () => {
    try {
      await mutate({
        requestBody: Object.assign(
          { title, contents, isPrivate },
          isPrivate === IS_PRIVATE_VALUE.PRIVATE ? { password } : {},
        ),
        AUToken: await getCSRFToken(),
      });
      navigate(PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <Area>
        <BorderlessTextField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={'제목을 입력해주세요.'}
          maxLength={50}
        />
      </Area>

      <Area>
        <InformationArea>
          <Description>{nullableValueFallbackText(userInfo.name)}</Description>
          <Divider />
          <Description>
            {nullableValueFallbackText(userInfo.department)}
          </Description>
        </InformationArea>
      </Area>

      <Area>
        <Editor contents={contents} setContents={setContents} />
      </Area>

      <Area>
        <PrivacySetting>
          <RadioGroup style={{ display: 'flex', gap: 24 }}>
            <Radio
              name='public'
              checked={isPrivate === IS_PRIVATE_VALUE.PUBLIC}
              value={IS_PRIVATE_VALUE.PUBLIC}
              onChange={handleChangeRadio}
            >
              공개
            </Radio>
            <Radio
              name='private'
              checked={isPrivate === IS_PRIVATE_VALUE.PRIVATE}
              value={IS_PRIVATE_VALUE.PRIVATE}
              onChange={handleChangeRadio}
            >
              비공개
            </Radio>
          </RadioGroup>
          <TextField
            placeholder='비밀번호 입력'
            size={SIZE.SM}
            disabled={isPrivate === IS_PRIVATE_VALUE.PUBLIC}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </PrivacySetting>
      </Area>

      <Area style={{ display: 'flex', marginTop: 24, gap: 8 }}>
        <Button
          size='md'
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={() =>
            navigate(PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME)
          }
        >
          취소
        </Button>
        <Button
          size='md'
          variant={BUTTON_VARIANTS.PRIMARY}
          color={BUTTON_COLORS.THEMA}
          onClick={handleSubmitArticle}
          disabled={!title && !contents}
        >
          저장하기
        </Button>
      </Area>
    </Container>
  );
};

export default QuestionAndAnswerCreatePage;
