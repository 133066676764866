import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  DROPDOWN_BUTTON_ITEM_TYPE,
  Dropdown,
  Icon,
  SIZE,
} from '@ats/ui';
import { usePriority } from '@hooks/evaluation/grid-screening/usePriority';
import * as S from './left.style';

export const Priority = () => {
  const {
    checkPriorityList,
    searchText,
    sectorPriorityList,
    searchResultIndexList,
    isOpen,
    dropdownContainerRef,
    noticeSn,
    sectorSn,
    handleCheckPriority,
    handleToggleDropdown,
    handleChangePrioritySearch,
    handleClickSubmitPriority,
  } = usePriority();

  return (
    <Dropdown.Container ref={dropdownContainerRef}>
      <Dropdown.Trigger
        variant={BUTTON_VARIANTS.SECONDARY}
        color={BUTTON_COLORS.DARK}
        size={SIZE.SM}
        style={{ width: '93px' }}
        onClick={handleToggleDropdown}
      >
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          지망
        </span>
        <Icon name='icon_arrow_bottom_18_line' color='#E9ECEF' />
      </Dropdown.Trigger>
      <Dropdown.ItemContainer
        isOpen={isOpen}
        style={{ height: 'auto', width: '186px' }}
        isOverflowScrollable={false}
      >
        <Dropdown.TextField
          value={searchText}
          onChange={handleChangePrioritySearch}
          placeholder='검색어를 입력해주세요.'
        />
        <Dropdown.Separate />
        {noticeSn && sectorSn ? (
          sectorPriorityList.map((priority, index) => {
            if (searchResultIndexList.includes(index)) {
              return (
                <Dropdown.CheckItem
                  key={priority}
                  label={`${priority}지망`}
                  value={priority}
                  checked={checkPriorityList.includes(priority)}
                  onChange={handleCheckPriority}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <S.EmptyArea>
            <S.EmptyText>
              먼저 채용분야를 <br />
              선택해주세요.
            </S.EmptyText>
          </S.EmptyArea>
        )}
        <Dropdown.ButtonItem
          onClick={handleClickSubmitPriority}
          type={DROPDOWN_BUTTON_ITEM_TYPE.CONFIRM}
          size={SIZE.SM}
        >
          저장
        </Dropdown.ButtonItem>
      </Dropdown.ItemContainer>
    </Dropdown.Container>
  );
};
