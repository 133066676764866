import type { IconView } from '@constants/evaluation.constant';
import { formatDateString } from '@helpers/date.util';
import type { IconViewList } from '@repositories/evaluation/interview/interview.dto';
import { useMemo } from 'react';

interface Args {
  interviewStartDatetime?: string;
  interviewClosingDatetime?: string;
  iconViewList?: IconViewList[];
}

export const useCardData = (args: Args) => {
  const { interviewStartDatetime, interviewClosingDatetime, iconViewList } =
    args;
  const interviewDate = useMemo(() => {
    if (!interviewStartDatetime || !interviewClosingDatetime)
      return '일정 미배정';

    const date = formatDateString(interviewStartDatetime);

    const fromTime = interviewStartDatetime
      .split(' ')[1]
      .split(':')
      .slice(0, 2)
      .join(':');
    const toTime = interviewClosingDatetime
      .split(' ')[1]
      .split(':')
      .slice(0, 2)
      .join(':');
    const time = `${fromTime} - ${toTime}`;

    return `${date}, ${time}`;
  }, [interviewStartDatetime, interviewClosingDatetime]);

  const iconList = useMemo(() => {
    const iconList = iconViewList?.reduce(
      (prev, cur) => (cur.useYn ? prev.concat(cur.code) : prev),
      [] as IconView[],
    );

    return iconList;
  }, [iconViewList]);
  return { interviewDate, iconList };
};
