import { Z_INDEX } from '@constants/hierarchy.constant';
import styled from '@emotion/styled';

export const ToastManager = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  z-index: ${Z_INDEX.TOAST};
`;

export const ToastGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
