import type { InputHTMLAttributes } from 'react';
import * as S from './borderlessTextField.style.ts';

interface Props extends InputHTMLAttributes<HTMLInputElement> {}
const BorderlessTextField = (props: Props) => {
  const { children, ...rest } = props;

  return (
    <S.Container type={'text'} {...rest}>
      {children}
    </S.Container>
  );
};

export default BorderlessTextField;
