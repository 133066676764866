import styled from '@emotion/styled';

export const Container = styled.div``;

export const WriterArea = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18px;
`;

export const Writer = styled.span`
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const CreatedAt = styled.span`
  margin-left: 8px;
  vertical-align: text-bottom;
  ${({ theme }) => theme.body.b3};
  color: ${({ theme }) => theme.colors['gray-600']};
  line-height: 32px;
`;

export const Title = styled.h4`
  margin: 8px 0;
  ${({ theme }) => theme.body['b1-bold']};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const Content = styled.p`
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const EditorWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const EditorBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;
