import { disassembleHangul } from '@helpers/search';
import { useEffect, useState } from 'react';

export const useSearchIndex = (searchText: string, list: string[]) => {
  // 검색 결과를 저장할 상태
  const [searchResultIndexList, setSearchResultIndexList] = useState<number[]>(
    [],
  );

  // 검색어가 변경될 때마다 실행
  useEffect(() => {
    // 리스트에서 검색어를 포함하는 항목만 필터링
    const resultIndexList = list.reduce((total, item, index) => {
      const disassembledSearchText = [...searchText]
        .map(disassembleHangul)
        .join('');
      const disassembledItem = [...(item as string)]
        .map(disassembleHangul)
        .join('');

      if (disassembledItem.includes(disassembledSearchText)) {
        total.push(index);
      }

      return total;
    }, [] as number[]);

    setSearchResultIndexList(resultIndexList);
  }, [searchText, list]);

  return {
    searchResultIndexList,
  };
};
