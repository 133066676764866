import { useScreeningInterviewResumeListQuery } from '@repositories/evaluation/interview/interview.query';
import { interviewSelector } from '@stores/selectors/evaluation/interview.selector';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Card from '../Card/Card';
import EmptySearchInterview from '../EmptyInterview/EmptySearchInterview';
import * as S from './cardGrid.style';

const CardGrid = () => {
  const { screeningSn } = useParams();
  const request = useRecoilValue(interviewSelector(screeningSn!));
  const { data: resumeList } = useScreeningInterviewResumeListQuery(request);

  if (!resumeList) return <></>;
  const { interviewResumeList } = resumeList;

  if (interviewResumeList.length)
    return (
      <S.Container>
        {interviewResumeList.map((resumeData) => (
          <Card key={resumeData.resumeSn} {...resumeData} />
        ))}
      </S.Container>
    );

  return <EmptySearchInterview />;
};

export default CardGrid;
