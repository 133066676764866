import {
  gridFilterUnUploadFileAtom,
  gridFilterUploadFileAtom,
} from '@stores/atoms/evaluation/document.filter';
import type { ChangeEvent, ForwardedRef } from 'react';
import { useImperativeHandle, useState } from 'react';
import { useRecoilState } from 'recoil';
import type { ChildRefHandle } from '../useGridFilter';

export const useManagerUploadFileFilter = (
  ref: ForwardedRef<ChildRefHandle>,
) => {
  const [gridFilterUploadFileStore, setGridFilterUploadFileStore] =
    useRecoilState(gridFilterUploadFileAtom);
  const [gridFilterUnUploadFileStore, setGridFilterUnUploadFileStore] =
    useRecoilState(gridFilterUnUploadFileAtom);
  const [isUploadFile, setIsUploadFile] = useState(gridFilterUploadFileStore);
  const [isUnUploadFile, setIsUnUploadFile] = useState(
    gridFilterUnUploadFileStore,
  );

  const isAllUploadAndUnloadFile = isUploadFile && isUnUploadFile;

  const uploadAndUnUploadAllProps = {
    checked: isAllUploadAndUnloadFile,
    indeterminate:
      (isUploadFile || isUnUploadFile) && !isAllUploadAndUnloadFile,
    onChange: () => {
      if (isAllUploadAndUnloadFile) {
        setIsUploadFile(false);
        setIsUnUploadFile(false);
        return;
      }

      setIsUploadFile(true);
      setIsUnUploadFile(true);
    },
  };
  const uploadProps = {
    checked: isUploadFile,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setIsUploadFile(e.target.checked),
  };
  const unUploadProps = {
    checked: isUnUploadFile,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setIsUnUploadFile(e.target.checked),
  };

  const refreshFn = () => {
    setIsUploadFile(false);
    setIsUnUploadFile(false);
  };

  const applyFn = () => {
    setGridFilterUploadFileStore(isUploadFile);
    setGridFilterUnUploadFileStore(isUnUploadFile);
  };

  useImperativeHandle(ref, () => ({
    refreshFn,
    applyFn,
  }));

  return {
    uploadAndUnUploadAllProps,
    uploadProps,
    unUploadProps,
  };
};
