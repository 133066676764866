import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 48px 32px;
`;

export const TitleArea = styled.div``;

export const Title = styled.h2`
  ${({ theme }) => theme.title['t2-bold']};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;
