import { PAGES } from '@ats/constants';
import FullPageSpin from '@components/common/FullPageSpin/FullPageSpin';
import MainLayout from '@layouts/Main';
import QuestionAndAnswerCreatePage from '@pages/QuestionAndAnswer/Create';
import QuestionAndAnswerEditPage from '@pages/QuestionAndAnswer/Edit';
import QuestionAndAnswerHomePage from '@pages/QuestionAndAnswer/Home';
import QuestionAndAnswerReadPage from '@pages/QuestionAndAnswer/Read';
import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';

const QuestionAndAnswerRoute: RouteObject = {
  path: PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME,
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<FullPageSpin />}>
          <QuestionAndAnswerHomePage />
        </Suspense>
      ),
    },
    {
      path: PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.CREATE,
      element: (
        <Suspense fallback={<FullPageSpin />}>
          <QuestionAndAnswerCreatePage />
        </Suspense>
      ),
    },
    {
      path: PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.READ(':sn'),
      element: (
        <Suspense fallback={<FullPageSpin />}>
          <QuestionAndAnswerReadPage />
        </Suspense>
      ),
    },
    {
      path: PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.EDIT(':sn'),
      element: (
        <Suspense fallback={<FullPageSpin />}>
          <QuestionAndAnswerEditPage />
        </Suspense>
      ),
    },
  ],
};

export default QuestionAndAnswerRoute;
