import { css } from '@emotion/react';

export const globalStyle = css`
  * {
    font-family:
      'Pretendard Variable',
      Pretendard,
      -apple-system,
      BlinkMacSystemFont,
      system-ui,
      Roboto,
      'Helvetica Neue',
      'Segoe UI',
      'Apple SD Gothic Neo',
      'Noto Sans KR',
      'Malgun Gothic',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      sans-serif;
  }

  #modal-root {
    position: fixed;
    top: 0;
    left: 0;
  }

  body,
  #root {
    min-width: 100vw;
    min-height: 100vh;
  }
`;
