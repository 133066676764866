import EmptyGridImage from '@ats/foundation/src/images/flat_empty_grid.png';
import * as S from './emptyGrid.style';

export const EmptyGrid = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: `calc(100vh - 162px)`,
      }}
    >
      <S.EmptyContainer>
        <img
          src={EmptyGridImage}
          alt='검색 결과 없음'
          style={{ width: '38px', height: '42px' }}
        />
        현재 설정된 검색조건에
        <br />
        해당되는 지원자가 없습니다.
      </S.EmptyContainer>
    </div>
  );
};
