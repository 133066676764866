import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.article`
  width: 330px;
  height: fit-content;
  position: relative;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors['gray-300']};
  border-radius: ${({ theme }) => theme.radius.radius100};
  &:hover {
    ${({ theme }) => theme.shadow.shadow3};
  }
  cursor: pointer;

  display: flex;
  flex-direction: column;
`;

export const AbsenceContainer = styled(Container)`
  cursor: default;
  border: none;
  &:hover {
    box-shadow: none;
  }
`;

export const BasicWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.space300};
`;

export const UpperSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ProfileImg = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors['gray-300']};
  overflow: hidden;
`;
export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.space100};
`;
export const ProfileName = styled.div`
  max-width: 106px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme }) => theme.body['b1-bold']};
  color: ${({ theme }) => theme.colors['gray-800']};
`;
export const ProfileAge = styled.div`
  ${({ theme }) => theme.body['b3-bold']};
  color: ${({ theme }) => theme.colors['gray-600']};
`;
export const ProfileIconGroup = styled.div`
  height: 18px;
  margin-top: 4px;
  display: flex;
  gap: ${({ theme }) => theme.spacing.space50};
`;

export const ResumeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ResumeDisplayNumber = styled.div`
  ${({ theme }) => theme.body.b3};
  color: ${({ theme }) => theme.colors['gray-600']};
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.space100};
`;
export const Recruit = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.space50};
`;
const interviewInfoStyle1 = ({ theme }: { theme: Theme }) => css`
  max-width: 282px;
  ${theme.body['b2-bold']};
  color: ${theme.colors['gray-800']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const RecruitNotice = styled.div`
  ${interviewInfoStyle1}
`;
export const RecruitSector = styled.div`
  ${interviewInfoStyle1}
`;

const interviewInfoStyle2 = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  gap: 4px;
  ${theme.body['b3']}
  ${theme.colors['gray-600']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Interview = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.space50};
`;
export const InterviewDate = styled.div`
  ${interviewInfoStyle2}
`;
export const InterviewPlace = styled.div`
  ${interviewInfoStyle2}
`;
export const InterviewGroup = styled.div`
  ${interviewInfoStyle2}
`;

export const LowerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors['gray-300']};
`;

export const BlurBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radius.radius100};
  border: 1px solid ${({ theme }) => theme.colors['gray-300']};

  z-index: 1;
  background-color: white;
  opacity: 0.8;
  backdrop-filter: blur(2px);
`;
export const AbsenceWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CardToolTip = styled.div`
  background-color: ${({ theme }) => theme.colors['gray-800']};
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
`;
