import { BUTTON_COLORS, BUTTON_VARIANTS, Dropdown, Icon, SIZE } from '@ats/ui';
import { useSector } from '../../../../../../hooks/evaluation/grid-screening/useSector';
import { SectorListEmpty } from './SectorEmpty.tsx';
import { SectorList } from './SectorList.tsx';
import * as S from './left.style.ts';

export const Sector = () => {
  const {
    selectSectorText,
    isOpen,
    dropdownContainerRef,
    noticeSn,
    handleSetSectorText,
    handleToggleDropdown,
  } = useSector();

  return (
    <Dropdown.Container ref={dropdownContainerRef}>
      <Dropdown.Trigger
        variant={BUTTON_VARIANTS.SECONDARY}
        color={BUTTON_COLORS.DARK}
        size={SIZE.SM}
        style={{ width: '230px' }}
        onClick={handleToggleDropdown}
      >
        <S.TriggerText>
          {selectSectorText ? selectSectorText : '채용분야'}
        </S.TriggerText>
        <Icon name='icon_arrow_bottom_18_line' color='#E9ECEF' />
      </Dropdown.Trigger>
      {/* 공고가 1개여서 공고가 자동으로 선택 되었을 때 or 공고가 여러개여서 공고를 선택했을 때  */}
      {noticeSn ? (
        <SectorList isOpen={isOpen} handleSetSectorText={handleSetSectorText} />
      ) : (
        // 공고가 여러개여서 공고를 아직 선택하지 않았을 때
        <SectorListEmpty isOpen={isOpen} />
      )}
    </Dropdown.Container>
  );
};
