import styled from '@emotion/styled';

export const Title = styled.header`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 56px;
  color: white;
  background: ${({ theme }) => theme.colors['primary-700']};
  padding: 14px 24px;

  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`;
1;
