import { ThemaProvider, ThemeType } from '@ats/theme-provider';
import { CommonAlert } from '@components/common/Alert/CommonAlert';
import { ModalManager } from '@components/common/Modal/ModalManager';
import ToastManager from '@components/common/ToastManager/ToastManager';
import { Global } from '@emotion/react';
import { queryClient } from '@helpers/client';
import AuthProvider from '@layouts/Authenticated';
import { router } from '@routes/route';
import { globalStyle } from '@styles/globalStyle';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { type FC, type PropsWithChildren } from 'react';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

const AppLayout: FC<PropsWithChildren> = () => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ThemaProvider themeType={ThemeType.INTERVIEW_EVALUATOR} mode='light'>
          <AuthProvider>
            <RouterProvider router={router} />
            <Global styles={globalStyle} />
            <ModalManager />
            <ToastManager />
            <CommonAlert />
          </AuthProvider>
        </ThemaProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default AppLayout;
