import type { SCREENING_RESULT_CODE } from '@constants/evaluation.constant';
import type { EvaluationItemRatingScales } from '@repositories/evaluation/document/document.dto';
import { atom, selector } from 'recoil';

/** 열람여부 */
export const gridFilterReadAtom = atom({
  key: 'gridFilterReadAtom',
  default: false,
});
export const gridFilterUnReadAtom = atom({
  key: 'gridFilterUnReadAtom',
  default: false,
});

/** 대상지원자(관심/블랙지원자) */
export const gridFilterInterestAtom = atom({
  key: 'gridFilterInterestAtom',
  default: false,
});
export const gridFilterBlackAtom = atom({
  key: 'gridFilterBlackAtom',
  default: false,
});

/** 평가 항목 */
export const gridFilterEvaluationItemsAtom = atom<EvaluationItemRatingScales[]>(
  {
    key: 'gridFilterEvaluationItemsAtom',
    default: [],
  },
);

/** 종합 평가 */
export const gridFilterTotalEvaluationAtom = atom<EvaluationItemRatingScales>({
  key: 'gridFilterTotalEvaluationAtom',
  default: {
    evaluationItemSn: 0,
    ratingScaleSns: [],
  },
});

/** 평가 항목 + 종합 평가 */
export const gridFilterEvaluationItemRatingScales = selector({
  key: 'gridFilterEvaluationItemRatingScales',
  get: ({ get }) => {
    if (get(gridFilterTotalEvaluationAtom).evaluationItemSn) {
      return [
        ...get(gridFilterEvaluationItemsAtom),
        get(gridFilterTotalEvaluationAtom),
      ];
    }

    return [...get(gridFilterEvaluationItemsAtom)];
  },
});

/** 최종 평가 */
export const gridFilterFinalEvaluateListAtom = atom<SCREENING_RESULT_CODE[]>({
  key: 'gridFilterFinalEvaluateListAtom',
  default: [],
});

/** 관리자 업로드 파일(업로드/미업로드) */
export const gridFilterUploadFileAtom = atom({
  key: 'gridFilterUploadFileAtom',
  default: false,
});
export const gridFilterUnUploadFileAtom = atom({
  key: 'gridFilterUnUploadFileAtom',
  default: false,
});
