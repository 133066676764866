import {
  Body,
  BreakPoints,
  Heading,
  Radius,
  Shadow,
  Space,
  Title,
} from '@ats/foundation';

export type SharedThemeType = typeof SharedTheme;

export const SharedTheme = {
  heading: Heading.toJS(),
  body: Body.toJS(),
  title: Title.toJS(),
  radius: Radius.toJS(),
  spacing: Space.toJS(),
  shadow: Shadow.toJS(),
  breakpoints: BreakPoints.toJS(),
};
