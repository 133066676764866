import { Button, BUTTON_COLORS, BUTTON_VARIANTS } from '@ats/ui';
import { READ_STATUS } from '@constants/evaluation.constant.ts';
import { useTheme } from '@emotion/react';
import * as S from './gridBodyCol.style.ts';

interface GridBodyColReadStatusProps {
  handleClickToggleReadStatus: (modifyYn: boolean) => void;
  localReadStatus?: READ_STATUS;
}
export const GridBodyColReadStatus = ({
  handleClickToggleReadStatus,
  localReadStatus,
}: GridBodyColReadStatusProps) => {
  const theme = useTheme();

  const renderReadStatus = () => {
    switch (localReadStatus) {
      case READ_STATUS.READ:
        return '열람';
      case READ_STATUS.UNREAD:
        return '미열람';
      default:
        return '-';
    }
  };

  const buttonStyle = {
    background: theme.colors['primary-100'],
    color: theme.colors['primary-600'],
  };

  return (
    <S.CellCenterPosition>
      <Button
        onClick={() => handleClickToggleReadStatus(false)}
        variant={BUTTON_VARIANTS.SECONDARY}
        color={
          localReadStatus === READ_STATUS.READ
            ? BUTTON_COLORS.THEMA
            : BUTTON_COLORS.DEFAULT
        }
        style={{
          padding: '2px 6px',
          ...(localReadStatus === READ_STATUS.READ && buttonStyle),
        }}
      >
        {renderReadStatus()}
      </Button>
    </S.CellCenterPosition>
  );
};
