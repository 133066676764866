import { PAGES } from '@ats/constants';
import { usePostCheckPasswordMutation } from '@repositories/qna/qna.query.ts';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export interface ModalFormProps {
  password: string;
}

export function useModal({ articleSn }: { articleSn: string }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ModalFormProps>({
    defaultValues: {
      password: '',
    },
  });
  const navigate = useNavigate();
  const { mutate } = usePostCheckPasswordMutation();

  const onSubmit = async ({ password }: ModalFormProps) => {
    mutate(
      { articleSn, password },
      {
        onSuccess: ({ successYn }: { successYn: boolean }) => {
          if (successYn) {
            return navigate(
              PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.READ(articleSn),
              {
                state: {
                  privatePassword: password,
                },
              },
            );
          }

          setError('password', {
            message:
              '비밀번호가 틀리거나 권한이 없습니다. 다시 시도해 주십시오.',
          });
        },
      },
    );
  };

  return { register, onSubmit, handleSubmit, errors };
}
