import styled from '@emotion/styled';
import { footerHeight, headerHeight } from '../Main/main.style';

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - ${headerHeight + footerHeight}px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['gray-50']};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
