import styled from '@emotion/styled';

export const Container = styled.div``;

export const TextArea = styled.textarea`
  width: 100%;
  height: 104px;
  box-sizing: border-box;
  padding: 14px 16px;
  background: ${({ theme }) => theme.colors['gray-80']};
  border-radius: 6px;
  outline: none;
  resize: none;
  margin-top: 16px;
  border: 1px solid transparent;

  ::placeholder {
    ${({ theme }) => theme.body.b2};
    color: ${({ theme }) => theme.colors['gray-600']};
  }

  :active,
  :focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['gray-800']};
    border: 1px solid ${({ theme }) => theme.colors['gray-800']};
    background: white;
  }
`;

export const EditorBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
`;
