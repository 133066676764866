import {
  BUTTON_VARIANTS,
  DROPDOWN_DIRECTION,
  Dropdown,
  Icon,
  Indicator,
  SIZE,
  Text,
  useDropdown,
} from '@ats/ui';
import type { PageUtil } from '@repositories/common/pageUtil.dto.ts';
import type { Dispatch, SetStateAction } from 'react';
import { PageNumberList } from './PageNumberList';
import * as S from './pagination.style';

interface Props extends PageUtil {
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageSize: (prev: number) => void;
  pageSizeOption?: number[];
  disabled?: boolean;
  countUnitText?: string;
}

const PAGE_BLOCK_OPTIONS = [10, 20, 30, 40, 50] as const;

const Pagination = (props: Props) => {
  const {
    recordCount,
    lastPage,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pageSizeOption = PAGE_BLOCK_OPTIONS,
    disabled = false,
    countUnitText = '개',
  } = props;
  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  return (
    <S.Container>
      <S.LeftSection>
        <Icon name='icon_list_view_18_line' size={24} />
        <Text variant={'b2'}>
          총 {recordCount} {countUnitText}
        </Text>
      </S.LeftSection>

      <S.MiddleSection>
        <Indicator
          onClick={() => setCurrentPage((prev) => prev - 1)}
          size={SIZE.SM}
          direction='left'
          disabled={currentPage === 1 || disabled}
        />
        <PageNumberList
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          lastPage={lastPage}
          disabled={disabled}
        />
        <Indicator
          onClick={() => setCurrentPage((prev) => prev + 1)}
          size={SIZE.SM}
          direction='right'
          disabled={currentPage === lastPage || disabled}
        />
      </S.MiddleSection>

      <S.RightSection>
        <Text variant={'b2'}>페이지 행</Text>
        <Dropdown.Container ref={dropdownContainerRef}>
          <Dropdown.Trigger
            size={'sm'}
            variant={BUTTON_VARIANTS.SECONDARY}
            style={{ width: '68px' }}
            onClick={handleToggleDropdown}
          >
            <span>{pageSize}</span>
            <Icon name='icon_arrow_bottom_18_line' />
          </Dropdown.Trigger>
          <Dropdown.ItemContainer
            isOverflowScrollable={false}
            isOpen={isOpen}
            direction={DROPDOWN_DIRECTION.TOP}
            style={{ height: 'auto' }}
          >
            {pageSizeOption.map((opt) => (
              <Dropdown.ButtonItem
                key={opt}
                onClick={() => {
                  setPageSize(opt);
                  handleCloseDropdown();
                }}
              >
                {opt}
              </Dropdown.ButtonItem>
            ))}
          </Dropdown.ItemContainer>
        </Dropdown.Container>
      </S.RightSection>
    </S.Container>
  );
};

export default Pagination;
