import { TABLE_Z_INDEX } from '@constants/TableZIndex.constants';
import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FreezePanesWrapper = styled.div`
  position: sticky;
  left: 0;
  z-index: ${TABLE_Z_INDEX.FREEZE_PANES};
  background: white;
`;

export const headerStyle = ({ theme }: { theme: Theme }) => css`
  border-right: 1px solid ${theme.colors['gray-300']};
  border-bottom: 1px solid ${theme.colors['gray-400']};
  background: ${theme.colors['gray-80']};
`;

export const HeadCellCheckboxInner = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  height: 36px;
  ${headerStyle}
`;

export const HeadCellInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 16px;
  height: 36px;

  ${headerStyle}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
