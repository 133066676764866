import { ReactComponent as EmptyIcon } from '@assets/interview/flat_description_mono.svg';
import { Text } from '@ats/ui';
import { useTheme } from '@emotion/react';
import { useEvaluationStepListQuery } from '@repositories/evaluation/evaluation/evaluation.query.ts';
import { stepSearchKeywordAtom } from '@stores/atoms/evaluation/evaluation.atom.ts';
import { evaluationStepListSelector } from '@stores/selectors/evaluation/evaluation.selector.ts';
import { useRecoilValue } from 'recoil';
import Footer from '../Footer/Footer.tsx';
import { StepItem } from '../StepItem/StepItem.tsx';
import * as S from './stepList.style.ts';
import { useCleanInit } from './useCleanInit.ts';

const StepList = () => {
  const request = useRecoilValue(evaluationStepListSelector);
  const theme = useTheme();
  const {
    data: { list: stepList },
  } = useEvaluationStepListQuery(request);
  useCleanInit();
  const stepSearchKeyword = useRecoilValue(stepSearchKeywordAtom);

  if (stepList.length)
    return (
      <S.StepListWrapper>
        {stepList.map((step) => (
          <StepItem key={step.stepSn} {...step} />
        ))}
        <Footer />
      </S.StepListWrapper>
    );

  if (stepSearchKeyword)
    return (
      <S.EmptyStepListContainer>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <EmptyIcon />
          <Text
            variant={'b2'}
            color={theme.colors['gray-700']}
            style={{
              whiteSpace: 'pre-line',
              textAlign: 'center',
            }}
          >
            현재 설정된 검색조건에 <br /> 해당되는 지원자 평가가 없습니다.
          </Text>
        </div>
      </S.EmptyStepListContainer>
    );

  return (
    <S.EmptyStepListContainer>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <EmptyIcon />
        <Text
          variant={'b2'}
          color={theme.colors['gray-700']}
          style={{
            whiteSpace: 'pre-line',
            textAlign: 'center',
          }}
        >
          평가할 전형이 없습니다.
        </Text>
      </div>
    </S.EmptyStepListContainer>
  );
};

export default StepList;
