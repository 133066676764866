import styled from '@emotion/styled';

export const OathContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const AgreementBox = styled.div`
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors['gray-50']};
  border-radius: 4px;
  padding: 16px;
`;

export const ButtonWrapper = styled.div`
  padding: 24px;
  display: flex;
  justify-content: end;
  gap: 8px;
`;
