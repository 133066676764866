import type { ALIGN } from '@ats/ui';
import { Table } from '@ats/ui';
import {
  freezePanesIndexAtom,
  gridSumWidthSelector,
} from '@stores/atoms/evaluation/document.atom';
import type { CSSProperties } from 'react';
import { type ReactNode } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from '../../gridBody.style';

export interface GridBodyColProps {
  children: ReactNode;
  index: number;
  isHover?: boolean;
  isCheckedRow?: boolean;
  position?: ALIGN;
}

export const GridBodyCol = ({
  children,
  index,
  isHover,
  isCheckedRow,
}: GridBodyColProps) => {
  const [freezePanesIndex] = useRecoilState(freezePanesIndexAtom);

  const width = useRecoilValue(gridSumWidthSelector);

  const isFreeze = index <= freezePanesIndex;
  const freezeStyle: CSSProperties = isFreeze
    ? {
        position: 'sticky',
        left: `${width[index - 1]}px`,
        zIndex: 1,
      }
    : {};

  return (
    <Table.Cell
      style={{
        ...freezeStyle,
        height: '48px',
      }}
    >
      <S.BodyCellInner isHover={isHover} isCheckedRow={isCheckedRow}>
        {children}
      </S.BodyCellInner>
    </Table.Cell>
  );
};
