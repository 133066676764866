import {
  STEP_SEARCH_TYPE,
  STEP_TYPE_CODE,
} from '@constants/evaluation.constant';
import { atom } from 'recoil';

export const selectedStepTypeAtom = atom({
  key: 'selectedStepTypeAtom',
  default: STEP_TYPE_CODE.ALL,
});

export const selectedStepSearchTypeAtom = atom({
  key: 'selectedStepSearchTypeAtom',
  default: STEP_SEARCH_TYPE.ALL,
});

export const stepSearchKeywordAtom = atom({
  key: 'stepSearchKeywordAtom',
  default: '',
});

export const stepCurrentPageAtom = atom({
  key: 'stepCurrentPageAtom',
  default: 1,
});
export const stepPageSizeAtom = atom({ key: 'stepPageSizeAtom', default: 5 });
export const stepLastPageAtom = atom({ key: 'stepLastPageAtom', default: 1 });
export const stepRecordCountAtom = atom({
  key: 'stepRecordCountAtom',
  default: 0,
});

// 평가표 서약서용
export const stepSnForEvaluationOathAtom = atom({
  key: 'stepSnForEvaluationOathAtom',
  default: '',
});
export const stepTypeCodeForEvaluationOathAtom = atom({
  key: 'stepTypeCodeForEvaluationOathAtom',
  default: STEP_TYPE_CODE.ALL,
});
export const jobNoticeSnForEvaluationOathAtom = atom({
  key: 'jobNoticeSnForEvaluationOathAtom',
  default: '',
});
