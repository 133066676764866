export const PAGES = {
  ALL: '*',
  HEALTH: '/health',
  VIDEO_INTERVIEW: {
    INTERVIEWEE: {
      HOME: '/interviewee',
      ROOM: `/interviewee/room`,
      WAITING: '/interviewee/waiting',
      SETTING: '/interviewee/setting',
      CHECK_BROWSER: '/interviewee/check-browser',
    },
    INTERVIEWEE_SECURE: {
      HOME: '/secure/interviewee',
      WAITING: '/secure/interviewee/waiting',
      ROOM: `/secure/interviewee/room`,
      SETTING: '/secure/interviewee/setting',
      CHECK_BROWSER: '/secure/interviewee/check-browser',
    },
    INTERVIEWER: {
      HOME: '/interviewer',
      ROOM: `/interviewer/room`,
      WAITING: '/interviewer/waiting',
      SETTING: '/interviewer/setting',
      CHECK_BROWSER: '/interviewer/check-browser',
    },
    SETTING: {
      HOME: '/setting',
    },
  },
  INTERVIEWER_EVALUATOR: {
    HOME: `/`,
    NOTICE: {
      HOME: '/notice',
      READ: (sn: string) => `/notice/read/${sn}`,
    },
    QUESTION_AND_ANSWER: {
      HOME: '/question-and-answer',
      CREATE: '/question-and-answer/create',
      READ: (sn: string) => `/question-and-answer/read/${sn}`,
      EDIT: (sn: string) => `/question-and-answer/edit/${sn}`,
    },
    EVALUATION: {
      HOME: '/evaluation',
      GRID: (screeningType: string, screeningSn: string) =>
        `/evaluation/${screeningType}/${screeningSn}`,
      INTERVIEW: (screeningSn: string) =>
        `/evaluation/interview/${screeningSn}`,
    },
    SEARCH: '/search',
    GUIDE: '/guide',
    CHANGE_PASSWORD: '/change-password',
    CHOICE_SERVICE: '/choice-service',
    ERROR: {
      SYSTEM_ERROR: '/system-error',
      INVALID_SESSION: '/invalid-session',
    },
  },
};
