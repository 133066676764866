import { convertToFormData } from '@helpers/formData.util';
import { interviewQueryKey } from '@repositories/evaluation/interview/interview.query';
import { interviewEvaluationRepository } from '@repositories/evaluation/interview/interview.repository';
import {
  interviewBaseSelector,
  interviewSelector,
} from '@stores/selectors/evaluation/interview.selector';
import { useQueries } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

export const useInterviewMainPrefetch = (screeningSn: string) => {
  const resumeRq = useRecoilValue(interviewSelector(screeningSn!));
  const resumeBaseRq = useRecoilValue(interviewBaseSelector(screeningSn!));

  useQueries({
    queries: [
      {
        queryKey: interviewQueryKey.scheduleList(screeningSn),
        queryFn: () =>
          interviewEvaluationRepository.getScreeningInterviewScheduleList(
            convertToFormData({ screeningSn } as unknown as Record<
              string,
              string
            >),
          ),
      },
      {
        queryKey: interviewQueryKey.resumeList(resumeRq),
        queryFn: () =>
          interviewEvaluationRepository.getScreeningInterviewResumeList(
            convertToFormData(resumeRq as unknown as Record<string, string>),
          ),
      },
      {
        queryKey: interviewQueryKey.resumeList(resumeBaseRq),
        queryFn: () =>
          interviewEvaluationRepository.getScreeningInterviewResumeList(
            convertToFormData(
              resumeBaseRq as unknown as Record<string, string>,
            ),
          ),
      },
    ],
  });
};
