import { Portal } from '@ats/ui';
import { Z_INDEX } from '@constants/hierarchy.constant.ts';
import type { CSSProperties } from 'react';
import { useEffect, useRef, useState, type ReactNode } from 'react';
import * as S from './PortalHeadlessTooltip.style.ts';

export interface Props {
  children: ReactNode;
  content: ReactNode;
  width?: number;
  wrap?: boolean;
  style?: CSSProperties;
}
const PortalHeadlessTooltip = ({
  children,
  content,
  width,
  wrap = false,
  style,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    if (!contentRef.current) return;

    const position = contentRef.current.getBoundingClientRect();
    const contentRefHeight = contentRef.current.clientHeight;

    setTop(position.top + contentRefHeight);
    setLeft(position.left);
  }, [isHover]);

  return (
    <S.Container
      ref={contentRef}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      {isHover && (
        <Portal selector={'#tooltip'}>
          <S.Content
            style={{
              top: top,
              left: left,
              zIndex: Z_INDEX.TOOLTIP,
              width,
              whiteSpace: wrap ? 'normal' : 'nowrap',
              ...style,
            }}
          >
            {content}
          </S.Content>
        </Portal>
      )}
      {children}
    </S.Container>
  );
};

export default PortalHeadlessTooltip;
