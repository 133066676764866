import { Button, SIZE } from '@ats/ui';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip';
import { useTheme } from '@emotion/react';
import {
  freezePanesIndexAtom,
  screeningNoticeSnAtom,
  screeningSectorSnAtom,
  selectedNoticeAtom,
  selectedSectorAtom,
} from '@stores/atoms/evaluation/document.atom';
import {
  gridFilterBlackAtom,
  gridFilterEvaluationItemsAtom,
  gridFilterFinalEvaluateListAtom,
  gridFilterInterestAtom,
  gridFilterReadAtom,
  gridFilterTotalEvaluationAtom,
  gridFilterUnReadAtom,
  gridFilterUnUploadFileAtom,
  gridFilterUploadFileAtom,
} from '@stores/atoms/evaluation/document.filter';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as S from './left.style';

export const Reset = () => {
  const noticeSn = useRecoilValue(screeningNoticeSnAtom);
  const setGridFilterRead = useSetRecoilState(gridFilterReadAtom);
  const setGridFilterUnReadAtom = useSetRecoilState(gridFilterUnReadAtom);

  const setGridFilterInterestAtom = useSetRecoilState(gridFilterInterestAtom);
  const setGridFilterBlackAtom = useSetRecoilState(gridFilterBlackAtom);

  const setGridFilterEvaluationItemsAtom = useSetRecoilState(
    gridFilterEvaluationItemsAtom,
  );
  const setGridFilterTotalEvaluationAtom = useSetRecoilState(
    gridFilterTotalEvaluationAtom,
  );

  const setGridFilterFinalEvaluateListAtom = useSetRecoilState(
    gridFilterFinalEvaluateListAtom,
  );

  const setGridFilterUploadFileAtom = useSetRecoilState(
    gridFilterUploadFileAtom,
  );
  const setGridFilterUnUploadFileAtom = useSetRecoilState(
    gridFilterUnUploadFileAtom,
  );

  const setFreezePanesIndex = useSetRecoilState(freezePanesIndexAtom);
  const FREEZE_PANES_INDEX_INIT = 4;

  const setNoticeSn = useSetRecoilState(screeningNoticeSnAtom);
  const setSectorSn = useSetRecoilState(screeningSectorSnAtom);

  const setSelectedNotice = useSetRecoilState(selectedNoticeAtom);
  const setSelectedSectorAtom = useSetRecoilState(selectedSectorAtom);

  const handleClickReset = () => {
    // setGridFilterRead(false);
    // setGridFilterUnReadAtom(false);
    // setGridFilterInterestAtom(false);
    // setGridFilterBlackAtom(false);
    // setGridFilterEvaluationItemsAtom([]);
    // setGridFilterTotalEvaluationAtom({
    //   evaluationItemSn: 0,
    //   ratingScaleSns: [],
    // });
    // setGridFilterFinalEvaluateListAtom([]);
    // setGridFilterUploadFileAtom(false);
    // setGridFilterUnUploadFileAtom(false);

    // setNoticeSn(null);
    // setSectorSn(null);
    // setSelectedNotice('');
    // setSelectedSectorAtom('');

    window.location.reload();
  };

  const theme = useTheme();

  return (
    <HeadlessTooltip
      isShow={!noticeSn}
      content={<S.Tooltip>공고를 선택해주세요</S.Tooltip>}
    >
      <Button
        size={SIZE.SM}
        // leftIcon={'icon_refresh_18_line'}
        style={{
          background: !noticeSn ? 'none' : '',
          color: !noticeSn ? theme.colors['gray-600'] : '',
        }}
        disabled={!noticeSn}
        onClick={handleClickReset}
      >
        초기화
      </Button>
    </HeadlessTooltip>
  );
};
