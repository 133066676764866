import { queryClient } from '@helpers/client';
import { convertToFormData } from '@helpers/formData.util';
import { interviewSelector } from '@stores/selectors/evaluation/interview.selector';
import type {
  UseMutationResult,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useRecoilValue } from 'recoil';
import type { ScreeningSn } from '../common/common.dto';
import type {
  ResumeEvaluationItemsRqDTO,
  ResumeEvaluationItemsRsDTO,
  ScreeningFinalEvaluationRqDTO,
  ScreeningInfoRsDTO,
  ScreeningInterviewResumeListRqDTO,
  ScreeningInterviewResumeListRsDTO,
  ScreeningInterviewScheduleListRqDTO,
  ScreeningInterviewScheduleListRsDTO,
} from './interview.dto';
import { interviewEvaluationRepository } from './interview.repository';

export const interviewQueryKey = {
  interview: 'interview',
  screeningInfo: (screeningSn: ScreeningSn) => [
    interviewQueryKey.interview,
    'screeningInfo',
    screeningSn,
  ],
  scheduleList: (screeningSn: ScreeningSn) => [
    interviewQueryKey.interview,
    'scheduleList',
    screeningSn,
  ],
  resumeList: (request: ScreeningInterviewResumeListRqDTO) => [
    interviewQueryKey.interview,
    'resumeList',
    request,
  ],
  resumeEvaluationItems: () => [
    interviewQueryKey.interview,
    'resumeEvaluationItems',
    // request,
  ],
};

export const useScreeningInfoQuery = (
  request: ScreeningInterviewScheduleListRqDTO,
): UseSuspenseQueryResult<ScreeningInfoRsDTO, AxiosError> =>
  useSuspenseQuery({
    queryKey: interviewQueryKey.screeningInfo(request.screeningSn),
    queryFn: () =>
      interviewEvaluationRepository.getScreeningInfo(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useScreeningInterviewScheduleListQuery = (
  request: ScreeningInterviewScheduleListRqDTO,
): UseSuspenseQueryResult<ScreeningInterviewScheduleListRsDTO, AxiosError> =>
  useSuspenseQuery({
    queryKey: interviewQueryKey.scheduleList(request.screeningSn),
    queryFn: () =>
      interviewEvaluationRepository.getScreeningInterviewScheduleList(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });
export const getScreeningInterviewScheduleListQueryData = (
  request: ScreeningInterviewScheduleListRqDTO,
) =>
  queryClient.getQueryData(
    interviewQueryKey.scheduleList(request.screeningSn),
  ) as ScreeningInterviewScheduleListRsDTO;

export const useScreeningInterviewResumeListQuery = (
  request: ScreeningInterviewResumeListRqDTO,
): UseSuspenseQueryResult<ScreeningInterviewResumeListRsDTO, AxiosError> =>
  useSuspenseQuery({
    queryKey: interviewQueryKey.resumeList(request),
    queryFn: () =>
      interviewEvaluationRepository.getScreeningInterviewResumeList(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useResumeEvaluationItemsQuery = (
  request: ResumeEvaluationItemsRqDTO,
): UseSuspenseQueryResult<ResumeEvaluationItemsRsDTO, AxiosError> =>
  useSuspenseQuery({
    queryKey: interviewQueryKey.resumeEvaluationItems(),
    queryFn: () =>
      interviewEvaluationRepository.getResumeEvaluationItems(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useFinalEvaluationMutation = (
  screeningSn: string,
): UseMutationResult<unknown, AxiosError, ScreeningFinalEvaluationRqDTO> => {
  const requestBody = useRecoilValue(interviewSelector(screeningSn!));

  return useMutation({
    mutationFn: (request) =>
      interviewEvaluationRepository.updateFinalEvaluation(
        convertToFormData(request as unknown as Record<string, string>),
      ),
    onMutate: async (request) => {
      await queryClient.cancelQueries({
        queryKey: ['evaluateInterviewResume', requestBody],
      });

      const { finalEvalResultCode, screeningValuerSn } = request;

      queryClient.setQueryData(
        ['evaluateInterviewResume', requestBody],
        (prev: ScreeningInterviewResumeListRsDTO) => ({
          ...prev,
          interviewResumeList: prev.interviewResumeList.map((resume) =>
            resume.screeningValuerSn !== screeningValuerSn
              ? resume
              : { ...resume, screeningResultCode: finalEvalResultCode },
          ),
        }),
      );

      const previousData = queryClient.getQueryData([
        'evaluateInterviewResume',
        Object.values(requestBody),
      ]);

      return { previousData };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        ['evaluateInterviewResume', requestBody],
        context?.previousData,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['evaluateInterviewResume'],
      });
    },
  });
};
