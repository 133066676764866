import styled from '@emotion/styled';

export const RedText = styled.span`
  color: ${({ theme }) => theme.colors['red-600']};
`;

export const TextArea = styled.textarea`
  background: ${({ theme }) => theme.colors['gray-80']};
  resize: none;
  width: 732px;
  height: 201px;
  margin-top: 8px;
  padding: 16px;
`;
