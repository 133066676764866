import type { Nullable } from '@ats/interfaces';
import { INTERVIEW_DATE_TYPE } from '@components/evaluation/interview/interview.constant';
import type { ScreeningInterviewResumeListRqDTO } from '@repositories/evaluation/interview/interview.dto';
import {
  interviewDateAtom,
  interviewDateTypeAtom,
  interviewEndDateTimeAtom,
  interviewPlaceSnAtom,
  interviewStartDateTimeAtom,
} from '@stores/atoms/evaluation/interview/interview.date.atom';
import { selector } from 'recoil';

type InterviewDate = Pick<
  ScreeningInterviewResumeListRqDTO,
  | 'searchAllApplicant'
  | 'searchDayAllApplicant'
  | 'interviewDate'
  | 'startDateTime'
  | 'endDateTime'
  | 'placeSn'
>;

export const getFormmatedDate = (dateTime: Nullable<string>) => {
  if (!dateTime) return '';

  const [date, time] = dateTime.split(' ');
  return date.split('-').join('.');
};

export const interviewDateSelector = selector<InterviewDate>({
  key: 'interviewDateSelector',
  get: ({ get }) => {
    const interviewDateType = get(interviewDateTypeAtom);
    const interviewDate = get(interviewDateAtom);
    const startDateTime = get(interviewStartDateTimeAtom);
    const endDateTime = get(interviewEndDateTimeAtom);
    const placeSn = get(interviewPlaceSnAtom);

    if (interviewDateType === INTERVIEW_DATE_TYPE.ALL)
      return {
        searchAllApplicant: true,
        searchDayAllApplicant: false,
        interviewDate: '',
        startDateTime: '',
        endDateTime: '',
        placeSn: '',
      };

    if (interviewDateType === INTERVIEW_DATE_TYPE.DATE)
      return {
        searchAllApplicant: false,
        searchDayAllApplicant: true,
        interviewDate: getFormmatedDate(interviewDate),
        startDateTime: '',
        endDateTime: '',
        placeSn: '',
      };

    if (interviewDateType === INTERVIEW_DATE_TYPE.DATE_TIME_PLACE)
      return {
        searchAllApplicant: false,
        searchDayAllApplicant: false,
        interviewDate: getFormmatedDate(interviewDate),
        startDateTime: startDateTime ?? '',
        endDateTime: endDateTime ?? '',
        placeSn: placeSn ?? '',
      };

    return {};
  },
});
