import styled from '@emotion/styled';

export const Container = styled.div`
  /* position: absolute; */
  /* top: 40px; */
  width: 426px;

  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;
  ${({ theme }) => theme.shadow.shadow3};
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: ${({ theme }) => theme.radius.radius100};
`;

export const UpperSection = styled.div`
  padding: 24px 24px 8px 24px;
`;
export const LowerSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors['gray-400']};
  padding: 16px 24px;
  display: flex;
  justify-content: end;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
