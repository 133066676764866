import { ReactComponent as ApprovalWaiting } from '@assets/error/icon_desktop_approval_waiting_mono.svg';
import { PAGES } from '@ats/constants';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Content, Description, Title, Wrapper } from './style';

const InvalidSessionPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <Content>
          <ApprovalWaiting />
          <Title>로그인 세션 만료</Title>
          <Description>
            인증이 만료되었습니다.
            <br />
            다시 한 번 로그인해주세요.
          </Description>
          <Button
            size={SIZE.SM}
            variant={BUTTON_VARIANTS.PRIMARY}
            color={BUTTON_COLORS.DEFAULT}
            style={{ marginTop: 40 }}
            onClick={() =>
              navigate(PAGES.INTERVIEWER_EVALUATOR.EVALUATION.HOME)
            }
          >
            로그인하기
          </Button>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default InvalidSessionPage;
