import type { RadioProps } from '@ats/ui';
import { Alert, InputItem, InputList, RadioGroup, Text } from '@ats/ui';
import type { SortType } from '@constants/evaluation.constant';
import { SORT_TYPE } from '@constants/evaluation.constant';
import { useTheme } from '@emotion/react';
import { usePrintForm } from '@hooks/common/usePrintForm';
import { useModal } from '@hooks/shared/useModal';
import { isVideoInterviewAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import type { SortColumn } from '../../interview.constant';
import { SORT_COLUMN } from '../../interview.constant';
import * as S from './interviewPrintModal.style';

const InputRadio = (props: RadioProps) => (
  <InputItem.Radio style={{ width: '180px' }} {...props} />
);

const InterviewPrintModal = () => {
  const { closeModal } = useModal();
  const { screeningSn } = useParams();
  const theme = useTheme();

  const isVideoInterview = useRecoilValue(isVideoInterviewAtom);
  const [sortColumn, setSortColumn] = useState(SORT_COLUMN.RESUME_DISPLAY_NO);
  const [summarySortType, setSummarySortType] = useState(SORT_TYPE.ASC);

  const { RenderPrintForm, handleSubmit } = usePrintForm({
    url: '/cus/evaluation/popScreeningSummary',
    screeningSn: screeningSn!,
    sortColumn,
    sortType: summarySortType,
  });

  const handleChangeSortColumn = (e: ChangeEvent<HTMLInputElement>) => {
    setSortColumn(e.target.value as SortColumn);
  };
  const handleChangeSummarySortType = (e: ChangeEvent<HTMLInputElement>) => {
    setSummarySortType(e.target.value as SortType);
  };
  const handleClickPrint = () => {
    handleSubmit();
    closeModal();
  };

  return (
    <Alert.Container style={{ width: '548px' }} showBackgroundDim>
      <Alert.Header title='평가표 출력' onClickClose={closeModal} />
      <Alert.Separate />
      <Alert.Body>
        <S.Column>
          <Text variant={'b2'} bold color={theme.colors['gray-800']}>
            정렬
          </Text>
          <div style={{ display: 'flex', gap: '16px' }}>
            <RadioGroup style={{ width: '100%' }}>
              <InputList>
                <InputRadio
                  style={{ width: '100%' }}
                  checked={SORT_COLUMN.RESUME_DISPLAY_NO === sortColumn}
                  value={SORT_COLUMN.RESUME_DISPLAY_NO}
                  onChange={handleChangeSortColumn}
                  name='filterType'
                >
                  <Text variant={'b2'}>수험번호</Text>
                </InputRadio>
                <InputRadio
                  style={{ width: '100%' }}
                  checked={SORT_COLUMN.NAME === sortColumn}
                  value={SORT_COLUMN.NAME}
                  onChange={handleChangeSortColumn}
                  name='filterType'
                >
                  <Text variant={'b2'}>지원자 성명</Text>
                </InputRadio>
                {!isVideoInterview && (
                  <InputRadio
                    style={{ width: '100%' }}
                    checked={SORT_COLUMN.INTERVIEW_GROUP === sortColumn}
                    value={SORT_COLUMN.INTERVIEW_GROUP}
                    onChange={handleChangeSortColumn}
                    name='filterType'
                  >
                    <Text variant={'b2'}>면접조별</Text>
                  </InputRadio>
                )}

                <InputRadio
                  style={{ width: '100%' }}
                  checked={SORT_COLUMN.TOTAL_EVAL === sortColumn}
                  value={SORT_COLUMN.TOTAL_EVAL}
                  onChange={handleChangeSortColumn}
                  name='filterType'
                >
                  <Text variant={'b2'}>종합평가</Text>
                </InputRadio>
              </InputList>
            </RadioGroup>

            <RadioGroup style={{ width: '100%' }}>
              <InputList>
                <InputRadio
                  style={{ width: '100%' }}
                  checked={SORT_TYPE.ASC === summarySortType}
                  value={SORT_TYPE.ASC}
                  onChange={handleChangeSummarySortType}
                  name='filterOrder'
                >
                  <Text variant={'b2'}>오름차순</Text>
                </InputRadio>
                <InputRadio
                  style={{ width: '100%' }}
                  checked={SORT_TYPE.DESC === summarySortType}
                  value={SORT_TYPE.DESC}
                  onChange={handleChangeSummarySortType}
                  name='filterOrder'
                >
                  <Text variant={'b2'}>내림차순</Text>
                </InputRadio>
              </InputList>
            </RadioGroup>
          </div>
        </S.Column>
      </Alert.Body>
      <Alert.Separate />
      <Alert.Footer
        onClickCancel={closeModal}
        onClickConfirm={handleClickPrint}
        confirmText='평가표 출력'
      />
      <RenderPrintForm />
    </Alert.Container>
  );
};

export default InterviewPrintModal;
