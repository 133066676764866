import { Pagination as Page } from '@ats/ui';
import * as S from './pageNumberList.style';

interface PageNumberList {
  currentPage: number;
  setCurrentPage: (num: number) => void;
  lastPage: number;
  disabled?: boolean;
}
const ELL = 'ELL';
type PageNumber = number | typeof ELL;

export const PageNumberList = (props: PageNumberList) => {
  const { setCurrentPage, disabled, ...rest } = props;
  const pageNumList = getPageNumberList(rest);

  const renderPageNumberEl = (num: PageNumber, i: number) => {
    if (num === ELL) return <Page.Ellipsis key={i} />;
    else
      return (
        <Page.Number
          disabled={disabled}
          onClick={() => setCurrentPage(num)}
          value={num}
          key={i}
          selected={props.currentPage === num}
        />
      );
  };

  return (
    <S.Container>
      {pageNumList.map((el, i) => renderPageNumberEl(el, i))}
    </S.Container>
  );
};

const getPageNumberList = (
  props: Pick<PageNumberList, 'currentPage' | 'lastPage'>,
): PageNumber[] => {
  const { currentPage, lastPage } = props;
  const startPage = 1;
  const pageNum = lastPage - startPage + 1;
  const MAX_PAGE_NUM = 7;
  const needEllipsis = pageNum > MAX_PAGE_NUM;
  const needLeftEllipsis = needEllipsis && lastPage - currentPage < 4;
  const needRightEllipsis = needEllipsis && currentPage - startPage < 4;

  const getPageNum = (from: number = startPage, to: number = lastPage + 1) =>
    new Array(to - from).fill(0).map((_, i) => i + from);

  if (!needEllipsis) return getPageNum();
  if (needEllipsis && needRightEllipsis)
    return [...getPageNum(1, 6), ELL, ...getPageNum(lastPage)];
  if (needEllipsis && needLeftEllipsis) {
    return [...getPageNum(1, 2), ELL, ...getPageNum(lastPage - 4)];
  }
  // 양쪽 모두 줄임이 있는 경우
  return [
    ...getPageNum(1, 2),
    ELL,
    ...getPageNum(currentPage - 1, currentPage + 2),
    ELL,
    ...getPageNum(lastPage),
  ];
};
