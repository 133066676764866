import type { Nullable } from '@ats/interfaces';
import { sessionEndTime } from '@stores/atoms/auth';
import dayjs, { isDayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

const defaultSessionTime = '00:00:00';
let intervalId: Nullable<NodeJS.Timeout> = null;

export function useSessionTime() {
  const [currentSessionEndTime, setCurrentSessionEndTime] =
    useRecoilState(sessionEndTime);

  const [remainSessionTime, setRemainSessionTime] =
    useState<Nullable<string>>(defaultSessionTime);

  const [refreshCount, setRefreshCount] = useState(0);

  const getSessionTime = (day = dayjs()) =>
    day.add(dayjs.duration({ hours: 2 }));

  const resetIntervalId = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };

  const refresh = () => {
    resetIntervalId();
    setCurrentSessionEndTime(getSessionTime());
    setRefreshCount(refreshCount + 1);
  };

  const reset = () => {
    resetIntervalId();
    setCurrentSessionEndTime(null);
    setRemainSessionTime(defaultSessionTime);
  };

  useEffect(() => {
    intervalId = setInterval(() => {
      if (!currentSessionEndTime) return;
      if (!isDayjs(currentSessionEndTime)) return;

      const diff = currentSessionEndTime?.diff(dayjs());
      setRemainSessionTime(dayjs.duration(diff).format('HH:mm:ss'));
    }, 1000);

    return () => {
      if (!intervalId) return;
      clearInterval(intervalId);
    };
  }, [refreshCount, currentSessionEndTime]);

  return {
    currentSessionEndTime,
    remainSessionTime,
    refresh,
    reset,
    getSessionTime,
  };
}
