import type { ModalKey } from '@constants/modal.constant';
import { useSetRecoilState } from 'recoil';
import { modalKeyAtom } from '../../stores/atoms/modal.atom';

export function useModal() {
  const setModalKey = useSetRecoilState(modalKeyAtom);

  const openModal = (modalKey: ModalKey) => {
    setModalKey(modalKey);
  };
  const closeModal = () => {
    setModalKey(null);
  };

  return {
    openModal,
    closeModal,
  };
}
