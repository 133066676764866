import { useQnaCommentsQuery } from '@repositories/qna/qna.query.ts';
import type { FC } from 'react';
import Comment from './Comment';
import { Container, Count, Total, TotalCountArea } from './style';

interface Props {
  upArticleSn: string;
  memberSn: string;
}
const Comments: FC<Props> = ({ upArticleSn, memberSn }) => {
  const { data, isSuccess } = useQnaCommentsQuery({ upArticleSn });

  if (!isSuccess) return null;
  if (!data.length) return null;

  return (
    <Container>
      <TotalCountArea>
        <Total>답변</Total>&nbsp;<Count>{data.length}</Count>
      </TotalCountArea>
      {data.map((props, idx) => (
        <Comment key={`comment-${idx}`} memberSn={memberSn} {...props} />
      ))}
    </Container>
  );
};

export default Comments;
