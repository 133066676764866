import { useResumeForm } from '@hooks/common/useResumeForm.ts';
import type { SerialNumberInfo } from '@repositories/evaluation/document/document.dto.ts';
import {
  screeningSnAtom,
  screeningTypeAtom,
} from '@stores/atoms/evaluation/document.atom.ts';
import type { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

type GridBodyColOpenResumeProps = {
  handleClickToggleReadStatus: (modifyYn: boolean) => void;
  children: ReactNode;
} & SerialNumberInfo;
export const GridBodyColOpenResume = ({
  resumeSn,
  screeningValuerSn,
  recruitNoticeSn,
  recruitSectorSn,
  screeningApplySectorSn,
  handleClickToggleReadStatus,
  children,
}: GridBodyColOpenResumeProps) => {
  const screeningSn = useRecoilValue(screeningSnAtom);
  const screeningType = useRecoilValue(screeningTypeAtom);
  const { RenderResumeForm, handleSubmit } = useResumeForm({
    resumeSn,
    screeningValuerSn,
    recruitNoticeSn,
    recruitSectorSn,
    screeningSn,
    screeningType,
    screeningApplySectorSn,
  });

  const handleClickOpenResume = () => {
    handleSubmit();
    handleClickToggleReadStatus(true);
  };

  return (
    <>
      <button onClick={handleClickOpenResume} style={{ display: 'flex' }}>
        {children}
      </button>
      <RenderResumeForm />
    </>
  );
};
