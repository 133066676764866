import type { Nullable } from '@ats/interfaces';
import { isGridFilterModalOpenAtom } from '@stores/atoms/evaluation/document.atom';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

export type ChildRefHandle = { refreshFn: () => void; applyFn: () => void };

export const useGridFilter = () => {
  const [isGridFilterModalOpen, setIsGridFilterModalOpen] = useRecoilState(
    isGridFilterModalOpenAtom,
  );

  const modalRef = useRef<Nullable<HTMLDivElement>>(null);
  useEffect(() => {
    const listener = (event: Event) => {
      const $dropdownRoot = document.querySelector('#dropdown-root');
      if (
        isGridFilterModalOpen &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        $dropdownRoot &&
        !$dropdownRoot.contains(event.target as Node)
      ) {
        setIsGridFilterModalOpen(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [isGridFilterModalOpen, setIsGridFilterModalOpen]);

  const readStatusFilterRef = useRef<ChildRefHandle>(null);
  const interestBlackFilterRef = useRef<ChildRefHandle>(null);
  const evaluationItemsFilterRef = useRef<ChildRefHandle>(null);
  const totalEvaluationFilterRef = useRef<ChildRefHandle>(null);
  const finalEvaluationFilterRef = useRef<ChildRefHandle>(null);
  const managerUploadFileFilterRef = useRef<ChildRefHandle>(null);

  const handleRefreshFilter = () => {
    readStatusFilterRef.current?.refreshFn();
    interestBlackFilterRef.current?.refreshFn();
    evaluationItemsFilterRef.current?.refreshFn();
    totalEvaluationFilterRef.current?.refreshFn();
    finalEvaluationFilterRef.current?.refreshFn();
    managerUploadFileFilterRef.current?.refreshFn();
  };

  const handleApplyFilter = () => {
    readStatusFilterRef.current?.applyFn();
    interestBlackFilterRef.current?.applyFn();
    evaluationItemsFilterRef.current?.applyFn();
    totalEvaluationFilterRef.current?.applyFn();
    finalEvaluationFilterRef.current?.applyFn();
    managerUploadFileFilterRef.current?.applyFn();

    setIsGridFilterModalOpen(false);
  };

  // const filterCount =
  //   0 + (noInterestBlackChecked ? 0 : 1) + (noEvaluationChecked ? 0 : 1);

  return {
    modalRef,

    readStatusFilterRef,
    interestBlackFilterRef,
    evaluationItemsFilterRef,
    totalEvaluationFilterRef,
    finalEvaluationFilterRef,
    managerUploadFileFilterRef,

    handleRefreshFilter,
    handleApplyFilter,
  };
};
