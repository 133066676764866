import { BaseRepository } from '@ats/repositories';
import type {
  ArticleListRqDTO,
  ArticleListRsDTO,
  ArticleRqDTO,
  ArticleRsDTO,
} from './article.dto';

class ArticleRepository extends BaseRepository {
  public async getArticleList(request: ArticleListRqDTO) {
    const { data } = await super.post<ArticleListRsDTO>(
      '/v2/bbs/article/list',
      request,
    );
    return data;
  }

  public async getArticle(request: ArticleRqDTO) {
    const { data } = await super.post<ArticleRsDTO>(
      '/v2/bbs/article/read',
      request,
    );
    return data;
  }
}

export const articleRepository = new ArticleRepository('');
