import type { IconName } from '@ats/ui';
import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Dropdown,
  Icon,
  SIZE,
  Tag,
  Text,
  TextField,
  useDropdown,
} from '@ats/ui';
import FlatTag from '@components/common/FlatTag/FlatTag';
import { FLAT_TAG_SHAPE } from '@components/common/FlatTag/flatTag.constant';
import { MODAL_KEY } from '@constants/modal.constant';
import { useTheme } from '@emotion/react';
import { useModal } from '@hooks/shared/useModal';
import { getScreeningInterviewScheduleListQueryData } from '@repositories/evaluation/interview/interview.query';
import {
  interviewKeywordAtom,
  interviewKeywordTypeAtom,
  isFilterModalOpenAtom,
} from '@stores/atoms/evaluation/interview/interview.atom';
import {
  interviewDateAtom,
  interviewDateIndexAtom,
  interviewDateNavTypeAtom,
  isTodayInterviewAtom,
} from '@stores/atoms/evaluation/interview/interview.date.atom';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import FilterModal from '../Modal/FilterModal/FilterModal';
import { formatDateString } from '../Sidebar/sidebar.utils';
import type { KeywordType } from '../interview.constant';
import {
  DATE_NAV_STATE,
  KEYWORD_TYPE,
  KEYWORD_TYPE_MAP,
  KEYWORD_TYPE_OPTIONS,
} from '../interview.constant';
import * as S from './header.style';
import { useFilterCount } from './useFilterCount';

const Header = () => {
  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  const interviewDateNavType = useRecoilValue(interviewDateNavTypeAtom);
  const [isFilterModalOpen, setIsFilterModalOpen] = useRecoilState(
    isFilterModalOpenAtom,
  );
  const setKeywordTypeStore = useSetRecoilState(interviewKeywordTypeAtom);
  const setKeywordStore = useSetRecoilState(interviewKeywordAtom);
  const filterCount = useFilterCount();
  const [detailedFilterCount] = useState(1);

  const [selectedKeywordType, setSelectedKeywordType] = useState<KeywordType>(
    KEYWORD_TYPE.NAME,
  );
  const [keyword, setKeyword] = useState('');
  const { openModal } = useModal();
  const theme = useTheme();

  const handleClickPrint = () => {
    openModal(MODAL_KEY.INTERVIEW_PRINT);
    setIsFilterModalOpen(false);
  };

  const handleClickSearch = () => {
    setKeywordTypeStore(selectedKeywordType);
    setKeywordStore(keyword);
  };

  return (
    <S.Container>
      {interviewDateNavType === DATE_NAV_STATE.DATE && <DateHeader />}
      <S.LowerSection>
        <S.LeftSection>
          {isFilterModalOpen && <FilterModal />}
          <Button
            size={SIZE.SM}
            variant={BUTTON_VARIANTS.OUTLINED}
            leftIcon='icon_filter_18_line'
            rightSection={
              <>
                {!!filterCount && (
                  <FlatTag
                    backgroundColor={theme.colors['primary-600']}
                    color={theme.colors['green-50']}
                    shape={FLAT_TAG_SHAPE.ROUND}
                  >
                    <Text variant={'b3'} bold>
                      {'0' + filterCount}
                    </Text>
                  </FlatTag>
                )}
              </>
            }
            onClick={() => {
              setIsFilterModalOpen((prev) => !prev);
            }}
          >
            필터
          </Button>

          <Button
            size='sm'
            variant={BUTTON_VARIANTS.OUTLINED}
            leftIcon='icon_add_18_line'
            rightSection={
              <>
                {!!detailedFilterCount && (
                  <FlatTag
                    backgroundColor={theme.colors['primary-600']}
                    color={theme.colors['green-50']}
                    shape={FLAT_TAG_SHAPE.ROUND}
                  >
                    <Text variant={'b3'} bold>
                      {'0' + detailedFilterCount}
                    </Text>
                  </FlatTag>
                )}
              </>
            }
          >
            더보기
          </Button>

          <Button
            size='sm'
            variant={BUTTON_VARIANTS.OUTLINED}
            leftIcon='icon_list_output_18_line'
            onClick={handleClickPrint}
          >
            평가표 출력
          </Button>

          <Button size='sm' variant={BUTTON_VARIANTS.TEXT}>
            필터 초기화
          </Button>

          <Button
            size='sm'
            variant={BUTTON_VARIANTS.PRIMARY}
            color={BUTTON_COLORS.THEMA}
            rightIcon={'icon_next_18_line'}
          >
            평가 결과 제출
          </Button>
        </S.LeftSection>
        <S.RightSection>
          <Dropdown.Container ref={dropdownContainerRef}>
            <Dropdown.Trigger
              variant={BUTTON_VARIANTS.SECONDARY}
              size={SIZE.SM}
              onClick={handleToggleDropdown}
              style={{ width: '100px', paddingRight: '8px' }}
              rightIcon='icon_arrow_bottom_18_line'
            >
              {KEYWORD_TYPE_MAP[selectedKeywordType]}
            </Dropdown.Trigger>
            <Dropdown.ItemContainer
              isOverflowScrollable={false}
              isOpen={isOpen}
              style={{ height: 'auto', width: '100px' }}
            >
              {KEYWORD_TYPE_OPTIONS.map(({ value, label }) => (
                <Dropdown.ButtonItem
                  key={value}
                  onClick={() => {
                    setSelectedKeywordType(value);
                    handleCloseDropdown();
                  }}
                >
                  {label}
                </Dropdown.ButtonItem>
              ))}
            </Dropdown.ItemContainer>
          </Dropdown.Container>
          <TextField
            size={SIZE.SM}
            style={{ width: '200px' }}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder='검색어를 입력해주세요.'
            leftSection={
              <Icon
                name='icon_search_18_line'
                size={20}
                color={theme.colors['gray-600']}
              />
            }
            onPressEnter={handleClickSearch}
          />
          <Button
            size={SIZE.SM}
            color={BUTTON_COLORS.THEMA}
            onClick={handleClickSearch}
          >
            검색
          </Button>
        </S.RightSection>
      </S.LowerSection>
    </S.Container>
  );
};

export default Header;
const DateHeader = () => {
  const isTodayInterview = useRecoilValue(isTodayInterviewAtom);
  const [interviewDate, setInterviewDate] = useRecoilState(interviewDateAtom);
  const { screeningSn } = useParams();
  const interviewDateList = getScreeningInterviewScheduleListQueryData({
    screeningSn: screeningSn!,
  });
  const [interviewDateIndex, setInterviewDateIndex] = useRecoilState(
    interviewDateIndexAtom,
  );

  if (!interviewDateList) return <></>;

  const isFirstItem = interviewDateIndex === 0;
  const isLastItem = interviewDateIndex >= interviewDateList.length - 1;

  const handleClickArrow = (offset: number) => {
    const newIdx = interviewDateIndex + offset;
    setInterviewDateIndex(newIdx);
    setInterviewDate(interviewDateList[newIdx].date);
  };
  const handleClickToday = () => {
    const todayIdx = isTodayInterview!;
    setInterviewDateIndex(todayIdx);
    setInterviewDate(interviewDateList[todayIdx].date);
  };

  return (
    <S.UpperSection>
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <ArrowIconButton
          onClick={() => handleClickArrow(-1)}
          disabled={isFirstItem}
          name={'icon_arrow_left_18_line'}
        />
        <Text variant='t1' bold>
          {interviewDate ? formatDateString(interviewDate!) : '일정 미배정'}
        </Text>
        <ArrowIconButton
          onClick={() => handleClickArrow(+1)}
          disabled={isLastItem}
          name={'icon_arrow_right_18_line'}
        />
      </div>

      <Tag
        onClick={() => {
          if (isTodayInterview) handleClickToday();
        }}
        style={{ height: '24px' }}
        size='lg'
        disabled={!isTodayInterview}
      >
        오늘
      </Tag>
    </S.UpperSection>
  );
};

interface ArrowIconButtonProps {
  onClick: () => void;
  name: IconName;
  disabled?: boolean;
}
const ArrowIconButton = ({ onClick, name, disabled }: ArrowIconButtonProps) => {
  const theme = useTheme();
  return (
    <Icon
      onClick={() => {
        if (!disabled) onClick();
      }}
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      size={32}
      name={name}
      color={disabled ? theme.colors['gray-400'] : theme.colors['gray-800']}
    />
  );
};
