import { BaseRepository } from '@ats/repositories';
import { HTTP_HEADER_FORM } from '@constants/repository.constant';
import type {
  DeleteNoticeCommentsRsDTO,
  GetNoticeCommentsRsDTO,
  PostNoticeCommentsRqDTO,
  PutNoticeCommentsRsDTO,
} from './notice.dto';

class NoticeRepository extends BaseRepository {
  public async getNoticeComments(request: FormData) {
    const { data } = await super.post<GetNoticeCommentsRsDTO>(
      '/bbs/eval/notice/comment/list',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async postNoticeComments(request: FormData, AUToken: string) {
    const { data } = await super.post<PostNoticeCommentsRqDTO>(
      '/bbs/eval/notice/comment/writeOk',
      request,
      { headers: { ...HTTP_HEADER_FORM.headers, AUToken } },
    );

    return data;
  }

  public async putNoticeComments(request: FormData) {
    const { data } = await super.post<PutNoticeCommentsRsDTO>(
      '/bbs/eval/notice/comment/updateOk',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async deleteNoticeComments(request: FormData) {
    const { data } = await super.post<DeleteNoticeCommentsRsDTO>(
      '/bbs/eval/notice/comment/delete',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }
}

export const noticeRepository = new NoticeRepository(
  import.meta.env.VITE_API_URL,
);
