import type { Nullable } from '@ats/interfaces';
import { DROPDOWN_POSITION, Dropdown, Icon, useDropdown } from '@ats/ui';
import { useTheme } from '@emotion/react';
import { useUpdateEvaluationQuery } from '@repositories/evaluation/common/common.query';
import type {
  EvaluationItemInfos,
  RatingScale,
} from '@repositories/evaluation/document/document.dto';
import {
  evaluationItemInfosAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as S from './gridBodyCol.style';

interface GridBodyColEvalProps {
  evalItemSn: number;
  ratingScaleSn: Nullable<number>;
  applicantScreeningValuerSn: number;
  handleClickToggleReadStatus: (modifyYn: boolean) => void;
}

export const GridBodyColEval = ({
  evalItemSn,
  ratingScaleSn,
  applicantScreeningValuerSn,
  handleClickToggleReadStatus,
}: GridBodyColEvalProps) => {
  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  const theme = useTheme();
  const screeningSn = useRecoilValue(screeningSnAtom);
  const evaluationItemInfos = useRecoilValue(evaluationItemInfosAtom);
  const numberEvalItemSn = Number(evalItemSn);

  const [isAverage, setIsAverage] = useState(false);

  const [targetRatingScales, setTargetRatingScales] = useState<
    Nullable<RatingScale[]>
  >([]);

  const findRatingScalesByEvalItemSn = useCallback(
    (
      evaluationItemInfos: EvaluationItemInfos[],
      targetEvalItemSn: number,
    ): Nullable<RatingScale[]> => {
      for (const evalItem of evaluationItemInfos) {
        if (evalItem.evaluationItemType === 'AVERAGE') {
          setIsAverage(true);
        }
        if (evalItem.childList.length > 0) {
          const result = findRatingScalesByEvalItemSn(
            evalItem.childList,
            targetEvalItemSn,
          );
          if (result) {
            return result;
          }
        } else if (evalItem.evaluationItemSn === targetEvalItemSn) {
          return evalItem.ratingScales;
        }
      }
      return null;
    },
    [],
  );
  useEffect(() => {
    const ratingsScaleMap = findRatingScalesByEvalItemSn(
      evaluationItemInfos,
      numberEvalItemSn,
    );
    if (ratingsScaleMap && ratingsScaleMap.length > 0) {
      setTargetRatingScales(ratingsScaleMap);
    }
  }, [evaluationItemInfos, findRatingScalesByEvalItemSn, numberEvalItemSn]);

  const [select, setSelect] = useState<Nullable<string>>('');
  useEffect(() => {
    if (targetRatingScales && Object.keys(targetRatingScales).length > 0) {
      const targetSelect =
        targetRatingScales.find(
          ({ ratingScaleSn: targetRatingScaleSn }) =>
            targetRatingScaleSn === ratingScaleSn,
        )?.name || (isAverage ? '-' : '선택');

      setSelect(targetSelect);
    }
  }, [targetRatingScales, ratingScaleSn, isAverage]);

  const { mutate: updateEvaluationMutate } = useUpdateEvaluationQuery();
  const handleClickEvaluation = (e: MouseEvent<HTMLButtonElement>) => {
    const targetValue = Number(e.currentTarget.value);
    updateEvaluationMutate({
      screeningSn,
      evalItemSn,
      screeningValuerSnList: [applicantScreeningValuerSn],
      ratingScaleSn: targetValue,
    });
    setSelect(e.currentTarget.textContent);
    handleClickToggleReadStatus(true);
    handleCloseDropdown();
  };

  return (
    <>
      <S.CellCenterPosition>
        {isAverage ? (
          <S.Span>{select}</S.Span>
        ) : (
          <Dropdown.Container ref={dropdownContainerRef}>
            <S.EvalButton onClick={handleToggleDropdown}>
              <S.Span>{select}</S.Span>
              <div>
                <Icon
                  name='icon_arrow_drop_down_18_filled'
                  style={{ display: 'inline' }}
                  color={theme.colors['gray-600']}
                />
              </div>
            </S.EvalButton>
            <Dropdown.ItemContainer
              isOpen={isOpen}
              position={DROPDOWN_POSITION.CENTER}
              isOverflowScrollable
              showScrollbarWrapper
              style={{
                width: '126px',
                height: '200px',
              }}
            >
              <Dropdown.ButtonItem onClick={handleClickEvaluation} value={0}>
                선택
              </Dropdown.ButtonItem>
              {targetRatingScales?.map(({ ratingScaleSn, name }) => {
                return (
                  <Dropdown.ButtonItem
                    key={ratingScaleSn}
                    onClick={handleClickEvaluation}
                    value={ratingScaleSn}
                  >
                    {name}
                  </Dropdown.ButtonItem>
                );
              })}
              <Dropdown.ButtonItem onClick={handleClickEvaluation} value={0}>
                미평가
              </Dropdown.ButtonItem>
            </Dropdown.ItemContainer>
          </Dropdown.Container>
        )}
      </S.CellCenterPosition>
    </>
  );
};
