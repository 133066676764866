import type { ButtonProps, ButtonVariant, PolymorphicProps } from '@ats/ui';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE, Text } from '@ats/ui';
import type { ScreeningResultCode } from '@constants/evaluation.constant';
import { SCREENING_RESULT_CODE } from '@constants/evaluation.constant';
import { useTheme } from '@emotion/react';
import { useFinalEvaluationMutation } from '@repositories/evaluation/interview/interview.query';
import type { CSSProperties, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './finalEvaluationSection.style';

interface Props {
  screeningValuerSn: string;
  screeningResultCode: ScreeningResultCode;
  style?: CSSProperties;
}
export const FinalEvaluationSection = ({
  screeningValuerSn,
  screeningResultCode,
  style,
}: Props) => {
  const theme = useTheme();
  const { screeningSn } = useParams();
  const { mutate: updateFinalEvaluation } = useFinalEvaluationMutation(
    screeningSn!,
  );

  const handleClickButton = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLButtonElement;
    if (screeningResultCode === target.value) return;

    updateFinalEvaluation({
      screeningSn: screeningSn!,
      finalEvalResultCode: target.value as ScreeningResultCode,
      screeningValuerSn,
    });
  };

  return (
    <S.Container style={style}>
      <Text variant={'b3'} color={theme.colors['gray-800']}>
        최종평가
      </Text>
      <S.ButtonGroup onClick={handleClickButton}>
        <EvaluationButton
          screeningResultCode={screeningResultCode}
          value={SCREENING_RESULT_CODE.NOT_EVALUATE}
          style={{ width: '67px' }}
        >
          미평가
        </EvaluationButton>
        <EvaluationButton
          screeningResultCode={screeningResultCode}
          value={SCREENING_RESULT_CODE.NOT_PASS}
          style={{ width: '67px' }}
        >
          불합격
        </EvaluationButton>
        <EvaluationButton
          screeningResultCode={screeningResultCode}
          value={SCREENING_RESULT_CODE.PASS}
          style={{ width: '67px' }}
        >
          합격
        </EvaluationButton>
        <EvaluationButton
          screeningResultCode={screeningResultCode}
          value={SCREENING_RESULT_CODE.SPARE_PASS}
        >
          예비합격
        </EvaluationButton>
      </S.ButtonGroup>
    </S.Container>
  );
};

type BaseButtonProps<U extends ButtonVariant> = PolymorphicProps<
  'button',
  ButtonProps<U>
>;
interface EvaluationButtonProps<U extends ButtonVariant>
  extends BaseButtonProps<U> {
  screeningResultCode: ScreeningResultCode;
}
const EvaluationButton = <U extends ButtonVariant>({
  screeningResultCode,
  ...props
}: EvaluationButtonProps<U>) => {
  const isSelected = props.value === screeningResultCode;
  const variant = isSelected
    ? BUTTON_VARIANTS.PRIMARY
    : BUTTON_VARIANTS.SECONDARY;
  const color = isSelected ? BUTTON_COLORS.THEMA : BUTTON_COLORS.DEFAULT;

  return (
    <Button
      variant={variant}
      color={color}
      type={'button'}
      size={SIZE.SM}
      style={{ ...props.style, whiteSpace: 'nowrap' }}
      {...props}
    />
  );
};
