import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  min-height: 800px;
  box-sizing: border-box;
  padding: 76px 0;
`;

export const Area = styled.div`
  width: 948px;
  margin: 0 auto 12px;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.heading['h2-bold']};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const InformationArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const Description = styled.span`
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-600']};
`;

export const Divider = styled.div`
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background: ${({ theme }) => theme.colors['gray-400']};
`;
