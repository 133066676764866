import type { SortColumn } from '@components/evaluation/interview/interview.constant';
import { SORT_COLUMN } from '@components/evaluation/interview/interview.constant';
import type { SortType } from '@constants/evaluation.constant';
import { SORT_TYPE } from '@constants/evaluation.constant';
import { usePrintForm } from '@hooks/common/usePrintForm';
import { useSearch } from '@hooks/common/useSearch';
import { useModal } from '@hooks/shared/useModal';
import { useScreeningRecruitSectorListQuery } from '@repositories/evaluation/common/common.query';
import {
  screeningNoticeSnAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useEvaluationTablePrint = () => {
  const { closeModal } = useModal();

  const screeningSn = useRecoilValue(screeningSnAtom);
  const noticeSn = useRecoilValue(screeningNoticeSnAtom);

  const [searchText, setSearchText] = useState('');
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
  const { data: sectorList } = useScreeningRecruitSectorListQuery({
    screeningSn: screeningSn!,
    recruitNoticeCodeSn: noticeSn,
  });
  const { searchResultList } = useSearch(searchText, sectorList, 'name');

  const handleChangeNoticeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const [checkSectorSnList, setCheckSectorSnList] = useState<number[]>([]);
  const handleChangeSector = (e: ChangeEvent<HTMLInputElement>) => {
    const targetSectorSn = Number(e.target.value);
    if (checkSectorSnList.includes(targetSectorSn)) {
      const resultCheckSnList = checkSectorSnList.filter((sn) => {
        return sn !== targetSectorSn;
      });
      setCheckSectorSnList(resultCheckSnList);
    } else {
      setCheckSectorSnList((prev) => [...prev, targetSectorSn]);
    }
  };

  const [sortColumn, setSortColumn] = useState(SORT_COLUMN.RESUME_DISPLAY_NO);
  const [summarySortType, setSummarySortType] = useState(SORT_TYPE.ASC);
  const { RenderPrintForm, handleSubmit } = usePrintForm({
    url: 'https://demo01-cms-recruiter-co-kr.kr-dv-midasitwebsol.com/cus/evaluation/documentScreening',
    screeningSn,
    sortColumn,
    sortType: summarySortType,
    recruitSectorSnList: checkSectorSnList.join(','),
  });

  const handleClickConfirm = () => {
    handleSubmit();
  };
  const handleClickCancel = () => {
    closeModal();
  };

  const handleChangeSortColumn = (e: ChangeEvent<HTMLInputElement>) => {
    setSortColumn(e.target.value as SortColumn);
  };
  const handleChangeSummarySortType = (e: ChangeEvent<HTMLInputElement>) => {
    setSummarySortType(e.target.value as SortType);
  };

  useEffect(() => {
    if (checkSectorSnList.length > 0) {
      setIsConfirmButtonDisabled(false);
      return;
    }
    setIsConfirmButtonDisabled(true);
  }, [checkSectorSnList]);

  return {
    sortColumn,
    searchText,
    searchResultList,
    checkSectorSnList,
    summarySortType,
    isConfirmButtonDisabled,
    handleClickConfirm,
    handleClickCancel,
    handleChangeNoticeSearch,
    handleChangeSector,
    handleChangeSortColumn,
    handleChangeSummarySortType,
    RenderPrintForm,
  };
};
