import type { Nullable } from '@ats/interfaces';
import type { ModalKey } from '@constants/modal.constant';
import { atom } from 'recoil';

export interface AlertAtomType {
  isOpen?: boolean;
  title?: string;
  content?: string | string[];
  cancelText?: string;
  confirmText?: string;
  callback?: () => void;
}

export const alertAtom = atom<AlertAtomType>({
  key: 'alertAtom',
  default: {
    isOpen: false,
    title: '',
    content: '',
    cancelText: '',
    confirmText: '',
  },
});

export const modalKeyAtom = atom<Nullable<ModalKey>>({
  key: 'modalKeyAtom',
  default: null,
});
