import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px
    ${({ theme }) =>
      `${theme.spacing.space300} ${theme.spacing.space300} ${theme.spacing.space300}`};
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.space50};
`;
