export enum PAGE_KEY {
  EVALUATION = 'EVALUATION',
  QNA = 'QNA',
  NOTICE = 'NOTICE',
}
export const PAGE_KEY_ARTICLE_MAP: Record<PageKey, number> = {
  [PAGE_KEY.NOTICE]: 3,
  [PAGE_KEY.QNA]: 4,
  [PAGE_KEY.EVALUATION]: -1,
} as const;
export type PageKey = PAGE_KEY;
