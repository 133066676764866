import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import FlatTag from '@components/common/FlatTag/FlatTag';
import { FLAT_TAG_SHAPE } from '@components/common/FlatTag/flatTag.constant';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip';
import { GridFilterModal } from '@components/evaluation/grid-screening/modal/GridFilterModal/GridFilterModal';
import { useTheme } from '@emotion/react';
import { useFilterCount } from '@hooks/evaluation/grid-screening/filter/useFilterCount';
import {
  isGridFilterModalOpenAtom,
  screeningNoticeSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import { type MouseEvent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from './left.style';

export const Filter = () => {
  const [isGridFilterModalOpen, setIsGridFilterModalOpen] = useRecoilState(
    isGridFilterModalOpenAtom,
  );

  const noticeSn = useRecoilValue(screeningNoticeSnAtom);

  const handleClickOpenFilterModal = (e: MouseEvent) => {
    e.stopPropagation();
    setIsGridFilterModalOpen(true);
  };

  const filterCount = useFilterCount();

  const theme = useTheme();

  return (
    <>
      <HeadlessTooltip
        isShow={!noticeSn}
        content={<S.Tooltip>공고를 선택해주세요</S.Tooltip>}
      >
        <Button
          size={SIZE.SM}
          variant={BUTTON_VARIANTS.SECONDARY}
          color={BUTTON_COLORS.DARK}
          leftIcon='icon_filter_18_line'
          rightSection={
            <>
              {!!filterCount && (
                <FlatTag
                  backgroundColor={theme.colors['primary-600']}
                  color={theme.colors['green-50']}
                  shape={FLAT_TAG_SHAPE.ROUND}
                >
                  {'0' + filterCount}
                </FlatTag>
              )}
            </>
          }
          onClick={handleClickOpenFilterModal}
          disabled={!noticeSn}
        >
          필터
        </Button>
      </HeadlessTooltip>
      {isGridFilterModalOpen && <GridFilterModal />}
    </>
  );
};
