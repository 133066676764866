import type { RadioProps } from '@ats/ui';
import {
  BUTTON_VARIANTS,
  Dropdown,
  Icon,
  InputItem,
  InputList,
  Modal,
  RadioGroup,
  SIZE,
  useDropdown,
} from '@ats/ui';
import { SORT_COLUMN } from '@components/evaluation/interview/interview.constant';
import { SORT_TYPE } from '@constants/evaluation.constant';
import { useEvaluationTablePrint } from '@hooks/evaluation/grid-screening/useEvaluationTablePrint';
import * as S from './printEvaluationTableModal.style';

const InputRadio = (props: RadioProps) => (
  <InputItem.Radio style={{ width: '100%' }} {...props} />
);

export const PrintEvaluationTableModal = () => {
  const { isOpen, dropdownContainerRef, handleToggleDropdown } = useDropdown();

  const {
    sortColumn,
    searchText,
    searchResultList,
    checkSectorSnList,
    summarySortType,
    isConfirmButtonDisabled,
    handleClickConfirm,
    handleClickCancel,
    handleChangeNoticeSearch,
    handleChangeSector,
    handleChangeSortColumn,
    handleChangeSummarySortType,
    RenderPrintForm,
  } = useEvaluationTablePrint();

  return (
    <>
      <Modal.Container showBackgroundDim style={{ width: '548px' }}>
        <Modal.Header onClickClose={handleClickCancel} title='평가표 출력' />
        <Modal.Separate />
        <Modal.Body>
          <S.RecruitSectorArea>
            <S.Title>채용분야</S.Title>
            <Dropdown.Container ref={dropdownContainerRef}>
              <Dropdown.Trigger
                variant={BUTTON_VARIANTS.SECONDARY}
                size={SIZE.SM}
                onClick={handleToggleDropdown}
              >
                선택해주세요
                <Icon name='icon_arrow_bottom_18_line' />
              </Dropdown.Trigger>
              <Dropdown.ItemContainer isOpen={isOpen}>
                <Dropdown.TextField
                  value={searchText}
                  onChange={handleChangeNoticeSearch}
                  placeholder='검색어를 입력해주세요.'
                />
                <Dropdown.Separate />
                {searchResultList.map((item) => {
                  return (
                    <Dropdown.CheckItem
                      label={item.name}
                      key={item.sn}
                      checked={checkSectorSnList.includes(item.sn)}
                      onChange={handleChangeSector}
                      value={item.sn}
                    />
                  );
                })}
              </Dropdown.ItemContainer>
            </Dropdown.Container>
          </S.RecruitSectorArea>

          <S.AlignArea>
            <S.Title>정렬</S.Title>
            <S.AlignContentsArea>
              <S.Left>
                <RadioGroup>
                  <InputList>
                    <InputRadio
                      checked={SORT_COLUMN.RESUME_DISPLAY_NO === sortColumn}
                      value={SORT_COLUMN.RESUME_DISPLAY_NO}
                      onChange={handleChangeSortColumn}
                      name='filterType'
                    >
                      수험번호
                    </InputRadio>
                    <InputRadio
                      checked={SORT_COLUMN.NAME === sortColumn}
                      value={SORT_COLUMN.NAME}
                      onChange={handleChangeSortColumn}
                      name='filterType'
                    >
                      지원자 성명
                    </InputRadio>
                    <InputRadio
                      checked={SORT_COLUMN.TOTAL_EVAL === sortColumn}
                      value={SORT_COLUMN.TOTAL_EVAL}
                      onChange={handleChangeSortColumn}
                      name='filterType'
                    >
                      종합평가
                    </InputRadio>
                  </InputList>
                </RadioGroup>
              </S.Left>

              <S.Right>
                <RadioGroup>
                  <InputList>
                    <InputRadio
                      checked={SORT_TYPE.ASC === summarySortType}
                      value={SORT_TYPE.ASC}
                      onChange={handleChangeSummarySortType}
                      name='filterOrder'
                    >
                      오름차순
                    </InputRadio>
                    <InputRadio
                      checked={SORT_TYPE.DESC === summarySortType}
                      value={SORT_TYPE.DESC}
                      onChange={handleChangeSummarySortType}
                      name='filterOrder'
                    >
                      내림차순
                    </InputRadio>
                  </InputList>
                </RadioGroup>
              </S.Right>
            </S.AlignContentsArea>
          </S.AlignArea>
        </Modal.Body>
        <Modal.Separate />
        <Modal.Footer
          confirmText='평가표 출력'
          onClickCancel={handleClickCancel}
          onClickConfirm={handleClickConfirm}
          isConfirmButtonDisabled={isConfirmButtonDisabled}
        />
      </Modal.Container>
      <RenderPrintForm />
    </>
  );
};
