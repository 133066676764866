import { Checkbox as _Checkbox, Radio as _Radio } from '@ats/ui';
import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: absolute;
  top: 96px;
  height: 308px;
  left: 520px;

  display: flex;
  flex-flow: column;

  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;
  ${({ theme }) => theme.shadow.shadow3};
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: ${({ theme }) => theme.radius.radius100};
`;
export const UpperSection = styled.div`
  display: flex;
  padding: 24px 24px 0px 24px;
  gap: 16px;

  flex: 1;
`;
export const LowerSection = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors['gray-400']};
`;
export const Divider = styled.div`
  flex-shrink: 0;
  width: 1px;
  background-color: ${({ theme }) => theme.colors['gray-300']};
`;
export const Column = styled.div`
  width: 160px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const InputList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 8px;
`;

export const inputStyle = ({ theme }: { theme: Theme }) => css`
  padding: 6px 12px 6px 8px;
  width: 160px;
  color: ${theme.colors['gray-800']};
  ${theme.body.b2}
`;

export const Checkbox = styled(_Checkbox)`
  ${inputStyle}
`;

export const Radio = styled(_Radio)`
  ${inputStyle}
`;

export const Separate = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-300']};
  margin: 4px 0;
`;
