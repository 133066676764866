import { finalEvalMapping } from '@constants/evaluation.constant';
import { type Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const CellCenterPosition = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const EvalButton = styled.button`
  height: 20px;
  padding: 1px 4px 1px 6px;

  display: flex;
  align-items: center;

  background: ${({ theme }) => theme.colors['gray-100']};
  color: ${({ theme }) => theme.colors['gray-600']};
  border-radius: 4px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors['gray-600']};
  }
`;

interface FinalEvalButtonPros {
  evalResultCode: string;
}

export const FinalEvalButton = styled.button<FinalEvalButtonPros>`
  height: 20px;
  padding: 1px 4px 1px 6px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  &:focus {
    border: 1px solid
      ${({ evalResultCode, theme }) => {
        switch (evalResultCode) {
          case finalEvalMapping.NOT_EVALUATE:
          case finalEvalMapping.NOT_PASS:
            return theme.colors['gray-600'];
          case finalEvalMapping.SPARE_PASS:
            return theme.colors['cyan-600'];
          case finalEvalMapping.PASS:
            return theme.colors['primary-600'];
          default:
            return;
        }
      }};
  }

  ${({ theme, evalResultCode }: { theme: Theme; evalResultCode: string }) => {
    switch (evalResultCode) {
      case finalEvalMapping.NOT_EVALUATE:
        return {
          background: 'white',
          textColor: theme.colors['gray-600'],
          border: `1px solid ${theme.colors['gray-300']}`,
        };
      case finalEvalMapping.NOT_PASS:
        return {
          background: theme.colors['gray-100'],
          color: theme.colors['gray-600'],
        };
      case finalEvalMapping.SPARE_PASS:
        return {
          background: theme.colors['cyan-100'],
          color: theme.colors['cyan-600'],
        };
      case finalEvalMapping.PASS:
        return {
          background: theme.colors['primary-100'],
          color: theme.colors['primary-600'],
        };
      default:
        return;
    }
  }}
`;

export const Span = styled.span`
  ${({ theme }) => theme.body['b3']}
`;
