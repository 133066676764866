import type { RefObject } from 'react';
import { useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export const useClickOutsideHandler = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  capturing = false,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref.current;
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener, capturing);
    document.addEventListener('touchstart', listener, capturing);

    return () => {
      document.removeEventListener('mousedown', listener, capturing);
      document.removeEventListener('touchstart', listener, capturing);
    };
  }, [ref, handler]);
};
