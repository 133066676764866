import { BaseRepository } from '@ats/repositories';
import axios from 'axios';
import type { DocumentGridRqDTO, DocumentGridRsDTO } from './document.dto';

export class DocumentRepository extends BaseRepository {
  public async getDocumentDataGrid(
    request: DocumentGridRqDTO,
  ): Promise<DocumentGridRsDTO> {
    const accessTokenValue = localStorage
      .getItem('accessToken')
      ?.replaceAll(/"/g, '');

    const { data: response } = await axios.post<DocumentGridRsDTO>(
      'https://api-mrs-cms.kr-dv-midasitwebsol.com/cms/v1/eval/data-grid',
      request,
      {
        headers: {
          Authorization: accessTokenValue,
        },
      },
    );

    return response;
  }
}
