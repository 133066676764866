export enum SORT_COLUMN {
  NAME = 'name',
  RESUME_DISPLAY_NO = 'resumeDisplayNo',
  INTERVIEW_GROUP = 'interviewGroup',
  TOTAL_EVAL = 'totalEval',
}
export type SortColumn = (typeof SORT_COLUMN)[keyof typeof SORT_COLUMN];

export enum KEYWORD_TYPE {
  NAME = 'name',
  RESUME_DISPLAY_NUMBER = 'resumeDisplayNo',
  ACADEMY_NAME = 'academyName',
  COMPANY_NAME = 'companyName',
}
export type KeywordType = (typeof KEYWORD_TYPE)[keyof typeof KEYWORD_TYPE];

export const KEYWORD_TYPE_MAP = {
  [KEYWORD_TYPE.NAME]: '이름',
  [KEYWORD_TYPE.RESUME_DISPLAY_NUMBER]: '수험번호',
  [KEYWORD_TYPE.ACADEMY_NAME]: '힉교명',
  [KEYWORD_TYPE.COMPANY_NAME]: '회사명',
} as const;
export const KEYWORD_TYPE_OPTIONS = [
  { label: KEYWORD_TYPE_MAP[KEYWORD_TYPE.NAME], value: KEYWORD_TYPE.NAME },
  {
    label: KEYWORD_TYPE_MAP[KEYWORD_TYPE.RESUME_DISPLAY_NUMBER],
    value: KEYWORD_TYPE.RESUME_DISPLAY_NUMBER,
  },
  {
    label: KEYWORD_TYPE_MAP[KEYWORD_TYPE.ACADEMY_NAME],
    value: KEYWORD_TYPE.ACADEMY_NAME,
  },
  {
    label: KEYWORD_TYPE_MAP[KEYWORD_TYPE.COMPANY_NAME],
    value: KEYWORD_TYPE.COMPANY_NAME,
  },
] as const;

export enum DATE_NAV_STATE {
  ALL = 'ALL',
  DATE = 'DATE',
}

export enum INTERVIEW_STATUS_CODE {
  STANDBY = 'STANDBY',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}
export type InterviewStatusCode = INTERVIEW_STATUS_CODE;

export enum INTERVIEW_DATE_TYPE {
  ALL = 'ALL',
  DATE = 'DATE',
  DATE_TIME_PLACE = 'DATE_TIME_PLACE',
}
export type InterviewDateType =
  (typeof INTERVIEW_DATE_TYPE)[keyof typeof INTERVIEW_DATE_TYPE];

export const EVALUATION_ITEM_TYPE = {
  OVERALL: 'OVERALL',
  AVERAGE: 'AVERAGE',
  ITEM: 'ITEM',
} as const;
export type EvaluationItemType =
  (typeof EVALUATION_ITEM_TYPE)[keyof typeof EVALUATION_ITEM_TYPE];
