import {
  BlackAndWhite,
  CommonColor,
  Cyan,
  Gray,
  Guide,
  PrimaryBlue,
  StatusColors,
} from '@ats/foundation';

export type ColorType = typeof InterviewEvaluatorColor.light;

const CommonColors = {
  ...StatusColors,
  ...CommonColor,
  ...Gray,
  ...Cyan,
  ...BlackAndWhite,
  ...Guide,
};

const InterviewEvaluatorColor = {
  light: {
    ...PrimaryBlue,
    ...CommonColors,
  },
  dark: {
    ...PrimaryBlue,
    ...CommonColors,
  },
};

export { InterviewEvaluatorColor };
