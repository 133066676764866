import styled from '@emotion/styled';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 64px;
  box-sizing: border-box;
  padding: 7px 24px;
  background: #fff;
  box-shadow:
    0px 1px 4px 0px rgba(0, 0, 0, 0.15),
    0px 0px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.img`
  width: 150px;
  height: 50px;
  cursor: pointer;
`;
