import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: absolute;
  top: 40px;
  width: 768px;

  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;
  ${({ theme }) => theme.shadow.shadow3};
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: ${({ theme }) => theme.radius.radius100};
`;
export const UpperSection = styled.div`
  display: flex;
  padding: 24px 24px 0px 24px;
  gap: 16px;
`;
export const LowerSection = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors['gray-400']};
`;
export const Divider = styled.div`
  flex-shrink: 0;
  width: 1px;
  background-color: ${({ theme }) => theme.colors['gray-300']};
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const InputList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const inputStyle = ({ theme }: { theme: Theme }) => css`
  padding: 6px 12px 6px 8px;
  width: 160px;
  color: ${theme.colors['gray-800']};
  ${theme.body.b2}
`;
