import { PAGES } from '@ats/constants';
import {
  Alert,
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Checkbox,
  SIZE,
  Spin,
  Text,
} from '@ats/ui';
import type { StepTypeCode } from '@constants/evaluation.constant.ts';
import {
  SCREENING_TYPE,
  STEP_TYPE_CODE,
} from '@constants/evaluation.constant.ts';
import { useTheme } from '@emotion/react';
import { useModal } from '@hooks/shared/useModal';
import {
  useValuerSecurityOathMutation,
  useValuerSecurityOathQuery,
} from '@repositories/evaluation/evaluation/evaluation.query';
import {
  stepSnForEvaluationOathAtom,
  stepTypeCodeForEvaluationOathAtom,
} from '@stores/atoms/evaluation/evaluation.atom.ts';
import { evaluationOathSelector } from '@stores/selectors/evaluation/evaluation.oath.selector';
import { Suspense, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as S from './evaluationOathModal.style';

const EvaluationOathModal = () => {
  const { closeModal } = useModal();

  return (
    <Alert.Container
      style={{ width: '600px', height: '630px', border: 'none' }}
      showBackgroundDim
    >
      <Alert.Header
        onClickClose={closeModal}
        title={'평가표(전형위원) 서약서'}
      />
      <Alert.Separate />
      <Suspense fallback={<Spin />}>
        <ModalBody />
      </Suspense>
    </Alert.Container>
  );
};

export default EvaluationOathModal;

const ModalBody = () => {
  const { closeModal } = useModal();
  const stepTypeCodeForEvaluationOath = useRecoilValue(
    stepTypeCodeForEvaluationOathAtom,
  );
  const stepSnForEvaluationOath = useRecoilValue(stepSnForEvaluationOathAtom);
  const valuerSecurityOathRequest = useRecoilValue(evaluationOathSelector);

  const { data } = useValuerSecurityOathQuery(valuerSecurityOathRequest);
  const { mutate: saveValuerSecurityOath } = useValuerSecurityOathMutation();
  const [isAgree, setIsAgree] = useState(false);
  const theme = useTheme();

  const handleClickGoToEvaluation = async () => {
    saveValuerSecurityOath(valuerSecurityOathRequest);
    navigateToEvaluationPage(
      stepSnForEvaluationOath,
      stepTypeCodeForEvaluationOath,
    );
  };

  const navigateToEvaluationPage = (
    stepSn: string,
    stepTypeCode: StepTypeCode,
  ) => {
    switch (stepTypeCode) {
      case STEP_TYPE_CODE.DOCUMENT:
        window.location.href = PAGES.INTERVIEWER_EVALUATOR.EVALUATION.GRID(
          SCREENING_TYPE.DOCUMENT,
          stepSn,
        );
        break;

      case STEP_TYPE_CODE.EXAM:
        window.location.href = PAGES.INTERVIEWER_EVALUATOR.EVALUATION.GRID(
          SCREENING_TYPE.EXAM,
          stepSn,
        );
        break;

      case STEP_TYPE_CODE.INTERVIEW:
        window.location.href =
          PAGES.INTERVIEWER_EVALUATOR.EVALUATION.INTERVIEW(stepSn);
        break;

      case STEP_TYPE_CODE.ON_LINE_PT:
        window.location.href = PAGES.INTERVIEWER_EVALUATOR.EVALUATION.GRID(
          SCREENING_TYPE.PT_INTERVIEW,
          stepSn,
        );
        break;
    }
  };

  return (
    <>
      <Alert.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <S.OathContainer dangerouslySetInnerHTML={{ __html: data.contents }} />
        <S.AgreementBox>
          <Checkbox checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
          <Text variant={'b2'} color={theme.colors['gray-800']}>
            본인은 상기 내용을 모두 숙지하였으며, 내용에 동의합니다.
          </Text>
        </S.AgreementBox>
      </Alert.Body>
      <S.ButtonWrapper>
        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          color={BUTTON_COLORS.DEFAULT}
          size={SIZE.SM}
          onClick={closeModal}
        >
          취소
        </Button>
        <Button
          onClick={handleClickGoToEvaluation}
          color={BUTTON_COLORS.THEMA}
          size={SIZE.SM}
          disabled={!isAgree}
        >
          평가하러가기
        </Button>
      </S.ButtonWrapper>
    </>
  );
};
