import { useDropdown } from '@ats/ui';
import {
  INTEREST_OR_BLACK,
  MARKING_ACTION,
} from '@constants/evaluation.constant';
import { documentKeys } from '@constants/key.query';
import { MODAL_KEY } from '@constants/modal.constant';
import { useTheme } from '@emotion/react';
import { queryClient } from '@helpers/client';
import { useAlert } from '@hooks/shared/useAlert';
import { useModal } from '@hooks/shared/useModal';
import { useDeleteApplicantMarkingQuery } from '@repositories/evaluation/common/common.query';
import {
  currentPageResumeSnListAtom,
  currentPageScreeningValuerSnListAtom,
  interestOrBlackAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useInterestAndBlack = () => {
  const {
    isOpen,
    dropdownContainerRef,
    handleCloseDropdown,
    handleToggleDropdown,
  } = useDropdown();

  const theme = useTheme();
  const { openAlert, closeAlert } = useAlert();
  const { openModal, closeModal } = useModal();

  const currentPageScreeningValuerSnList = useRecoilValue(
    currentPageScreeningValuerSnListAtom,
  );
  const currentPageResumeSnList = useRecoilValue(currentPageResumeSnListAtom);
  const setInterestOrBlack = useSetRecoilState(interestOrBlackAtom);

  const { mutate: deleteApplicantMarkingMutate } =
    useDeleteApplicantMarkingQuery({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [documentKeys.evaluateDocumentGrid],
        });
      },
    });

  const getInterestOrBlackText = (interestOrBlack: INTEREST_OR_BLACK) => {
    switch (interestOrBlack) {
      case INTEREST_OR_BLACK.INTEREST:
        return '관심';
      case INTEREST_OR_BLACK.BLACK:
        return '블랙';
      default:
        return '';
    }
  };

  const registerApplication = (interestOrBlack: INTEREST_OR_BLACK) => {
    setInterestOrBlack(interestOrBlack);
    openModal(MODAL_KEY.INTEREST_BLACK);
  };
  const deleteApplication = (interestOrBlack: INTEREST_OR_BLACK) => {
    setInterestOrBlack(interestOrBlack);
    deleteApplicantMarkingMutate(
      {
        resumeSnList: currentPageResumeSnList.join(','),
        markingCode: interestOrBlack,
      },
      {
        onError: (e) =>
          openAlert({
            title: `${getInterestOrBlackText(interestOrBlack)}지원자 해제`,
            content: e.response?.data as string,
          }),
      },
    );
  };

  const handleAction = (
    action: MARKING_ACTION,
    interestOrBlack: INTEREST_OR_BLACK,
  ) => {
    if (action === MARKING_ACTION.REGISTER) {
      registerApplication(interestOrBlack);
    }
    if (action === MARKING_ACTION.DELETE) {
      openAlert({
        title: `${getInterestOrBlackText(interestOrBlack)}지원자 해제`,
        content: `선택한 지원자의 ${getInterestOrBlackText(
          interestOrBlack,
        )}지원자 설정을 해제하시겠습니까?`,
        callback: () => deleteApplication(interestOrBlack),
      });
    }
  };

  return {
    dropdownContainerRef,
    isOpen,
    theme,
    currentPageScreeningValuerSnList,
    handleToggleDropdown,
    handleAction,
  };
};
