import styled from '@emotion/styled';

export const Container = styled.form`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  padding: 32px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radius.radius200};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 15px 30px 4px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h3`
  ${({ theme }) => theme.title['t2-bold']};
`;

export const Description = styled.span`
  display: inline-block;
  margin: 24px 0 16px;
  color: ${({ theme }) => theme.colors['gray-600']};
  ${({ theme }) => theme.body.b2};
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  float: right;
  margin-top: 24px;
`;

export const ErrorMessage = styled.span`
  display: inline-block;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors['red-600']};
`;
