import { Button, Icon, SIZE } from '@ats/ui';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip';
import { MODAL_KEY } from '@constants/modal.constant';
import { useTheme } from '@emotion/react';
import { useModal } from '@hooks/shared/useModal';
import { screeningNoticeSnAtom } from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue } from 'recoil';
import * as S from '../right/right.style';

export const PrintEvaluationTable = () => {
  const { openModal } = useModal();
  const theme = useTheme();

  const handleClick = () => {
    openModal(MODAL_KEY.PRINT_SCORE_GRID);
  };

  const noticeSn = useRecoilValue(screeningNoticeSnAtom);

  return (
    <HeadlessTooltip
      isShow={!noticeSn}
      content={<S.Tooltip>공고를 선택해주세요</S.Tooltip>}
      style={{ right: 0 }}
    >
      <Button
        size={SIZE.SM}
        onClick={handleClick}
        style={{
          padding: '4px',
          background: !noticeSn ? 'none' : '',
          color: !noticeSn ? theme.colors['gray-600'] : '',
        }}
        disabled={!noticeSn}
      >
        <Icon
          name='icon_task_18_line'
          size={24}
          style={{ fill: theme.colors['gray-600'] }}
        />
      </Button>
    </HeadlessTooltip>
  );
};
