export const atomicKeys = {
  accessToken: 'accessToken',
  keepLogin: 'keepLogin',
  sessionEndTime: 'sessionEndTime',
  tenantId: 'tenantId',
};

export const selectorKeys = {};

export const screeningListKeys = {
  valuerSecurityOath: 'valuerSecurityOath',
};

export const gridScreeningKeys = {
  screeningInfo: 'screeningInfo',
  screeningNoticeList: 'screeningNoticeList',
  screeningRecruitSectorList: 'screeningRecruitSectorList',
};

export const markingKeys = {
  interest: 'interest',
  black: 'black',
};

export const documentKeys = {
  evaluateDocumentGrid: 'evaluateDocumentGrid',
};
