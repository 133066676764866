import styled from '@emotion/styled';
import { headerHeight } from '../main.style';

export const Container = styled.aside`
  position: absolute;
  right: 0;
  top: ${headerHeight}px;

  height: calc(100vh - ${headerHeight}px);
  width: 380px;
  background-color: white;

  border-left: 1px solid ${({ theme }) => theme.colors['gray-300']};
  ${({ theme }) => theme.shadow.shadow6};

  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const Header = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 32px 24px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['gray-300']};
`;
