import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';

interface TimeItemProps {
  isActive?: boolean;
  isInProgress?: boolean;
}
const activeItemStyle = ({ theme }: { theme: Theme }) => css`
  border: 1px solid ${theme.colors['primary-500']};
  background-color: ${theme.colors['primary-50']};
  color: ${theme.colors['primary-600']};
`;
const itemStyle = ({ theme }: { theme: Theme }) => css`
  &:hover {
    background-color: ${theme.colors['primary-50']};
  }
`;
const inProgressStyle = ({ theme }: { theme: Theme }) => css`
  border: 1px solid ${theme.colors['primary-500']};
`;

export const Container = styled.li<TimeItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  width: 274px;
  padding: 14px 16px;
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: ${({ theme }) => theme.radius.radius75};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ isActive }) => (isActive ? activeItemStyle : itemStyle)}
  ${({ isInProgress }) => isInProgress && inProgressStyle}
`;

export const LeftSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors['gray-600']};
`;
