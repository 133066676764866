import type { PageKey } from '@constants/pageKey.constant';
import {
  currentPageAtomFamily,
  pageSizeAtomFamily,
} from '@stores/atoms/page.atomFamily';
import {
  searchKeywordAtomFamily,
  searchKeywordTypeAtomFamily,
} from '@stores/atoms/searchKeyword.atomFamily';
import { useRecoilCallback } from 'recoil';

export const useInitArticleSelector = (pageKey: PageKey) => {
  const resetArticleSelector = useRecoilCallback(({ reset }) => () => {
    reset(searchKeywordAtomFamily(pageKey));
    reset(searchKeywordTypeAtomFamily(pageKey));
    reset(currentPageAtomFamily(pageKey));
    reset(pageSizeAtomFamily(pageKey));
  });

  return { resetArticleSelector };
};
