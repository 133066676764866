import { PAGE_KEY } from '@constants/pageKey.constant';
import { useInitArticleSelector } from '@hooks/useInitArticleSelectorFamily';
import type { ArticleItem } from '@repositories/article/article.dto';
import { useArticleListQuery } from '@repositories/article/article.query';
import { articleSelectorFamily } from '@stores/selectors/article.selectorFamily';
import { useEffect, type FC } from 'react';
import { useRecoilValue } from 'recoil';
import Card from './Card';
import Empty from './Empty';
import Footer from './Footer';
import NotFound from './NotFound';
import Search from './Search';
import * as S from './style';

const NoticePage: FC = () => {
  const { resetArticleSelector } = useInitArticleSelector(PAGE_KEY.NOTICE);
  const articleRequestDTO = useRecoilValue(
    articleSelectorFamily(PAGE_KEY.NOTICE),
  );
  const {
    data: { articleList },
  } = useArticleListQuery(articleRequestDTO);

  useEffect(() => {
    resetArticleSelector();
  }, []);

  return (
    <S.Container>
      <S.TitleArea>
        <S.Title>공지사항</S.Title>
      </S.TitleArea>
      <S.Header>
        <Search />
      </S.Header>
      <S.Content>
        <ContentArea
          articleList={articleList}
          keyword={articleRequestDTO.searchText}
        />
      </S.Content>
      <Footer />
    </S.Container>
  );
};

interface ContentAreaProps {
  articleList: ArticleItem[];
  keyword: string;
}
const ContentArea = ({ articleList, keyword }: ContentAreaProps) => {
  if (articleList)
    return (
      <>
        {articleList.map((el) => (
          <Card key={el.articleSn} {...el} />
        ))}
      </>
    );

  if (keyword) return <NotFound />;
  return <Empty />;
};

export default NoticePage;
