import type { PrintFormProps } from '@components/common/PrintForm/PrintForm';
import { PrintForm } from '@components/common/PrintForm/PrintForm';
import { createElement, useRef } from 'react';
export const usePrintForm = ({ ...props }: PrintFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { url, ...rest } = props;

  const handleSubmit = () => {
    const POPUP_WIDTH = 1465;
    const POPUP_HEIGHT = 800;

    const target = window.open(
      url,
      'printPopup',
      `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},left=${
        screen.width / 2 - POPUP_WIDTH / 2
      },top=${screen.height / 2 - POPUP_HEIGHT / 2}`,
    );

    target?.focus();
    formRef.current?.submit();
  };

  const RenderPrintForm = (): React.ReactNode => {
    return createElement(PrintForm, { ref: formRef, url: url, ...rest });
  };

  return {
    RenderPrintForm,
    handleSubmit,
  };
};
