import { atom, atomFamily } from 'recoil';

export const screeningValuerSnAtom = atom({
  key: 'screeningValuerSnAtom',
  default: '',
});
export const recruitNoticeSnAtom = atom({
  key: 'recruitNoticeSnAtom',
  default: '',
});
export const recruitNoticeCodeSnAtom = atom({
  key: 'recruitNoticeCodeSnAtom',
  default: '',
});
export const recruitSectorSnAtom = atom({
  key: 'recruitSectorSnAtom',
  default: '',
});

export const evaluationItemValueAtom = atomFamily({
  key: 'evaluationItemValueAtom',
  default: '',
});
