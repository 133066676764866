import { SORT_TYPE } from '@constants/evaluation.constant.ts';
import { useTheme } from '@emotion/react';
import type { Header } from '@repositories/evaluation/document/document.dto';
import {
  freezePanesIndexAtom,
  gridFreezePanesCoordinateSelector,
  gridSortingColumnAndTypeAtom,
  gridSumWidthSelector,
  gridWidthAtom,
  headersWithFreezeYnAtom,
  screeningNoticeSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const useGridHeadCol = (header: Header, colIndex: number) => {
  const theme = useTheme();

  const [freezePanesIndex, setFreezePanesIndex] =
    useRecoilState(freezePanesIndexAtom);

  const gridFreezePanesCoordinate = useRecoilValue(
    gridFreezePanesCoordinateSelector,
  );
  const setHeadersWithFreezePanesIndex = useSetRecoilState(
    headersWithFreezeYnAtom,
  );
  const handleClickFreezePanesIndex = () => {
    setHeadersWithFreezePanesIndex((prev) =>
      prev.map((item) => ({
        code: item.code,
        freezeYn: header.code === item.code,
      })),
    );
  };

  const [hovering, setHovering] = useState(false);
  const handleHoverIn = () => {
    setHovering(true);
  };
  const handleHoverOut = () => {
    setHovering(false);
  };

  const [ref, setRef] = useState<HTMLElement>();
  const updateRef = (newRef: HTMLElement) => setRef(newRef as HTMLElement);

  const setGridWidthStore = useSetRecoilState(gridWidthAtom);
  const screeningNoticeSn = useRecoilValue(screeningNoticeSnAtom);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setGridWidthStore((prev) => ({
          ...prev,
          [colIndex]: entry.contentRect.width,
        }));
      }
    });

    if (ref) {
      resizeObserver.observe(ref);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [screeningNoticeSn, colIndex, ref, setGridWidthStore]);

  const widthStore = useRecoilValue(gridSumWidthSelector);
  const isFreeze = colIndex <= freezePanesIndex;
  const freezeStyle: CSSProperties = isFreeze
    ? {
        position: 'sticky',
        left: `${widthStore[colIndex - 1]}px`,
        // zIndex: 1,
      }
    : {};

  const [gridSortingColumnAndType, setGridSortingColumnAndType] =
    useRecoilState(gridSortingColumnAndTypeAtom);
  const handleClickSorting = () => {
    if (!header.code) return;
    setGridSortingColumnAndType((prev) => ({
      [header.code]:
        prev[header.code] === SORT_TYPE.DESC ? SORT_TYPE.ASC : SORT_TYPE.DESC,
    }));
  };

  return {
    screeningNoticeSn,
    updateRef,
    theme,
    freezePanesIndex,
    handleClickFreezePanesIndex,
    hovering,
    handleHoverIn,
    handleHoverOut,
    freezeStyle,
    gridSortingColumnAndType,
    handleClickSorting,
  };
};
