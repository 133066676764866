import { useCustomerInfoQuery } from '@repositories/customer/customer.query.ts';
import { useNoticeCommentsQuery } from '@repositories/notice/notice.query.ts';
import type { FC } from 'react';
import Comment, { COMMENT_TYPE } from './Comment';
import { Container, Count, Total, TotalCountArea } from './style';

export interface CommentsProps {
  articleSn: string;
}

const Comments: FC<CommentsProps> = ({ articleSn }) => {
  const { data, isSuccess: commentSuccess } = useNoticeCommentsQuery({
    articleSn,
  });
  const { data: customerInfo, isSuccess: infoSuccess } = useCustomerInfoQuery();

  if (!commentSuccess || !infoSuccess) return null;

  const {
    userInfo: { name: creatorName, memberSn },
  } = customerInfo;

  return (
    <Container>
      <TotalCountArea>
        <Total>댓글</Total>&nbsp;<Count>{data.length}</Count>
      </TotalCountArea>
      {[
        ...data,
        {
          articleSn,
          commentSn: '',
          creatorName,
          content: '',
          type: COMMENT_TYPE.WRITE,
        },
      ].map((props, idx) => (
        <Comment
          key={`comment-${props.commentSn}-${idx}`}
          memberSn={memberSn}
          {...props}
        />
      ))}
    </Container>
  );
};

export default Comments;
