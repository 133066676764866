import { BaseRepository } from '@ats/repositories';
import { HTTP_HEADER_FORM } from '@constants/repository.constant';
import type {
  ScreeningInterviewResumeListRsDTO,
  ScreeningInterviewScheduleListRsDTO,
} from './interview.dto';

class InterviewEvaluationRepository extends BaseRepository {
  public async getScreeningInfo(request: FormData) {
    const { data } = await super.post<ScreeningInterviewScheduleListRsDTO>(
      '/mrs2/eval/getScreeningInfo',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async getScreeningInterviewScheduleList(request: FormData) {
    const { data } = await super.post<ScreeningInterviewScheduleListRsDTO>(
      '/mrs2/eval/getScreeningInterviewScheduleList',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async getScreeningInterviewResumeList(request: FormData) {
    const { data } = await super.post<ScreeningInterviewResumeListRsDTO>(
      '/mrs2/eval/getScreeningInterviewResumeList',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async getResumeEvaluationItems(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/getResumeEvaluationItems',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async updateEvaluation(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/updateEvaluation',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async updateFinalEvaluation(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/updateAndGetFinalEvaluation',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async updateAllEvaluation(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/updateAllEvaluation',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }

  public async completeEvaluation(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/completeEvaluation',
      request,
      HTTP_HEADER_FORM,
    );

    return data;
  }
}

export const interviewEvaluationRepository = new InterviewEvaluationRepository(
  '',
);
