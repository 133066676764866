import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 40px;
  border-radius: ${({ theme }) => theme.radius.radius150};
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  cursor: pointer;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors['gray-800']};
    border-radius: ${({ theme }) => theme.radius.radius150};
    background: ${({ theme }) => theme.colors['gray-80']};
  }
`;

export const IconArea = styled.div``;

export const ContentArea = styled.div`
  margin-left: 16px;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.title['t2-bold']};
`;

export const DescriptionArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const Description = styled.span`
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-600']};
`;

export const Divider = styled.div`
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background: ${({ theme }) => theme.colors['gray-400']};
`;

export const ReplyCount = styled.span`
  display: inline-block;
  height: 20px;
  box-sizing: border-box;
  padding: 0 6px;
  margin-left: 8px;
  background: ${({ theme }) => theme.colors['blue-100']};
  color: ${({ theme }) => theme.colors['blue-600']};
  border-radius: ${({ theme }) => theme.radius.radius50};
  ${({ theme }) => theme.body.b3}
`;
