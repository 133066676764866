import styled from '@emotion/styled';

export const Container = styled.div``;

export const WriterArea = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18px;
`;

export const Writer = styled.span`
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const CreatedAt = styled.span`
  margin-left: 8px;
  vertical-align: text-bottom;
  ${({ theme }) => theme.body.b3};
  color: ${({ theme }) => theme.colors['gray-600']};
`;

export const Title = styled.h4`
  margin: 8px 0;
  ${({ theme }) => theme.body['b1-bold']};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const Content = styled.p`
  margin-top: 8px;
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-800']};
`;

export const Editor = styled.div``;

export const TextArea = styled.textarea`
  width: 100%;
  height: 180px;
  box-sizing: border-box;
  padding: 14px 16px;
  background: ${({ theme }) => theme.colors['gray-80']};
  border-radius: 6px;
  outline: none;

  ::placeholder {
    ${({ theme }) => theme.body.b2};
    color: ${({ theme }) => theme.colors['gray-600']};
  }

  :active,
  :focus {
    border: 2px solid ${({ theme }) => theme.colors['gray-800']};
    background: white;
  }
`;

export const EditorBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;
