import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor as TUI } from '@toast-ui/react-editor';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { toolbarItems } from './config';
import { useTextEditor } from './hooks';
import { Container } from './style';

interface Props {
  contents: string;
  setContents: Dispatch<SetStateAction<string>>;
  height?: number;
}
const Editor = (props: Props) => {
  const { contents, setContents, height = 300 } = props;
  const { editorRef, addImageBlobHook, onChange } = useTextEditor();

  useEffect(() => {
    if (editorRef && editorRef.current) {
      editorRef.current.getInstance().setHTML(contents);
    }
  }, []);

  return (
    <Container>
      <TUI
        initialValue={contents}
        toolbarItems={toolbarItems}
        usageStatistics={false}
        previewStyle='vertical'
        initialEditType='wysiwyg' // markdown, wysiwyg
        ref={editorRef}
        hooks={{
          addImageBlobHook,
        }}
        height={`${height}px`}
        onChange={() => onChange(setContents)}
        theme=''
        hideModeSwitch={true}
      />
    </Container>
  );
};

export default Editor;
