import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  box-sizing: border-box;
  width: 100vw;
  height: 53px;
  border-top: 1px solid ${({ theme }) => theme.colors['gray-300']};
  background-color: ${({ theme }) => theme.colors.white};
`;
