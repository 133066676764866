import {
  INTEREST_OR_BLACK,
  SCREENING_RESULT_CODE,
} from '@constants/evaluation.constant';
import type { ScreeningInterviewResumeListRqDTO } from '@repositories/evaluation/interview/interview.dto';
import { interviewKeywordAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import {
  interviewFilterBlackAtom,
  interviewFilterInterestAtom,
  interviewFilterNotEvaluateAtom,
  interviewFilterNotPassAtom,
  interviewFilterPassAtom,
  interviewFilterSparePassAtom,
  sortColumnAtom,
  summarySortTypeAtom,
} from '@stores/atoms/evaluation/interview/interview.filter.atom';
import { selector } from 'recoil';

type InterviewFilter = Pick<
  ScreeningInterviewResumeListRqDTO,
  'checkMyMarkingList' | 'screeningResultCodeList' | 'sortColumn' | 'sortType'
>;

/** 검색필터 > 대상지원자 */
export const interviewCheckMyMarkingListSelector = selector({
  key: 'interviewCheckMyMarkingListSelector',
  get: ({ get }) => {
    const interest = get(interviewFilterInterestAtom);
    const black = get(interviewFilterBlackAtom);

    const list = [];
    if (interest) list.push(INTEREST_OR_BLACK.INTEREST);
    if (black) list.push(INTEREST_OR_BLACK.BLACK);

    return list.join(',');
  },
});

/** 검색필터 > 평가결과 */
export const screeningResultCodeListSelector = selector({
  key: 'screeningResultCodeListSelector',
  get: ({ get }) => {
    const notEvaluate = get(interviewFilterNotEvaluateAtom);
    const notPass = get(interviewFilterNotPassAtom);
    const pass = get(interviewFilterPassAtom);
    const sparePass = get(interviewFilterSparePassAtom);

    const list = [];
    if (notEvaluate) list.push(SCREENING_RESULT_CODE.NOT_EVALUATE);
    if (notPass) list.push(SCREENING_RESULT_CODE.NOT_PASS);
    if (pass) list.push(SCREENING_RESULT_CODE.PASS);
    if (sparePass) list.push(SCREENING_RESULT_CODE.SPARE_PASS);

    return list.join(',');
  },
});

export const interviewFilterSelector = selector<InterviewFilter>({
  key: 'interviewFilterSelector',
  get: ({ get }) => {
    const checkMyMarkingList = get(interviewCheckMyMarkingListSelector);
    const screeningResultCodeList = get(screeningResultCodeListSelector);
    const sortColumn = get(sortColumnAtom);
    const sortType = get(summarySortTypeAtom);

    return {
      checkMyMarkingList,
      screeningResultCodeList,
      sortColumn,
      sortType,
    };
  },
});

export const isFilterAppliedSelector = selector({
  key: 'isFilterAppliedSelector',
  get: ({ get }) => {
    const checkMyMarkingList = get(interviewCheckMyMarkingListSelector);
    const screeningResultCodeList = get(screeningResultCodeListSelector);
    const keyword = get(interviewKeywordAtom);

    const isFilterApplied =
      keyword || checkMyMarkingList || screeningResultCodeList;
    return !!isFilterApplied;
  },
});
