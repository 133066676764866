import { PAGES } from '@ats/constants';
import { BUTTON_COLORS, Button } from '@ats/ui';
import Attachment from '@components/notice/Attachment';
import Comments from '@components/notice/Comments';
import { ARTICLE_CATEGORY_TYPE } from '@constants/article.constants';
import { useArticleQuery } from '@repositories/article/article.query';
import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './style';

const NoticeReadPage: FC = () => {
  const { sn } = useParams();
  const naviate = useNavigate();

  if (!sn) throw Error('잘못된 접근입니다');

  const {
    data: { articleInfo, articleAttachFiles },
  } = useArticleQuery({
    categorySn: ARTICLE_CATEGORY_TYPE.EVALUATOR_NOTICE,
    articleSn: sn,
  });

  const { title, creatorName, lastModifyDate, contents, isAllowComment } =
    articleInfo;

  return (
    <S.Container>
      <S.Area>
        <S.Title>{title}</S.Title>
      </S.Area>
      <S.Area>
        <S.InformationArea>
          <S.Description>{creatorName}</S.Description>
          <S.Divider />
          <S.Description>{lastModifyDate}</S.Description>
        </S.InformationArea>
      </S.Area>
      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: contents }} />

        <S.AttachmentsWrapper>
          {articleAttachFiles.map((el) => (
            <Attachment key={el.fileUid} {...el} />
          ))}
        </S.AttachmentsWrapper>
      </S.Content>
      {isAllowComment && (
        <S.Area>
          <Comments articleSn={sn} />
        </S.Area>
      )}
      <S.Area style={{ display: 'flex' }}>
        <Button
          color={BUTTON_COLORS.THEMA}
          onClick={() => naviate(PAGES.INTERVIEWER_EVALUATOR.NOTICE.HOME)}
        >
          목록으로
        </Button>
      </S.Area>
    </S.Container>
  );
};

export default NoticeReadPage;
