import { InputItem, InputList, Text } from '@ats/ui';
import { useInterestBlack } from '@hooks/evaluation/grid-screening/filter/useInterestBlackFilter';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import * as S from '../gridFilterModal.style';

export const InterestBlackFilter = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    const { interestBlackAllProps, interestProps, blackProps } =
      useInterestBlack(ref);
    return (
      <>
        <S.Divider />

        <S.Column>
          <Text variant={'b2'} bold>
            대상지원자
          </Text>
          <InputList>
            <InputItem.Checkbox {...interestBlackAllProps}>
              전체
            </InputItem.Checkbox>
            <InputItem.Checkbox {...interestProps}>
              관심지원자
            </InputItem.Checkbox>
            <InputItem.Checkbox {...blackProps}>블랙지원자</InputItem.Checkbox>
          </InputList>
        </S.Column>
      </>
    );
  },
);
