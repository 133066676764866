import {
  gridFilterBlackAtom,
  gridFilterEvaluationItemsAtom,
  gridFilterFinalEvaluateListAtom,
  gridFilterInterestAtom,
  gridFilterReadAtom,
  gridFilterTotalEvaluationAtom,
  gridFilterUnReadAtom,
  gridFilterUnUploadFileAtom,
  gridFilterUploadFileAtom,
} from '@stores/atoms/evaluation/document.filter';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

export const useFilterCount = () => {
  const isRead = useRecoilValue(gridFilterReadAtom);
  const isUnRead = useRecoilValue(gridFilterUnReadAtom);

  const isInterest = useRecoilValue(gridFilterInterestAtom);
  const isBlack = useRecoilValue(gridFilterBlackAtom);

  /** 평가항목 */
  const gridFilterEvaluationItems = useRecoilValue(
    gridFilterEvaluationItemsAtom,
  );

  /** 종합평가 */
  const gridTotalEvaluation = useRecoilValue(gridFilterTotalEvaluationAtom);

  /** 최종평가 */
  const gridFilterFinalEvaluateList = useRecoilValue(
    gridFilterFinalEvaluateListAtom,
  );

  /** 관리자 업로드 파일 */
  const uploadFile = useRecoilValue(gridFilterUploadFileAtom);
  const unUploadFile = useRecoilValue(gridFilterUnUploadFileAtom);

  const filterCount = useMemo(() => {
    let count = 0;

    const checkReadFilter = isRead || isUnRead;
    const checkInterestBlackFilter = isInterest || isBlack;
    const checkUploadFilter = uploadFile || unUploadFile;

    if (checkReadFilter) count++;
    if (checkInterestBlackFilter) count++;
    if (gridFilterEvaluationItems.length > 0) count++;
    if (gridTotalEvaluation.ratingScaleSns.length > 0) count++;
    if (gridFilterFinalEvaluateList.length > 0) count++;
    if (checkUploadFilter) count++;

    return count;
  }, [
    isRead,
    isUnRead,
    isInterest,
    isBlack,
    gridFilterEvaluationItems.length,
    gridFilterFinalEvaluateList.length,
    gridTotalEvaluation.ratingScaleSns.length,
    unUploadFile,
    uploadFile,
  ]);

  console.log();

  return filterCount;
};
