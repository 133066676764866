import styled from '@emotion/styled';

export const TabWrapper = styled.div`
  width: 602px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors['gray-80']};
  display: flex;
  gap: 4px;
  border-radius: ${({ theme }) => theme.radius.radius75};

  margin-top: 16px;
  margin-bottom: 24px;
`;
