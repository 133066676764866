import { useDropdown } from '@ats/ui';
import {
  screeningNoticeSnAtom,
  selectedSectorAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useRecoilState, useRecoilValue } from 'recoil';

export const useSector = () => {
  const [selectSectorText, setSelectSectorText] =
    useRecoilState(selectedSectorAtom);

  const noticeSn = useRecoilValue(screeningNoticeSnAtom);

  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  const handleSetSectorText = (
    text: string,
    isCloseDropdown: boolean = false,
  ) => {
    setSelectSectorText(text);

    if (isCloseDropdown) {
      handleCloseDropdown();
    }
  };

  return {
    selectSectorText,
    isOpen,
    dropdownContainerRef,
    noticeSn,
    handleSetSectorText,
    handleToggleDropdown,
  };
};
