import { DROPDOWN_COLOR_VARIANT, Dropdown, Icon } from '@ats/ui';
import {
  INTEREST_OR_BLACK,
  MARKING_ACTION,
} from '@constants/evaluation.constant';
import { useInterestAndBlack } from '@hooks/evaluation/grid-screening/useInterestAndBlack';
import * as S from './right.style';

export const InterestAndBlack = () => {
  const {
    dropdownContainerRef,
    isOpen,
    theme,
    currentPageScreeningValuerSnList,
    handleToggleDropdown,
    handleAction,
  } = useInterestAndBlack();

  return (
    <Dropdown.Container ref={dropdownContainerRef}>
      <Dropdown.Trigger
        onClick={handleToggleDropdown}
        style={{
          padding: '4px',
          outline: isOpen ? `2px solid ${theme.colors['gray-700']}` : '',
        }}
      >
        <Icon
          name='icon_bookmark_18_line'
          size={24}
          style={{
            fill: isOpen ? theme.colors['gray-400'] : theme.colors['gray-600'],
          }}
        />
      </Dropdown.Trigger>
      <Dropdown.ItemContainer
        variant={DROPDOWN_COLOR_VARIANT.DARK}
        isOpen={isOpen}
        isOverflowScrollable={false}
        style={{ width: '158px', height: '156px', right: '0%' }}
      >
        {currentPageScreeningValuerSnList.length > 0 ? (
          <>
            <Dropdown.ButtonItem
              onClick={() =>
                handleAction(
                  MARKING_ACTION.REGISTER,
                  INTEREST_OR_BLACK.INTEREST,
                )
              }
              style={{ paddingLeft: '8px' }}
            >
              <Icon name='icon_star_18_filled' />
              <span style={{ marginLeft: '4px' }}>관심지원자 등록</span>
            </Dropdown.ButtonItem>
            <Dropdown.ButtonItem
              onClick={() =>
                handleAction(MARKING_ACTION.DELETE, INTEREST_OR_BLACK.INTEREST)
              }
              style={{ paddingLeft: '8px' }}
            >
              <Icon name='icon_star_18_line' />
              <span style={{ marginLeft: '4px' }}>관심지원자 해제</span>
            </Dropdown.ButtonItem>
            <Dropdown.ButtonItem
              onClick={() =>
                handleAction(MARKING_ACTION.REGISTER, INTEREST_OR_BLACK.BLACK)
              }
              style={{ paddingLeft: '8px' }}
            >
              <Icon name='icon_person_18_filled' />
              <span style={{ marginLeft: '4px' }}>블랙지원자 등록</span>
            </Dropdown.ButtonItem>
            <Dropdown.ButtonItem
              onClick={() =>
                handleAction(MARKING_ACTION.DELETE, INTEREST_OR_BLACK.BLACK)
              }
              style={{ paddingLeft: '8px', justifyContent: 'start' }}
            >
              <Icon name='icon_person_18_line' />
              <span style={{ marginLeft: '4px' }}>블랙지원자 해제</span>
            </Dropdown.ButtonItem>
          </>
        ) : (
          <S.EmptyCurrentCheckContainer>
            <span>
              지원자를
              <br />
              선택해주세요
            </span>
          </S.EmptyCurrentCheckContainer>
        )}
      </Dropdown.ItemContainer>
    </Dropdown.Container>
  );
};
