import { getDay } from '@helpers/date.util';

export const formatDateString = (dateTime: string) => {
  const [date] = dateTime.split(' ');
  const [yyyy, mm, dd] = date.split('-');

  return `${yyyy}년 ${mm}월 ${dd}일(${getDay(new Date(date))})`;
};

export const formatTimeString = (dateTime: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_, time] = dateTime.split(' ');

  const [hh, mm] = time.split(':');
  return `${hh}:${mm}`;
};

export const InProgress = (from: string, to: string) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const nowDate = new Date();

  if (nowDate >= fromDate && nowDate <= toDate) return true;
  return false;
};
