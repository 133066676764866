import { useScreeningNoticeListQuery } from '@repositories/evaluation/common/common.query';
import {
  initScreeningSnAtom,
  screeningNoticeListAtom,
  screeningNoticeSnAtom,
  screeningSectorSnAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const useGridScreening = () => {
  const { screeningSn } = useParams();
  const setScreeningSn = useSetRecoilState(screeningSnAtom);
  const setInitScreeningSn = useSetRecoilState(initScreeningSnAtom);
  useEffect(() => {
    setScreeningSn(Number(screeningSn));
    setInitScreeningSn(Number(screeningSn));
  }, []);

  const { data: screeningNoticeList } = useScreeningNoticeListQuery(
    screeningSn!,
  );
  const [noticeSn, setNoticeSn] = useRecoilState(screeningNoticeSnAtom);
  const sectorSn = useRecoilValue(screeningSectorSnAtom);
  const setNoticeList = useSetRecoilState(screeningNoticeListAtom);

  useEffect(() => {
    setNoticeList(screeningNoticeList);
    if (screeningNoticeList.length === 1) {
      setNoticeSn(screeningNoticeList[0].sn);
    } else {
      setNoticeSn(null);
    }
  }, [screeningNoticeList, setNoticeSn]);

  return {
    noticeSn,
    sectorSn,
  };
};
