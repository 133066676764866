import { Icon } from '@ats/ui';
import { UPLOAD_STATUS } from '@constants/evaluation.constant';
import { useDownloadManagerUploadFile } from '@repositories/evaluation/common/common.query';
import * as S from './gridBodyCol.style';

interface GridBodyColManagerUploadFileButtonProps {
  resumeSn: number;
  managerUploadFile: UPLOAD_STATUS;
}

export const GridBodyColManagerUploadFileButton = ({
  resumeSn,
  managerUploadFile,
}: GridBodyColManagerUploadFileButtonProps) => {
  const { mutate: downloadManagerUploadFileMutate } =
    useDownloadManagerUploadFile();
  const handleClickDownload = () => {
    downloadManagerUploadFileMutate({
      resumeSn: resumeSn,
    });
  };

  return (
    <S.CellCenterPosition>
      {managerUploadFile === UPLOAD_STATUS.UPLOADED ? (
        <button onClick={handleClickDownload}>
          <Icon name='icon_file_done_18_line' size={24} />
        </button>
      ) : (
        <Icon name='icon_unable_file_18_line' size={24} fill={'#ced4da'} />
      )}
    </S.CellCenterPosition>
  );
};
