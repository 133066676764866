import { ReactComponent as ChatBlue } from '@assets/q&a/icon_chat_blue.svg';
import { ReactComponent as LockYellow } from '@assets/q&a/icon_lock_yellow.svg';
import { PAGES } from '@ats/constants';
import ModalQnAPassword from '@components/q&a/QnAPasswordModal';
import type { ArticleItem } from '@repositories/article/article.dto';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  ContentArea,
  Description,
  DescriptionArea,
  Divider,
  IconArea,
  ReplyCount,
  Title,
} from './style';

// const QuestionIcons: Record<QUESTION_TYPE, ReactElement> = {
//   [QUESTION_TYPE.QUESTION]: <ChatBlue />,
//   [QUESTION_TYPE.PRIVACY]: <LockYellow />,
//   [QUESTION_TYPE.PUBLIC]: <LockOpenGreen />,
// };

const Card = (props: ArticleItem & { loginMemberSn: string }) => {
  const navigate = useNavigate();
  const [showQnAPasswordModal, setShowQnAPasswordModal] = useState(false);
  const {
    articleSn,
    title,
    creatorName,
    lastModifyDate,
    answerCount,
    isPrivate,
    creatorSn,
    loginMemberSn,
  } = props;

  return (
    <>
      <Container
        onClick={() => {
          if (creatorSn === loginMemberSn)
            return navigate(
              PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.READ(articleSn),
            );
          if (isPrivate) {
            return setShowQnAPasswordModal(true);
          }
          navigate(
            PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.READ(articleSn),
          );
        }}
      >
        <IconArea>{isPrivate ? <LockYellow /> : <ChatBlue />}</IconArea>
        <ContentArea>
          <Title>{title}</Title>
          <DescriptionArea>
            <Description>{creatorName}</Description>
            <Divider />
            <Description>{lastModifyDate}</Description>
            {!!answerCount && <ReplyCount>답변&nbsp;{answerCount}</ReplyCount>}
          </DescriptionArea>
        </ContentArea>
      </Container>
      {showQnAPasswordModal && (
        <ModalQnAPassword
          key={articleSn}
          articleSn={articleSn}
          onClose={() => setShowQnAPasswordModal(false)}
        />
      )}
    </>
  );
};

export default Card;
