import { ReactComponent as EmptyImg } from '@assets/interview/no_search_result.svg';
import { Text } from '@ats/ui';
import * as S from './emptySearchInterview.style.ts';

const EmptyInterview = () => {
  return (
    <S.Container>
      <S.Content>
        <EmptyImg />
        <S.Text>
          <Text variant={'b2'}>현재 설정된 검색조건에</Text>
          <Text variant={'b2'}>해당되는 지원자가 없습니다.</Text>
        </S.Text>
      </S.Content>
    </S.Container>
  );
};

export default EmptyInterview;
