import { ReactComponent as ErrorYellow } from '@assets/error/icon_error_yellow_fill.svg';
import { PAGES } from '@ats/constants';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { accessToken, sessionEndTime, tenantId } from '@stores/atoms/auth';
import { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Container, Content, Description, Title, Wrapper } from './style';

const ErrorPage: FC = () => {
  const setSessionEndTime = useSetRecoilState(sessionEndTime);
  const setTenant = useSetRecoilState(tenantId);
  const setAccessToken = useSetRecoilState(accessToken);
  const navigate = useNavigate();

  useEffect(() => {
    setSessionEndTime(null);
    setTenant(null);
    setAccessToken(null);
  }, []);

  return (
    <Container>
      <Wrapper>
        <Content>
          <ErrorYellow />
          <Title>서비스 에러</Title>
          <Description>
            서비스 이용에 불편을 드려 죄송합니다.
            <br />
            관리자에게 문의하시거나 잠시 후 다시 시도해주세요.
          </Description>
          <Button
            size={SIZE.SM}
            variant={BUTTON_VARIANTS.PRIMARY}
            color={BUTTON_COLORS.DEFAULT}
            style={{ marginTop: 40 }}
            onClick={() =>
              navigate(PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.HOME)
            }
          >
            뒤로가기
          </Button>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default ErrorPage;
