import { BUTTON_COLORS, BUTTON_VARIANTS, Dropdown, Icon, SIZE } from '@ats/ui';
import { useNotice } from '@hooks/evaluation/grid-screening/useNotice.ts';
import * as S from './left.style.ts';

export const Notice = () => {
  const {
    searchText,
    selectedNotice,
    searchResultList,
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleClickNotice,
    handleChangeNoticeSearch,
  } = useNotice();

  return (
    <Dropdown.Container ref={dropdownContainerRef}>
      <Dropdown.Trigger
        variant={BUTTON_VARIANTS.SECONDARY}
        color={BUTTON_COLORS.DARK}
        size={SIZE.SM}
        style={{ width: '160px' }}
        onClick={handleToggleDropdown}
      >
        <S.TriggerText>
          {selectedNotice ? selectedNotice : '채용공고'}
        </S.TriggerText>
        <Icon name='icon_arrow_bottom_18_line' color='#E9ECEF' />
      </Dropdown.Trigger>
      <Dropdown.ItemContainer
        isOpen={isOpen}
        style={{ height: '240px', width: '340px' }}
        showScrollbarWrapper
        isOverflowScrollable
      >
        <Dropdown.TextField
          value={searchText}
          onChange={handleChangeNoticeSearch}
          placeholder='검색어를 입력해주세요.'
        />
        <Dropdown.Separate />
        {searchResultList &&
          searchResultList.map((notice) => (
            <Dropdown.ButtonItem
              onClick={handleClickNotice}
              key={notice.sn}
              value={notice.sn}
            >
              {notice.name}
            </Dropdown.ButtonItem>
          ))}
      </Dropdown.ItemContainer>
    </Dropdown.Container>
  );
};
