import { convertToFormData } from '@helpers/formData.util';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type {
  GetQnaCommentsRqDTO,
  GetQnaCommentsRsDTO,
  PostQnaPasswordCheckRqDTO,
  PostQnaRqDTO,
} from './qna.dto';
import { qnaRepository } from './qna.repository';

export const QnAQueryKey = {
  all: ['qna'] as const,
  getComment: () => [...QnAQueryKey.all] as const,
};
export const useQnaCommentsQuery = (
  request: GetQnaCommentsRqDTO,
): UseQueryResult<GetQnaCommentsRsDTO[], AxiosError> =>
  useQuery({
    enabled: !!request.upArticleSn,
    queryKey: [...QnAQueryKey.getComment()],
    queryFn: () =>
      qnaRepository.getQnaComments(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useUpdateQnaArticleMutation = () =>
  useMutation({
    mutationFn: (request: PostQnaRqDTO) =>
      qnaRepository.updateQnaArticle(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

interface PostQnaArticleMutationArgs {
  requestBody: PostQnaRqDTO;
  AUToken: string;
}

export const usePostQnaArticleMutation = () =>
  useMutation({
    mutationFn: ({ requestBody, AUToken }: PostQnaArticleMutationArgs) =>
      qnaRepository.postQnaArticle(
        convertToFormData(requestBody as unknown as Record<string, string>),
        AUToken,
      ),
  });

export const useDeleteArticleMutation = () =>
  useMutation({
    mutationFn: (request: { articleSn: string }) =>
      qnaRepository.deleteQnaArticle(convertToFormData(request)),
  });

export const usePostCheckPasswordMutation = (): UseMutationResult<
  { successYn: boolean },
  unknown,
  PostQnaPasswordCheckRqDTO,
  unknown
> => {
  return useMutation({
    mutationFn: (request: PostQnaPasswordCheckRqDTO) =>
      qnaRepository.postQnaPasswordCheck(request),
  });
};
