import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  min-width: ${1280 - 70}px;
  height: 100%;
  min-height: calc(100vh - 64px);

  display: flex;
  flex-direction: column;
  padding: 48px 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 48px 16px;
  }
`;
