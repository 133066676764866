import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { FinalEvaluationFilter } from '@components/evaluation/grid-screening/modal/GridFilterModal/modalColumns/FinalEvaluationFilter.tsx';
import { useGridFilter } from '@hooks/evaluation/grid-screening/useGridFilter';
import {
  evaluationItemInfosAtom,
  resultCodeMappingsAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue } from 'recoil';
import * as S from './gridFilterModal.style';
import { EvaluationItemsFilter } from './modalColumns/EvaluationItemsFilter';
import { InterestBlackFilter } from './modalColumns/InterestBlackFilter';
import { ManagerUploadFileFilter } from './modalColumns/ManagerUploadFilerFilter';
import { ReadStatusFilter } from './modalColumns/ReadStatusFilter';
import { TotalEvaluationFilter } from './modalColumns/TotalEvaluationFilter';

export const GridFilterModal = () => {
  const {
    modalRef,

    readStatusFilterRef,
    interestBlackFilterRef,
    evaluationItemsFilterRef,
    totalEvaluationFilterRef,
    finalEvaluationFilterRef,
    managerUploadFileFilterRef,

    handleRefreshFilter,
    handleApplyFilter,
  } = useGridFilter();

  const evaluationItemInfos = useRecoilValue(evaluationItemInfosAtom);
  const showTotalEvaluationFilter = evaluationItemInfos.some(
    (item) => item.evaluationItemType === 'OVERALL',
  );

  const showFinalEvaluationFilter = useRecoilValue(resultCodeMappingsAtom);

  return (
    <S.Container ref={modalRef}>
      <S.UpperSection>
        {/* 열람/미열람 */}
        <ReadStatusFilter ref={readStatusFilterRef} />

        {/* 관심/블랙지원자 */}
        <InterestBlackFilter ref={interestBlackFilterRef} />

        {/* 평가항목 */}
        {evaluationItemInfos.length > 0 && (
          <EvaluationItemsFilter ref={evaluationItemsFilterRef} />
        )}

        {/* 종합평가 */}
        {showTotalEvaluationFilter && (
          <TotalEvaluationFilter ref={totalEvaluationFilterRef} />
        )}

        {/* 최종평가 */}
        {showFinalEvaluationFilter && (
          <FinalEvaluationFilter ref={finalEvaluationFilterRef} />
        )}

        {/* 관리자 업로드 파일 */}
        <ManagerUploadFileFilter ref={managerUploadFileFilterRef} />
      </S.UpperSection>

      <S.LowerSection>
        <Button
          leftIcon={'icon_reset_18_line'}
          size={SIZE.SM}
          variant={BUTTON_VARIANTS.OUTLINED}
          onClick={handleRefreshFilter}
        >
          초기화
        </Button>
        <Button
          size={SIZE.SM}
          color={BUTTON_COLORS.THEMA}
          onClick={handleApplyFilter}
        >
          검색
        </Button>
      </S.LowerSection>
    </S.Container>
  );
};
