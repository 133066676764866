import type { Nullable } from '@ats/interfaces';
import type { AuthorizationType } from '../constants/authorization.constant';
import { AUTHORIZATION_TYPE } from '../constants/authorization.constant';

class KeyRepository {
  private static _instance: Nullable<KeyRepository> = null;
  private _store = new Map<string, string>();
  private authorizationType = AUTHORIZATION_TYPE.BEARER;

  private constructor() {
    KeyRepository._instance = this;
  }

  public static getInstance(): KeyRepository {
    if (!KeyRepository._instance) {
      KeyRepository._instance = new KeyRepository();
    }

    return KeyRepository._instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setData(key: string, value: any): this {
    this._store.set(key, JSON.stringify(value));
    return this;
  }

  public getData(key: string): Nullable<string> {
    const data = this._store.get(key);
    if (!data) return null;
    return JSON.parse(data) as string;
  }

  public get accessToken(): string {
    const accessToken = this.getData('accessToken');
    if (!accessToken) return '';
    if (this.authorizationType === AUTHORIZATION_TYPE.BEARER)
      return `Bearer ${accessToken}`;
    return accessToken;
  }

  public updateAuthorizationType(authorizationType: AuthorizationType): this {
    this.authorizationType = authorizationType;
    return this;
  }

  public updateAccessToken(token: string): this {
    this.setData('accessToken', token);
    return this;
  }

  public get refreshToken(): string {
    const refreshToken = this.getData('refreshToken');
    if (!refreshToken) return '';
    return refreshToken;
  }

  public updateRefreshToken(token: string): this {
    this.setData('refreshToken', token);
    return this;
  }

  public clear(): void {
    this._store.clear();
  }
}

export default KeyRepository;
