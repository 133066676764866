import { BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { useTheme } from '@emotion/react';
import type { ArticleAttachFiles } from '@repositories/article/article.dto';

const Attachment = (props: ArticleAttachFiles) => {
  const { fileName } = props;
  const theme = useTheme();

  return (
    <Button
      variant={BUTTON_VARIANTS.SECONDARY}
      leftIcon={'icon_attach_file_18_line'}
      size={SIZE.SM}
    >
      {fileName}
    </Button>
  );
};

export default Attachment;
