import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SidebarItemBase = styled.li`
  cursor: pointer;
  width: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.radius.radius75};
`;

interface SidebarItemProps {
  isActive?: boolean;
}

const activeSidebarItemStyle = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.colors['primary-600']};
  color: ${theme.colors.white};
  svg {
    fill: ${theme.colors.white};
  }
`;
const sidebarItemStyle = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.colors['gray-80']};
  &:hover {
    background-color: ${theme.colors['gray-600']};
    color: ${theme.colors.white};
    svg {
      fill: ${theme.colors.white};
    }
  }
`;
export const SidebarItem = styled(SidebarItemBase)<SidebarItemProps>`
  ${({ isActive }) => (isActive ? activeSidebarItemStyle : sidebarItemStyle)}
`;
export const SideBarItemLeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TimeListContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const TimeList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.space100};
`;

export const Divider = styled.div`
  width: 4px;
  background-color: ${({ theme }) => theme.colors['gray-200']};
  flex-shrink: 0;
`;
