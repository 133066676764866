import type { PageKey } from '@constants/pageKey.constant';
import type { PageUtil } from '@repositories/common/pageUtil.dto';
import {
  currentPageAtomFamily,
  lastPageAtomFamily,
  pageSizeAtomFamily,
  recordCountAtomFamily,
} from '@stores/atoms/page.atomFamily';
import { selectorFamily } from 'recoil';

export const pageSelectorFamily = selectorFamily<PageUtil, PageKey>({
  key: 'pageSelectorFamily',
  get:
    (pageKey) =>
    ({ get }) => {
      const currentPage = get(currentPageAtomFamily(pageKey));
      const pageSize = get(pageSizeAtomFamily(pageKey));
      const lastPage = get(lastPageAtomFamily(pageKey));
      const recordCount = get(recordCountAtomFamily(pageKey));

      return { currentPage, pageSize, lastPage, recordCount };
    },
});
