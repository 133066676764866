import { PAGES } from '@ats/constants';
import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Radio,
  RadioGroup,
  SIZE,
  Text,
  TextField,
} from '@ats/ui';
import BorderlessTextField from '@components/common/BorderlessTextField/BorderlessTextField.tsx';
import FlatTag from '@components/common/FlatTag/FlatTag.tsx';
import Editor from '@components/q&a/Editor';
import { ARTICLE_CATEGORY_TYPE } from '@constants/article.constants.ts';
import {
  IS_PRIVATE_VALUE,
  type IsPrivateValue,
} from '@constants/qna.constants';
import { useTheme } from '@emotion/react';
import { PrivacySetting } from '@pages/QuestionAndAnswer/Create/style.ts';
import { useArticleQuery } from '@repositories/article/article.query.ts';
import { useUpdateQnaArticleMutation } from '@repositories/qna/qna.query.ts';
import { useState, type ChangeEvent, type FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Area,
  Container,
  Description,
  Divider,
  InformationArea,
} from './style';

const QuestionAndAnswerEditPage: FC = () => {
  const { sn } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  if (!sn) throw Error('잘못된 접근입니다.');
  const { mutate } = useUpdateQnaArticleMutation();
  const { data } = useArticleQuery({
    categorySn: ARTICLE_CATEGORY_TYPE.EVALUATOR_QNA,
    articleSn: sn,
    privatePassword: location.state?.privatePassword,
  });

  const {
    articleInfo: {
      title: articleTitle,
      contents: articleContents,
      isPrivate: articleIsPrivate,
      password: articlePrivatePassword,
      creatorName,
      lastModifyDate,
      department,
      answerCount,
    },
  } = data;

  const [title, setTitle] = useState(articleTitle);
  const [contents, setContents] = useState(articleContents);
  const [isPrivate, setIsPrivate] = useState<IsPrivateValue>(
    articleIsPrivate ? IS_PRIVATE_VALUE.PRIVATE : IS_PRIVATE_VALUE.PUBLIC,
  );
  const [password, setPassword] = useState(articlePrivatePassword ?? '');

  const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword('');
    setIsPrivate(Number(e.target.value) as IsPrivateValue);
  };

  return (
    <Container>
      <Area>
        <BorderlessTextField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={'제목을 입력해주세요.'}
          maxLength={50}
        />
      </Area>
      <Area>
        <InformationArea>
          <Description>{creatorName}</Description>
          <Divider />
          <Description>{department}</Description>
          <Divider />
          <Description style={{ marginRight: '8px' }}>
            {lastModifyDate}
          </Description>
          <FlatTag
            color={
              answerCount ? theme.colors['blue-600'] : theme.colors['gray-600']
            }
            backgroundColor={
              answerCount ? theme.colors['blue-100'] : theme.colors['gray-100']
            }
          >
            <Text variant={'b3'}>
              답변 {answerCount ? answerCount : '미완료'}
            </Text>
          </FlatTag>
        </InformationArea>
      </Area>
      <Area>
        <Editor contents={contents} setContents={setContents} />
      </Area>
      <Area>
        <PrivacySetting>
          <RadioGroup style={{ display: 'flex', gap: 24 }}>
            <Radio
              name='private'
              checked={isPrivate === IS_PRIVATE_VALUE.PUBLIC}
              value={IS_PRIVATE_VALUE.PUBLIC}
              onChange={handleChangeRadio}
            >
              공개
            </Radio>
            <Radio
              name='private'
              checked={isPrivate === IS_PRIVATE_VALUE.PRIVATE}
              value={IS_PRIVATE_VALUE.PRIVATE}
              onChange={handleChangeRadio}
            >
              비공개
            </Radio>
          </RadioGroup>
          <TextField
            placeholder='비밀번호 입력'
            size={SIZE.SM}
            disabled={isPrivate === IS_PRIVATE_VALUE.PUBLIC}
            value={password ?? ''}
            onChange={(e) => setPassword(e.target.value)}
          />
        </PrivacySetting>
      </Area>
      <Area style={{ display: 'flex', marginTop: 24 }}>
        <Button
          size='md'
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={() => navigate(-1)}
        >
          취소
        </Button>
        <Button
          size='md'
          variant={BUTTON_VARIANTS.PRIMARY}
          color={BUTTON_COLORS.THEMA}
          style={{ marginLeft: 8 }}
          onClick={() => {
            mutate(
              { title, contents, isPrivate, articleSn: sn, password },
              {
                onSuccess: () => {
                  navigate(
                    PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.READ(sn),
                    {
                      state: {
                        privatePassword: password,
                      },
                    },
                  );
                },
              },
            );
          }}
        >
          수정하기
        </Button>
      </Area>
    </Container>
  );
};

export default QuestionAndAnswerEditPage;
