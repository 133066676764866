export const Radius = {
  toJSON() {
    return {
      '.radius-0': {
        'border-radius': '0px',
      },
      '.radius-25': {
        'border-radius': '2px',
      },
      '.radius-50': {
        'border-radius': '4px',
      },
      '.radius-75': {
        'border-radius': '6px',
      },
      '.radius-100': {
        'border-radius': '8px',
      },
      '.radius-150': {
        'border-radius': '12px',
      },
      '.radius-200': {
        'border-radius': '16px',
      },
      '.radius-300': {
        'border-radius': '24px',
      },
      '.radius-400': {
        'border-radius': '32px',
      },
      '.radius-full': {
        'border-radius': '100%',
      },
    };
  },
  toJS() {
    return {
      radius0: '0px',
      radius25: '2px',
      radius50: '4px',
      radius75: '6px',
      radius100: '8px',
      radius150: '12px',
      radius200: '16px',
      radius300: '24px',
      radius400: '32px',
    };
  },
};
