import type { StepTypeCode } from '@constants/evaluation.constant';
import { MODAL_KEY } from '@constants/modal.constant';
import { ONE_SECOND } from '@constants/time.constant';
import { convertToFormData } from '@helpers/formData.util';
import { useModal } from '@hooks/shared/useModal';
import { EvaluationQueryKey } from '@repositories/evaluation/evaluation/evaluation.query';
import { evaluationStepListRepository } from '@repositories/evaluation/evaluation/evaluation.repository';
import {
  jobNoticeSnForEvaluationOathAtom,
  stepSnForEvaluationOathAtom,
  stepTypeCodeForEvaluationOathAtom,
} from '@stores/atoms/evaluation/evaluation.atom';
import { useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

interface Props {
  jobnoticeSn: string;
  stepSn: string;
  stepTypeCode: StepTypeCode;
}
export const useEvaluationOath = (props: Props) => {
  const { jobnoticeSn, stepSn, stepTypeCode } = props;
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const setStepSnForEvaluationOath = useSetRecoilState(
    stepSnForEvaluationOathAtom,
  );
  const setStepTypeCodeForEvaluationOath = useSetRecoilState(
    stepTypeCodeForEvaluationOathAtom,
  );
  const setJobNoticeSnForEvaluationOath = useSetRecoilState(
    jobNoticeSnForEvaluationOathAtom,
  );

  const securityOathRequestDTO = {
    jobnoticeSn,
    stepSn,
    systemKindCode: 'MRS2',
  } as const;

  const openOathModal = () => {
    setStepSnForEvaluationOath(stepSn);
    setStepTypeCodeForEvaluationOath(stepTypeCode);
    setJobNoticeSnForEvaluationOath(jobnoticeSn);

    openModal(MODAL_KEY.EVALUATION_OATH);
  };

  const prefetchOath = () => {
    queryClient.prefetchQuery({
      queryKey: EvaluationQueryKey.securityOath(securityOathRequestDTO),
      queryFn: () =>
        evaluationStepListRepository.getValuerSecurityOath(
          convertToFormData(
            securityOathRequestDTO as unknown as Record<string, string>,
          ),
        ),
      staleTime: 10 * ONE_SECOND,
    });
  };

  const oathDataQuery = async () => {
    const data = await queryClient.ensureQueryData({
      queryKey: EvaluationQueryKey.securityOath(securityOathRequestDTO),
      queryFn: () =>
        evaluationStepListRepository.getValuerSecurityOath(
          convertToFormData(
            securityOathRequestDTO as unknown as Record<string, string>,
          ),
        ),
      staleTime: 10 * ONE_SECOND,
    });

    return data;
  };

  return { openOathModal, prefetchOath, oathDataQuery };
};
