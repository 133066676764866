import { Toast } from '@ats/ui';
import { PORTAL_ID } from '@constants/portal.constant';
import { ONE_SECOND } from '@constants/time.constant';
import type { ToastContext } from '@hooks/shared/useToast';
import { toastStoreAtom } from '@stores/atoms/toast.atom';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState } from 'recoil';
import * as S from './toastManager.style';

const ToastManager = () => {
  const [toastStore, setToastStore] = useRecoilState(toastStoreAtom);

  if (!toastStore.length) return <></>;

  return createPortal(
    <S.ToastManager>
      <S.ToastGroup>
        {toastStore.map((toast) => (
          <ToastItem key={toast.text} {...toast} />
        ))}
      </S.ToastGroup>
    </S.ToastManager>,
    document.getElementById(PORTAL_ID.TOAST)!,
  );
};

const ToastItem = (toastContext: ToastContext) => {
  const [toastStore, setToastStore] = useRecoilState(toastStoreAtom);

  useEffect(() => {
    setTimeout(() => {
      setToastStore((prev) =>
        prev.filter((toastItem) => toastItem.text !== toastContext.text),
      );
    }, 4 * ONE_SECOND);
  }, []);

  return <Toast {...toastContext} />;
};

export default ToastManager;
