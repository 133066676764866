import { PAGES } from '@ats/constants';
import { KeyRepository } from '@ats/repositories';
import { atomicKeys } from '@constants/key.query';
import { useSessionTime } from '@hooks/useSessionTime';
import { accessToken, sessionEndTime, tenantId } from '@stores/atoms/auth';
import dayjs from 'dayjs';
import { useEffect, type FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const keyRepository = KeyRepository.getInstance();

const HomePage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const setSessionEndTime = useSetRecoilState(sessionEndTime);
  const setTenant = useSetRecoilState(tenantId);
  const setAccessToken = useSetRecoilState(accessToken);
  const { getSessionTime } = useSessionTime();

  useEffect(() => {
    const currentTime = dayjs();
    const sessionInitTime = getSessionTime(currentTime);

    const currentParams = Object.fromEntries([...searchParams]);

    if (!currentParams.tenant || !currentParams.token) {
      return navigate(PAGES.INTERVIEWER_EVALUATOR.ERROR.INVALID_SESSION, {
        replace: true,
      });
    }

    keyRepository.setData(atomicKeys.accessToken, currentParams.accessToken);
    setAccessToken(currentParams.token);

    setTenant(currentParams.tenant);
    setSessionEndTime(sessionInitTime);

    setTimeout(() => {
      navigate(PAGES.INTERVIEWER_EVALUATOR.EVALUATION.HOME, {
        replace: true,
      });
    }, 1000);
  }, []);

  return <></>;
};

export default HomePage;
