import { ReactComponent as BlueBook } from '@assets/q&a/icon_description.svg';
import { PAGES } from '@ats/constants';
import { Icon } from '@ats/ui';
import type { ArticleItem } from '@repositories/article/article.dto';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  ContentArea,
  Counter,
  Description,
  DescriptionArea,
  Divider,
  IconArea,
  Title,
  TitleWrapper,
} from './style';

const Card = (props: ArticleItem) => {
  const navigate = useNavigate();
  const {
    articleSn,
    title,
    creatorName,
    lastModifyDate,
    attachFileCount,
    hit,
    isShowNotice,
  } = props;

  return (
    <Container
      onClick={() =>
        navigate(PAGES.INTERVIEWER_EVALUATOR.NOTICE.READ(articleSn))
      }
    >
      {isShowNotice && (
        <IconArea>
          <BlueBook />
        </IconArea>
      )}
      <ContentArea>
        <TitleWrapper>
          <Title>{title}</Title>
          {!!attachFileCount && (
            <Icon
              name='icon_attach_file_24_line'
              size={24}
              style={{ marginLeft: 4 }}
            />
          )}
        </TitleWrapper>
        <DescriptionArea>
          <Description>{creatorName}</Description>
          <Divider />
          <Description>{lastModifyDate}</Description>
          <Counter>조회수&nbsp;{hit}</Counter>
        </DescriptionArea>
      </ContentArea>
    </Container>
  );
};

export default Card;
