import { ReactComponent as EmptyIcon } from '@assets/interview/flat_description_mono.svg';
import { Button, SIZE, Text } from '@ats/ui';
import { useNavigate } from 'react-router-dom';
import * as S from './noEvaluationItem.style.ts';

const NoEvaluationItem = () => {
  const navigate = useNavigate();
  const navigateToConfig = () => {
    // TODO: 설정 페이지로 이동
  };

  return (
    <S.Container>
      <S.Wrapper>
        <EmptyIcon width={56} height={56} style={{ marginBottom: '16px' }} />
        <S.TextBox>
          <Text variant={'b2'}>평가척도가 설정되지 않아</Text>
          <Text variant={'b2'}>평가를 진행할 수 없습니다.</Text>
        </S.TextBox>
        <Button size={SIZE.SM} onClick={navigateToConfig}>
          설정하러 가기
        </Button>
      </S.Wrapper>
    </S.Container>
  );
};

export default NoEvaluationItem;
