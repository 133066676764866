import Pagination from '@components/common/Pagination/Pagination';
import { PAGE_KEY } from '@constants/pageKey.constant';
import { useArticleListQuery } from '@repositories/article/article.query';
import {
  currentPageAtomFamily,
  pageSizeAtomFamily,
} from '@stores/atoms/page.atomFamily';
import { articleSelectorFamily } from '@stores/selectors/article.selectorFamily';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const Footer = () => {
  const articleRequestDTO = useRecoilValue(
    articleSelectorFamily(PAGE_KEY.NOTICE),
  );
  const {
    data: { pageUtil },
  } = useArticleListQuery(articleRequestDTO);

  const setCurrentPage = useSetRecoilState(
    currentPageAtomFamily(PAGE_KEY.NOTICE),
  );
  const setPageSize = useSetRecoilState(pageSizeAtomFamily(PAGE_KEY.NOTICE));

  return (
    <Pagination
      {...pageUtil}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      pageSizeOption={[5, 10, 20, 30]}
    />
  );
};

export default Footer;
