import type { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react';
import { Container, TextArea } from './style';

interface TextareaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  content: string;
  setContent: (content: string) => void;
}

const Textarea: FC<TextareaProps> = ({ ...props }) => {
  const { content, setContent } = props;

  return (
    <Container>
      <TextArea
        placeholder='내용을 입력해주세요'
        value={content}
        onChange={(e) => setContent(e.target.value)}
        {...props}
      />
    </Container>
  );
};

export default Textarea;
