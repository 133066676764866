export const SENTRY_KEYS = {
  SERVICES: {
    VIDEO_INTERVIEW: 'VIDEO_INTERVIEW', // 영상면접
    INTERVIEW_EVALUATOR: 'INTERVIEW_EVALUATOR', // 면접평가자
  },
  TYPES: {
    API: 'API', // 내/외부 API 에러 타입
    BROWSER: 'BROWSER', // 클라이언트 브라우저 에러 타입
    SOCKET: 'SOCKET', // 웹소켓 에러 타입
  },
  CONTEXTS: {
    DEBUG: 'DEBUG',
    BROWSER_ERROR_DETAIL: '브라우저 에러 상세 (SPA)', // 브라우저 에러
    API_REQUEST_DETAIL: 'API 요청 파라미터', // API 요청값
    API_RESPONSE_DETAIL: 'API 서버 응답 전문', // API 응답값
    API_REQUEST_HEADERS: 'API 요청 헤더 정보', // API 요청 헤더 정보
    API_UNKNOWN_ERROR_DETAIL: 'API 예외처리 상세', // 알 수 없는 API 에러
    AXIOS_CONFIG_DETAIL: 'Axios 설정 정보', // Axios 호출 설정값
  },
  MESSAGES: {
    API_ERROR: 'API 서버 에러', // 외부 API 에러
    BROWSER_ERROR: '브라우저 런타임 에러 (SPA)', // 브라우저 런타임 에러 (SPA)
    TROUBLE_SHOOTING: '디버깅', // 디버깅
    AMPLITUDE_ERROR: 'Amplitude 트래킹 에러',
    FIREBASE_ERROR: 'Firebase 에러',
    SOCKET_ERROR: 'Socket 에러',
    REDIS_ERROR: 'Redis 에러',
  },
};
