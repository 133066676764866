import { Icon } from '@ats/ui';
import { SCREENING_TYPE } from '@constants/evaluation.constant';
import { useScreeningInfoQuery } from '@repositories/evaluation/common/common.query';
import { evaluationResultModifyAllowYnAtom } from '@stores/atoms/evaluation/document.atom';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as S from './gridScreeningTitle.style';

export const GridScreeningTitle = () => {
  const { screeningSn } = useParams();

  const {
    data: screeningInfo = {
      screeningType: SCREENING_TYPE.DOCUMENT,
      screeningName: '',
      evalResultModifyAllowYn: false,
    },
  } = useScreeningInfoQuery({ screeningSn: screeningSn! });

  const setEvalResultModifyAllowYn = useSetRecoilState(
    evaluationResultModifyAllowYnAtom,
  );
  useEffect(() => {
    setEvalResultModifyAllowYn(screeningInfo.evalResultModifyAllowYn);
  }, [setEvalResultModifyAllowYn, screeningInfo.evalResultModifyAllowYn]);

  const getTitleWithPrefix = (type: SCREENING_TYPE, title: string) => {
    switch (type) {
      case SCREENING_TYPE.DOCUMENT.toUpperCase():
        return `서류전형 : ${title}`;
      case SCREENING_TYPE.EXAM.toUpperCase():
        return `시험전형 : ${title}`;
      case SCREENING_TYPE.PT_INTERVIEW.toUpperCase():
        return `온라인PT전형 : ${title}`;
      default:
        return title;
    }
  };

  return (
    <S.Title>
      <Link to={'/evaluation'}>
        <Icon name='icon_back_18_line' size={24} style={{ fill: 'white' }} />
      </Link>
      {getTitleWithPrefix(
        screeningInfo.screeningType,
        screeningInfo.screeningName,
      )}
    </S.Title>
  );
};
