import { convertToFormData } from '@helpers/formData.util';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DeleteNoticeCommentsRqDTO,
  GetNoticeCommentsRqDTO,
  PostNoticeCommentsRqDTO,
  PutNoticeCommentsRqDTO,
} from './notice.dto';
import { noticeRepository } from './notice.repository';

export const NoticeQueryKey = {
  all: ['notice'] as const,
  getComment: () => [...NoticeQueryKey.all] as const,
};

export const useNoticeCommentsQuery = (request: GetNoticeCommentsRqDTO) =>
  useQuery({
    queryKey: [...NoticeQueryKey.getComment()],
    queryFn: () =>
      noticeRepository.getNoticeComments(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const usePostNoticeCommentMutation = () =>
  useMutation({
    mutationFn: ({
      request,
      AUToken,
    }: {
      request: PostNoticeCommentsRqDTO;
      AUToken: string;
    }) =>
      noticeRepository.postNoticeComments(
        convertToFormData(request as unknown as Record<string, string>),
        AUToken,
      ),
  });

export const useUpdateNoticeCommentMutation = () =>
  useMutation({
    mutationFn: (request: PutNoticeCommentsRqDTO) =>
      noticeRepository.putNoticeComments(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });

export const useDeleteNoticeCommentMutation = () =>
  useMutation({
    mutationFn: (request: DeleteNoticeCommentsRqDTO) =>
      noticeRepository.deleteNoticeComments(
        convertToFormData(request as unknown as Record<string, string>),
      ),
  });
