import { Z_INDEX } from '@constants/hierarchy.constant';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }
`;

export const Content = styled.div`
  display: none;
  position: absolute;
  z-index: ${Z_INDEX.TOOLTIP};

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;
