import { PAGES } from '@ats/constants';
import FullPageSpin from '@components/common/FullPageSpin/FullPageSpin';
import { queryClient } from '@helpers/client';
import { convertToFormData } from '@helpers/formData.util';
import MainLayout from '@layouts/Main';
import EvaluationHomePage from '@pages/Evaluation';
import Document from '@pages/Evaluation/GridScreening';
import Interview from '@pages/Evaluation/Interview';
import { CommonEvaluationRepository } from '@repositories/evaluation/common/common.repository';
import { Suspense } from 'react';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { defer, type RouteObject } from 'react-router-dom';

const commonEvaluationRepository = new CommonEvaluationRepository('');

async function loader({ params }: LoaderFunctionArgs<{ screeningSn: string }>) {
  const { screeningType, screeningSn } = params;

  return defer({
    data: Promise.all([
      queryClient.prefetchQuery({
        queryKey: ['keepLogin'],
        queryFn: () => commonEvaluationRepository.keepLogin(),
      }),
      queryClient.prefetchQuery({
        queryKey: ['screeningInfo', screeningSn],
        queryFn: () =>
          commonEvaluationRepository.getScreeningInfo(
            convertToFormData({
              screeningSn,
            } as unknown as Record<string, string>),
          ),
      }),
      queryClient.prefetchQuery({
        queryKey: ['screeningNoticeList', screeningSn],
        queryFn: () =>
          commonEvaluationRepository.getScreeningNoticeList(
            convertToFormData({ screeningSn } as unknown as Record<
              string,
              string
            >),
          ),
      }),
    ]),
  });
}

const EvaluationRoute: RouteObject = {
  path: PAGES.INTERVIEWER_EVALUATOR.EVALUATION.HOME,
  children: [
    {
      path: PAGES.INTERVIEWER_EVALUATOR.EVALUATION.HOME,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <EvaluationHomePage />,
        },
      ],
    },
    {
      path: PAGES.INTERVIEWER_EVALUATOR.EVALUATION.GRID(
        ':screeningType',
        ':screeningSn',
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<FullPageSpin />}>
              <Document />
            </Suspense>
          ),
          loader,
        },
      ],
    },
    {
      path: PAGES.INTERVIEWER_EVALUATOR.EVALUATION.INTERVIEW(':screeningSn'),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<FullPageSpin />}>
              <Interview />
            </Suspense>
          ),
        },
      ],
    },
  ],
};

export default EvaluationRoute;
