import { PAGES } from '@ats/constants';
import ErrorPage from '@pages/Error';
import InvalidSessionPage from '@pages/InvalidSession';
import type { RouteObject } from 'react-router-dom';
import NotFoundPage from 'src/pages/NotFound';

const ExceptionRoute: RouteObject[] = [
  {
    path: PAGES.INTERVIEWER_EVALUATOR.ERROR.SYSTEM_ERROR,
    element: <ErrorPage />,
  },
  {
    path: PAGES.INTERVIEWER_EVALUATOR.ERROR.INVALID_SESSION,
    element: <InvalidSessionPage />,
  },
  {
    path: PAGES.ALL,
    element: <NotFoundPage />,
  },
];

export default ExceptionRoute;
