import {
  gridFilterReadAtom,
  gridFilterUnReadAtom,
} from '@stores/atoms/evaluation/document.filter';
import type { ChangeEvent, ForwardedRef } from 'react';
import { useImperativeHandle, useState } from 'react';
import { useRecoilState } from 'recoil';
import type { ChildRefHandle } from '../useGridFilter';

export const useReadStatusFilter = (ref: ForwardedRef<ChildRefHandle>) => {
  const [gridFilterReadStore, setGridFilterReadStore] =
    useRecoilState(gridFilterReadAtom);
  const [gridFilterUnReadStore, setGridFilterUnReadStore] =
    useRecoilState(gridFilterUnReadAtom);
  const [isRead, setIsRead] = useState(gridFilterReadStore);
  const [isUnRead, setIsUnread] = useState(gridFilterUnReadStore);

  const isAllCheckedReadAndUnchecked = isRead && isUnRead;

  const readUnReadAllProps = {
    checked: isAllCheckedReadAndUnchecked,
    indeterminate: (isRead || isUnRead) && !isAllCheckedReadAndUnchecked,
    onChange: () => {
      if (isAllCheckedReadAndUnchecked) {
        setIsRead(false);
        setIsUnread(false);
        return;
      }

      setIsRead(true);
      setIsUnread(true);
    },
  };
  const readProps = {
    checked: isRead,
    onChange: (e: ChangeEvent<HTMLInputElement>) => setIsRead(e.target.checked),
  };
  const unReadProps = {
    checked: isUnRead,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setIsUnread(e.target.checked),
  };

  const refreshFn = () => {
    setIsRead(false);
    setIsUnread(false);
  };

  const applyFn = () => {
    setGridFilterReadStore(isRead);
    setGridFilterUnReadStore(isUnRead);
  };

  useImperativeHandle(ref, () => ({
    refreshFn,
    applyFn,
  }));

  return {
    readUnReadAllProps,
    readProps,
    unReadProps,
  };
};
