import { ReactComponent as OnlinePtIcon } from '@assets/interview/icon_desktop_face_green.svg';
import { ReactComponent as ExamIcon } from '@assets/interview/icon_evaluation.svg';
import { ReactComponent as DocumentIcon } from '@assets/interview/icon_filebox_blue.svg';
import { ReactComponent as InterviewIcon } from '@assets/interview/icon_people_green.svg';
import { Text } from '@ats/ui';
import type { StepTypeCode } from '@constants/evaluation.constant.ts';
import { STEP_TYPE_CODE } from '@constants/evaluation.constant.ts';
import { ONE_DAY } from '@constants/time.constant.ts';
import { useTheme } from '@emotion/react';
import { formatEvaluationDateString } from '@helpers/date.util.ts';
import type {
  EvaluationDate,
  EvaluationStep,
} from '@repositories/evaluation/evaluation/evaluation.dto.ts';

import { PAGES } from '@ats/constants';
import { useNavigate } from 'react-router-dom';
import * as S from '../StepList/stepList.style.ts';
import { useEvaluationOath } from './useEvaluationOath.ts';

const getStepIcon = (stepTypeCode: StepTypeCode) => {
  if (stepTypeCode === STEP_TYPE_CODE.DOCUMENT) return <DocumentIcon />;
  if (stepTypeCode === STEP_TYPE_CODE.EXAM) return <ExamIcon />;
  if (stepTypeCode === STEP_TYPE_CODE.INTERVIEW) return <InterviewIcon />;
  if (stepTypeCode === STEP_TYPE_CODE.ON_LINE_PT) return <OnlinePtIcon />;

  throw Error(`invalid step code: ${stepTypeCode}`);
};

export interface StepItemProps extends EvaluationStep {}
export const StepItem = (props: StepItemProps) => {
  const {
    stepTypeCode,
    stepName,
    stepSn,
    startDate,
    endDate,
    jobnoticeName,
    jobnoticeSn,
    total,
    isClose,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const { openOathModal, prefetchOath, oathDataQuery } = useEvaluationOath({
    jobnoticeSn,
    stepSn,
    stepTypeCode,
  });

  const handleClickItem = async () => {
    if (isClose) {
      alert('이미 종료된 전형입니다.');
      return;
    }

    const oathData = await oathDataQuery();

    if (oathData && oathData.isUse && !oathData.securityOath) openOathModal();
    else navigateToEvaluation();
  };

  const navigateToEvaluation = () => {
    if (stepTypeCode === STEP_TYPE_CODE.INTERVIEW) {
      navigate(PAGES.INTERVIEWER_EVALUATOR.EVALUATION.INTERVIEW(stepSn));
      return;
    }

    navigate(PAGES.INTERVIEWER_EVALUATOR.EVALUATION.GRID('DOCUMENT', stepSn));
  };

  return (
    <S.Container
      onClick={handleClickItem}
      onFocus={prefetchOath}
      onMouseEnter={prefetchOath}
    >
      <S.LeftSection>
        {getStepIcon(stepTypeCode)}
        <S.StepInfo>
          <Text variant={'t2'} bold color={theme.colors['gray-800']}>
            {stepName}
          </Text>
          <S.NoticeInfo>
            <Text variant={'b2'} color={theme.colors['gray-600']}>
              {jobnoticeName}
            </Text>
            <S.Divider />
            <Text variant={'b2'} color={theme.colors['gray-600']}>
              총 {total}명 배정
            </Text>
          </S.NoticeInfo>
        </S.StepInfo>
      </S.LeftSection>

      <S.RightSection>
        <Text variant={'b2'} color={theme.colors['gray-800']}>
          {formatEvaluationDateString(new Date(startDate.time))} -
          {formatEvaluationDateString(new Date(endDate.time))}
        </Text>
        <EvaluationStatus isClose={isClose} endDate={endDate} />
      </S.RightSection>
    </S.Container>
  );
};
interface EvaluationStatusProps {
  isClose: boolean;
  endDate: EvaluationDate;
}
const getDDayString = (targetDate: EvaluationDate) => {
  const d = new Date(targetDate.time);
  const diff = +d - +new Date();
  const dayDiff = Math.floor(diff / ONE_DAY);

  if (dayDiff === 0) return 'DAY';
  return dayDiff;
};
const EvaluationStatus = ({ isClose, endDate }: EvaluationStatusProps) => {
  const theme = useTheme();

  if (isClose)
    return (
      <Text variant={'b1'} color={theme.colors['gray-600']}>
        평가종료
      </Text>
    );

  return (
    <Text variant={'b1'} color={theme.colors['red-600']}>
      평가마감 D-{getDDayString(endDate)}
    </Text>
  );
};
