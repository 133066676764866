export const getDay = (date: Date) => {
  const dayMap = ['일', '월', '화', '수', '목', '금', '토'];
  return dayMap[date.getDay()];
};

export const extractDate = (dateString: string) => {
  return new Date(dateString.split(' ')[0]);
};

export const formatDateString = (dateTime: string, delimiter: string = '.') => {
  const [date] = dateTime.split(' ');
  const [yyyy, mm, dd] = date.split('-');

  return `${yyyy + delimiter + mm + delimiter + dd}(${getDay(new Date(date))})`;
};

export const formatEvaluationDateString = (date: Date) => {
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const dd = date.getDate().toString().padStart(2, '0');

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${yyyy}.${mm}.${dd}(${getDay(date)}) ${hours}:${minutes}`;
};

export const formatBasicDateString = (dateString: string) => {
  return dateString.split('T')[0].replaceAll('-', '.');
};
