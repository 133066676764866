import { PAGES } from '@ats/constants';
import MainLayout from '@layouts/Main';
import HomePage from '@pages/Home';
import type { RouteObject } from 'react-router-dom';

const MainRoute: RouteObject = {
  path: PAGES.INTERVIEWER_EVALUATOR.HOME,
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: <HomePage />,
    },
  ],
};

export default MainRoute;
