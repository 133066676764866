import styled from '@emotion/styled';

export const EmptyContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: ${({ theme }) => theme.spacing.space200};
  & img {
    width: 56px;
    height: 56px;
  }

  ${({ theme }) => theme.body.b2}
`;
