import { Modal } from '@ats/ui';
import { useAlert } from '@hooks/shared/useAlert';
import { useModal } from '@hooks/shared/useModal';
import { useSubmitEvaluationResultQuery } from '@repositories/evaluation/common/common.query';
import {
  isRenderEvaluationAllButtonAtom,
  isRenderEvaluationResultSubmitButtonAtom,
  screeningSnAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  DescriptionLi,
  DescriptionUl,
} from './EvaluationResultSubmitModal.style';

export const EvaluationResultSubmitModal = () => {
  const { closeModal } = useModal();
  const { openAlert, closeAlert } = useAlert();
  const { mutate: submitEvaluationResultMutate } =
    useSubmitEvaluationResultQuery();

  const screeningSn = useRecoilValue(screeningSnAtom);
  const setIsRenderEvaluationResultSubmitButton = useSetRecoilState(
    isRenderEvaluationResultSubmitButtonAtom,
  );
  const setIsRenderEvaluationAllButtonAtom = useSetRecoilState(
    isRenderEvaluationAllButtonAtom,
  );

  const handleClickFinalConfirm = () => {
    submitEvaluationResultMutate({
      screeningSn,
    });
    setIsRenderEvaluationResultSubmitButton(false);
    setIsRenderEvaluationAllButtonAtom(false);
    closeModal();
  };
  const handleClickConfirm = () => {
    openAlert({
      title: '',
      content: ['제출 후 평가결과를 수정할 수 없습니다.', '평가결과를 제출하시겠습니까?'],
      callback: handleClickFinalConfirm,
    });
  };

  const handleClickCancel = () => {
    closeModal();
  };

  return (
    <Modal.Container>
      <Modal.Header title='평가결과 제출' onClickClose={closeModal} />
      <Modal.Separate />
      <Modal.Body>
        <DescriptionUl>
          <DescriptionLi>
            나에게 배정된 모든 분야에 대한 평가 결과를 일괄로 제출완료
            처리합니다.
          </DescriptionLi>
          <DescriptionLi>
            제출완료 후에는 현재 화면에 보이는 분야를 포함하여 나에게 배정된
            모든 분야에 대해서 평가결과를 수정할 수 없습니다.
          </DescriptionLi>
        </DescriptionUl>
      </Modal.Body>
      <Modal.Separate />
      {/* TODO: 평가 완료 지원자 수 API나오면 개발 */}
      {/* <Modal.Body>평가 완료 지원자</Modal.Body>
      <Modal.Separate /> */}
      <Modal.Footer
        onClickConfirm={handleClickConfirm}
        onClickCancel={handleClickCancel}
        confirmText='결과 제출'
      />
    </Modal.Container>
  );
};
