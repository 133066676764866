import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 800px;
  background: ${({ theme }) => theme.colors['gray-50']};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-align: center;
  ${({ theme }) => theme.body.b2};
  color: ${({ theme }) => theme.colors['gray-700']};
`;
