import styled from '@emotion/styled';
import { FLAT_TAG_SHAPE, type FlatTagShape } from './flatTag.constant';

interface ContainerProps {
  shape?: FlatTagShape;
}

export const Container = styled.div<ContainerProps>`
  padding: 1px 6px;
  height: 20px;
  color: ${({ theme }) => theme.colors['gray-600']};
  background-color: ${({ theme }) => theme.colors['gray-100']};
  border-radius: ${({ theme, shape }) =>
    shape === FLAT_TAG_SHAPE.ROUND ? '30px' : theme.radius.radius50};

  display: flex;
  justify-content: center;
  align-items: center;
`;
