import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type GridCellProps = {
  isHover?: boolean;
  isCheckedRow?: boolean;
};

export const gridCellBasicStyle = ({
  theme,
  isHover,
  isCheckedRow,
}: { theme: Theme } & GridCellProps) => {
  const background = (() => {
    if (isHover && !isCheckedRow) return theme.colors['gray-80'];
    if (isCheckedRow) return theme.colors['primary-50'];
    return 'white';
  })();

  return css`
    border-right: 1px solid ${theme.colors['gray-300']};
    border-bottom: 1px solid ${theme.colors['gray-300']};
    background: ${background};
  `;
};

export const BodyCheckboxCellInner = styled.div<GridCellProps>`
  height: 100%;
  /* padding: 0 16px; */

  display: flex;
  justify-content: center;
  align-items: center;

  ${gridCellBasicStyle};
`;

export const BodyDropdownCellInner = styled.div<GridCellProps>`
  padding: 14px 29px;

  display: flex;

  ${gridCellBasicStyle}
`;

export const BodyCellInner = styled.div<GridCellProps>`
  height: 100%;
  padding: 0 16px;

  display: flex;
  align-items: center;

  ${gridCellBasicStyle};
`;
