import { Container } from './style';

const SearchPage = () => {
  return (
    <Container>
      <h1>지원자 검색</h1>
    </Container>
  );
};

export default SearchPage;
