import { Dropdown } from '@ats/ui';
import * as S from './left.style.ts';

interface SectorListEmptyProps {
  isOpen: boolean;
}
export const SectorListEmpty = ({ isOpen }: SectorListEmptyProps) => {
  return (
    <Dropdown.ItemContainer
      isOpen={isOpen}
      style={{ height: '240px', width: '340px' }}
    >
      <Dropdown.TextField />
      <Dropdown.Separate />
      <S.EmptyArea>
        <S.EmptyText>먼저 공고를 선택해주세요.</S.EmptyText>
      </S.EmptyArea>
    </Dropdown.ItemContainer>
  );
};
