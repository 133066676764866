import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const HeaderRightSection = styled.div`
  display: flex;
  gap: 8px;
`;

export const StepListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ICalTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: 8px;
  color: ${({ theme }) => theme.colors['gray-700']};
  background-color: white;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  padding: 13px 16px 16px 16px;

  position: absolute;
  top: 10px;
  right: -22px;
`;
