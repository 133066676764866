import type { PageKey } from '@constants/pageKey.constant';
import { atomFamily } from 'recoil';

export const currentPageAtomFamily = atomFamily<number, PageKey>({
  key: 'currentPageAtom',
  default: 1,
});
export const pageSizeAtomFamily = atomFamily<number, PageKey>({
  key: 'pageSizeAtom',
  default: 5,
});
export const lastPageAtomFamily = atomFamily<number, PageKey>({
  key: 'lastPageAtom',
  default: 1,
});
export const recordCountAtomFamily = atomFamily<number, PageKey>({
  key: 'recordCountAtom',
  default: 0,
});
