import { Blue, Green } from './colors';
import { SharedColors } from './shared';

export const PrimaryColors = {
  ['primary-50']: 'var(--color-primary-50)',
  ['primary-100']: 'var(--color-primary-100)',
  ['primary-200']: 'var(--color-primary-200)',
  ['primary-300']: 'var(--color-primary-300)',
  ['primary-400']: 'var(--color-primary-400)',
  ['primary-500']: 'var(--color-primary-500)',
  ['primary-600']: 'var(--color-primary-600)',
  ['primary-700']: 'var(--color-primary-700)',
  ['primary-800']: 'var(--color-primary-800)',
  ['primary-900']: 'var(--color-primary-900)',
  ...SharedColors,
};

export const PrimaryGreen = {
  ['primary-50']: Green['green-50'],
  ['primary-100']: Green['green-100'],
  ['primary-200']: Green['green-200'],
  ['primary-300']: Green['green-300'],
  ['primary-400']: Green['green-400'],
  ['primary-500']: Green['green-500'],
  ['primary-600']: Green['green-600'],
  ['primary-700']: Green['green-700'],
  ['primary-800']: Green['green-800'],
  ['primary-900']: Green['green-900'],
  ...SharedColors,
} as const;

export const PrimaryBlue = {
  ['primary-50']: Blue['blue-50'],
  ['primary-100']: Blue['blue-100'],
  ['primary-200']: Blue['blue-200'],
  ['primary-300']: Blue['blue-300'],
  ['primary-400']: Blue['blue-400'],
  ['primary-500']: Blue['blue-500'],
  ['primary-600']: Blue['blue-600'],
  ['primary-700']: Blue['blue-700'],
  ['primary-800']: Blue['blue-800'],
  ['primary-900']: Blue['blue-900'],
  ...SharedColors,
} as const;
