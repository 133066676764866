import { Checkbox, Table } from '@ats/ui';
import {
  currentPageResumeSnListAtom,
  currentPageScreeningValuerSnListAtom,
  freezePanesIndexAtom,
  isAllPageCheckAtom,
} from '@stores/atoms/evaluation/document.atom';
import type { CSSProperties, ChangeEvent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as S from '../../gridBody.style';

interface GridBodyColCheckboxProps {
  isHover: boolean;
  isCheckedRow: boolean;
  recruitNoticeSn: number;
  recruitSectorSn: number;
  resumeSn: number;
  screeningApplySectorSn: number;
  screeningValuerSn: number;
  index: number;
}

export const GridBodyColCheckbox = ({
  isHover,
  isCheckedRow,
  recruitNoticeSn,
  recruitSectorSn,
  resumeSn,
  screeningApplySectorSn,
  screeningValuerSn,
  index,
}: GridBodyColCheckboxProps) => {
  const [currentPageCheck, setCurrentPageCheck] = useRecoilState(
    currentPageScreeningValuerSnListAtom,
  );
  const [currentPageResumeSnList, setCurrentPageResumeSnList] = useRecoilState(
    currentPageResumeSnListAtom,
  );

  const allPageCheck = useRecoilValue(isAllPageCheckAtom);
  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    if (currentPageResumeSnList.includes(Number(e.target.dataset.resumesn))) {
      setCurrentPageResumeSnList((prev) => {
        return prev.filter(
          (resumesn) => resumesn !== Number(e.target.dataset.resumesn),
        );
      });

      setCurrentPageCheck((prev) => {
        return prev.filter(
          (resumesn) => resumesn !== Number(e.target.dataset.screeningvaluersn),
        );
      });

      return;
    }

    setCurrentPageCheck((prev) => [
      ...prev,
      Number(e.target.dataset.screeningvaluersn),
    ]);
    setCurrentPageResumeSnList((prev) => [
      ...prev,
      Number(e.target.dataset.resumesn),
    ]);
  };

  const [freezePanesIndex] = useRecoilState(freezePanesIndexAtom);

  const isFreeze = index <= freezePanesIndex;
  const freezeStyle: CSSProperties = isFreeze
    ? {
        verticalAlign: 'middle',
        position: 'sticky',
        left: 0,
      }
    : {};

  return (
    <Table.Cell style={{ ...freezeStyle, height: '48px' }}>
      <S.BodyCheckboxCellInner isHover={isHover} isCheckedRow={isCheckedRow}>
        <Checkbox
          style={{ gap: 0 }}
          onChange={handleChangeCheckbox}
          checked={currentPageCheck.includes(screeningValuerSn) || allPageCheck}
          disabled={allPageCheck}
          value={screeningValuerSn}
          data-recruitnoticesn={recruitNoticeSn}
          data-recruitsectorsn={recruitSectorSn}
          data-resumesn={resumeSn}
          data-screeningapplysectorsn={screeningApplySectorSn}
          data-screeningvaluersn={screeningValuerSn}
        />
      </S.BodyCheckboxCellInner>
    </Table.Cell>
  );
};
