import { ReactComponent as DefaultProfileImg } from '@assets/interview/profile_default.svg';
import { PrimaryColors } from '@ats/foundation';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, Icon, SIZE } from '@ats/ui';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip';
import { ICON_VIEW_MAP } from '@constants/evaluation.constant';
import { useTheme } from '@emotion/react';
import { useResumeForm } from '@hooks/common/useResumeForm';
import type { InterviewResume } from '@repositories/evaluation/interview/interview.dto';
import {
  isEvaluationRNBOpenAtom,
  isVideoInterviewAtom,
  screeningResumeSnAtom,
} from '@stores/atoms/evaluation/interview/interview.atom';
import {
  recruitNoticeCodeSnAtom,
  recruitNoticeSnAtom,
  recruitSectorSnAtom,
  screeningValuerSnAtom,
} from '@stores/atoms/evaluation/interview/interview.eval.atom';
import type { MouseEventHandler } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AbsenceCard from './AbsenceCard';
import { FinalEvaluationSection } from './FinalEvaluationSection';
import VideoInterviewButton from './VideoInterviewButton/VideoInterviewButton';
import * as S from './card.style';
import { useCardData } from './useCardData';

interface Props extends InterviewResume {}

const Card = (props: Props) => {
  const {
    name,
    age,
    iconViewList,
    resumeDisplayNo,
    resumeSn,

    recruitNoticeSn,
    recruitNoticeName,
    recruitSectorSn,
    recruitSectorName,
    screeningApplySectorSn,

    interviewStartDatetime,
    interviewClosingDatetime,
    interviewPlaceName,
    interviewGroupName,
    pictureFileUrl,
    absenceYn,

    finalValuerYn,
    screeningValuerSn,
    screeningResultCode,
    screeningType,

    interviewStatusCode,
    screeningResumeSn,
  } = props;
  const videoInterviewProps = {
    screeningResumeSn,
    interviewStatusCode,
    interviewStartDatetime,
  };
  const theme = useTheme();
  const { screeningSn } = useParams();

  const { interviewDate, iconList } = useCardData({
    interviewStartDatetime,
    interviewClosingDatetime,
    iconViewList,
  });

  const { RenderResumeForm, handleSubmit: openResumeWindow } = useResumeForm({
    resumeSn,
    screeningValuerSn,
    recruitNoticeSn,
    recruitSectorSn,
    screeningSn: screeningSn!,
    screeningType,
    screeningApplySectorSn,
  });

  const noticeRef = useRef<HTMLDivElement>(null);
  const sectorRef = useRef<HTMLDivElement>(null);
  const isVideoInterview = useRecoilValue(isVideoInterviewAtom);
  const setIsEvaluationRNBOpen = useSetRecoilState(isEvaluationRNBOpenAtom);

  const setScreeningValuerSn = useSetRecoilState(screeningValuerSnAtom);
  const setScreeningResumeSn = useSetRecoilState(screeningResumeSnAtom);
  const setRecruitNoticeSn = useSetRecoilState(recruitNoticeSnAtom);
  const setRecruitNoticeCodeSn = useSetRecoilState(recruitNoticeCodeSnAtom);
  const setRecruitSectorSn = useSetRecoilState(recruitSectorSnAtom);

  const handleClickDetailedEvaluation: MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();
    setScreeningValuerSn(screeningValuerSn);
    setScreeningResumeSn(screeningResumeSn);
    setRecruitNoticeSn(recruitNoticeSn);
    // setRecruitNoticeCodeSn(recruitNoticeCodeSn);
    setRecruitSectorSn(recruitSectorSn);
    setIsEvaluationRNBOpen(true);
  };

  if (absenceYn) return <AbsenceCard {...props} />;

  const getTooltipWidth = (height: number | undefined) => {
    if (!height) return 0;
    if (height < 80) return height + 8;
    else return height;
  };

  return (
    <S.Container onClick={openResumeWindow}>
      <S.BasicWrapper>
        <S.UpperSection>
          <S.Profile>
            <S.ProfileInfo>
              <S.ProfileName>{name}</S.ProfileName>
              <S.ProfileAge>{age ?? '비어있음'}</S.ProfileAge>
            </S.ProfileInfo>
            <S.ProfileIconGroup>
              {iconList?.map((iconName) => (
                <HeadlessTooltip
                  key={iconName}
                  content={
                    <S.CardToolTip>
                      {ICON_VIEW_MAP[iconName].label}
                    </S.CardToolTip>
                  }
                >
                  <Icon
                    name={ICON_VIEW_MAP[iconName].name}
                    color={PrimaryColors['primary-600']}
                  />
                </HeadlessTooltip>
              ))}
            </S.ProfileIconGroup>
          </S.Profile>
          <S.ResumeInfo>
            <S.ResumeDisplayNumber>{resumeDisplayNo}</S.ResumeDisplayNumber>
          </S.ResumeInfo>
        </S.UpperSection>

        <S.MiddleSection>
          <S.Recruit>
            <HeadlessTooltip
              wrap={(noticeRef.current?.clientWidth ?? 0) > 80}
              width={getTooltipWidth(noticeRef.current?.clientWidth)}
              content={<S.CardToolTip>{recruitNoticeName}</S.CardToolTip>}
            >
              <S.RecruitNotice ref={noticeRef}>
                {recruitNoticeName}
              </S.RecruitNotice>
            </HeadlessTooltip>
            <HeadlessTooltip
              wrap={(sectorRef.current?.clientWidth ?? 0) > 80}
              width={getTooltipWidth(sectorRef.current?.clientWidth)}
              content={<S.CardToolTip>{recruitSectorName}</S.CardToolTip>}
            >
              <S.RecruitSector ref={sectorRef}>
                {recruitSectorName}
              </S.RecruitSector>
            </HeadlessTooltip>
          </S.Recruit>
          <S.Interview>
            <S.InterviewDate>
              <Icon
                name={'icon_event_18_line'}
                color={theme.colors['gray-600']}
              />
              <div>{interviewDate}</div>
            </S.InterviewDate>
            {isVideoInterview ? (
              <div style={{ height: '22px' }} />
            ) : (
              <>
                <S.InterviewPlace>
                  <Icon
                    name={'icon_pin_18_line'}
                    color={theme.colors['gray-600']}
                  />
                  <div>{interviewPlaceName ?? '장소 미배정'}</div>
                </S.InterviewPlace>
                <S.InterviewGroup>
                  <Icon
                    name={'icon_people_18_line'}
                    color={theme.colors['gray-600']}
                  />
                  {interviewGroupName ?? '면접조 미배정'}
                </S.InterviewGroup>
              </>
            )}
          </S.Interview>
        </S.MiddleSection>

        <S.LowerSection>
          {isVideoInterview ? (
            <VideoInterviewButton {...videoInterviewProps} />
          ) : (
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={SIZE.SM}
              color={BUTTON_COLORS.THEMA}
              rightIcon={'icon_arrow_right_18_line'}
              style={{ width: '100px', paddingRight: '8px' }}
              onClick={handleClickDetailedEvaluation}
            >
              상세평가
            </Button>
          )}

          {pictureFileUrl ? (
            <S.ProfileImg>
              <img src={pictureFileUrl} alt='profile_img' />
            </S.ProfileImg>
          ) : (
            <DefaultProfileImg />
          )}
        </S.LowerSection>
      </S.BasicWrapper>
      {finalValuerYn && (
        <>
          <S.Divider />
          <FinalEvaluationSection
            screeningValuerSn={screeningValuerSn}
            screeningResultCode={screeningResultCode}
          />
        </>
      )}
      <RenderResumeForm />
    </S.Container>
  );
};

export default Card;
