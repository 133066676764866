import styled from '@emotion/styled';

export const FilterContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }

  justify-content: space-between;
  align-items: center;

  width: 100%;
  gap: 4px;
  height: 100%;
  background: ${({ theme }) => theme.colors['gray-800']};

  padding: 4px 16px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  gap: 8px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  gap: 8px;
`;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  gap: 4px;
`;
