import { Text } from '@ats/ui';
import FlatTag from '@components/common/FlatTag/FlatTag';
import { useTheme } from '@emotion/react';
import type { ScreeningInterview } from '@repositories/evaluation/interview/interview.dto';
import { isVideoInterviewAtom } from '@stores/atoms/evaluation/interview/interview.atom';
import {
  interviewDateTypeAtom,
  interviewEndDateTimeAtom,
  interviewPlaceSnAtom,
  interviewStartDateTimeAtom,
} from '@stores/atoms/evaluation/interview/interview.date.atom';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { INTERVIEW_DATE_TYPE } from '../../interview.constant';
import { InProgress, formatTimeString } from '../sidebar.utils';
import * as S from './timeItem.style';

export const TimeItem = ({ interview }: { interview: ScreeningInterview }) => {
  const theme = useTheme();
  const { placeSn, placeName, startTime, endTime, resumeCount } = interview;
  const isVideoInterview = useRecoilValue(isVideoInterviewAtom);
  const [interviewDateType, setInterviewDateType] = useRecoilState(
    interviewDateTypeAtom,
  );
  const [interviewStartDateTime, setInterviewStartDateTime] = useRecoilState(
    interviewStartDateTimeAtom,
  );
  const [interviewEndDateTime, setInterviewEndDateTime] = useRecoilState(
    interviewEndDateTimeAtom,
  );
  const [interviewPlaceSn, setInterviewPlaceSn] =
    useRecoilState(interviewPlaceSnAtom);

  const isActive =
    interviewDateType === INTERVIEW_DATE_TYPE.DATE_TIME_PLACE &&
    interviewStartDateTime === startTime &&
    interviewEndDateTime === endTime &&
    interviewPlaceSn === placeSn;

  const isTimeAssigned = startTime && endTime;
  const isInProgress = isTimeAssigned ? InProgress(startTime, endTime) : false;
  const timeText = useMemo(
    () =>
      isTimeAssigned
        ? `${formatTimeString(startTime)} - ${formatTimeString(endTime)}`
        : '시간 미배정',
    [isTimeAssigned],
  );

  const handleClick = () => {
    setInterviewDateType(INTERVIEW_DATE_TYPE.DATE_TIME_PLACE);
    setInterviewStartDateTime(startTime);
    setInterviewEndDateTime(endTime);
    setInterviewPlaceSn(placeSn);
  };

  const getItemLabel = () => {
    if (isVideoInterview) return '화상면접';
    if (placeName) return placeName;
    return '장소 미배정';
  };

  return (
    <S.Container
      onClick={handleClick}
      isActive={isActive}
      isInProgress={isInProgress}
    >
      <S.LeftSection>
        <Text variant={'b2'}>{timeText}</Text>
        <Text
          variant={'b3'}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '110px',
          }}
        >
          {getItemLabel()}
        </Text>
      </S.LeftSection>
      <FlatTag
        color={isActive ? theme.colors['blue-600'] : theme.colors['gray-600']}
        backgroundColor={
          isActive ? theme.colors['blue-100'] : theme.colors['gray-100']
        }
      >
        <Text variant='b3'>{resumeCount}명</Text>
      </FlatTag>
    </S.Container>
  );
};
