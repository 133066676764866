import type { PageUtil } from '@repositories/common/pageUtil.dto';
import {
  InterviewCurrentPageAtom,
  InterviewLastPageAtom,
  InterviewPageSizeAtom,
  InterviewRecordCountAtom,
} from '@stores/atoms/evaluation/interview/interview.page.atom';
import { selector } from 'recoil';

export const InterviewPageUtilSelector = selector<PageUtil>({
  key: 'InterviewPageUtilSelector',
  get: ({ get }) => {
    const currentPage = get(InterviewCurrentPageAtom);
    const pageSize = get(InterviewPageSizeAtom);
    const lastPage = get(InterviewLastPageAtom);
    const recordCount = get(InterviewRecordCountAtom);

    return { currentPage, pageSize, lastPage, recordCount };
  },
});
