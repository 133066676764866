import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { CustomerInfoRsDTO } from './customer.dto';
import { customerRepository } from './customer.repository';

export const useCustomerInfoQuery = (): UseSuspenseQueryResult<
  CustomerInfoRsDTO,
  AxiosError
> =>
  useSuspenseQuery({
    queryKey: ['customerInfo'],
    queryFn: customerRepository.getCustomerInfo,
  });
