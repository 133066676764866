import { ReactComponent as Bubble } from '@assets/q&a/flat_bubble_pending_mono.svg';
import type { FC } from 'react';
import { Container, Wrapper } from './style';

const NotFound: FC = () => {
  return (
    <Container>
      <Wrapper>
        <Bubble />
        <span>
          현재 설정된 검색조건에
          <br />
          해당되는 공지사항이 없습니다.
        </span>
      </Wrapper>
    </Container>
  );
};

export default NotFound;
