import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { ExamRepository } from './exam.repository';

const examRepository = new ExamRepository('');

export const useExamScreeningResultFileDownload = (
  request: string,
): UseQueryResult<AxiosResponse, AxiosError> => {
  return useQuery({
    queryKey: [request],
    queryFn: () => {
      return examRepository.examScreeningResultFileDownload(request);
    },
    enabled: false,
  });
};
