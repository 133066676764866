export const Space = {
  toJSON() {
    return {
      50: '4px',
      75: '6px',
      100: '8px',
      125: '10px',
      150: '12px',
      200: '16px',
      250: '20px',
      300: '24px',
      400: '32px',
      500: '40px',
    };
  },
  toJS() {
    return {
      space50: '4px',
      space75: '6px',
      space100: '8px',
      space125: '10px',
      space150: '12px',
      space200: '16px',
      space250: '20px',
      space300: '24px',
      space400: '32px',
      space500: '40px',
    };
  },
};
