import styled from '@emotion/styled';

export const Container = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    padding: 32px 0px;
    width: 64px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 32px 8px;
    width: 224px;
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  border-right: 1px solid ${({ theme }) => theme.colors['gray-300']};
  flex-shrink: 0;
`;

export const Nav = styled.nav<{ hasBottomLine?: boolean }>`
  box-sizing: border-box;
  padding: 16px 0;
`;

export const Item = styled.span<{ isActive?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    align-items: center;
  }

  height: 44px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radius.radius50};
  cursor: pointer;

  ${({ theme }) => theme.body.b2};

  :hover {
    background: ${({ theme }) => theme.colors['gray-100']};
  }

  background: ${({ isActive, theme }) => isActive && theme.colors['gray-100']};

  span {
    margin-left: 8px;
  }
`;

export const MobileItem = styled(Item)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }

  width: max-content;
`;

export const AccordionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-around;
  font-size: 14px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors['gray-300']};
`;

export const Tooltip = styled.div`
  background-color: white;
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors['gray-300']};
  border-radius: 8px;
`;
