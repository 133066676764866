import { Spin } from '@ats/ui';
import { useScreeningInterviewResumeListQuery } from '@repositories/evaluation/interview/interview.query.ts';
import { isEvaluationRNBOpenAtom } from '@stores/atoms/evaluation/interview/interview.atom.ts';
import { isFilterAppliedSelector } from '@stores/selectors/evaluation/interview.filter.selector.ts';
import { interviewBaseSelector } from '@stores/selectors/evaluation/interview.selector.ts';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CardGrid from '../CardGrid/CardGrid.tsx';
import EmptyInterview from '../EmptyInterview/EmptyInterview.tsx';
import Footer from '../Footer/Footer.tsx';
import Sidebar from '../Sidebar/Sidebar.tsx';
import EvaluationRNB from './EvaluationRNB/EvaluationRNB.tsx';
import Header from './Header.tsx';
import * as S from './main.style.ts';
import { useInterviewMainPrefetch } from './useInterviewMainPrefetch.ts';

const Main = () => {
  const { screeningSn } = useParams();
  const isFilterApplied = useRecoilValue(isFilterAppliedSelector);
  useInterviewMainPrefetch(screeningSn!);

  const resumeBaseRq = useRecoilValue(interviewBaseSelector(screeningSn!));
  const { data } = useScreeningInterviewResumeListQuery(resumeBaseRq);

  const isEvaluationRNBOpen = useRecoilValue(isEvaluationRNBOpenAtom);

  if (!data.interviewResumeList.length && !isFilterApplied)
    return <EmptyInterview />;

  return (
    <S.Container>
      <Sidebar />
      <S.Main>
        <Header />
        <Suspense fallback={<Spin size={'md'} color={'blue'} />}>
          <CardGrid />
        </Suspense>
        <Footer />
      </S.Main>
      {isEvaluationRNBOpen && <EvaluationRNB />}
    </S.Container>
  );
};

export default Main;
