import { useScreeningDocumentGridQuery } from '@repositories/evaluation/document/document.query';
import {
  currentPageAtom,
  currentPageResumeSnListAtom,
  currentPageScreeningValuerSnListAllAtom,
  currentPageScreeningValuerSnListAtom,
  evaluationItemInfosAtom,
  evaluationResultModifyAllowYnAtom,
  gridFilterCounterAtom,
  gridStateSelector,
  isAllPageCheckAtom,
  isGridFilterModalOpenAtom,
  isRenderEvaluationAllButtonAtom,
  isRenderEvaluationResultSubmitButtonAtom,
  pageSizeAtom,
  resultCodeMappingsAtom,
  screeningNoticeSnAtom,
  screeningSectorSnAtom,
  screeningSnAtom,
  totalApplicantCountAtom,
} from '@stores/atoms/evaluation/document.atom';
import {
  gridFilterBlackAtom,
  gridFilterEvaluationItemsAtom,
  gridFilterFinalEvaluateListAtom,
  gridFilterInterestAtom,
  gridFilterReadAtom,
  gridFilterTotalEvaluationAtom,
  gridFilterUnReadAtom,
  gridFilterUnUploadFileAtom,
  gridFilterUploadFileAtom,
} from '@stores/atoms/evaluation/document.filter';
import { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

export const useGrid = () => {
  const gridRq = useRecoilValue(gridStateSelector);
  const { data: gridData } = useScreeningDocumentGridQuery(gridRq);

  const resetValuerSnList = useResetRecoilState(
    currentPageScreeningValuerSnListAtom,
  );
  const resetValuerSnListAll = useResetRecoilState(
    currentPageScreeningValuerSnListAllAtom,
  );
  const resetResumeSnList = useResetRecoilState(currentPageResumeSnListAtom);
  const resetIsAllPageCheck = useResetRecoilState(isAllPageCheckAtom);

  const resetGridFilterCounter = useResetRecoilState(gridFilterCounterAtom);
  const resetIsGridFilterModalOpen = useResetRecoilState(
    isGridFilterModalOpenAtom,
  );
  const resetEvaluationResultModifyAllowYn = useResetRecoilState(
    evaluationResultModifyAllowYnAtom,
  );
  const resetIsRenderEvaluationResultSubmitButton = useResetRecoilState(
    isRenderEvaluationResultSubmitButtonAtom,
  );
  const resetIsRenderEvaluationAllButton = useResetRecoilState(
    isRenderEvaluationAllButtonAtom,
  );
  const resetCurrentPage = useResetRecoilState(currentPageAtom);
  const resetPageSize = useResetRecoilState(pageSizeAtom);
  const resetTotalApplicantCount = useResetRecoilState(totalApplicantCountAtom);

  /** 필터 */
  const resetGridFilterRead = useResetRecoilState(gridFilterReadAtom);
  const resetGridFilterUnRead = useResetRecoilState(gridFilterUnReadAtom);
  const resetGridFilterInterest = useResetRecoilState(gridFilterInterestAtom);
  const resetGridFilterBlack = useResetRecoilState(gridFilterBlackAtom);
  const resetGridFilterEvaluationItems = useResetRecoilState(
    gridFilterEvaluationItemsAtom,
  );
  const resetGridFilterTotalEvaluation = useResetRecoilState(
    gridFilterTotalEvaluationAtom,
  );
  const resetGridFilterFinalEvaluateList = useResetRecoilState(
    gridFilterFinalEvaluateListAtom,
  );
  const resetGridFilterUploadFile = useResetRecoilState(
    gridFilterUploadFileAtom,
  );
  const resetGridFilterUnUploadFile = useResetRecoilState(
    gridFilterUnUploadFileAtom,
  );

  const screeningSn = useRecoilValue(screeningSnAtom);
  const noticeSn = useRecoilValue(screeningNoticeSnAtom);
  const sectorSn = useRecoilValue(screeningSectorSnAtom);

  useEffect(() => {
    resetValuerSnList();
    resetResumeSnList();
    resetValuerSnListAll();
    resetIsAllPageCheck();
    resetGridFilterCounter();
    resetIsGridFilterModalOpen();
    resetEvaluationResultModifyAllowYn();
    resetIsRenderEvaluationResultSubmitButton();
    resetIsRenderEvaluationAllButton();
    resetCurrentPage();
    resetPageSize();
    resetTotalApplicantCount();
  }, [noticeSn, sectorSn]);

  useEffect(() => {
    resetGridFilterRead();
    resetGridFilterUnRead();
    resetGridFilterInterest();
    resetGridFilterBlack();
    resetGridFilterEvaluationItems();
    resetGridFilterTotalEvaluation();
    resetGridFilterFinalEvaluateList();
    resetGridFilterUploadFile();
    resetGridFilterUnUploadFile();
  }, [screeningSn]);

  const setCurrentPageScreeningValuerSnListAll = useSetRecoilState(
    currentPageScreeningValuerSnListAllAtom,
  );
  const setResultCodeMappings = useSetRecoilState(resultCodeMappingsAtom);
  const setEvaluationItemInfos = useSetRecoilState(evaluationItemInfosAtom);
  const setTotalApplicantCount = useSetRecoilState(totalApplicantCountAtom);
  useEffect(() => {
    setCurrentPageScreeningValuerSnListAll(
      gridData.response.content.map(
        (applicant) => applicant.serialNumberInfo.screeningValuerSn,
      ),
    );

    setResultCodeMappings(gridData.screeningResultCodeMappings);
    setEvaluationItemInfos(gridData.evaluationItemInfos);
    setTotalApplicantCount(gridData.response.totalElements);
  }, [gridData, setCurrentPageScreeningValuerSnListAll, setResultCodeMappings]);

  // const messageReceiveFn = useCallback((event: MessageEvent) => {
  //   if (event.origin !== import.meta.env.VITE_API_URL) return;
  //   queryClient.invalidateQueries({
  //     queryKey: [documentKeys.evaluateDocumentGrid],
  //   });
  // }, []);
  // useEffect(() => {
  //   window.addEventListener('message', messageReceiveFn);
  //   return () => {
  //     window.removeEventListener('message', messageReceiveFn);
  //   };
  // }, [messageReceiveFn]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const scrollHeight =
    windowWidth <= 1100
      ? { height: `calc(100vh - 198px)` }
      : { height: `calc(100vh - 162px)` };

  return {
    gridData,
    scrollHeight,
  };
};
