import type { SearchKeywordType } from '@constants/main.constant';
import { SEARCH_KEYWORD_TYPE } from '@constants/main.constant';
import type { PageKey } from '@constants/pageKey.constant';
import { atomFamily } from 'recoil';

export const searchKeywordAtomFamily = atomFamily<string, PageKey>({
  key: 'searchKeywordAtomFamily',
  default: '',
});
export const searchKeywordTypeAtomFamily = atomFamily<
  SearchKeywordType,
  PageKey
>({
  key: 'searchKeywordTypeAtomFamily',
  default: SEARCH_KEYWORD_TYPE.TITLE_CONTENT,
});
