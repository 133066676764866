import { DIRECTION, Scroll, Text } from '@ats/ui';
import type { ChildRefHandle } from '@hooks/evaluation/grid-screening/useGridFilter';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { EvaluationList } from '../EvaluationList';
import * as S from '../gridFilterModal.style';

export const EvaluationItemsFilter = forwardRef(
  (_, ref: ForwardedRef<ChildRefHandle>) => {
    return (
      <>
        <S.Divider />

        <S.Column>
          <Text variant={'b2'} bold>
            평가 항목
          </Text>
          <Scroll
            containerStyle={{ height: '100%', width: '110%' }}
            scrollDirection={DIRECTION.Y}
            isOverflowScrollable
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                gap: '6px',
                margin: '2px',
              }}
            >
              <EvaluationList ref={ref} />
            </div>
          </Scroll>
        </S.Column>
      </>
    );
  },
);
