import { Alert } from '@ats/ui';
import { PORTAL_ID } from '@constants/portal.constant';
import { useAlert } from '@hooks/shared/useAlert';
import { alertAtom } from '@stores/atoms/modal.atom';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue } from 'recoil';

export const CommonAlert = () => {
  const alertState = useRecoilValue(alertAtom);
  const { closeAlert } = useAlert();

  useEffect(() => {
    const $body = document.querySelector('body');
    if (alertState.isOpen) {
      $body!.style.overflow = 'hidden';
    } else {
      $body!.style.overflow = '';
    }
  }, [alertState.isOpen]);

  if (!alertState.isOpen) return <></>;

  const handleClickCancel = () => {
    closeAlert();
  };

  const handleClickConfirm = () => {
    if (alertState.callback) {
      alertState.callback();
    }
    closeAlert();
  };

  const contentRender = (content: string | string[]) => {
    if (Array.isArray(content)) {
      return content.map((p) => <p>{p}</p>);
    }

    return content;
  };

  return createPortal(
    <>
      <Alert.Container style={{ width: '440px' }}>
        {alertState.title && <Alert.Header title={alertState.title} />}
        {alertState.content && (
          <Alert.Body>{contentRender(alertState.content)}</Alert.Body>
        )}
        <Alert.Footer
          cancelText={alertState.cancelText}
          confirmText={alertState.confirmText}
          onClickCancel={handleClickCancel}
          onClickConfirm={handleClickConfirm}
        />
      </Alert.Container>
    </>,
    document.getElementById(PORTAL_ID.ALERT)!,
  );
};
