import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100vw;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1632px;
  margin: 0 auto;
`;
