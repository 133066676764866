import { BUTTON_COLORS, Button, SIZE } from '@ats/ui';
import { MODAL_KEY } from '@constants/modal.constant';
import { useTheme } from '@emotion/react';
import { useModal } from '@hooks/shared/useModal';
import * as S from './right.style';

export const SubmitEvaluationResult = () => {
  const theme = useTheme();
  const { openModal } = useModal();

  const handleClickOpenModalEvaluationResultSubmit = () => {
    openModal(MODAL_KEY.EVALUATION_RESULT_SUBMIT);
  };

  return (
    <Button
      size={SIZE.SM}
      color={BUTTON_COLORS.THEMA}
      rightIcon={'icon_arrow_right_18_line'}
      onClick={handleClickOpenModalEvaluationResultSubmit}
      // style={{
      //   background: theme.colors['blue-100'],
      //   color: theme.colors['blue-600'],
      // }}
    >
      <S.Span>평가 결과 제출</S.Span>
    </Button>
  );
};
