import { BaseRepository } from '@ats/repositories';
import axios from 'axios';
import type {
  GetValuerSecurityOathRqDTO,
  ScreeningInfoRsDTO,
  ScreeningNoticeListRsDTO,
  ScreeningRecruitSectorListRsDTO,
  UpdateFinalEvaluationRqDTO,
  UpdateReadStatusRqDTO,
} from './common.dto';

export class CommonEvaluationRepository extends BaseRepository {
  /* ------------------------------- 모든 전형에서 사용 ------------------------------- */
  /** 기본적인 전형 정보 */
  public async getScreeningInfo(request: FormData) {
    const { data } = await super.post<ScreeningInfoRsDTO>(
      `/mrs2/eval/getScreeningInfo`,
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }
  public async getValuerSecurityOath(request: GetValuerSecurityOathRqDTO) {
    const { data } = await super.post(
      '/cus/evaluation/getValuerSecurityOath',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }

  /* --------------------------- 서류, 시험, 온라인PT에서 사용 --------------------------- */
  public async keepLogin() {
    const { data } = await super.get('/cus/keepLogin');

    return data;
  }

  /** 공고 리스트 */
  public async getScreeningNoticeList(request: FormData) {
    const { data } = await super.post<ScreeningNoticeListRsDTO[]>(
      `/mrs2/eval/screeningNoticeList`,
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }
  /** 채용 분야 리스트 */
  public async getScreeningRecruitSectorList(request: FormData) {
    const { data } = await super.post<ScreeningRecruitSectorListRsDTO[]>(
      '/mrs2/eval/screenigRecruitSectorList',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }

  /** 지원자를 관심/블랙지원자 등록한 평가자 수 */
  public async checkMyApplicantMarkingCnt(request: FormData) {
    const { data } = await super.post(
      '/mrs2/manager/applicant/checkMyApplicantMarkingCnt',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  }

  /** 관심/블랙지원자 등록 */
  public async saveApplicantMarking(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/saveApplicantMarking',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  }

  /** 관심/블랙지원자 해제 */
  public async deleteApplicantMarking(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/deleteApplicantMarking',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  }

  /** 지원자 평가 업데이트 */
  public async updateEvaluation(request: FormData) {
    const { data } = await super.post('/mrs2/eval/updateEvaluation', request, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  }

  /** 지원자 평가결과 업데이트 */
  public async updateFinalEvaluation(request: UpdateFinalEvaluationRqDTO) {
    const { data } = await super.post(
      '/mrs2/eval/updateFinalEvaluation',
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return data;
  }

  /** 평가결과 최종 제출 */
  public async submitEvaluationResult(request: FormData) {
    const { data } = await super.post(
      '/mrs2/eval/submitEvaluationResult',
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  }

  /** 열람/미열람 업데이트 */
  public async updateReadStatus(request: UpdateReadStatusRqDTO) {
    const { data } = await super.post('/mrs2/eval/updateReadStatusV2', request);

    return data;
  }

  /** 관리자 업로드 파일 다운로드 */
  public async downloadManagerUploadFile(request: { resumeSn: number }) {
    const params = new URLSearchParams();
    params.append('resumeSn', String(request.resumeSn));

    const res = await axios.post(
      '/mrs2/manager/applicant/noneEncrypt/downloadManagerUploadFiles',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob',
      },
    );

    return res;
  }
}
