import { PAGES } from '@ats/constants';
import { BUTTON_COLORS, BUTTON_VARIANTS, Button, SIZE } from '@ats/ui';
import { FC } from 'react';
import { useModal } from './hooks';
import {
  ButtonArea,
  Container,
  Description,
  ErrorMessage,
  Title,
  Wrapper,
} from './style';

interface Props {
  articleSn: string;
  onClose: () => void;
}
const QnAPasswordModal: FC<Props> = ({ articleSn, onClose }) => {
  const { register, handleSubmit, onSubmit, errors } = useModal({ articleSn });

  return (
    <Container
      method='POST'
      action={PAGES.INTERVIEWER_EVALUATOR.QUESTION_AND_ANSWER.READ(articleSn)}
      onSubmit={handleSubmit(async (data, event) => {
        event?.preventDefault();
        await onSubmit(data);
      })}
    >
      <Wrapper>
        <Title>비공개글입니다.</Title>
        <Description>비밀번호를 입력해주세요.</Description>
        <input type={'text'} {...register('password')} name={'password'} />
        {/*<TextField*/}
        {/*  {...register('password')}*/}
        {/*  name='password'*/}
        {/*  placeholder='입력해주세요.'*/}
        {/*  size={SIZE.SM}*/}
        {/*  error={!!errors.password}*/}
        {/*/>*/}
        {errors.password && (
          <ErrorMessage>{errors.password.message}</ErrorMessage>
        )}
        <ButtonArea>
          <Button
            size={SIZE.SM}
            variant={BUTTON_VARIANTS.SECONDARY}
            type={'button'}
            onClick={onClose}
          >
            취소
          </Button>
          <Button size={SIZE.SM} color={BUTTON_COLORS.THEMA} type='submit'>
            확인
          </Button>
        </ButtonArea>
      </Wrapper>
    </Container>
  );
};

export default QnAPasswordModal;
