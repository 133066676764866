import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

export const Content = styled.div`
  display: block;
  position: absolute;
  z-index: 200;

  padding: 4px 6px;
  border-radius: 4px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;
