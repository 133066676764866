import { atom } from 'recoil';

export const InterviewCurrentPageAtom = atom({
  key: 'InterviewPageCurrentPageAtom',
  default: 1,
});
export const InterviewPageSizeAtom = atom({
  key: 'InterviewPagePageSizeAtom',
  default: 20,
});
export const InterviewLastPageAtom = atom({
  key: 'InterviewPageLastPageAtom',
  default: 1,
});
export const InterviewRecordCountAtom = atom({
  key: 'InterviewPageRecordCountAtom',
  default: 0,
});
