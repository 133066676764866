import { fileNameRegex } from '@constants/regex';
import type { AxiosResponse } from 'axios';

export const fileDownload = (response: AxiosResponse) => {
  const blob = new Blob([response.data], { type: response.data.type });
  const blobURL = window.URL.createObjectURL(blob);

  const contentDisposition = response.headers['content-disposition'];

  let match: string[] = [];
  if (contentDisposition !== null) {
    match = fileNameRegex.exec(contentDisposition) as string[];
  }

  const downloadLinkEl = document.createElement('a');
  downloadLinkEl.href = blobURL;
  if (match) {
    downloadLinkEl.download = decodeURIComponent(match[1]);
  }

  document.body.appendChild(downloadLinkEl);
  downloadLinkEl.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 60000);
  downloadLinkEl.remove();
};
