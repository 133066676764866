import {
  interviewKeywordAtom,
  interviewKeywordTypeAtom,
} from '@stores/atoms/evaluation/interview/interview.atom';
import {
  interviewDateNavTypeAtom,
  interviewDateTypeAtom,
} from '@stores/atoms/evaluation/interview/interview.date.atom';
import {
  interviewFilterBlackAtom,
  interviewFilterInterestAtom,
  interviewFilterNotEvaluateAtom,
  interviewFilterNotPassAtom,
  interviewFilterPassAtom,
  interviewFilterSparePassAtom,
  localInterviewFilterBlackAtom,
  localInterviewFilterInterestAtom,
  localInterviewFilterNotEvaluateAtom,
  localInterviewFilterNotPassAtom,
  localInterviewFilterPassAtom,
  localInterviewFilterSparePassAtom,
} from '@stores/atoms/evaluation/interview/interview.filter.atom';
import {
  InterviewCurrentPageAtom,
  InterviewLastPageAtom,
  InterviewPageSizeAtom,
  InterviewRecordCountAtom,
} from '@stores/atoms/evaluation/interview/interview.page.atom';
import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

export const useCleanStore = () => {
  const refreshInterviewFilterInterest = useResetRecoilState(
    interviewFilterInterestAtom,
  );
  const refreshInterviewFilterBlack = useResetRecoilState(
    interviewFilterBlackAtom,
  );
  const refreshLocalInterviewFilterInterest = useResetRecoilState(
    localInterviewFilterInterestAtom,
  );
  const refreshLocalInterviewFilterBlack = useResetRecoilState(
    localInterviewFilterBlackAtom,
  );
  const refreshInterviewFilterNotEvaluate = useResetRecoilState(
    interviewFilterNotEvaluateAtom,
  );
  const refreshInterviewFilterNotPass = useResetRecoilState(
    interviewFilterNotPassAtom,
  );
  const refreshInterviewFilterPass = useResetRecoilState(
    interviewFilterPassAtom,
  );
  const refreshInterviewFilterSparePass = useResetRecoilState(
    interviewFilterSparePassAtom,
  );
  const refreshLocalInterviewFilterNotEvaluate = useResetRecoilState(
    localInterviewFilterNotEvaluateAtom,
  );
  const refreshLocalInterviewFilterNotPass = useResetRecoilState(
    localInterviewFilterNotPassAtom,
  );
  const refreshLocalInterviewFilterPass = useResetRecoilState(
    localInterviewFilterPassAtom,
  );
  const refreshLocalInterviewFilterSparePass = useResetRecoilState(
    localInterviewFilterSparePassAtom,
  );

  const refreshInterviewCurrentPage = useResetRecoilState(
    InterviewCurrentPageAtom,
  );
  const refreshInterviewPageSize = useResetRecoilState(InterviewPageSizeAtom);
  const refreshInterviewLastPage = useResetRecoilState(InterviewLastPageAtom);
  const refreshInterviewRecordCount = useResetRecoilState(
    InterviewRecordCountAtom,
  );
  const refreshInterviewKeyword = useResetRecoilState(interviewKeywordAtom);
  const refreshInterviewKeywordType = useResetRecoilState(
    interviewKeywordTypeAtom,
  );
  const refreshInterviewDateType = useResetRecoilState(interviewDateTypeAtom);
  const refreshInterviewDateNavType = useResetRecoilState(
    interviewDateNavTypeAtom,
  );

  interviewDateNavTypeAtom;

  useEffect(() => {
    refreshInterviewFilterInterest();
    refreshInterviewFilterBlack();
    refreshLocalInterviewFilterInterest();
    refreshLocalInterviewFilterBlack();

    refreshInterviewFilterNotEvaluate();
    refreshInterviewFilterNotPass();
    refreshInterviewFilterPass();
    refreshInterviewFilterSparePass();

    refreshLocalInterviewFilterNotEvaluate();
    refreshLocalInterviewFilterNotPass();
    refreshLocalInterviewFilterPass();
    refreshLocalInterviewFilterSparePass();

    refreshInterviewCurrentPage();
    refreshInterviewPageSize();
    refreshInterviewLastPage();
    refreshInterviewRecordCount();

    refreshInterviewKeyword();
    refreshInterviewKeywordType();
    refreshInterviewDateType();
    refreshInterviewDateNavType();
  }, []);
};
