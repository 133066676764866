import styled from '@emotion/styled';

export const StepListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const EmptyStepListContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors['gray-50']};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const Container = styled.article`
  cursor: pointer;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.space500};
  display: flex;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: 12px;
  background-color: white;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors['gray-800']};
    background-color: ${({ theme }) => theme.colors['gray-80']};
  }
`;
export const LeftSection = styled.div`
  display: flex;
  gap: 16px;
`;
export const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const NoticeInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 4px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors['gray-400']};
  flex-shrink: 0;
`;
