import { useDropdown } from '@ats/ui';
import { useSearch } from '@hooks/common/useSearch.ts';
import type { ScreeningNoticeListRsDTO } from '@repositories/evaluation/common/common.dto.ts';
import {
  screeningNoticeListAtom,
  screeningNoticeSnAtom,
  selectedNoticeAtom,
} from '@stores/atoms/evaluation/document.atom.ts';
import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

export const useNotice = () => {
  const { screeningSn } = useParams();

  const screeningNoticeList = useRecoilValue(screeningNoticeListAtom);

  const [searchText, setSearchText] = useState('');
  const { searchResultList } = useSearch<ScreeningNoticeListRsDTO, 'name'>(
    searchText,
    screeningNoticeList,
    'name',
  );

  const [screeningNoticeSn, setScreeningNoticeSn] = useRecoilState(
    screeningNoticeSnAtom,
  );

  const [selectedNotice, setSelectedNotice] =
    useRecoilState(selectedNoticeAtom);
  useEffect(() => {
    if (screeningNoticeList.length === 1) {
      setSelectedNotice(screeningNoticeList[0].name);
    }
  }, [screeningNoticeList, screeningNoticeSn]);

  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();

  const handleClickNotice = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.textContent) {
      setSelectedNotice(e.currentTarget.textContent);
      setScreeningNoticeSn(Number(e.currentTarget.value));
    }

    handleCloseDropdown();
  };

  const handleChangeNoticeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return {
    searchText,
    selectedNotice,
    searchResultList,
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleClickNotice,
    handleChangeNoticeSearch,
  };
};
