import { Table } from '@ats/ui';
import { GridBodyColOpenResume } from '@components/evaluation/grid-screening/grid/grid-body/grid-body-row/grid-body-col/GridBodyColOpenResume.tsx';
import { GridBodyColReadStatus } from '@components/evaluation/grid-screening/grid/grid-body/grid-body-row/grid-body-col/GridBodyColReadStatus.tsx';
import { GRID_FIELD_TYPE, READ_STATUS } from '@constants/evaluation.constant';
import { formatBasicDateString } from '@helpers/date.util.ts';
import { useUpdateReadStatusQuery } from '@repositories/evaluation/common/common.query';
import type {
  Applicant,
  SerialNumberInfo,
} from '@repositories/evaluation/document/document.dto';
import {
  columnListAtom,
  currentPageScreeningValuerSnListAtom,
} from '@stores/atoms/evaluation/document.atom';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GridBodyCol } from './grid-body-col/GridBodyCol';
import { GridBodyColCheckbox } from './grid-body-col/GridBodyColCheckbox';
import { GridBodyColEval } from './grid-body-col/GridBodyColEval';
import { GridBodyColFinalEval } from './grid-body-col/GridBodyColFinalEval';
import { GridBodyColManagerUploadFileButton } from './grid-body-col/GridBodyColManagerUploadFileButton';
import { GridBodyExamScreeningResult } from './grid-body-col/GridBodyExamScreeningResult';
import { GridBodyName } from './grid-body-col/GridBodyName';

interface GridBodyRowProps {
  applicant: Applicant;
  applicantSerialNumberInfo: SerialNumberInfo;
}

export const GridBodyRow = ({
  applicant,
  applicantSerialNumberInfo,
}: GridBodyRowProps) => {
  const columnList = useRecoilValue(columnListAtom);

  const [isHover, setIsHover] = useState(false);
  const handleHoverMouse = useCallback(() => {
    setIsHover(true);
  }, []);
  const handleOutMouse = useCallback(() => {
    setIsHover(false);
  }, []);

  const checkedApplicant = useRecoilValue(currentPageScreeningValuerSnListAtom);

  const isCheckedRow = checkedApplicant.includes(
    applicantSerialNumberInfo.screeningValuerSn,
  );

  /** 열람/미열람 */
  const [localReadStatus, setLocalReadStatus] = useState<READ_STATUS>();
  useEffect(() => {
    setLocalReadStatus(applicant.screeningValuerReadStatus);
  }, [applicant.screeningValuerReadStatus]);

  const { mutate: updateReadStatusMutate } = useUpdateReadStatusQuery();
  const handleClickToggleReadStatus = (modifyYn: boolean) => {
    updateReadStatusMutate({
      evalItemModifyYn: modifyYn, // 열람/미열람 클릭 시에는 무조건 false 전달한다. 평가 시에는 true를 전달한다.
      screeningValuerSnList: [applicantSerialNumberInfo.screeningValuerSn],
    });
    setLocalReadStatus((prev) =>
      prev === READ_STATUS.READ ? READ_STATUS.UNREAD : READ_STATUS.READ,
    );

    // 평가했을 때 hover를 풀어주기 위한 코드
    setIsHover(false);
  };

  const renderGridBodyCols = (column: GRID_FIELD_TYPE) => {
    switch (column) {
      case GRID_FIELD_TYPE.screeningValuerReadStatus:
        return (
          <GridBodyColReadStatus
            handleClickToggleReadStatus={handleClickToggleReadStatus}
            localReadStatus={localReadStatus}
          />
        );

      case GRID_FIELD_TYPE.resumeDisplayNo:
        return (
          <GridBodyColOpenResume
            {...applicantSerialNumberInfo}
            handleClickToggleReadStatus={handleClickToggleReadStatus}
          >
            {applicant.resumeDisplayNo}
          </GridBodyColOpenResume>
        );
      case GRID_FIELD_TYPE.name:
        return (
          <GridBodyName
            applicant={applicant}
            applicantSerialNumberInfo={applicantSerialNumberInfo}
            handleClickToggleReadStatus={handleClickToggleReadStatus}
          />
        );

      case GRID_FIELD_TYPE.genderFlag:
        return <>{applicant.genderFlag === 'M' ? '남' : '여'}</>;

      case GRID_FIELD_TYPE.createDatetime:
        return <>{formatBasicDateString(applicant.createDatetime)}</>;
      case GRID_FIELD_TYPE.submitDatetime:
        return <>{formatBasicDateString(applicant.submitDatetime)}</>;
      case GRID_FIELD_TYPE.screeningResumeAssignmentDateTime:
        return (
          <>
            {formatBasicDateString(applicant.screeningResumeAssignmentDateTime)}
          </>
        );

      case GRID_FIELD_TYPE.managerUploadFile:
        return (
          <GridBodyColManagerUploadFileButton
            resumeSn={applicantSerialNumberInfo.resumeSn}
            managerUploadFile={applicant.managerUploadFile}
          />
        );

      case GRID_FIELD_TYPE.finalResultCode:
        return (
          <GridBodyColFinalEval
            valuerSn={applicantSerialNumberInfo.screeningValuerSn}
            applicantFinalEval={applicant.finalResultCode}
            handleClickToggleReadStatus={handleClickToggleReadStatus}
          />
        );

      case GRID_FIELD_TYPE.examScreeningResult:
        return (
          <GridBodyExamScreeningResult
            uid={applicant.examScreeningResult as string}
          />
        );

      case GRID_FIELD_TYPE.recruitNoticeName:
      case GRID_FIELD_TYPE.applySectorName:
        return <span>{applicant.recruitNoticeName}</span>

      case GRID_FIELD_TYPE.age:
      case GRID_FIELD_TYPE.finalAcademyCode:
      case GRID_FIELD_TYPE.finalAcademySchool:
      case GRID_FIELD_TYPE.finalAcademyMajor:
      case GRID_FIELD_TYPE.career:
      case GRID_FIELD_TYPE.evaluationPeriod:
        return applicant[column] ? applicant[column] : '-';

      default:
        return (
          <GridBodyColEval
            evalItemSn={column}
            ratingScaleSn={
              applicant.evaluationRatingScaleMap &&
              applicant.evaluationRatingScaleMap[column]
            }
            applicantScreeningValuerSn={
              applicantSerialNumberInfo.screeningValuerSn
            }
            handleClickToggleReadStatus={handleClickToggleReadStatus}
          />
        );
    }
  };

  return (
    <Table.Row onMouseOver={handleHoverMouse} onMouseOut={handleOutMouse}>
      <GridBodyColCheckbox
        isHover={isHover}
        isCheckedRow={isCheckedRow}
        recruitNoticeSn={applicantSerialNumberInfo.recruitNoticeSn}
        recruitSectorSn={applicantSerialNumberInfo.recruitSectorSn}
        resumeSn={applicantSerialNumberInfo.resumeSn}
        screeningApplySectorSn={
          applicantSerialNumberInfo.screeningApplySectorSn
        }
        screeningValuerSn={applicantSerialNumberInfo.screeningValuerSn}
        index={0}
      />
      {columnList.map((column, columnIndex) => (
        <GridBodyCol
          key={columnIndex}
          index={columnIndex + 1}
          isHover={isHover}
          isCheckedRow={isCheckedRow}
        >
          {renderGridBodyCols(column)}
        </GridBodyCol>
      ))}
    </Table.Row>
  );
};
