import { Tab, Text } from '@ats/ui';
import { extractDate } from '@helpers/date.util';
import type { ScreeningInterviewScheduleListRsDTO } from '@repositories/evaluation/interview/interview.dto';
import { getScreeningInterviewScheduleListQueryData } from '@repositories/evaluation/interview/interview.query';
import {
  interviewDateAtom,
  interviewDateIndexAtom,
  interviewDateNavTypeAtom,
  interviewDateTypeAtom,
  isTodayInterviewAtom,
} from '@stores/atoms/evaluation/interview/interview.date.atom';
import { InterviewCurrentPageAtom } from '@stores/atoms/evaluation/interview/interview.page.atom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { DATE_NAV_STATE, INTERVIEW_DATE_TYPE } from '../interview.constant';
import { AllDateItem, DateItem } from './DateItem/DateItem';
import * as S from './sidebar.style';
import { useSortedDateList } from './useSortedDateList';

const Sidebar = () => {
  const [dateNavType, setDateNavType] = useRecoilState(
    interviewDateNavTypeAtom,
  );
  const { screeningSn } = useParams();
  const dateList = getScreeningInterviewScheduleListQueryData({
    screeningSn: screeningSn!,
  });
  const sortedDateList = useSortedDateList(dateList ?? []);
  const resetCurrentPage = useResetRecoilState(InterviewCurrentPageAtom);

  if (!dateList) return <></>;

  return (
    <S.Container>
      <S.Nav>
        <Tab
          style={{ width: '76px', height: '40px' }}
          onClick={() => {
            setDateNavType(DATE_NAV_STATE.ALL);
            resetCurrentPage();
          }}
          isActive={dateNavType === DATE_NAV_STATE.ALL}
        >
          <Text variant='b1' bold>
            전체
          </Text>
        </Tab>
        <Tab
          style={{ width: '76px', height: '40px' }}
          onClick={() => {
            setDateNavType(DATE_NAV_STATE.DATE);
            resetCurrentPage();
          }}
          isActive={dateNavType === DATE_NAV_STATE.DATE}
        >
          <Text variant='b1' bold>
            일자
          </Text>
        </Tab>
      </S.Nav>
      {dateNavType === DATE_NAV_STATE.ALL ? (
        <AllDateList dateList={sortedDateList} />
      ) : (
        <DateList dateList={sortedDateList} />
      )}
    </S.Container>
  );
};

const AllDateList = ({
  dateList,
}: {
  dateList: ScreeningInterviewScheduleListRsDTO;
}) => {
  const setInterviewDateTypeAtom = useSetRecoilState(interviewDateTypeAtom);
  useEffect(() => {
    setInterviewDateTypeAtom(INTERVIEW_DATE_TYPE.ALL);
  }, []);

  return (
    <S.SidebarList>
      <AllDateItem dateList={dateList} />
      {dateList.map((dateItem) => (
        <DateItem
          key={dateItem.date}
          date={dateItem.date}
          interviewList={dateItem.interviewList}
        />
      ))}
    </S.SidebarList>
  );
};

const DateList = ({
  dateList,
}: {
  dateList: ScreeningInterviewScheduleListRsDTO;
}) => {
  const setInterviewDateTypeAtom = useSetRecoilState(interviewDateTypeAtom);
  const setInterviewDate = useSetRecoilState(interviewDateAtom);
  const setIsTodayInterview = useSetRecoilState(isTodayInterviewAtom);
  const [interviewDateIndex, setInterviewDateIndex] = useRecoilState(
    interviewDateIndexAtom,
  );

  useEffect(() => {
    setInterviewDate(dateList[0].date);
    setIsTodayInterview(undefined);
    setInterviewDateIndex(0);
    setInterviewDateTypeAtom(INTERVIEW_DATE_TYPE.DATE);

    for (let i = 0; i < dateList.length; i++) {
      if (!dateList[i].date) continue;

      if (
        new Date(extractDate(dateList[i].date!)).toDateString() ===
        new Date().toDateString()
      ) {
        setInterviewDate(dateList[i].date);
        setIsTodayInterview(i);
        setInterviewDateIndex(i);
        break;
      }

      if (new Date(extractDate(dateList[i].date!)) > new Date()) {
        setInterviewDate(dateList[i].date);
        setInterviewDateIndex(i);
        break;
      }
    }
  }, []);

  if (!dateList[interviewDateIndex]) return <></>;
  const { date, interviewList } = dateList[interviewDateIndex];

  return (
    <S.SidebarList>
      <DateItem date={date} interviewList={interviewList} />
    </S.SidebarList>
  );
};

export default Sidebar;
