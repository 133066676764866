import { ReactComponent as Bubble } from '@assets/q&a/flat_bubble_pending_mono.svg';
import { Container, Wrapper } from './style';

const Empty = () => {
  return (
    <Container>
      <Wrapper>
        <Bubble />
        <span>등록된 공지사항이 없습니다.</span>
      </Wrapper>
    </Container>
  );
};

export default Empty;
