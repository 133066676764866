import {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
  Button,
  Dropdown,
  Icon,
  TextField,
  useDropdown,
} from '@ats/ui';
import {
  SEARCH_KEYWORD_TYPE,
  SEARCH_KEYWORD_TYPE_LIST,
  SEARCH_KEYWORD_TYPE_MAP,
} from '@constants/main.constant';
import { PAGE_KEY } from '@constants/pageKey.constant';
import { useTheme } from '@emotion/react';
import {
  searchKeywordAtomFamily,
  searchKeywordTypeAtomFamily,
} from '@stores/atoms/searchKeyword.atomFamily';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Container } from './style';
import { SIZE } from '@ats/ui';

const Search = () => {
  const {
    isOpen,
    dropdownContainerRef,
    handleToggleDropdown,
    handleCloseDropdown,
  } = useDropdown();
  const theme = useTheme();

  const [keyword, setKeyword] = useState('');
  const setKeywordStore = useSetRecoilState(
    searchKeywordAtomFamily(PAGE_KEY.NOTICE),
  );
  const [keywordType, setKeywordType] = useState(
    SEARCH_KEYWORD_TYPE.TITLE_CONTENT,
  );
  const setKeywordTypeStore = useSetRecoilState(
    searchKeywordTypeAtomFamily(PAGE_KEY.NOTICE),
  );

  const handleSearch = () => {
    setKeywordStore(keyword);
    setKeywordTypeStore(keywordType);
  };

  return (
    <Container>
      <Dropdown.Container ref={dropdownContainerRef}>
        <Dropdown.Trigger
          style={{ paddingRight: '8px' }}
          onClick={handleToggleDropdown}
          variant={BUTTON_VARIANTS.SECONDARY}
          size={SIZE.SM}
          rightSection={
            <Icon
              name={'icon_arrow_bottom_18_line'}
              size={20}
              style={{ minWidth: '20px' }}
              color={theme.colors['gray-800']}
            />
          }
        >
          {SEARCH_KEYWORD_TYPE_MAP[keywordType]}
        </Dropdown.Trigger>
        <Dropdown.ItemContainer
          isOpen={isOpen}
          style={{ width: '156px', height: '156px' }}
        >
          {SEARCH_KEYWORD_TYPE_LIST.map((item) => (
            <Dropdown.ButtonItem
              key={item}
              onClick={() => {
                handleCloseDropdown();
                setKeywordType(item);
              }}
            >
              {SEARCH_KEYWORD_TYPE_MAP[item]}
            </Dropdown.ButtonItem>
          ))}
        </Dropdown.ItemContainer>
      </Dropdown.Container>
      <TextField
        placeholder='입력해주세요'
        leftSection={
          <Icon
            color={theme.colors['gray-600']}
            name='icon_search_18_line'
            size={20}
            style={{ minWidth: '20px' }}
          />
        }
        size={SIZE.SM}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onPressEnter={handleSearch}
      />
      <Button
        variant={BUTTON_VARIANTS.PRIMARY}
        color={BUTTON_COLORS.THEMA}
        onClick={handleSearch}
        size={SIZE.SM}
      >
        검색
      </Button>
    </Container>
  );
};

export default Search;
