import styled from '@emotion/styled';

export const Container = styled.div`
  background: white;
  padding: ${({ theme }) =>
    theme.spacing.space125 + ' ' + theme.spacing.space300};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.space100};
  min-width: 100px;
`;
export const MiddleSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.space100};
`;
export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.space100};
`;
