import { TAB_VARIANTS, Tab } from '@ats/ui';
import { STEP_TYPE_CODE } from '@constants/evaluation.constant';
import { selectedStepTypeAtom } from '@stores/atoms/evaluation/evaluation.atom';
import { useRecoilState } from 'recoil';
import * as S from './stepTypeTab.style';

export const StepTypeTab = () => {
  const [selectedStepType, setSelectedStepType] =
    useRecoilState(selectedStepTypeAtom);
  const stepTypeList = [
    { label: '전체', value: STEP_TYPE_CODE.ALL },
    { label: '서류', value: STEP_TYPE_CODE.DOCUMENT },
    { label: '시험', value: STEP_TYPE_CODE.EXAM },
    { label: '면접', value: STEP_TYPE_CODE.INTERVIEW },
    { label: '온라인 PT', value: STEP_TYPE_CODE.ON_LINE_PT },
  ];

  return (
    <S.TabWrapper>
      {stepTypeList.map((stepType) => (
        <Tab
          tabVariant={TAB_VARIANTS.SECONDARY}
          key={stepType.value}
          onClick={() => setSelectedStepType(stepType.value)}
          isActive={stepType.value === selectedStepType}
          style={{
            width:
              stepType.value === STEP_TYPE_CODE.ON_LINE_PT ? '146px' : '108px',
          }}
        >
          {stepType.label}
        </Tab>
      ))}
    </S.TabWrapper>
  );
};
