import styled from '@emotion/styled';

export const Title = styled.p`
  ${({ theme }) => theme.body.b2}
`;

export const RecruitSectorArea = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

export const AlignArea = styled.div`
  margin-top: 16px;

  display: flex;
  flex-flow: column;
  gap: 8px;

  margin-bottom: 24px;
`;

export const AlignContentsArea = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
`;

export const Left = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  flex: 1;
`;
export const Right = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  flex: 1;
`;
