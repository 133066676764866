import { Text } from '@ats/ui';
import HeadlessTooltip from '@components/common/HeadlessTooltip/HeadlessTooltip';
import type { ReactNode } from 'react';
import { useMemo, type FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavItems, TopNavItems } from './config';
import * as S from './style';

export interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
  const { pathname } = useLocation();
  const rootPathname = useMemo(
    () => pathname.split('/').slice(0, 2).join('/'),
    [pathname],
  );

  return (
    <S.Container>
      <S.Nav>
        {TopNavItems.map(({ icon, value, key, to }) => {
          return (
            <Link key={key} to={to}>
              <S.Item isActive={key === rootPathname}>
                {icon}
                <span>{value}</span>
              </S.Item>
              <MobileItem
                navKey={key}
                value={value}
                rootPathname={rootPathname}
                icon={icon}
              />
            </Link>
          );
        })}
      </S.Nav>
      <S.Divider />
      <S.Nav>
        {BottomNavItems.map(({ icon, value, key, to }) => {
          return (
            <Link key={key} to={to}>
              <S.Item>
                {icon}
                {value}
              </S.Item>
              <MobileItem
                navKey={key}
                value={value}
                rootPathname={rootPathname}
                icon={icon}
              />
            </Link>
          );
        })}
      </S.Nav>
    </S.Container>
  );
};

interface MobileItemProps {
  navKey: string;
  value: ReactNode;
  rootPathname: string;
  icon: ReactNode;
}
const MobileItem = (props: MobileItemProps) => {
  const { navKey, value, rootPathname, icon } = props;
  return (
    <HeadlessTooltip
      content={
        <S.Tooltip>
          <Text variant={'b3'} bold>
            {value}
          </Text>
        </S.Tooltip>
      }
    >
      <S.MobileItem isActive={navKey === rootPathname}>{icon}</S.MobileItem>
    </HeadlessTooltip>
  );
};

export default Sidebar;
