import { Icon } from '@ats/ui';
import PortalHeadlessTooltip from '@components/common/PortalHeadlessTooltip/PortalHeadlessTooltip';
import { INTEREST_OR_BLACK } from '@constants/evaluation.constant';
import type {
  Applicant,
  SerialNumberInfo,
} from '@repositories/evaluation/document/document.dto';
import { useEffect, useState } from 'react';
import { GridBodyColOpenResume } from './GridBodyColOpenResume';
import * as S from './GridBodyName.style';

interface GridBodyNameProps {
  applicant: Applicant;
  applicantSerialNumberInfo: SerialNumberInfo;
  handleClickToggleReadStatus: (modifyYn: boolean) => void;
}

export const GridBodyName = ({
  applicant,
  applicantSerialNumberInfo,
  handleClickToggleReadStatus,
}: GridBodyNameProps) => {
  const [interestMembers, setInterestMembers] = useState<string[]>([]);
  const [blackMembers, setBlackMembers] = useState<string[]>([]);

  useEffect(() => {
    applicant.markingInfo?.forEach((info) => {
      if (info.markingType === INTEREST_OR_BLACK.INTEREST) {
        setInterestMembers((prev) => [...prev, info.markingMemberName]);
      }

      if (info.markingType === INTEREST_OR_BLACK.BLACK) {
        setBlackMembers((prev) => [...prev, info.markingMemberName]);
      }
    });
  }, [applicant]);

  return (
    <GridBodyColOpenResume
      {...applicantSerialNumberInfo}
      handleClickToggleReadStatus={handleClickToggleReadStatus}
    >
      {applicant.name}
      <S.IconArea>
        {interestMembers.length > 0 && (
          <PortalHeadlessTooltip
            content={
              <S.Tooltip>
                {interestMembers.join(',')}님이 관심지원자로 등록했습니다.
              </S.Tooltip>
            }
          >
            <Icon name='icon_star_18_filled' style={{ minWidth: '18px' }} />
          </PortalHeadlessTooltip>
        )}
        {blackMembers.length > 0 && (
          <PortalHeadlessTooltip
            content={
              <S.Tooltip>
                {blackMembers.join(',')}님이 블랙지원자로 등록했습니다.
              </S.Tooltip>
            }
          >
            <Icon name='icon_person_18_filled' style={{ minWidth: '18px' }} />
          </PortalHeadlessTooltip>
        )}
      </S.IconArea>
    </GridBodyColOpenResume>
  );
};
