import { EvaluationResultSubmitModal } from '@components/common/Modal/EvaluationResultSubmitModal';
import EvaluationOathModal from '@components/evaluation/evaluation/EvaluationOathModal/EvaluationOathModal';
import { InterestOrBlackModal } from '@components/evaluation/grid-screening/modal/InterestOrBlackModal/InterestOrBlackModal';
import { PrintEvaluationTableModal } from '@components/evaluation/grid-screening/modal/PrintEvaluationTableModal/PrintEvaluationTableModal';
import VideoInterviewerNameModal from '@components/evaluation/interview/Card/VideoInterviewButton/VideoInterviewerNameModal';
import InterviewPrintModal from '@components/evaluation/interview/Modal/InterviewPrintModal/InterviewPrintModal';
import type { jsx } from '@emotion/react';

export const MODAL_KEY_DOCUMENT = {
  INTEREST_BLACK: 'INTEREST_BLACK',
  PRINT_SCORE_GRID: 'PRINT_SCORE_GRID',
  EVALUATION_RESULT_SUBMIT: 'EVALUATION_RESULT_SUBMIT',
} as const;
export const MODAL_KEY_INTERVIEW = {
  VIDEO_INTERVIEW_NAME: 'VIDEO_INTERVIEW_NAME',
  INTERVIEW_PRINT: 'INTERVIEW_PRINT',
} as const;
export const MODAL_KEY_EVALUATION = {
  ICAL_URL_COPY: 'ICAL_URL_COPY',
  EVALUATION_OATH: 'EVALUATION_OATH',
} as const;

export const MODAL_KEY = {
  ...MODAL_KEY_DOCUMENT,
  ...MODAL_KEY_INTERVIEW,
  ...MODAL_KEY_EVALUATION,
} as const;
export type ModalKey = (typeof MODAL_KEY)[keyof typeof MODAL_KEY];

export const MODAL_COMPONENT_MAP = {
  [MODAL_KEY.INTEREST_BLACK]: InterestOrBlackModal,
  [MODAL_KEY.VIDEO_INTERVIEW_NAME]: VideoInterviewerNameModal,
  [MODAL_KEY.PRINT_SCORE_GRID]: PrintEvaluationTableModal,
  [MODAL_KEY.EVALUATION_RESULT_SUBMIT]: EvaluationResultSubmitModal,
  [MODAL_KEY.EVALUATION_OATH]: EvaluationOathModal,
  [MODAL_KEY.INTERVIEW_PRINT]: InterviewPrintModal,
} as Record<ModalKey, () => jsx.JSX.Element>;
