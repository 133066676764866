import { alertAtom } from '@stores/atoms/modal.atom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

export function useAlert() {
  const setAlertState = useSetRecoilState(alertAtom);
  const resetAlertState = useResetRecoilState(alertAtom);

  const openAlert = ({
    title,
    content,
    callback,
  }: {
    title: string;
    content: string | string[];
    callback?: () => void;
  }) => {
    setAlertState({
      isOpen: true,
      title,
      content,
      callback,
    });
  };
  const closeAlert = () => {
    resetAlertState();
  };

  return {
    openAlert,
    closeAlert,
  };
}
