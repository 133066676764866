import styled from '@emotion/styled';

export const DescriptionUl = styled.ul`
  padding-bottom: 20px;
`;

export const DescriptionLi = styled.li`
  color: ${({ theme }) => theme.colors['gray-600']};
  ${({ theme }) => theme.body.b2}

  &:before {
    padding-right: 10px;
    content: '-';
    color: #6a7684;
  }
`;
