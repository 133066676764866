export const Title = {
  toJSON() {
    return {
      '.t1': {
        'font-size': '24px',
        'line-height': '34px',
        'font-weight': '500',
      },
      '.t1-bold': {
        'font-size': '24px',
        'line-height': '34px',
        'font-weight': '700',
      },
      '.t2': {
        'font-size': '20px',
        'line-height': '30px',
        'font-weight': '500',
      },
      '.t2-bold': {
        'font-size': '20px',
        'line-height': '30px',
        'font-weight': '700',
      },
      '.t3': {
        'font-size': '18px',
        'line-height': '28px',
        'font-weight': '500',
      },
      '.t3-bold': {
        'font-size': '18px',
        'line-height': '28px',
        'font-weight': '700',
      },
    };
  },
  toJS() {
    return {
      t1: `
      font-size: 24px;
      line-height: 34px;
      font-weight: 500;
    `,
      ['t1-bold']: `
      font-size: 24px;
      line-height: 34px;
      font-weight: 700;
    `,
      t2: `
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    `,
      ['t2-bold']: `
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
    `,
      t3: `
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
    `,
      ['t3-bold']: `
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
    `,
    };
  },
};
